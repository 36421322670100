import React, { useState, useEffect } from 'react';
import {
  Button,
  SearchInputText,
  LoadingSpinner,
  ModalConfirmation,
  TableGrid,
  ColumnSelector,
  SelectForm,
  LabelForm,
  ButtonAccion,
} from '../../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  listarRegistrosM,
  eliminarRegistroM,
  obtenerRegistrosManualesUno,
  obtenerListadoEtiquetas,
  obtenerCaracPosTotal,
  cargaMasivaPythonP,
} from '../../../../../../services/public.service';
import {
  createFlujoCajaEtiquetaAdapter,
  createRegistroManualAdapter,
} from '../../../../../../adapters';
import Swal from 'sweetalert2';
import {
  excelDateToJSDate,
  exportToExcelRegManual,
  exportToExcelRegistroManual,
  formatString_MMDDYYYY_TO_DDMMYYYY,
  listaUniverso,
} from '../../../../../../common';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import {
  setListarRegistrosM,
  setSelectConceptoManual,
  limpiarCamposRegistroM,
  setFiltroListaRM,
  setListaIDRM,
} from '../../../../../../redux/states';
import { read, utils } from 'xlsx';
import { useNavigate } from 'react-router-dom';
import './../../../../../../index.css';
import {
  ColFlex,
  ContentButton,
  ContentOption,
  MenuAccion,
  RowAccion,
  RowFlex,
} from '../../../../../../styles';
import { Divider, Dropdown } from 'antd';
import { ModalImport } from './components';
import { Selected } from '../../../../../../common';
import { AccionColumna, columns, items } from './misc';

export const ListarRegistroPropio = (props) => {
  const [showModalEliminarRegistroM, setShowModalEliminarRegistroM] = useState(false);
  const [selectedRegistroM, setSelectedRegistroM] = useState([]);
  const [showModalImport, setShowModalImport] = useState(false);
  const [universo, setUniverso] = useState([listaUniverso[0]]);
  const [selectEtiquetaM, setSelectEtiquetaM] = useState([]);
  const [columnSelector, setColumnSelector] = useState([]);
  const [listaEtiquetaM, setListaEtiquetaM] = useState([]);
  const manualState = useSelector((store) => store.registroManual);
  const [loadingData, setLoadingData] = useState(false);
  const authState = useSelector((store) => store.auth);
  const [selectColumn, setSelectColumn] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [searchText, setSearchtext] = useState('');
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const titleExport = 'RegistroManualesData';
  const mapColumnsDownloadExcel = {
    Cliente: 'Nombre',
    NombreConcepto: 'Concepto',
    NombrePosicion: 'Posición',
    Importe: 'Monto',
    MonedaImporte: 'Moneda',
    FechaVcto: 'Fecha',
  };

  useEffect(() => {
    (async () => {
      dispatch(setListarRegistrosM([]));
      await loadEtiquetasM();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadListaRM(0);
    })();
  }, [
    manualState.searchText,
    manualState.searchArray,
    manualState.page,
    manualState.pageSize,
    universo,
  ]);

  const onAñadirRegistroM = (importado) => {
    dispatch(setSelectConceptoManual([]));
    dispatch(limpiarCamposRegistroM());
    navigate('/dashboard/flujo_caja/propio/crear');
  };

  const loadListaRM = async (importado) => {
    let inicioLista = manualState.page * manualState.pageSize;
    let finLista = (manualState.page + 1) * manualState.pageSize;
    let listID =
      importado == 1
        ? []
        : !manualState.searchBool
        ? manualState.listaRegistrosMID.slice(inicioLista, finLista)
        : [];

    const responseListaRegistrosM = await callEndpoint(
      listarRegistrosM({
        IDUsuario: authState.user.uid,
        IDUniverso: universo[0].value,
        Inicio: inicioLista,
        Fin: finLista,
        Filtro: manualState.searchText,
        Buscador: manualState.searchArray,
        Etiqueta: [],
        IDs: listID,
      })
    );
    var listaRegistroM = [],
      listaID = [],
      total = 0;
    if (responseListaRegistrosM.data) {
      for (
        let index = 0;
        index < responseListaRegistrosM.data.respuesta.aListaRegistrosAgrupado.length;
        index++
      ) {
        const element = responseListaRegistrosM.data.respuesta.aListaRegistrosAgrupado[index];
        let obj = createRegistroManualAdapter(element);
        let nombre = '';
        obj.Items.forEach((element2) => {
          obj[element2.Caracteristica] = element2.Valor;
        });
        var aEncontradoLlave = obj.Items.filter(function (item) {
          return item.Llave === 'X' && item.Valor !== '';
        });
        if (aEncontradoLlave.length > 0) {
          obj['Nombre'] = aEncontradoLlave[0].Valor;
        }
        listaRegistroM.push(obj);
      }
      total = responseListaRegistrosM.data.respuesta.totalReg;
      listaID = responseListaRegistrosM.data.respuesta.totalIDs;
    }
    setLoadingData(false);

    dispatch(setListarRegistrosM(listaRegistroM));
    if (listID.length == 0) {
      dispatch(setListaIDRM({ rowCount: total, listaRegistrosMID: listaID }));
    }
  };

  const loadEtiquetasM = async () => {
    const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
    var listarEtiquetasM = [];
    if (responseListarEtiquetas.data) {
      for (
        let index = 0;
        index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
        index++
      ) {
        const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
        var obj = createFlujoCajaEtiquetaAdapter(element);
        listarEtiquetasM.push(obj);
      }
    }
    setListaEtiquetaM(listarEtiquetasM);
  };

  const loadExtraColumn = async () => {
    const plus = [];
    var caracteristicas = await obtenerCaracPosTotal().call;
    caracteristicas.data.respuesta.aCaracPosSinRep.map((v) => {
      if (v != 'MonedaImporte' && v != 'Importe' && v != 'FechaVcto') {
        plus.push({
          field: v,
          headerName: v,
          flex: 1,
          minWidth: 160,
          renderCell: (params) => <>{params.value}</>,
          seleccionado: Selected.FALSE,
          columna: v,
          parsed: v,
          restricted: Selected.FALSE,
        });
      }
    });
    setColumnSelector([...columns, ...plus]);
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      setSearchtext(oEvent.target.value);
    } else {
      setSearchtext('');
    }
  };

  const handleChangeMultiKey = async (event, value) => {
    setSelectEtiquetaM(value);
  };

  const onClickEliminarRegistroM = () => {
    if (selectedRegistroM.length > 0) {
      setShowModalEliminarRegistroM(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onClickCopiarRegistroM = () => {
    if (selectedRegistroM.length == 1) {
      navigate('/dashboard/flujo_caja/propio/crear/' + selectedRegistroM[0]);
    } else if (selectedRegistroM.length > 1) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Solo se puede copiar un registro a la vez.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro a copiar.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onCloseModalEliminarRegistroM = () => {
    setShowModalEliminarRegistroM(false);
  };

  const onConfirmarEliminarRegistroM = async () => {
    var aListaRegistroMEliminar = [];
    selectedRegistroM.forEach((element) => {
      var o = {};
      o.IDRegistro = element;
      aListaRegistroMEliminar.push(o);
    });
    const responseEliminarRegistroM = await callEndpoint(
      eliminarRegistroM({ RegistroManualElim: aListaRegistroMEliminar })
    );
    setShowModalEliminarRegistroM(false);
    if (responseEliminarRegistroM == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseEliminarRegistroM.data.cabecera.statusCode === 200) {
        setSelectEtiquetaM([]);
        setSearchtext('');
        setSelectedRegistroM([]);
        loadListaRM(0);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseEliminarRegistroM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseEliminarRegistroM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const closeModalImport = (e) => {
    setShowModalImport(false);
  };

  const okModalImport = (file) => {
    const files = file;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        var wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          // Cambio de Raw a False para que venga el dato en tipo de string
          const rows3 = utils.sheet_to_json(wb.Sheets['RegistroManual'], { raw: true });

          // const jsonData = XLSX.utils.sheet_to_json(sheet);

          // Convertir fechas de mes dia año a dia mes año
          let letras = ['C', 'F'];
          rows3.forEach((row) => {
            debugger;
            // Convertir la celda de la columna especificada a su valor
            letras.forEach((element) => {
              if (wb.Sheets['RegistroManual'][element + (row.__rowNum__ + 1)].t == 'n') {
                row[wb.Sheets['RegistroManual'][element + 1].v] = excelDateToJSDate(
                  wb.Sheets['RegistroManual'][element + (row.__rowNum__ + 1)].v
                );
              }
            });
          });

          var responseEjecutarCargaMasiva = await callEndpoint(
            cargaMasivaPythonP({
              JSONMASIVO: rows3,
            })
          );
          if (responseEjecutarCargaMasiva == null) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Ocurrió un error en el servicio.',
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            if (responseEjecutarCargaMasiva.data) {
              if (responseEjecutarCargaMasiva.data.cabecera.statusCode === 200) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: responseEjecutarCargaMasiva.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
                setShowModalImport(false);
                setTimeout(() => {
                  loadEtiquetasM();
                  loadListaRM(1);
                }, 2000);
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: responseEjecutarCargaMasiva.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Ocurrió un error en el servicio.',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const onClickEditarRegistroM = async (row) => {
    let aLista = [];
    let filtroDetalleCaract = [];
    let filtroCabCaract = [];
    var oParam = {
      RegistroManualConsulta: {
        IDRegistro: row.IDRegistro,
      },
    };
    const responseObtRgManualUno = await callEndpoint(obtenerRegistrosManualesUno(oParam));
    if (responseObtRgManualUno == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseObtRgManualUno.data) {
        if (responseObtRgManualUno.data.cabecera.statusCode === 200) {
          var oInfoRegManualUno = responseObtRgManualUno.data.respuesta.aListaRegistrosAgrupado;
          if (oInfoRegManualUno.length > 0) {
            navigate('/dashboard/flujo_caja/propio/editar/' + row.IDRegistro);
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'No se encontró información.',
              showConfirmButton: false,
              timer: 4000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseObtRgManualUno.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const handleUniverso = (event) => {
    setUniverso([event]);
  };

  const ListarRegistroManual = async () => {
    await loadListaRM(0);
    await loadExtraColumn();
  };

  const onChangePagination = async (e, dato) => {
    debugger;
    let oParam = {};
    oParam.searchBool = false;
    switch (dato) {
      case 'page':
        oParam.page = e;
        break;
      case 'pageSize':
        oParam.pageSize = e;
        break;
      default:
        break;
    }
    await dispatch(setFiltroListaRM(oParam));
  };

  const onChangeFiltros = async (e, dato) => {
    debugger;
    let oParam = {};
    oParam.page = 0;
    oParam.searchBool = true;
    switch (dato) {
      case 'array':
        oParam.searchArray = e.items;
        break;
      case 'texto':
        oParam.searchText = e.target.value;
        break;
      default:
        break;
    }
    await dispatch(setFiltroListaRM(oParam));
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="300px"
            placeholder={'Buscar Registros Manuales...'}
            onChange={(e) => onChangeSearch(e)}
            filter={searchText}
            style={{ marginRight: '9px' }}
          />
          <LabelForm width={'50px'} title={'Universo : '}></LabelForm>
          <SelectForm
            width={'240px'}
            bValid={true}
            disableClearable={true}
            value={universo}
            handleChange={(evnt, v) => handleUniverso(v)}
            options={listaUniverso}
            placeholder="Seleccione Universo"
          />
          <LabelForm width={'60px'} title={'Etiquetas : '}></LabelForm>
          <SelectForm
            isMultiple={true}
            width={'240px'}
            value={selectEtiquetaM}
            handleChange={(e, v) => handleChangeMultiKey(e, v)}
            options={listaEtiquetaM}
            placeholder={'Etiquetas'}
          />
          <ContentButton>
            <RowAccion>
              <ColumnSelector
                allColumns={
                  columnSelector.filter((r) => r.parsed == 'Acciones').length > 0
                    ? columnSelector
                    : [...columnSelector, ...AccionColumna(onClickEditarRegistroM)]
                }
                setColumn={setSelectColumn}
                setAllColumn={setColumnSelector}
              />
              <Dropdown
                trigger={['click']}
                menu={{
                  items: items(
                    onClickCopiarRegistroM,
                    exportToExcelRegistroManual,
                    searchText.length === 0 && selectEtiquetaM.length == 0
                      ? manualState.listaRegistrosM
                      : manualState.filterListarRegistrosM,
                    setShowModalImport,
                    onClickEliminarRegistroM
                  ),
                }}
              >
                <ButtonAccion />
              </Dropdown>
              <Button action={onAñadirRegistroM} name="Agregar" isAction={true} type="primary" />
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={
          selectColumn.length > 0
            ? [...selectColumn.filter((r) => r.seleccionado == Selected.TRUE)]
            : [
                ...columns.filter((r) => r.seleccionado == Selected.TRUE),
                ...AccionColumna(onClickEditarRegistroM),
              ]
        }
        rows={
          // searchText.length === 0 && selectEtiquetaM.length == 0
          // ? manualState.listaRegistrosM
          // : manualState.filterListarRegistrosM
          manualState.listaRegistrosM
        }
        getRowId={(row) => row.IDRegistro}
        checkboxSelection={true}
        onSelectionModelChange={(oEvent) => {
          setSelectedRegistroM(oEvent);
        }}
        selectionModel={selectedRegistroM}
        // pageSize={15}
        //   rowCount={100}
        //Pagination
        //   pagination
        //   rowsPerPageOptions={[15]}
        //   paginationMode="Server"
        //   onPageChange={handlePageChange}
        //   page={1}
        //   loading={15}
        //
        onPageSizeChange={(e) => onChangePagination(e, 'pageSize')}
        pageSize={manualState.pageSize}
        rowCount={manualState.rowCount}
        pagination
        rowsPerPageOptions={[15, 25, 50, 100]}
        paginationMode="Server"
        onPageChange={(e) => onChangePagination(e, 'page')}
        page={manualState.page}
        loading={loading}
        // filtro
        filterMode="server"
        onFilterModelChange={(e) => onChangeFiltros(e, 'array')}
      />
      <ModalConfirmation
        showModal={showModalEliminarRegistroM}
        closeModal={(e) => onCloseModalEliminarRegistroM()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminarRegistroM(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      <ModalImport
        isOpen={showModalImport}
        loading={false}
        descargarPlantilla={(e) => exportToExcelRegManual(titleExport)}
        handleClose={closeModalImport}
        handleOk={okModalImport}
      />
      {loading === true || loadingData ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
