import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { theme } from '../../../common';
import { LoadingForm, SelectForm } from '../../../components';
import { CardDash, RowForm, Title } from '../../../styles';
import { useSelector } from 'react-redux';

export const SalesOverview = (props) => {
  // select
  const dashboardState = useSelector((store) => store.dashboard);
  const [versionSelected, setVersionSelected] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [serie, setSerie] = React.useState([]);
  const [categorie, setCategorie] = React.useState([]);

  useEffect(() => {
    debugger;
    if (props.data.length > 0 && props.version.length > 0) {
      setData(props.data);
      setVersionSelected([props.version[props.version.length - 1]]);
    }
    //  else {
    //   setData([]);
    //   setVersionSelected([]);
    // }
  }, [props.data, props.version]);

  useEffect(() => {
    if (versionSelected.length > 0 && data.length > 0) {
      filtrarDatos();
    }
  }, [versionSelected, data]);

  const filtrarDatos = () => {
    let listaData = [],
      listaFiltrada = [],
      listaDato = [],
      listaDatoComplete = [],
      listaLabel = [];
    data.map((a) => {
      if (versionSelected[0].Registros.includes(a.IDRegistro)) {
        let dato = a.Items.filter((a) => a.Caracteristica == 'Importe');
        let dato1 = a.Items.filter((a) => a.Caracteristica == 'FechaVcto');
        listaFiltrada.push({
          fecha: new Date(
            dato1[0].Valor.substring(6, 10),
            dato1[0].Valor.substring(3, 5) - 1,
            dato1[0].Valor.substring(0, 2)
          ).toLocaleDateString('en-GB'),
          importe: parseInt(dato[0].Valor),
        });
      }
    });
    listaFiltrada.sort(function (a, b) {
      a = a.fecha.split('/').reverse().join('');
      b = b.fecha.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });
    listaData = listaFiltrada.reduce((acumulador, valorActual) => {
      const elementoYaExiste = acumulador.find((elemento) => elemento.fecha == valorActual.fecha);
      if (elementoYaExiste) {
        return acumulador.map((elemento) => {
          if (elemento.fecha == valorActual.fecha) {
            return {
              ...elemento,
              importe: elemento.importe + valorActual.importe,
            };
          }

          return elemento;
        });
      }

      return [...acumulador, valorActual];
    }, []);

    let sumaDato = 0;
    listaData.map((b) => {
      if (b.fecha != 'Invalid Date') {
        sumaDato = sumaDato + b.importe;
        listaDato.push(b.importe);
        listaDatoComplete.push(sumaDato);
        listaLabel.push(b.fecha);
      }
    });
    setSerie([
      { name: versionSelected[0].label + ' ACUMULADO', data: listaDatoComplete },
      // { name: versionSelected[0].label + ' PERIODO', data: listaDato },
    ]);
    setCategorie(listaLabel);
  };

  const handleChange = (event) => {
    setVersionSelected(
      props.version.filter((r) => {
        return r.value == event.value;
      })
    );
  };

  // chart
  const optionscolumnchart = {
    chart: {
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      type: 'area',
      foreColor: '#adb0bb',
      fontFamily: theme.font.family,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: theme.colorsDashboard,
    xaxis: {
      categories: categorie,
      axisBorder: {
        show: false,
      },

      title: {
        text: 'Diario',
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
      title: {
        text: dashboardState.moneda,
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
    },
    events: {
      mounted: (chart) => {
        chart.windowResizeHandler();
      },
    },
  };

  return (
    <CardDash
      size={'large'}
      title={
        <RowForm>
          <Title>{props.titulo}</Title>
          <SelectForm
            style={{ marginLeft: 'auto' }}
            width={'40%'}
            value={versionSelected}
            handleChange={(evnt, v) => handleChange(v)}
            validate={true}
            options={props.version}
            placeholder={'Seleccione'}
            disableClearable={true}
          />
        </RowForm>
      }
    >
      {props.loading ? (
        <LoadingForm />
      ) : (
        <Chart options={optionscolumnchart} series={serie} type="area" height={300} />
      )}
    </CardDash>
  );
};
