export const items = (exportToExcelRegistroManual, data, onOpenModalImportRF) => [
  {
    label: 'Exportar',
    key: '1',
    onClick: () => exportToExcelRegistroManual(data),
  },
  {
    label: 'Importar',
    key: '2',
    onClick: () => onOpenModalImportRF(),
  },
];
