import { AutoComplete, Input } from 'antd';
import styled from 'styled-components';
import { theme } from '../common';

export const InputSearchForm = styled(Input.Search)`
  width: ${({ width }) => (width ? width : '98%')};
  min-height: 32px;
  height: 32px;
  padding: 0px;
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};
  .ant-input {
    border: 1px solid ${theme.colors.line} !important;
    border-color: ${theme.colors.line} !important;
    border-radius: 2px !important;
    box-shadow: none;
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    height: 32px;
    &::placeholder {
      color: ${theme.colors.text};
      opacity: 0.7;
    }
  }
  .ant-input:focus,
  .ant-input:hover {
    border-color: ${theme.colors.line} !important;
    box-shadow: none;
  }

  .ant-btn-primary {
    border-color: ${theme.colors.primary} !important;
    background: ${theme.colors.primary} !important;
    color: ${theme.colors.white} !important;
    min-height: 32px !important;
    border-radius: 0px 2px 2px 0px !important;
    svg {
      color: ${theme.colors.white} !important;
    }
  }
`;

export const AutoCompleteForm = styled(AutoComplete)`
  width: ${({ width }) => (width ? width : '98%')};
  min-height: 32px;
  height: 32px;
  padding: 0px;
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};

  .ant-select-selector {
    border: 2px solid ${theme.colors.line} !important;
    border-color: ${theme.colors.line} !important;
    border-radius: 2px !important;
    box-shadow: none;
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    height: 32px;
  }
  .ant-select-selection-placeholder {
    color: ${theme.colors.text};
    opacity: 0.7;
  }

  .ant-select-selection-search-input:focus,
  .ant-select-selection-search-input:hover {
    border-color: ${theme.colors.line} !important;
    box-shadow: none;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  position: relative;
  width: 100%;
  input[type='password'],
  input[type='text'] {
    padding: 12px 36px 12px 12px;
    box-sizing: border-box;
  }
`;
