export const TiempoPeriodosData = [
  {
    Id: 1,
    Codigo: 'D',
    value: 'D',
    Nombre: 'Días',
    label: 'Días',
    Habilitado: 1,
  },
  {
    Id: 2,
    Codigo: 'S',
    value: 'S',
    Nombre: 'Semana',
    label: 'Semana',
    Habilitado: 1,
  },
  {
    Id: 3,
    Codigo: 'M',
    value: 'M',
    Nombre: 'Mensual',
    label: 'Mensual',
    Habilitado: 1,
  },
];
