import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
export const LabelForm = (props) => {
  return (
    <Content width={props.width}>
      <Label
        text_align={props.text_align}
        weight={props.weight}
        color={props.color}
        obligatorio={props.obligatorio}
        disabled={props.disabled}
      >
        {props.title}
      </Label>
    </Content>
  );
};
export const Content = styled.div`
  width: ${({ width }) => (width ? width : '100%')}; //150px;
  display: flex;
  align-items: center;
`;
export const Label = styled.span`
  font-family: ${theme.font.family};
  font-weight: ${({ weight, obligatorio }) => (weight ? weight : obligatorio ? 'bold' : 'normal')};
  font-size: ${theme.font.size};
  color: ${({ color }) => (color ? color : 'black')};
  text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
`;
