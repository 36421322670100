import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  onChangeCollapseDetalleListaAgrupadaXSoluciones,
  onChangeCollapseSolucionesXSolucion,
  onSetDetalleListaAgrupadaXSoluciones,
} from '../../../../../../../redux/states';
import { useFetchAndLoad } from '../../../../../../../common/hooks';
import collect from 'collect.js';
import {
  Content,
  NumberTable,
  NumberTotalTable,
  TableContain,
  TableFC,
} from '../../../../../../../styles';

export const TableDetalleFlujoCajaSolucion = React.memo(function TableDetalleFlujoCajaSolucion(
  props
) {
  const dispatch = useDispatch();
  const personalizacionState = useSelector((store) => store.personalizacion);
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { oFlujoCajaAgregar } = flujoCajaState;
  const [objDrag, setObjDrag] = useState({});
  const [signoAllow, setSignoAllow] = useState(true);

  useEffect(() => {
    if (
      personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion == 'Formato')
        .length > 0
    ) {
      setSignoAllow(
        personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion == 'Formato')[0]
          .DatoPersonalizacion == 1
          ? true
          : false
      );
    }
  }, []);
  const handleClickSolucionCollapse = (oEvent, item, index) => {
    dispatch(onChangeCollapseSolucionesXSolucion({ index: index }));
    return;
  };

  const handleClick = (oEvent, item, index) => {
    dispatch(onChangeCollapseDetalleListaAgrupadaXSoluciones({ index: index }));
    return;
    const rowsInput = [...flujoCajaState.detalleListaAgrupadaXSoluciones];
    const oItem = rowsInput[index];
    oItem['collapseIcon'] = !oItem['collapseIcon'];
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(oItem.Id + '-') && element.Id !== oItem.Id;
    });
    var aEncontrado2 = rowsInput.filter((element) => {
      return (
        element.Id.includes(oItem.Id + '-') &&
        element.Id !== oItem.Id &&
        oItem.Id.split('-').length + 1 === element.Id.split('-').length
      );
    });
    if (aEncontrado.length > 0) {
      aEncontrado2.forEach((element) => {
        element.collapse = !element.collapse;
        // element["collapseIcon"]  = !element["collapseIcon"]
        if (element.collapse) {
          element['collapseIcon'] = true;
        } else {
          element['collapseIcon'] = false;
        }
        var aEncontrado = rowsInput.filter((elemen2) => {
          return elemen2.Id.includes(element.Id + '-') && elemen2.Id !== element.Id;
        });
        aEncontrado.forEach((element) => {
          element.collapse = true;
          element['collapseIcon'] = false;
        });
      });
    }
    // props.setFinalArray(rowsInput);
    dispatch(onSetDetalleListaAgrupadaXSoluciones({ detalleListaAgrupadaXSoluciones: rowsInput }));
  };

  const onDragStart = (e, item2, index, oItem, indexPadre) => {
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', item2.Valor);
    setObjDrag({
      item2,
      indexPartida: index,
      oItem,
      indexPadre,
      e,
    });
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const obtenerCabecera = (nuevoId, array) => {
    var aId = nuevoId.split('-');
    if (aId.length >= 2) {
      var sId = '';
      for (let index = 0; index < aId.length; index++) {
        const element = aId[index];
        if (index < aId.length - 1) {
          if (index === 0) {
            sId = element;
          } else {
            sId = sId + '-' + element;
          }
        }
      }
      array.push(sId);
      return obtenerCabecera(sId, array);
    }
    return array;
  };

  const obtenerHijos = (rowsInput, nuevoId) => {
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(nuevoId) && element.Id !== nuevoId;
    });
    return aEncontrado;
  };

  const realizarRecalculo = (rowsInputSaldo, rowsInput) => {
    rowsInputSaldo.forEach((element) => {
      element.iFila = element.Id.split('-').length + 1;
    });
    var aFilterAcumuladores = rowsInputSaldo.filter((element) => {
      return (
        element.IdentificadorCPR === 'C' ||
        element.IdentificadorCPR === 'P' ||
        element.IdentificadorCPR === 'D'
      );
    });
    if (aFilterAcumuladores.length > 0) {
      aFilterAcumuladores.sort((a, b) => (a.iFila < b.iFila ? 1 : -1));
      aFilterAcumuladores.forEach((obj) => {
        var aEncontrado = rowsInputSaldo.filter((element) => {
          return (
            element.Id.includes(obj.Id + '-') &&
            element.Id !== obj.Id &&
            obj.Id.split('-').length + 1 === element.Id.split('-').length
          );
        });
        if (aEncontrado.length > 0) {
          var aEncontradoPadre = rowsInput.filter((element) => {
            return element.Id === obj.Id;
          });
          for (let i = 0; i < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; i++) {
            var acumulador2 = 0;
            for (let j = 0; j < aEncontrado.length; j++) {
              const element2 = aEncontrado[j].Items[i];
              acumulador2 = acumulador2 + element2.Valor;
            }
            aEncontradoPadre[0].Items[i].Valor = acumulador2;
          }
        }
      });
    }
  };

  const calcularTotalYsubTotal = () => {
    const rowsInput = [...flujoCajaState.detalleListaAgrupadaXSoluciones];
    var detalleFlujoCajaCabeceras = oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
    var objecto = {};
    objecto.EsRaiz = 1;
    objecto.Id = 'Total Acumulado';
    objecto.sNombre = 'Total Acumulado';
    objecto.Items = [];
    var objectoParcial = {};
    objectoParcial.EsRaiz = 1;
    objectoParcial.Id = 'Total Periodo';
    objectoParcial.sNombre = 'Total Periodo';
    objectoParcial.Items = [];
    var aEncontradoGruposRaiz = rowsInput.filter((element) => {
      return element.EsGrupo === 1 && element.EsRaiz === 1;
    });
    for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
      var fAcumulado = 0;
      var item = {
        Id: objecto.Id + '-' + index,
        Id_Movimiento: null,
        EsRaiz: 1,
        EsGrupo: 1,
        Valor: 0,
      };
      for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
        const element = aEncontradoGruposRaiz[w];
        if (element.Posicion === 'Egreso') {
          fAcumulado = fAcumulado - element.Items[index].Valor;
        } else {
          fAcumulado = fAcumulado + element.Items[index].Valor;
        }
      }
      item.Valor = fAcumulado;
      objecto.Items.push(item);
      objectoParcial.Items.push({ ...item });
    }
    props.setFinalArrayTotalParciales(objectoParcial);
    props.setFinalArray(rowsInput);
    for (let index = 0; index < objecto.Items.length; index++) {
      const element = objecto.Items[index - 1];
      if (!element) {
        objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
      } else {
        objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
      }
    }
    props.setFinalArrayTotal(objecto);
  };

  const findPadreXCaracteristica = (person) => {
    return !!props.selectedDesdobles.find((p) => p.oPadre.Id === person.oPadre.Id);
  };

  const filterPadreXCaracteristica = (person) => {
    return props.selectedDesdobles.filter((p) => p.oPadre.Id !== person.oPadre.Id);
  };

  const handleChangeCaracteristica = (person) => {
    const filteredPeople = findPadreXCaracteristica(person)
      ? filterPadreXCaracteristica(person)
      : [...props.selectedDesdobles, person];
    props.setSelectedDesdobles(filteredPeople);
  };

  const onSelectCaracteristicas = (e, item) => {
    // return;
    var aListaRows = [];
    var indexFind = -1;
    props.selectedDesdobles.find(function (obj, i) {
      if (obj.oPadre.Id === props.objRightPosicionClick.oItem.Id) {
        indexFind = i;
        return i;
      }
    });
    if (indexFind > -1) {
      props.selectedDesdobles.splice(indexFind, 1);
      var aFinalArray = [];
      for (let index = 0; index < props.finalArray.length; index++) {
        const element = { ...props.finalArray[index] };
        aFinalArray.push(element);
      }
      var Id = props.objRightPosicionClick.oItem.Id;
      var aHijosDesdobles = obtenerHijos(props.finalArray, Id);
      var aHijosInicialesSinDesdobles = obtenerHijos(props.finalArraySinFiltro, Id);
      if (aHijosDesdobles.length > aHijosInicialesSinDesdobles.length) {
        var indexFind = -1;
        aFinalArray.find(function (item, i) {
          if (item.Id === Id) {
            indexFind = i;
            return i;
          }
        });
        aFinalArray.splice(indexFind + 1, aHijosDesdobles.length);
        for (let index = 0; index < aHijosInicialesSinDesdobles.length; index++) {
          const element = aHijosInicialesSinDesdobles[index];
          indexFind = indexFind + 1;
          aFinalArray.splice(indexFind, 0, element);
        }
        aListaRows = aFinalArray;
        props.setFinalArray(aFinalArray);
      }
    } else {
      aListaRows = props.finalArray;
    }
    var oItem = props.objRightPosicionClick.oItem;
    handleChangeCaracteristica({
      oPadre: oItem,
      oDesdoble: item,
      collapse: true,
      collapseIcon: true,
    });
  };

  const onRealizarDesdoblesAutomatico = (finalArraySinFiltro) => {
    // var aFinalArray = [...finalArraySinFiltro];
    const aFinalArray = finalArraySinFiltro.map((object) => ({ ...object }));
    let rows = finalArraySinFiltro.map((object) => ({ ...object })); //[...finalArraySinFiltro];
    // let rows = [...aFinalArray];
    ///
    //Proceso 1.
    var aIdExcluir = [];
    var aHijosFinal = [];
    var aAgrupadoXDesdobleXhijo = [];
    for (let index = 0; index < props.selectedDesdobles.length; index++) {
      const oPadreDesdoble = props.selectedDesdobles[index];
      var aAgrupadoDatos = [];
      var oItem = oPadreDesdoble.oPadre;
      // props.finalArray.forEach((element) => {
      var aHijos = [...obtenerHijos(aFinalArray, oItem.Id)];
      var helper = {};
      aHijos.forEach((element) => {
        var aEncontrado = element.datos_reg.filter((element2) => {
          return element2.sNombreCampo === oPadreDesdoble.oDesdoble.sNombreCampo;
        });
        if (aEncontrado.length > 0) {
          var key =
            // aEncontrado[0].iIdCaracteristica +
            // "_" +
            aEncontrado[0].sNombreCampo + '_' + aEncontrado[0].sDatoPosicion;
          if (!helper[key]) {
            //inicializa
            helper[key] = {
              // iIdCaracteristica: aEncontrado[0].iIdCaracteristica,
              sNombreCampo: aEncontrado[0].sNombreCampo,
              sDatoPosicion: aEncontrado[0].sDatoPosicion,
              datos: [element],
            };
            aAgrupadoDatos.push(helper[key]);
          } else {
            helper[key].datos.push(element);
          }
          aHijosFinal.push(element);
        }
      });
      aHijos = aHijosFinal;
      //
      aAgrupadoDatos.forEach((element) => {
        let datos = element.datos;
        datos.forEach((element2) => {
          aIdExcluir.push(element2.Id);
        });
      });
      ////
      // var indexFind = -1;
      // rows.find(function (item, i) {
      //   if (item.Id === oItem.Id) {
      //     indexFind = i;
      //     return i;
      //   }
      // });
      var obj = { ...oPadreDesdoble, ...{ aAgrupadoDatos: aAgrupadoDatos } };
      aAgrupadoXDesdobleXhijo.push(obj);
    }
    if (props.selectedDesdobles.length > 0) {
      var colectData = collect;
      rows = colectData(rows).whereNotIn('Id', aIdExcluir).all();
    }
    //
    // for (let indexDesdoble = 0; indexDesdoble < props.selectedDesdobles.length; indexDesdoble++) {
    // var indexAcum= 0 ;
    // const oPadreDesdoble = props.selectedDesdobles[indexDesdoble];
    // var IdDesdoble = oPadreDesdoble.oPadre.Id + "-"+(index+1);
    ////
    // var indexFind = -1;
    // rows.find(function (item, i) {
    //   if (item.Id === oItem.Id) {
    //     indexFind = i;
    //     return i;
    //   }
    // });
    //
    for (let j = 0; j < aAgrupadoXDesdobleXhijo.length; j++) {
      var indexAcum = 0;
      const element = aAgrupadoXDesdobleXhijo[j];
      var indexFind = -1;
      rows.find(function (item, i) {
        if (item.Id === element.oPadre.Id) {
          indexFind = i;
          return i;
        }
      });
      var oItem = element.oPadre;
      var aAgrupado = element.aAgrupadoDatos;
      for (let p = 0; p < aAgrupado.length; p++) {
        indexAcum = indexAcum + 1;
        var IdDesdoble = element.oPadre.Id + '-' + indexAcum;
        ////----
        const aDatos = [...aAgrupado[p].datos];
        //var IdDesdoble = oItem.Id + "-" + (p + 1);
        for (let index = 0; index < aDatos.length; index++) {
          const element = aDatos[index];
          // var Id = element.Id.replace(oItem.Id, IdDesdoble);
          // element.Id = Id;
          var aId = element.Id.split('-');
          element.Id = IdDesdoble + '-' + aId[aId.length - 1]; //IdDesdoble + aId[aId.length-1];
          for (let w = 0; w < element.Items.length; w++) {
            const element2 = element.Items[w];
            var Id = element.Id + '-' + (w + 1);
            element2.Id = Id;
          }
        }
        var Items = [];
        for (let f = 0; f < oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length; f++) {
          var oCabecera = {
            Id: IdDesdoble,
            Id2: IdDesdoble,
            IdPadre: 'S-2-8',
            IdPadre2: 'S-2-8',
            IdHijo: 'S-2-8',
            IdHijo2: 'S-2-8',
            EsRaiz: 0,
            EsGrupo: 1,
            EsManual: 0,
            Posicion: aAgrupado[p].sDatoPosicion,
            Valor: 0,
          };
          var acumulador = 0;
          for (let j = 0; j < aDatos.length; j++) {
            const element = aDatos[j].Items[f].Valor;
            acumulador = acumulador + element;
          }
          oCabecera.Valor = acumulador;
          Items.push(oCabecera);
        }
        ////TEMPORAL GUILLE
        //if (p === 0) {
        indexFind = indexFind + 1;
        rows.splice(indexFind, 0, {
          EsGrupo: 1,
          EsManual: 0,
          EsRaiz: 0,
          EsSplit: 0,
          Id: IdDesdoble,
          Id2: IdDesdoble,
          IdPadre: IdDesdoble,
          IdPadre2: IdDesdoble,
          Id_Partida1: oItem.Id_Partida1,
          Id_Partida2: oItem.Id_Partida2,
          Id_Partida3: oItem.Id_Partida3,
          IdentificadorCPR: 'D',
          Items: Items,
          LlaveCPR: 'D-' + (p + 1),
          Posicion: aAgrupado[p].sDatoPosicion,
          bEditable: 'NULL',
          datos_carpeta: [],
          datos_posic: [],
          datos_reg: [],
          sNombre_Partida1: oItem.sNombre_Partida1,
          sNombre_Partida2: oItem.sNombre_Partida2,
        });
        // var indexAdd = 0
        for (let indexAdd = 0; indexAdd < aDatos.length; indexAdd++) {
          const element = aDatos[indexAdd];
          indexFind = indexFind + 1;
          rows.splice(indexFind, 0, element);
        }
      }
      // rows = collect(rows).unique('Id').all();
      ///----
      //  }
    }
    // const unique = collect(rows).unique('Id').all();
    const rowsInput = [...rows];
    const rowsInputSaldo = [...rows].filter((element) => {
      return element.Id.includes('S');
    });
    const rowsInputIngreso = [...rows].filter((element) => {
      return element.Id.includes('I');
    });
    const rowsInputEgreso = [...rows].filter((element) => {
      return element.Id.includes('E');
    });
    realizarRecalculo(rowsInputSaldo, rowsInput);
    realizarRecalculo(rowsInputIngreso, rowsInput);
    realizarRecalculo(rowsInputEgreso, rowsInput);
    // setFinalArray(lista);
    props.setFinalArray(rows);
  };

  const onSelectLimpiarDesdoble = (e, oItem) => {
    var indexFind = -1;
    const selectedDesdobles = [...props.selectedDesdobles];
    props.selectedDesdobles.find(function (item, i) {
      if (item.oPadre.Id === oItem.Id) {
        indexFind = i;
        return i;
      }
    });
    if (indexFind > -1) {
      selectedDesdobles.splice(indexFind, 1);
      props.setSelectedDesdobles(selectedDesdobles);
      var aFinalArray = [];
      for (let index = 0; index < props.finalArray.length; index++) {
        const element = { ...props.finalArray[index] };
        aFinalArray.push(element);
      }
      var Id = props.objRightPosicionClick.oItem.Id;
      var aHijosDesdobles = obtenerHijos(props.finalArray, Id);
      var aHijosInicialesSinDesdobles = obtenerHijos(props.finalArraySinFiltro, Id);
      if (aHijosDesdobles.length > aHijosInicialesSinDesdobles.length) {
        var indexFind = -1;
        aFinalArray.find(function (item, i) {
          if (item.Id === Id) {
            indexFind = i;
            return i;
          }
        });
        aFinalArray.splice(indexFind + 1, aHijosDesdobles.length);
        for (let index = 0; index < aHijosInicialesSinDesdobles.length; index++) {
          const element = aHijosInicialesSinDesdobles[index];
          indexFind = indexFind + 1;
          aFinalArray.splice(indexFind, 0, element);
        }
        props.setFinalArray(aFinalArray);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No se ha seleccionado ningún desdoble.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha seleccionado ningún desdoble.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
    // }else{
    //   Swal.fire({
    //     position: "center",
    //     icon: "warning",
    //     title: "No se ha seleccionado ningún desdoble.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  return (
    <Content>
      {/* <button onClick={(e)=>onRealizarDesdoblesAutomatico(e)}></button> */}
      {flujoCajaState.selectListaSoluciones.length > 0 ? (
        <TableContain>
          <TableFC>
            <thead style={{ fontWeight: 'bold', textAlign: 'center' }}>
              <tr>
                <td style={{ width: '120px' }}> Partida \\ Periodo </td>
                {flujoCajaState.detalleFlujoCajaCabecerasSolucion.map((item, index) => (
                  <td>{item.sCabecera}</td>
                ))}
              </tr>
            </thead>
            <tbody id="table_body">
              {flujoCajaState.detalleListaAgrupadaXSoluciones.map((item, index) => {
                var oItem = item;
                var indexPadre = index;
                var espacio = 0;
                var sangria = '';
                if (item.IdentificadorCPR != 'CS') {
                  if (oItem.Id.split('-').length > 1) {
                    espacio = oItem.Id.split('-').length;
                  }
                  for (var i = 0; i < espacio; i++) {
                    sangria += '&nbsp;&nbsp;';
                  }
                }
                return (
                  <tr id={'f-' + item.Id}>
                    {item.EsRaiz === 1 && item.EsGrupo === 1 ? (
                      <td
                        id={item.Id}
                        data-raiz={item.EsRaiz}
                        data-grupo={item.EsGrupo}
                        className={'redips-mark'}
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                        }}
                      >
                        <>
                          <span dangerouslySetInnerHTML={{ __html: sangria }} />
                        </>
                        <a
                          href="#"
                          data-id={'f-' + item.Id}
                          className={'show-row'}
                          onClick={(event) => handleClick(event, item, index)}
                        >
                          {/* [ - ]{" "} */}
                          {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                        </a>
                        {item.Posicion}
                      </td>
                    ) : item.EsRaiz === 0 && item.EsGrupo === 1 ? (
                      item.collapse ? (
                        <></>
                      ) : (
                        <td
                          id={item.Id}
                          data-raiz={item.EsRaiz}
                          data-grupo={item.EsGrupo}
                          className={'redips-mark'}
                        >
                          {/* <ContextMenuTrigger id="my-context-menu-3"> */}
                          <div
                          // onContextMenu={(e) => {
                          //   props.setObjRightPosicionClick(null);
                          //   if (oItem.IdentificadorCPR == 'P') {
                          //     let caracEncontradas = oItem.datos_posic;
                          //     ////TEMPORAL GUILLE
                          //     if (caracEncontradas.length === 0 && oItem.Id_Partida2 !== 0) {
                          //       Swal.fire({
                          //         position: 'center',
                          //         icon: 'warning',
                          //         title: 'No se encontraron características asociadas.',
                          //         showConfirmButton: false,
                          //         timer: 2000,
                          //       });
                          //     } else if (caracEncontradas.length > 0) {
                          //       props.setObjRightPosicionClick({
                          //         oEvent: e,
                          //         item: item,
                          //         indexPadre: indexPadre,
                          //         posi: index + 1,
                          //         oItem: oItem,
                          //         aCaracteristicas: caracEncontradas,
                          //       });
                          //     }
                          //   } else {
                          //     props.setObjRightPosicionClick({
                          //       oEvent: e,
                          //       item: item,
                          //       indexPadre: indexPadre,
                          //       posi: index + 1,
                          //       oItem: oItem,
                          //       aCaracteristicas: [],
                          //     });
                          //   }
                          // }}
                          >
                            <>
                              <span dangerouslySetInnerHTML={{ __html: sangria }} />
                            </>
                            <a
                              href="#"
                              data-id={'f-' + item.Id}
                              className={'show-row'}
                              onClick={(event) => handleClick(event, item, index)}
                            >
                              {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                            </a>
                            {item.Posicion}
                          </div>
                          {/* </ContextMenuTrigger> */}
                        </td>
                      )
                    ) : item.collapse ? (
                      <></>
                    ) : (
                      <td
                        id={item.Id}
                        data-raiz={item.EsRaiz}
                        data-grupo={item.EsGrupo}
                        className={'redips-mark'}
                      >
                        {/* <ContextMenuTrigger id="my-context-menu-4"> */}
                        <div
                        // onContextMenu={(e) => {
                        //   props.setObjRightRegistroClick({
                        //     oEvent: e,
                        //     item: item,
                        //     indexPadre: indexPadre,
                        //     posi: index + 1,
                        //     oItem: oItem,
                        //   });
                        // }}
                        >
                          <>
                            <span dangerouslySetInnerHTML={{ __html: sangria }} />
                          </>
                          {item.Posicion}
                        </div>
                        {/* </ContextMenuTrigger> */}
                      </td>
                    )}
                    {item.collapse ? (
                      <></>
                    ) : (
                      oItem.Items.map((item2, index) => (
                        <td
                          className={`contenidoTable ${
                            oItem.IdentificadorCPR !== 'R'
                              ? ' otros '
                              : item2.EsGrupo === 0 &&
                                item2.EsManual === 1 &&
                                item2.EsRaiz === 0 &&
                                item2.EsSplit === 0
                              ? ' manual '
                              : item2.EsGrupo === 0 &&
                                item2.EsManual === 0 &&
                                item2.EsRaiz === 0 &&
                                item2.EsSplit === 0
                              ? ' noManual '
                              : ''
                          }
                    `}
                        >
                          {item2.EsGrupo === 0 &&
                          item2.EsManual === 1 &&
                          item2.EsRaiz === 0 &&
                          item2.EsSplit === 0 &&
                          item2.bEditable === true ? (
                            <div></div>
                          ) : item2.Valor !== 0 ? (
                            <>
                              <div
                                id={item2.Id}
                                data-pos={oItem.Id}
                                data-raiz={item2.EsRaiz}
                                data-grupo={item2.EsGrupo}
                                data-col={index + 1}
                                data-val={item2.Valor}
                                data-mid={item2.Id_Movimiento}
                                className={`redips-drag`}
                                onContextMenu={(e) => {
                                  props.setObjRightClick({
                                    oEvent: e,
                                    item2: item2,
                                    indexPadre: indexPadre,
                                    posi: index + 1,
                                    indexCabecera: index,
                                    oItem: oItem,
                                  });
                                }}
                              >
                                <NumberTable
                                  onDragStart={(e) =>
                                    onDragStart(e, item2, index + 1, oItem, indexPadre)
                                  }
                                  draggable="true"
                                  value={item2.Valor}
                                  allowNegative={signoAllow}
                                  displayType={'text'}
                                  thousandSeparator={','}
                                  decimalSeparator={'.'}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                />
                              </div>
                            </>
                          ) : (
                            <div>
                              <NumberTable
                                style={{ display: 'block', width: '24px', height: '24px' }}
                                value={''}
                                allowNegative={signoAllow}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                              />
                            </div>
                          )}
                        </td>
                      ))
                    )}
                  </tr>
                );
              })}
              {flujoCajaState.aListaSolucionesSumarizada.map((item, index2) => {
                let sangria = '&nbsp;';

                sangria += item.IdPadre ? '' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';

                return (
                  <tr>
                    {item.EsRaiz === 1 ? (
                      <td
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty('color', item.HexColor, 'important');
                          }
                        }}
                        className={'redips-mark-Soluciones'}
                      >
                        <div>
                          <>
                            <span dangerouslySetInnerHTML={{ __html: sangria }} />
                          </>
                          <a
                            href="#"
                            data-id={'f-' + item.Id}
                            className={'show-row'}
                            onClick={(event) => handleClickSolucionCollapse(event, item, index2)}
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty('color', item.HexColor, 'important');
                              }
                            }}
                          >
                            {/* [ - ]{" "} */}
                            {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                          </a>
                          {item.Posicion}
                        </div>
                      </td>
                    ) : item.collapse ? (
                      <></>
                    ) : (
                      <td
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty('color', item.HexColor, 'important');
                          }
                        }}
                        className={'redips-mark-Soluciones'}
                      >
                        <div>
                          <>
                            <span dangerouslySetInnerHTML={{ __html: sangria }} />
                          </>
                          {item.Posicion}
                        </div>
                      </td>
                    )}

                    {item.collapse ? (
                      <></>
                    ) : (
                      item.Items.map((item2, index2) => (
                        <td className="soluciones">
                          <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                            <NumberTotalTable
                              isNegative={item2.Valor < 0 ? true : false}
                              value={item2.Valor == 0 ? '' : item2.Valor}
                              allowNegative={true}
                              displayType={'text'}
                              thousandSeparator={','}
                              decimalSeparator={'.'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </div>
                        </td>
                      ))
                    )}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className={'redips-mark-Totales'}>
                  {flujoCajaState.detalleListaAgrupadaXSolucionesParcial.sNombre}
                </td>
                {flujoCajaState.detalleListaAgrupadaXSolucionesParcial.Items != null ? (
                  flujoCajaState.detalleListaAgrupadaXSolucionesParcial.Items.map(
                    (item2, index2) => (
                      <td className="totales">
                        <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                          <NumberTotalTable
                            isNegative={item2.Valor < 0 ? true : false}
                            value={item2.Valor}
                            allowNegative={true}
                            displayType={'text'}
                            thousandSeparator={','}
                            decimalSeparator={'.'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </div>
                      </td>
                    )
                  )
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td className={'redips-mark-Totales'}>
                  {flujoCajaState.detalleListaAgrupadaXSolucionesTotal.sNombre}
                </td>
                {flujoCajaState.detalleListaAgrupadaXSolucionesTotal.Items != null ? (
                  flujoCajaState.detalleListaAgrupadaXSolucionesTotal.Items.map((item2, index2) => (
                    <td className="totales">
                      <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                        <NumberTotalTable
                          isNegative={item2.Valor < 0 ? true : false}
                          value={item2.Valor}
                          allowNegative={true}
                          displayType={'text'}
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </div>
                    </td>
                  ))
                ) : (
                  <></>
                )}
              </tr>
            </tfoot>
          </TableFC>
          {!props.objRightPosicionClick ? (
            <></>
          ) : (
            <></>
            // <ContextMenu id="my-context-menu-3">
            //   {props.objRightPosicionClick.oItem.IdentificadorCPR == 'P' ? (
            //     <>
            //       <Submenu title="Características">
            //         {props.objRightPosicionClick.aCaracteristicas.map((item, index) => (
            //           <ContextMenuItem onClick={(e) => onSelectCaracteristicas(e, item)}>
            //             {item.sNombreCampo}
            //           </ContextMenuItem>
            //         ))}
            //       </Submenu>
            //       <ContextMenuItem
            //         onClick={(e) => onSelectLimpiarDesdoble(e, props.objRightPosicionClick.oItem)}
            //       >
            //         Limpiar Desdoble
            //       </ContextMenuItem>
            //     </>
            //   ) : (
            //     <></>
            //   )}
            // </ContextMenu>
          )}
        </TableContain>
      ) : (
        <></>
      )}
    </Content>
  );
});
