import React from 'react';
import styled from 'styled-components';
export const ButtonForm = (props) => (
  <ButtonActionForm onClick={props.action} isAction={props.isAction}>
    {props.name ? <span>{props.name}</span> : <></>}
  </ButtonActionForm>
);
const ButtonActionForm = styled.button`
  font-family: UbuntuBold;
  font-size: 12px;
  background: ${({ isAction }) => (isAction ? '#2E6B6A' : '#183838')};
  padding: 8px 20px 8px 20px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isAction }) => (isAction ? '#2E6B6A' : '#183838')};
  gap: 10px;
  span {
    color: white;
  }
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background: white;
    transform: scale(1.09);
    span {
      color: ${({ isAction }) => (isAction ? '#2E6B6A' : '#183838')};
    }
  }
`;
