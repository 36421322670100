import { Checkbox } from '@mui/material';
import styled from 'styled-components';
import { theme } from '../common';

export const CheckboxStyle = styled(Checkbox)`
  border-radius: 3px;
  height: 20px !important;
  width: 20px !important;
  &.Mui-checked {
    color: ${theme.colors.primary} !important;
  }
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`;
