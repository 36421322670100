import { createSlice } from '@reduxjs/toolkit';
export const personalizacionInitialState = {
  listaPersonalizada: [],
  listaMoneda: [],
  listaPeriodo: [],
  listaCaracteristica: [],
  listaEstructura: [],
};
export const personalizacionSlice = createSlice({
  name: 'personalizacion',
  initialState: personalizacionInitialState,
  reducers: {
    setPersonalizacion: (state, action) => {
      return { ...state, ...{ listaPersonalizada: action.payload } };
    },
    setListaPersonalizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaPersonalizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaPeriodoPersonalizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaCaracteristicaPersonalizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEstructuraPersonalizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  setPersonalizacion,
  setListaPersonalizacion,
  setListaMonedaPersonalizacion,
  setListaPeriodoPersonalizacion,
  setListaCaracteristicaPersonalizacion,
  setListaEstructuraPersonalizacion,
} = personalizacionSlice.actions;
export default personalizacionSlice.reducer;
