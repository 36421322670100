import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const posicionMasterInitialState = {
  searchtext: '',
  listaPosicionMaster: [],
  filterListaPosicionMaster: [],
  idDetallePM: null,
  nombreDetallePM: '',
};
export const posicionMasterSlice = createSlice({
  name: 'posicionMaster',
  initialState: posicionMasterInitialState,
  reducers: {
    setListarPosicionMaster: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaPosicionMaster: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setFilterListaPosicionMaster: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaPosicionMaster = estadoInspeccionado.listaPosicionMaster.filter(function (
        item,
        index
      ) {
        return item.NombreMaster.toLowerCase().includes(action.payload.toLowerCase());
      });
      var obj = {
        filterListaPosicionMaster: filterListaPosicionMaster,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setModalDetallePM: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarModalDetallePM: (state, action) => {
      let oClean = {
        idDetallePM: null,
        nombreDetallePM: '',
      };
      return { ...state, ...oClean };
    },
  },
});
export const {
  setListarPosicionMaster,
  setFilterListaPosicionMaster,
  setModalDetallePM,
  limpiarModalDetallePM,
} = posicionMasterSlice.actions;
export default posicionMasterSlice.reducer;
