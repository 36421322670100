import { createGlobalStyle } from 'styled-components';
import { theme } from '../common';

const styled = { createGlobalStyle };

export const GlobalStyle = styled.createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${theme.font.family} !important;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
  body {
    margin: 0;
    padding: 0;
    margin: 0px;
    padding: 0px;
    color: ${theme.colors.text} !important;
    font-weight: 400 !important;
    font-family: ${theme.font.family} !important;
    font-size: 12px !important;
    background: ${theme.colors.backgroundAll};
  }
  .App {
    height: 100%;
    display: flex;
  }
  input[type='checkbox'] {
    accent-color: ${theme.colors.primary};
  }
  /* DatePicker CSS */
  .react-datepicker {
    font-family: ${theme.font.family};
  }
  .react-datepicker__close-icon {
    /* background-color: ${theme.colors.primary};
    color: ${theme.colors.white} !important;
    border-width: 2px;
    border-style: solid;
    border-color: ${theme.colors.primary};
    border-radius: 0 2px 2px 0; */
    bottom: 18px;
    padding: 0 16px 0 0;
  }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    /* background-color: ${theme.colors.primary} !important; */
    background-color: transparent !important;
    color: ${theme.colors.line};
    height: 18px;
    width: 18px;
    padding: 2px;
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '×';
  }
  .react-datepicker__header {
    background-color: ${theme.colors.white} !important;
    padding-top: 18px;
  }
  .react-datepicker__day-names {
    padding-top: 8px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${theme.colors.tertiary};
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.secondary};
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.secondary};
    }
  }
  .react-datepicker__navigation {
    top: 16px;
  }
  /* DatePicker CSS */

  /* ESTILOS SWAL ALERT */
  .swal2-styled.swal2-confirm {
    background: ${theme.colors.primary} !important;
    border-radius: 0.25rem !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: ${theme.colors.primary} !important;
  }
  .swal2-styled.swal2-confirm:hover {
    background: white !important;
    transform: scale(1.09);
    color: ${theme.colors.primary} !important;
  }
  .swal2-styled.swal2-cancel {
    background: ${theme.colors.white} !important;
    border-radius: 0.25rem !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: ${theme.colors.warning} !important;
    color: ${theme.colors.warning} !important;
  }
  .swal2-styled.swal2-cancel:hover {
    background: ${theme.colors.white} !important;
    transform: scale(1.09);
    color: ${theme.colors.warning} !important;
  }
  /* ESTILOS SWAL ALERT */

  /* ESTILOS MENU RIGHT CLICK */
  .contextmenu {
    padding: 0px;
    border-radius: 2px;
  }
  .contextmenu__item {
    padding: 2px 10px;
    font-weight: 600;
    border-radius: 2px;
    color: ${theme.colors.text};
    :hover {
      background: ${theme.colors.primary} !important;
      color: ${theme.colors.white};
    }
  }

  .contextmenu .submenu:hover > .contextmenu__item {
    background: ${theme.colors.primary} !important;
    color: ${theme.colors.white};
  }
  .contextmenu .submenu > .contextmenu__item:after {
    border-color: transparent transparent transparent ${theme.colors.text};
  }
  .contextmenu .submenu > .contextmenu__item:hover:after {
    border-color: transparent transparent transparent ${theme.colors.white};
  }
  .submenu__item {
    max-height: 50vh;
    overflow: hidden auto;
  }
  /* ESTILOS MENU RIGHT CLICK */

  /* Estilos TimePicker*/
  .ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
      background-color: ${theme.colors.primary} !important;
      color: ${theme.colors.white} !important;
    }
  }
  a {
    color: ${theme.colors.primary} !important;
  }
  .ant-btn-primary {
    background: ${theme.colors.primary} !important;
    border-color: ${theme.colors.primary} !important;
  }
  /* Estilos TimePicker*/

  /* Estilos Modal Estructura*/
  .container_card {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    display: flex;
    align-items: center;
    width: 98%;
  }
  /* Estilos Modal Estructura*/

  .login-fa-eye {
    position: absolute;
    top: 35%;
    right: 4%;
    cursor: pointer;
    color: lightgray;
  }
  span,
  a {
    text-decoration: none !important;
    font-family: ${theme.font.family} !important;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }

  .date-wrapper-60 {
    width: 60% !important;
  }

  .d-nonemeWidth {
    width: 0px !important;
  }
  .d-nonemeAll {
    width: 0px !important;
    height: 0px !important;
  }
  .d-nonemeApp {
    width: calc(100vw - 300px) !important;
  }

  /* Estructura */

  body,
  html,
  #root,
  .wrapper {
    overflow: hidden;
    height: 100%;
    margin: 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .bar-wrapper {
    margin-top: 5px;
    margin-left: 5px;
  }
  .tree-wrapper {
    height: 100%;
    overflow: visible;
  }
  .previous {
    margin-left: 10px;
  }
  .next,
  .collapse {
    margin-right: 10px;
    margin-left: 5px;
  }
  .rowContents {
    border-radius: '10px';
  }
  .rstcustom__rowContents {
    min-width: auto;
  }
  /* Estructura */

  /* AUTOCOMPLETE DROPDOWN */
  .ant-select-dropdown {
    border-radius: 2px;
    .ant-select-item-option {
      padding: 1px 5px;
      min-height: 24px;
      .ant-select-item-option-content {
        font-size: ${theme.font.size};
        font-family: ${theme.font.family};
      }
    }
    .ant-select-item-option-active {
      color: ${theme.colors.white};
      background: ${theme.colors.primary} !important;
      border-radius: 2px;
    }
  }

  /* AUTOCOMPLETE DROPDOWN */

  .apexcharts-treemap-series .apexcharts-datalabel {
    font-size: 8px !important;
  }
  /* TREE CHECKBOX */

  .ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: ${theme.colors.primary} !important;
    border-color: ${theme.colors.primary} !important;
  }
  .ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner:hover {
    border-color: ${theme.colors.primary} !important;
  }

  /* Modal */
  .ReactModalPortal > div {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
    z-index: 999;
  }
  .modal-fondo {
    background-color: rgba(0, 0, 0, 0.419);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
  }
  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .react-datepicker-popper {
    z-index: 1100 !important;
  }
  .modalInicial {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 350px;
    height: auto;
    min-height: 100px;
    max-width: 500px;
    outline: none;
    padding: 10px;
  }

  .modalEliminarRegistro {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    height: fit-content !important;
    min-height: 100px;
    max-width: 500px;
    outline: none;
    padding: 10px;
  }
  .modalFiltros {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /*max-height: 300px;
  */
    /* height: auto; */
    min-height: 300px;
    max-height: 500px;
    max-width: 1000px;
    outline: none;
    padding: 10px;
  }
  .modalRestricciones {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /*max-height: 300px;
  */
    /* height: auto; */
    min-height: 300px;
    max-height: 500px;
    max-width: 1400px;
    outline: none;
    padding: 10px;
  }
  .ReactModal__Content {
    position: absolute;
    width: 100%;
    height: 100%;
    /* left: 50%;
    top: 50%; */
    overflow: auto;
    /* background-color: #fff; */
    /* -webkit-transform: translate(-50,-50%); */
    /* transform: translate(-50,-50%); */
  }
  /* FABS */
  .fab {
    border-radius: 100%;
    bottom: 25px;
    font-size: 30px;
    padding: 25px;
    position: fixed;
    right: 25px;
  }
  .fab-danger {
    border-radius: 100%;
    bottom: 25px;
    font-size: 22px;
    position: fixed;
    left: 25px;
    width: 70px;
    height: 70px;
  }
  .dashed-box {
    position: relative;
    z-index: 1;
    border: dashed;
    height: 8em;
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .gold-box {
    position: absolute;
    z-index: 3;
    left: 60px;
    top: 3em;
  }
  .green-box {
    position: absolute;
    z-index: 999;
    left: 95%;
    top: -10px;
  }
  /*--*/
  .modal-confirm {
    /* background-color: rgba(0, 0, 0, 0.419); */
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    color: #434e65;
    /* width: 525px; */
  }
  .modal-confirm .modal-content {
    padding: 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
  }
  .modal-confirm .modal-header {
    background: #47c9a2;
    border-bottom: none;
    position: relative;
    text-align: center;
    margin: -20px -20px 0;
    border-radius: 5px 5px 0 0;
    padding: 35px;
  }
  .modal-confirm h4 {
    text-align: center;
    font-size: 36px;
    margin: 10px 0;
  }
  .modal-confirm .form-control,
  .modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
  }
  .modal-confirm .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    text-shadow: none;
    opacity: 0.5;
  }
  .modal-confirm .close:hover {
    opacity: 0.8;
  }
  .modal-confirm .icon-box {
    color: #fff;
    width: 95px;
    height: 95px;
    display: inline-block;
    border-radius: 50%;
    z-index: 9;
    border: 5px solid #fff;
    padding: 15px;
    text-align: center;
  }
  .modal-confirm .icon-box i {
    font-size: 64px;
    margin: -4px 0 0 -4px;
  }
  .modal-confirm.modal-dialog {
    margin-top: 80px;
  }
  .modal-confirm .btn,
  .modal-confirm .btn:active {
    color: #fff;
    border-radius: 4px;
    background: #eeb711 !important;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border-radius: 30px;
    margin-top: 10px;
    padding: 6px 20px;
    border: none;
  }
  .modal-confirm .btn:hover,
  .modal-confirm .btn:focus {
    background: #eda645 !important;
    outline: none;
  }
  .modal-confirm .btn span {
    margin: 1px 3px 0;
    float: left;
  }
  .modal-confirm .btn i {
    margin-left: 1px;
    font-size: 20px;
    float: right;
  }
  .trigger-btn {
    display: inline-block;
    margin: 100px auto;
  }
  .Fields {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px 20px 20px;
    justify-content: space-around;
  }
  @media (max-width: 657px) {
    .Fields {
      flex-direction: column-reverse;
    }
  }
  .FieldsInput {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px 20px 50px;
    justify-content: space-around;
  }
  @media (max-width: 657px) {
    .FieldsInput {
      flex-direction: column-reverse;
    }
  }

  .popup-content {
    margin-top: 40px;
    width: 120px;
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    /* background: #23252C; */
    background: white;
    /* max-width: 317px; */
    height: fit-content;
    border-radius: 20px 20px 10px 10px !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* margin: 20px 0; */
    position: absolute;
    z-index: 1;
    padding: 2px 2px 2px 2px;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 0 solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.75rem !important;
    box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  }
  @-webkit-keyframes slideInUp {
    0% {
      -webkit-transform: translateY(15%);
      transform: translateY(15%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes slideInUp {
    0% {
      -webkit-transform: translateY(15%);
      transform: translateY(15%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .item {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: 'pointer';
    transition: background-color 0.9s;
  }
  .item:hover {
    color: #121314;
    text-decoration: none;
    background-color: rgba(194, 196, 199, 0.3);
  }
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .popup-content-user {
    margin-top: 40px;
    width: 120px;
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    /* background: #23252C; */
    background: white;
    /* max-width: 317px; */
    height: fit-content;
    border-radius: 20px 20px 10px 10px !important;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    text-align: center;
    /* margin: 20px 0; */
    color: #121314;
    position: absolute;
    z-index: 1;
    padding: 2px 2px 2px 2px;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 0 solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.75rem !important;
    box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  }
  /*Estilos seleccionar archivos*/
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  :root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: #fff;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 16px;
  }
  [data-rsbs-overlay] {
    border-top-left-radius: var(--rsbs-overlay-rounded);
    border-top-right-radius: var(--rsbs-overlay-rounded);
    display: flex;
    background: var(--rsbs-bg);
    flex-direction: column;
    height: var(--rsbs-overlay-h);
    transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
    will-change: height;
  }
  [data-rsbs-overlay]:focus {
    outline: none;
  }
  [data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
    box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11), 0 -1px 0 rgba(38, 89, 115, 0.05);
  }
  [data-rsbs-overlay],
  [data-rsbs-root]:after {
    max-width: var(--rsbs-max-w);
    margin-left: var(--rsbs-ml);
    margin-right: var(--rsbs-mr);
  }
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: 3;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  [data-rsbs-backdrop] {
    top: -60px;
    bottom: -60px;
    background-color: var(--rsbs-backdrop-bg);
    will-change: opacity;
    cursor: pointer;
    opacity: 1;
  }
  [data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
    cursor: ns-resize;
  }
  [data-rsbs-root]:after {
    content: '';
    pointer-events: none;
    background: var(--rsbs-bg);
    height: 1px;
    transform-origin: bottom;
    transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
    will-change: transform;
  }
  [data-rsbs-footer],
  [data-rsbs-header] {
    flex-shrink: 0;
    cursor: ns-resize;
    padding: 16px;
  }
  [data-rsbs-header] {
    text-align: center;
    user-select: none;
    box-shadow: 0 1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
    z-index: 1;
    padding-top: calc(20px + env(safe-area-inset-top));
    padding-bottom: 8px;
  }
  [data-rsbs-header]:before {
    position: absolute;
    content: '';
    display: block;
    width: 36px;
    height: 4px;
    top: calc(8px + env(safe-area-inset-top));
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: var(--rsbs-handle-bg);
  }
  @media (min-resolution: 2dppx) {
    [data-rsbs-header]:before {
      transform: translateX(-50%) scaleY(0.75);
    }
  }
  [data-rsbs-has-header='false'] [data-rsbs-header] {
    box-shadow: none;
    padding-top: calc(12px + env(safe-area-inset-top));
  }
  [data-rsbs-scroll] {
    flex-shrink: 1;
    flex-grow: 1;
    -webkit-tap-highlight-color: revert;
    -webkit-touch-callout: revert;
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
    overflow: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }
  [data-rsbs-scroll]:focus {
    outline: none;
  }
  [data-rsbs-has-footer='false'] [data-rsbs-content] {
    padding-bottom: env(safe-area-inset-bottom);
  }
  [data-rsbs-content] {
    /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
    overflow: hidden;
  }
  [data-rsbs-footer] {
    box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
      0 2px 0 var(--rsbs-bg);
    overflow: hidden;
    z-index: 1;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    display: flex !important;
    flex-direction: row !important;
    align-items: end !important;
    justify-content: end !important;
  }
  [data-rsbs-is-dismissable='true'],
  [data-rsbs-is-dismissable='false']:matches(
      [data-rsbs-state='opening'],
      [data-rsbs-state='closing']
    ) {
    & :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
      opacity: var(--rsbs-content-opacity);
    }
    & [data-rsbs-backdrop] {
      opacity: var(--rsbs-backdrop-opacity);
    }
  }
  [data-rsbs-state='closed'],
  [data-rsbs-state='closing'] {
    /* Allows interactions on the rest of the page before the close transition is finished */
    pointer-events: none;
  }

  /* MODAL CONFIRMATION */
  .modal-header {
    background-color: white;
  }
  /* .btn-close {
  background: url("assets/close-button.svg");
  opacity: none !important;
} */
  .modal-header .btn-close {
    padding: 0;
    margin: 0;
  }
  .modal-title {
    font-size: 16px;
    color: black;
    line-height: 0.5;
    font-family: PilcrowRounded;
  }

  .css-16zuak4-control {
    background-color: #f6f6f6 !important;
    background: #f6f6f6 !important;
    border: 1px solid white !important;
  }
  .css-bg1dmf-control:hover {
    background-color: #f6f6f6 !important;
    background: #f6f6f6 !important;
    border: 1px solid white !important;
  }
  .css-bg1dmf-control {
    border: 1px solid white !important;
    background-color: #f6f6f6 !important;
    background: #f6f6f6 !important;
    box-shadow: 0 0 0 1px white !important;
  }
  .css-1n7v3ny-option {
    background-color: #00bcca !important;
    color: white !important;
  }
  .css-1n7v3ny-option:active {
    background-color: #00bcca !important;
    color: white !important;
  }
  .css-1s2u09g-control {
    border: 1px solid white !important;
    background-color: #f6f6f6 !important;
    background: #f6f6f6 !important;
    box-shadow: 0 0 0 1px white !important;
  }
  .css-1pahdxg-control {
    border: 1px solid white !important;
    background-color: #f6f6f6 !important;
    background: #f6f6f6 !important;
    box-shadow: 0 0 0 1px white !important;
  }
  .UmoMh.show {
    height: 170px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
    background-color: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 10px;
    /* background-color: rgb(216, 216, 216);
    border-radius: 8px; */
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #333;
  }
  .side-menu {
    background: #f1f1f1;
    width: 245px;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    transition: width 0.2s ease-in;
  }
  .side-menu.inactive {
    width: 80px;
  }
  .side-menu .top-section {
    position: relative;
  }
  .side-menu .top-section .logo {
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: inline-block;
  }
  .side-menu .top-section .contenedorCabecera {
    display: flex;
    justify-content: center;
  }
  .side-menu .top-section .dot {
    margin-top: 5px;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  .side-menu .top-section .logo img {
    max-height: 100%;
  }
  .side-menu .top-section .toggle-menu-btn {
    color: #666;
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .side-menu.inactive .top-section .toggle-menu-btn {
    right: -50px;
  }
  .side-menu .search-controller {
    color: #fff;
    position: relative;
  }
  .side-menu .search-controller .search-btn {
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #666;
  }
  .side-menu .search-controller input[type='text'] {
    border: 0;
    outline: none;
    height: 35px;
    background: #333;
    border-radius: 5px;
    display: block;
    margin: 20px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #666;
  }
  .side-menu .main-menu .menu-item {
    color: #333;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-family: PilcrowRounded;
    font-size: 12px;
  }
  .side-menu .main-menu .menu-item.active {
    background: #0832de;
    border-radius: 8px;
    color: #fff;
    font-family: UbuntuBold;
    font-size: 12px;
  }
  .side-menu .main-menu {
    margin: 5px 0;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .side-menu .main-menu .menu-item .menu-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
  }
  .side-menu .main-menu .menu-item span {
    position: absolute;
    display: inline-block;
    line-height: 40px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
  .side-menu.inactive .main-menu .menu-item span {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .side-menu .main-menu .sub-menu {
    color: #333;
    box-sizing: border-box;
    padding-left: 30px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in;
  }
  .side-menu .main-menu .sub-menu.active {
    max-height: 310px;
    font-family: PilcrowRounded;
    font-size: 12px;
  }
  .side-menu .main-menu .sub-menu a {
    display: block;
    margin: 5px 0;
    font-family: PilcrowRounded;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    padding: 5px;
  }
  .side-menu .main-menu .sub-menu a.active {
    background: #ced6f8;
    display: block;
    border-radius: 5px;
    color: #343434;
    font-family: UbuntuBold;
    font-size: 12px;
  }
  .side-menu-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    padding-bottom: 60px;
  }
  .side-menu-footer .side-menu-footer {
    padding-left: 5px;
  }
  .side-menu-footer .side-menu-icon {
    align-items: center;
  }
  .divider {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #333;
  }
  .content {
    margin-left: 280px !important;
    transition: margin-left 0.2s ease-in;
    padding-top: 30px;
    height: 100vh;
    overflow: auto;
  }
  .content.inactive {
    margin-left: 80px;
  }
  /* ESTO SE HA AGREGADO PARA EL TEMA DE LOS MODALES */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1201;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  .swal2-container {
    z-index: 1203 !important;
  }
  .swal2-title {
    font-family: PilcrowRounded;
    font-size: 1.3em !important;
  }

  .sc-chuuOD-dHjdCH {
    background-color: #0832de;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 12px;
  }

  .apexcharts-datalabels-group text {
    fill: gray !important;
  }
  svg.login {
    position: absolute;
    top: 35%;
    right: 4%;
    cursor: pointer;
    /* color: lightgray; */
  }
`;
