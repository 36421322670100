export const randomRGBA = () => {
  var o = Math.round,
    r = Math.random,
    s = 255;
  let param0 = o(r() * s);
  let param1 = r().toFixed(1);

  var randomR = Math.floor(Math.random() * 130 + 100);
  var randomG = Math.floor(Math.random() * 130 + 100);
  var randomB = Math.floor(Math.random() * 130 + 100);

  //'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
  let borderColor = 'rgba(' + randomR + ',' + randomG + ',' + randomB + ')';
  let backgroundColor = 'rgba(' + randomR + ',' + randomG + ',' + randomB + ',0.5)';
  return { borderColor, backgroundColor };
};
