import { Input } from '../components';

export const adapterTableDerivacionDetalle = (onChangeValorFila, row, tipoDato) => ({
  field: row.label,
  headerName: row.label,
  flex: 1,
  minWidth: 120,
  renderCell: (params) => (
    <Input
      placeholder="Ingrese..."
      value={params.value}
      onChange={(evnt) =>
        onChangeValorFila(evnt, params.row.idDetalleDerivacion, row.label, tipoDato)
      }
    />
  ),
  columna: row.label,
  parsed: row.label,
  tipoDato: tipoDato,
});
