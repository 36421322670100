import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/fontawesome-free-solid';
import {
  SearchInputText,
  Button,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  ButtonAccion,
} from '../../../../components';
import {
  obtenerSoluciones,
  eliminarSoluciones,
  obtenerInfoSolucion,
  obtenerAgentes,
  obtenerListadoMoneda,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListaFactoring,
  filterListarFactoring,
  setDataInfoPR,
  limpiarEditCamposFC,
  limpiarCamposFC,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import {
  createAgenteSelectAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
} from '../../../../adapters';
import Swal from 'sweetalert2';
import { stringFechaToDate } from '../../../../common';
import { EstadoSolucionesData } from '../../../../common';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { createFactoringAdapter } from '../../../../adapters/createFactoring.adapter';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  MenuAccion,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';

export const ListarAdelanto = () => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const factoringState = useSelector((store) => store.factoring);
  const [selectedFactoring, setSelectedFactoring] = useState([]);
  const [buttonAction, setButtonAction] = useState(0);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [tabProceso, setTabProceso] = useState(0);
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Adelanto' }));
    (async () => {
      loadFactoring();
    })();
  }, []);

  const loadFactoring = async () => {
    const responseListaFactoring = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['FC'],
      })
    );
    var listaFactoring = [];
    if (responseListaFactoring != null) {
      if (responseListaFactoring.data) {
        if (responseListaFactoring.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaFactoring.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaFactoring.data.respuesta.aListaSolucion[index];
            var obj = createFactoringAdapter(element);
            listaFactoring.push(obj);
          }
        }
      }
    }
    dispatch(setListaFactoring({ listaFactoring: listaFactoring }));
  };

  const findFactoring = (person) => {
    return !!selectedFactoring.find((p) => p.idFactoring === person.idFactoring);
  };
  const filterConcepto = (person) => {
    return selectedFactoring.filter((p) => p.idFactoring !== person.idFactoring);
  };
  const handleChange = (person) => {
    const filteredPeople = findFactoring(person)
      ? filterConcepto(person)
      : [...selectedFactoring, person];
    setSelectedFactoring(filteredPeople);
  };
  const onClickEditarConcepto = async (row) => {
    dispatch(limpiarEditCamposFC());
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion == 'texto_moneda'
        )[0].DatoPersonalizacion;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    let aTiempoPeriodo = [];
    TiempoPeriodosData.forEach((element) => {
      aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
    });
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: row.idFactoring,
        },
      })
    );
    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        dispatch(updateTitle({ title: 'Editar Factoring' }));
        setButtonAction(2);
        var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];
        var oParam = {
          idSolucion: row.idFactoring,
          descripcion: oSolucion.SOL_NOMBRESOLUCION,
          bValidDescripcion: true,
          selectAgente: [
            {
              value: oSolucion.SOL_IDAGENTE,
              label: oSolucion.SOL_AGENTE,
            },
          ],
          bValidAgente: true,
          listaAgente: aAgentes,
          dDateRange: [
            stringFechaToDate(oSolucion.SOL_PERIODOINICIOP, '.', '-'),
            stringFechaToDate(oSolucion.SOL_PERIODOFINP, '.', '-'),
          ],
          bValidFecha: true,
          monto: oSolucion.SOL_IMPORTEINICIAL.toString(),
          bValidMonto: true,
          cantCuota: oSolucion.SOL_CANTIDADCUOTASP.toString(),
          bValidCantCuota: true,
          montoDes: oSolucion.SOL_PAGODESEMBOLSO.toString(),
          bValidMontoDes: true,
          selectMoneda: [
            {
              value: oSolucion.SOL_MONEDA,
              label: oSolucion.SOL_NOMBRE_MONEDA,
            },
          ],
          bValidMoneda: true,
          listaMoneda: aMonedas,
          peGracia: oSolucion.SOL_DESFASECUOTAP,
          bValidPeGracia: true,
          bValidTiempoPeGracia: true,
          selectTiempoPeGracia: [
            {
              value: oSolucion.SOL_DESFASECUOTAP_PERIODO,
              label: '',
            },
          ],
          peCuotas: oSolucion.SOL_PERIODOSENTREPAGO,
          bValidCuotas: true,
          bValidTiempoPeCuotas: true,
          selectTiempoPeCuotas: [
            {
              value: oSolucion.SOL_PERIODOSENTREPAGO_PERIODO,
              label: '',
            },
          ],
          tea: oSolucion.SOL_TEAP.toString(),
          bValidTea: true,
          frecMax: oSolucion.SOL_FRECUENCIA.toString(),
          bValidFrecMax: true,
          bValidTiempoFrecMax: true,
          selectTiempoFrecMax: [
            {
              value: oSolucion.SOL_FRECUENCIA_PERIODO,
              label: '',
            },
          ],
          apariciones: oSolucion.SOL_APARICIONES.toString(),
          bValidApariciones: true,
          minimo: oSolucion.SOL_IMPORTEINICIALMIN.toString(),
          bValidMinimo: true,
          // maximo : "",
          // bValidMaximo : null,
          selectEstado: [
            {
              value: oSolucion.IDESTADO,
              label: oSolucion.DESCESTADO,
            },
          ],
          bValidEstado: true,
          listaEstado: aEstadoSol,
          listaTiempo: aTiempoPeriodo,
        };
        if (oParam.selectTiempoPeGracia.length > 0) {
          let valor = oParam.selectTiempoPeGracia[0].value;
          if (valor == 'D') {
            oParam.selectTiempoPeGracia[0].label = 'Días';
          } else if (valor == 'S') {
            oParam.selectTiempoPeGracia[0].label = 'Semana';
          } else if (valor == 'M') {
            oParam.selectTiempoPeGracia[0].label = 'Mensual';
          }
        }
        if (oParam.selectTiempoPeCuotas.length > 0) {
          let valor = oParam.selectTiempoPeCuotas[0].value;
          if (valor == 'D') {
            oParam.selectTiempoPeCuotas[0].label = 'Días';
          } else if (valor == 'S') {
            oParam.selectTiempoPeCuotas[0].label = 'Semana';
          } else if (valor == 'M') {
            oParam.selectTiempoPeCuotas[0].label = 'Mensual';
          }
        }
        if (oParam.selectTiempoFrecMax.length > 0) {
          let valor = oParam.selectTiempoFrecMax[0].value;
          if (valor == 'D') {
            oParam.selectTiempoFrecMax[0].label = 'Días';
          } else if (valor == 'S') {
            oParam.selectTiempoFrecMax[0].label = 'Semana';
          } else if (valor == 'M') {
            oParam.selectTiempoFrecMax[0].label = 'Mensual';
          }
        }
        dispatch(setDataInfoPR(oParam));
        navigate('/dashboard/soluciones/factoring/editar/' + row.idFactoring);
      } else {
      }
    }
  };
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findFactoring(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <Button
              action={(oEvent) => onClickEditarConcepto(params.row)}
              icon={faEdit}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
              disabled={true}
            />
          }
        </>
      ),
    },
  ];
  const mapColumnsDownloadExcel = {
    sNombreAgrupador: 'Nombre',
    sTipoConcepto: 'Tipo',
    sReal_Proyectado: 'Real/Proyectado',
  };
  const onAgregarConcepto = () => {
    //setButtonAction(1);
    dispatch(limpiarCamposFC());
    navigate('/dashboard/soluciones/factoring/crear');
    dispatch(updateTitle({ title: 'Crear Factoring' }));
  };
  const onClickEliminarConcepto = () => {
    if (selectedFactoring.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarFactoring(oEvent.target.value));
    } else {
      dispatch(filterListarFactoring(''));
    }
  };
  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };
  const onConfirmarEliminar = async () => {
    var aListaSolucionEliminar = [];
    selectedFactoring.forEach((element) => {
      var o = {};
      o.idSolucion = element.idFactoring;
      aListaSolucionEliminar.push(o);
    });
    const responseListaFactoring = await callEndpoint(
      eliminarSoluciones({ aListaSolucionEliminar: aListaSolucionEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaFactoring == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaFactoring.data.cabecera.statusCode === 200) {
        loadFactoring();
        setSelectedFactoring([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaFactoring.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaFactoring.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="350px"
            placeholder={'Buscar Adelanto...'}
            filter={factoringState.searchtext}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <Button
                action={onAgregarConcepto}
                name="Agregar"
                isAction={true}
                type="primary"
                disabled={true}
              />
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={columns}
        rows={
          factoringState.searchtext.length === 0
            ? factoringState.listaFactoring
            : factoringState.filterListaFactoring
        }
        pageSize={15}
        getRowId={(row) => row.idFactoring}
      />
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
