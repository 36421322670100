import { createSlice } from '@reduxjs/toolkit';
export const perfilInitialState = {
  idUsuario: null,
  email: '',
  bValidEmail: null,
  username: '',
  bValidUsername: null,
  bValidPasswordChange: true,
  oldPassword: '',
  bValidOldPassword: null,
  password: '',
  bValidPassword: null,
  passwordConfirm: '',
  bValidPasswordMust: [null, null, null, null, null],
  bValidPasswordConfirm: null,
  foto: [],
  editFoto: 0,
};
export const perfilSlice = createSlice({
  name: 'perfil',
  initialState: perfilInitialState,
  reducers: {
    onChangeUsuarioPerfil: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCleanPerfil: (state, action) => {
      var obj = {
        bValidPasswordChange: true,
        oldPassword: '',
        bValidOldPassword: null,
        password: '',
        bValidPassword: null,
        passwordConfirm: '',
        bValidPasswordConfirm: null,
        editFoto: 0,
      };
      return { ...state, ...obj };
    },
  },
});
export const { onChangeUsuarioPerfil, setCleanPerfil } = perfilSlice.actions;
export default perfilSlice.reducer;
