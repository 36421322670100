import React, { useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import { Button } from '../../../../../components';
import { ColForm } from '../../../../../styles';
import { faDownload, faInbox } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const ModalImport = (props) => {
  const [error, setError] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState([]);

  useEffect(() => {
    if (!props.isOpen) return;

    return () => {
      setError(null);
      setSelectedFileList([]);
    };
  }, [props.isOpen]);

  const handleChange = (info) => {
    switch (info.file.status) {
      case 'uploading':
        onFileUploading(info);
        break;
      case 'done':
        onFileDone(info);
        break;
      case 'removed':
        onFileRemove(info);
        break;
    }
  };

  const dummyRequest = (option) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  const onFileUploading = (info) => {
    let files = [...selectedFileList];
    if (props.multipleFiles) files.push(info.file);
    else files = [info.file];
    setSelectedFileList(files);
  };

  const onFileDone = (info) => {
    let files = [...selectedFileList];
    if (!props.multipleFiles) files = [info.file];
    setSelectedFileList(files);
  };

  const onFileRemove = (info) => {
    if (props.multipleFiles) {
      const filtered = selectedFileList.filter((x) => x.uid != info.file.uid);
      setSelectedFileList(filtered);
    } else {
      setSelectedFileList([]);
    }
  };

  const handleOnCancel = (e) => {
    props.handleClose(e);
  };

  const onOk = () => {
    if (selectedFileList.length == 0) {
      setError('No has seleccionado ningún archivo');
      return;
    }
    setError(null);
    const finalFiles = [];
    for (const selectedFile of selectedFileList) {
      finalFiles.push(selectedFile.originFileObj);
    }
    props.handleOk(finalFiles);
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        title={'Importar Derivacion'}
        onOk={onOk}
        onCancel={handleOnCancel}
        footer={[
          <Button
            action={(oEvent) => handleOnCancel(oEvent)}
            name={'Cerrar'}
            isAction={false}
            type="warning"
          />,
          <Button
            style={{ marginLeft: '5px' }}
            action={(oEvent) => onOk()}
            name={'Importar'}
            isAction={true}
            type="primary"
          />,
        ]}
      >
        <ColForm
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ textAlign: 'right', marginBottom: '15px' }}
        >
          Descargar la plantilla :{' '}
          <Button
            style={{ marginLeft: '5px' }}
            action={props.descargarPlantilla}
            name={'Plantilla'}
            isAction={true}
            isOnlyIcon={false}
            icon={faDownload}
            type="secondary"
          />
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <Upload.Dragger
            accept=".xlsx, .xls" // TODO: segementar en una propiedad
            fileList={selectedFileList}
            onChange={handleChange}
            customRequest={dummyRequest}
            multiple={false}
            disabled={props.loading}
          >
            <p className="ant-upload-drag-icon">
              <FontAwesomeIcon icon={faInbox} />
            </p>
            <p className="ant-upload-text">Click o arrastra un archivo a esta zona para subirlo</p>
          </Upload.Dragger>
        </ColForm>
      </Modal>
    </>
  );
};
