import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const rolInitialState = {
  listaRol: [],
  searchtextHomologacion: '',
  filterListaRol: [],
  rolAccesoId: null,
  rolAccesoNombre: '',
  selectedAcceso: [],
  isSelectedAllAcceso: false,
  rolPermisoId: null,
  listaPermiso: [],
  tipoPermiso: [{ value: 'Caracteristica', label: 'Caracteristica' }],
  listaVariablePermiso: [],
  variablePermiso: [],
  valorPermiso: '',
  optionValorPermiso: [],
};
export const rolSlice = createSlice({
  name: 'rol',
  initialState: rolInitialState,
  reducers: {
    setListarRol: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaRol: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    changeSearchRol: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaRol = estadoInspeccionado.listaRol.filter(function (item, index) {
        return item.Descripcion.toLowerCase().includes(action.payload.toLowerCase());
      });
      var obj = {
        filterListaRol: filterListaRol,
        searchtextHomologacion: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setCleanDrawerAcceso: (state, action) => {
      var obj = {
        rolAccesoId: null,
        rolAccesoNombre: '',
        selectedAcceso: [],
        isSelectedAllAcceso: false,
      };
      return { ...state, ...obj };
    },
    onSetDrawerAcceso: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCleanDrawerPermiso: (state, action) => {
      var obj = {
        rolPermisoId: null,
        listaPermiso: [],
        tipoPermiso: [{ value: 'Caracteristica', label: 'Caracteristica' }],
        listaVariablePermiso: [],
        variablePermiso: [],
        valorPermiso: '',
        optionValorPermiso: [],
      };
      return { ...state, ...obj };
    },
    onSetDrawerPermiso: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  setListarRol,
  changeSearchRol,
  setCleanDrawerAcceso,
  onSetDrawerAcceso,
  setCleanDrawerPermiso,
  onSetDrawerPermiso,
} = rolSlice.actions;
export default rolSlice.reducer;
