import styled from 'styled-components';
import { theme } from '../common';

export const ButtonDobleIcon = styled.button`
  z-index: 2;
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.line};
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 0.25rem;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  font-family: ${theme.font.family};
  border: 0.0625rem solid transparent;
  box-shadow: 0 0 2px #999;
  color: ${({ show }) => (show === true ? theme.colors.primary : theme.colors.text)};
  color: ${({ show }) => (show === true ? 'bold !important' : '')};
  div {
    width: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 0.0625rem solid rgb(140 152 164 / 25%);
    color: ${theme.colors.primary};
    font-weight: 500 !important;
    box-shadow: 0 3px 6px -2px rgb(140 152 164 / 25%);
  }
`;

export const IconButton = styled.div`
  font-size: ${({ size }) => (size ? size : '12px')};
  margin-right: ${({ isOnlyIcon, isAction, noBackground, name }) =>
    (isOnlyIcon && isAction) || (noBackground && !isOnlyIcon && name) ? '0px' : '5px'};
  color: ${({ disabled, isAction, type, noBackground }) =>
    disabled
      ? 'gray'
      : !isAction
      ? noBackground
        ? theme.colors.white
        : type
        ? type === 'secondary'
          ? theme.colors.secondary
          : type === 'tertiary'
          ? theme.colors.tertiary
          : type === 'warning'
          ? theme.colors.warning
          : type === 'text'
          ? theme.colors.text
          : theme.colors.primary
        : theme.colors.primary
      : theme.colors.white};
  transition: all 0.3s ease-in-out;
  &.rotateimg180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out; /* added */
  }
`;
export const ButtonStandar = styled.button`
  z-index: 2;
  font-size: ${({ size }) => (size ? size : '12px')};
  font-weight: 400;
  font-family: ${theme.font.family};
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  outline: 0;
  padding: ${({ isOnlyIcon, isAction, icon, name, noBackground }) =>
    name
      ? icon
        ? '5px 15px 5px 15px'
        : '5px 15px 5px 15px'
      : icon
      ? isOnlyIcon && !isAction
        ? '0px 3px'
        : '5px 10px 5px 10px'
      : '5px 10px 5px 10px'};
  background: ${({ isOnlyIcon, isAction, disabled, type, noBackground }) =>
    disabled
      ? isOnlyIcon && !isAction
        ? 'none'
        : 'gray'
      : noBackground
      ? 'none'
      : isOnlyIcon && !isAction
      ? 'none'
      : isAction
      ? type
        ? type === 'secondary'
          ? theme.colors.secondary
          : type === 'tertiary'
          ? theme.colors.tertiary
          : type === 'warning'
          ? theme.colors.warning
          : theme.colors.primary
        : theme.colors.primary
      : theme.colors.white};
  border-radius: 0.25rem;
  border-style: ${({ isOnlyIcon, isAction, noBackground }) =>
    (isOnlyIcon && !isAction) || noBackground ? 'none' : 'solid'};
  border-color: ${({ isOnlyIcon, disabled, type, isAction, noBackground }) =>
    disabled
      ? isOnlyIcon && !isAction
        ? 'none'
        : 'gray'
      : noBackground
      ? 'none'
      : isOnlyIcon && !isAction
      ? 'none'
      : type
      ? type === 'secondary'
        ? theme.colors.secondary
        : type === 'tertiary'
        ? theme.colors.tertiary
        : type === 'warning'
        ? theme.colors.warning
        : theme.colors.primary
      : theme.colors.primary};
  gap: 10px;
  span {
    color: ${({ isAction, disabled, type, noBackground }) =>
      disabled
        ? theme.colors.white
        : !isAction
        ? noBackground
          ? theme.colors.white
          : type
          ? type === 'secondary'
            ? theme.colors.secondary
            : type === 'tertiary'
            ? theme.colors.tertiary
            : type === 'warning'
            ? theme.colors.warning
            : theme.colors.primary
          : theme.colors.primary
        : theme.colors.white};
  }

  box-shadow: ${({ isOnlyIcon, isAction, noBackground }) =>
    (isOnlyIcon && !isAction) || noBackground ? 'none' : '0 0 2px #999'};
  &:hover {
    transform: ${({ isAction, noBackground }) =>
      noBackground && isAction ? ' scale(1.09)' : 'scale(1)'};
    background: ${({ disabled, isOnlyIcon, isAction, noBackground }) =>
      disabled
        ? isOnlyIcon
          ? 'none'
          : 'gray'
        : noBackground
        ? 'none'
        : isOnlyIcon && !isAction
        ? 'none'
        : theme.colors.white};
    span {
      color: ${({ disabled, type, noBackground, isAction }) =>
        disabled
          ? theme.colors.white
          : noBackground && !isAction
          ? theme.colors.white
          : type
          ? type === 'secondary'
            ? theme.colors.secondary
            : type === 'tertiary'
            ? theme.colors.tertiary
            : type === 'warning'
            ? theme.colors.warning
            : theme.colors.primary
          : theme.colors.primary};
    }
    ${IconButton} {
      color: ${({ disabled, isOnlyIcon, isAction, type, noBackground }) =>
        disabled
          ? isOnlyIcon && !isAction
            ? 'gray'
            : theme.colors.white
          : noBackground && !isAction
          ? theme.colors.white
          : isOnlyIcon && !isAction
          ? 'gray'
          : type
          ? type === 'secondary'
            ? theme.colors.secondary
            : type === 'tertiary'
            ? theme.colors.tertiary
            : type === 'warning'
            ? theme.colors.warning
            : theme.colors.primary
          : theme.colors.primary};
    }
  }
`;
