export const TipoConcepto = [
  {
    value: "Saldo",
    label: "Saldo",
  },
  {
    value: "Ingreso",
    label: "Ingreso",
  },
  {
    value: "Egreso",
    label: "Egreso",
  },
];
