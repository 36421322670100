import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonStandar, IconButton, RowFlex } from '../../styles';
export const Button = (props) => (
  <ButtonStandar
    width={props.width}
    height={props.height}
    onClick={props.action ? props.action : undefined}
    isAction={props.isAction}
    isOnlyIcon={props.isOnlyIcon}
    noBackground={props.noBackground}
    type={props.type}
    name={props.name}
    icon={props.icon}
    style={props.style}
    disabled={props.disabled}
    size={props.size}
  >
    <RowFlex style={{ justifyContent: 'center', display: props.height ? 'block' : 'flex' }}>
      {props.icon ? (
        <IconButton
          disabled={props.disabled}
          isAction={props.isAction}
          isOnlyIcon={props.isOnlyIcon}
          noBackground={props.noBackground}
          type={props.type}
          size={props.size}
          className={props.className ? props.className : props.rotateIcon ? 'rotateimg180' : ''}
        >
          <FontAwesomeIcon icon={props.icon} />
        </IconButton>
      ) : (
        <></>
      )}
      {props.name ? <span>{props.name}</span> : <></>}
    </RowFlex>
  </ButtonStandar>
);
