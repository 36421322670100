import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { theme } from '../../common';
export const ButtonTable = (props) => (
  <ButtonStandar
    backgroundHoverButton={props.backgroundHoverButton}
    backgroundButton={props.backgroundButton}
    colorText={props.colorText}
    width={props.width}
    onClick={props.action}
    isAction={props.isAction}
  >
    {props.icon ? (
      <IconButton
        backgroundButton={props.backgroundButton}
        isAction={props.isAction}
        colorText={props.colorText}
        backgroundHoverButton={props.backgroundHoverButton}
      >
        <FontAwesomeIcon icon={props.icon} />
      </IconButton>
    ) : (
      <></>
    )}
    {props.name ? <span>{props.name}</span> : <></>}
  </ButtonStandar>
);
const IconButton = styled.div`
  color: ${({ colorText }) => (colorText ? colorText : 'white')};
`;
const ButtonStandar = styled.button`
  font-size: 12px;
  font-weight: 400;
  font-family: ${theme.font.family};
  background: ${({ backgroundButton }) => (backgroundButton ? backgroundButton : 'blue')};
  border-radius: 0.25rem;
  border-style: solid;
  border-color: ${({ isAction, backgroundButton, colorText }) =>
    isAction ? backgroundButton : colorText};
  gap: 10px;
  span {
    color: ${({ colorText }) => (colorText ? colorText : 'white')};
  }
  box-shadow: 0 0 2px #999;
  &:hover {
    background: ${({ backgroundHoverButton }) =>
      backgroundHoverButton ? backgroundHoverButton : 'white'};
    transform: scale(1.09);
    span {
      color: ${({ isAction, colorText, backgroundButton }) =>
        isAction ? backgroundButton : colorText};
    }
    ${IconButton} {
      color: ${({ isAction, colorText, backgroundButton }) =>
        isAction ? backgroundButton : colorText};
    }
  }
`;
