import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/fontawesome-free-solid';
import {
  SearchInputText,
  Button,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  PBR,
  ButtonAccion,
} from '../../../../components';
import { Checkbox } from '@mui/material';
import {
  listarConceptos,
  eliminarConceptos,
  obtenerCabDetalleConcepto,
  listarCaracteristicasXfuncion,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterListarConceptos,
  loadEditarConcepto,
  setCaracteristicaRegistro,
  setCaracteristicaXfuncionConcepto,
  setListarConceptos,
  limpiarCamposConcepto,
  onOpenModalUploadDataExcelConcepto,
  setUploadDataExcelConcepto,
} from '../../../../redux/states';
import { createCaracteristicaAdapter, createConceptoAdapter } from '../../../../adapters';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { exportToExcel } from '../../../../common';
import readXlsxFile from 'read-excel-file';
import { MapImportDataConcepto, permisos } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RowAccion,
  ContentButton,
  ContentOption,
  RowFlex,
  ColFlex,
  MenuAccion,
  CheckboxStyle,
} from '../../../../styles';
import { Divider, Dropdown } from 'antd';
import { columns, items } from './misc';
export const ListarConcepto = (props) => {
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const conceptoState = useSelector((store) => store.concepto);
  const [selectedConcepto, setSelectedConcepto] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [buttonAction, setButtonAction] = useState(0);
  const [tabProceso, setTabProceso] = useState(0);
  const [show, setShow] = React.useState(false);
  // 0 Sin acción - 1 Agregar - 2 Editar
  const titleExport = 'ConceptoData';
  const dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      loadConceptos();
    })();
  }, []);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Concepto' }));
  }, []);

  const loadConceptos = async () => {
    const responseListaConceptos = await callEndpoint(listarConceptos());
    var listaConceptos = [];
    for (
      let index = 0;
      index < responseListaConceptos.data.respuesta.aListaConceptos.length;
      index++
    ) {
      const element = responseListaConceptos.data.respuesta.aListaConceptos[index];
      var obj = createConceptoAdapter(element);
      listaConceptos.push(obj);
    }
    dispatch(setListarConceptos(listaConceptos));
  };

  const findConcepto = (person) => {
    return !!selectedConcepto.find((p) => p.iIDAgrupador === person.iIDAgrupador);
  };
  const filterConcepto = (person) => {
    return selectedConcepto.filter((p) => p.iIDAgrupador !== person.iIDAgrupador);
  };
  const handleChange = (person) => {
    const filteredPeople = findConcepto(person)
      ? filterConcepto(person)
      : [...selectedConcepto, person];
    setSelectedConcepto(filteredPeople);
  };
  const onClickEditarConcepto = async (row) => {
    // dispatch(updateTitle({ title: "Editar Concepto" }));
    const { data } = await callEndpoint(obtenerCabDetalleConcepto({ iId: row.iIDAgrupador }));
    var oCabecera = data.respuesta.oCabecera;

    var aDetalle = data.respuesta.aDetalle;

    var oConcepto = {
      iIDCabecera: oCabecera.IDConcepto,
      bValidTipo: true,
      selectTipo: [{ value: oCabecera.TipoConcepto, label: oCabecera.TipoConcepto }],
      sNombre: oCabecera.NombreConcepto,
      bValidNombre: true,
      selectRealProyectado: [
        { value: oCabecera.Real_Proyectado, label: oCabecera.Real_Proyectado },
      ],
      bValidRealProyectado: true,
      selectFuncion: [{ value: oCabecera.IDFConexion, label: oCabecera.NombreFuncion }],
      bValidFuncion: true,
      aCaracteristicaRegistro: [],
    };
    const responseListarCaracteristicasXfuncion = await callEndpoint(
      listarCaracteristicasXfuncion(oConcepto.selectFuncion)
    );
    var caracteristicasXfuncion = [];
    for (
      let index = 0;
      index < responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion.length;
      index++
    ) {
      const element =
        responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion[index];
      var obj = createCaracteristicaAdapter(element);
      obj.selected = false;
      caracteristicasXfuncion.push(obj);
    }
    let aCaract = caracteristicasXfuncion.filter(
      (item) =>
        item.sNombreFuente !== 'MonedaPago' &&
        item.sNombreFuente !== 'MonedaImporte' &&
        item.sNombreFuente !== 'Importe' &&
        item.sNombreFuente !== 'FechaVcto'
    );
    aDetalle.forEach((element) => {
      var o = {};
      o.selectCaracteristica = [];
      o.selectTipo = [];
      var oSelectC = {};
      oSelectC.iIDHomologacion = null;
      oSelectC.iIDFConexionAgrupador = oCabecera.IDFConexion;
      oSelectC.sNombreFuente = element.Nombre;
      oSelectC.sTipoConcepto = oCabecera.TipoConcepto;
      oSelectC.value = oCabecera.IDFConexion;
      oSelectC.label = element.Nombre;
      o.selectCaracteristica.push(oSelectC);
      o.orden = 1;
      o.enabledDelete = true;
      var oSelectT = {};
      oSelectT.idTipo = element.Tipo;
      oSelectT.sTipo = element.Tipo;
      oSelectT.value = element.Tipo;
      oSelectT.label = element.Tipo;
      o.selectTipo.push(oSelectT);
      o.sAccion = 'C';
      o.IDCaracteristica = element.IDCaracteristica;
      if (
        element.Nombre === 'MonedaPago' ||
        element.Nombre === 'MonedaImporte' ||
        element.Nombre === 'Importe' ||
        element.Nombre === 'FechaVcto'
      ) {
        o.enabledDelete = false;
        o.orden = 0;
      }
      oConcepto.aCaracteristicaRegistro.push(o);
      var aEnconrtado = aCaract.filter((item2) => element.Nombre == item2.sNombreFuente);
      if (aEnconrtado.length > 0) {
        aEnconrtado[0].selected = true;
      }
    });
    dispatch(loadEditarConcepto(oConcepto));
    dispatch(
      setCaracteristicaRegistro({
        aCaracteristicaRegistro: oConcepto.aCaracteristicaRegistro,
      })
    );
    dispatch(
      setCaracteristicaXfuncionConcepto({
        aCaracteristicaXfuncion: aCaract,
      })
    );
    // setButtonAction(2);
    navigate('/dashboard/configuracion/concepto/editar/' + row.iIDAgrupador);
  };

  const mapColumnsDownloadExcel = {
    sNombreAgrupador: 'Nombre',
    sTipoConcepto: 'Tipo',
    sReal_Proyectado: 'Real/Proyectado',
  };
  const onAgregarConcepto = () => {
    dispatch(limpiarCamposConcepto());
    navigate('/dashboard/configuracion/concepto/crear');
  };

  const onClickEliminarConcepto = () => {
    if (selectedConcepto.length > 0) {
      setShowModalEliminar(true);
      setShow(false);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarConceptos(oEvent.target.value));
    } else {
      dispatch(filterListarConceptos(''));
    }
  };

  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };

  const onConfirmarEliminar = async () => {
    var aListaConceptosEliminar = [];
    selectedConcepto.forEach((element) => {
      var o = {};
      o.iIDAgrupador = element.iIDAgrupador;
      aListaConceptosEliminar.push(o);
    });
    const responseListaConceptos = await callEndpoint(
      eliminarConceptos({ aListaConceptosEliminar: aListaConceptosEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaConceptos == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaConceptos.data.cabecera.statusCode === 200) {
        loadConceptos();
        setSelectedConcepto([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaConceptos.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaConceptos.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const handleChangeFile = async (e) => {
    let response = await readXlsxFile(e.target.files[0]);

    var mapObj = MapImportDataConcepto;
    let formato = true;
    if (response.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha cargado información en la importación de data de conceptos.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let cabecera = response[0];
      for (let index = 0; index < cabecera.length; index++) {
        const element = cabecera[index];
        if (mapObj[element]) {
        } else {
          formato = false;
          break;
        }
      }
    }
    if (!formato) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'El excel no cumple con el formato de columnas predeterminada.Por favor, descargue la plantilla.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let uploadDataExcel = [];
      for (let index = 1; index < response.length; index++) {
        const row = response[index];
        const emptyObject = {
          Id: 0,
          FilaAgrupada: 0,
          Nombre: '',
          TipoConcepto: '',
          Real_Proyectado: '',
          IdFuncion: 0,
          NombreCampo: '',
          TipoAtributo: '',
        };
        var obj = { ...emptyObject };
        obj.Id = row[0];
        obj.FilaAgrupada = row[1];
        obj.Nombre = row[2];
        obj.TipoConcepto = row[3];
        obj.Real_Proyectado = row[4];
        obj.IdFuncion = row[5];
        obj.NombreCampo = row[6];
        obj.TipoAtributo = row[7];
        uploadDataExcel.push(obj);
      }
      dispatch(setUploadDataExcelConcepto({ uploadDataExcel: uploadDataExcel }));
      dispatch(onOpenModalUploadDataExcelConcepto({ modalOpenUploadDataExcel: true }));
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar conceptos...'}
              filter={conceptoState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(
                      onClickEliminarConcepto,
                      exportToExcel,
                      conceptoState.searchtext.length === 0
                        ? conceptoState.listaConceptos
                        : conceptoState.filterListaConceptos,
                      titleExport,
                      mapColumnsDownloadExcel
                    ),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                <PBR permiso={[permisos.CREAR_CONCEPTO]}>
                  <Button
                    action={onAgregarConcepto}
                    name="Agregar"
                    isAction={true}
                    type="primary"
                  />
                </PBR>
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        {
          <TableGrid
            columns={columns(findConcepto, handleChange, onClickEditarConcepto)}
            rows={
              conceptoState.searchtext.length === 0
                ? conceptoState.listaConceptos
                : conceptoState.filterListaConceptos
            }
            pageSize={15}
            getRowId={(row) => row.iIDAgrupador}
          />
        }
      </ColFlex>
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
