import { createSlice, current } from '@reduxjs/toolkit';

export const tipoCambioInitialState = {
  searchtextHomologacion: '',
  listaTipoCambio: [],
  filterListaTipoCambio: [],
  fechaTipoCambio: '',
  respectoTipoCambio: [],
  bValidRespectoTipoCambio: null,
  monedaTipoCambio: [],
  bValidMonedaTipoCambio: null,
  importeTipoCambio: '',
  bValidImporteTipoCambio: null,
  factorTipoCambio: '',
  bValidFactorTipoCambio: null,
  listaMoneda: [],
  selectRowTC: [],
};

export const tipoCambioSlice = createSlice({
  name: 'tipocambio',
  initialState: tipoCambioInitialState,
  reducers: {
    changeSearchTextTipoCambio: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaTipoCambio = estadoInspeccionado.listaTipoCambio.filter(function (
        item,
        index
      ) {
        return (
          item.MonedaBase.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.NombreMoneda.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaTipoCambio: filterListaTipoCambio,
        searchtextHomologacion: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListaTipoCambio: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaTCC: (state, action) => {
      return { ...state, ...{ listaMoneda: action.payload } };
    },
    onChangeModalTCC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCleanModalTCC: (state, action) => {
      var obj = {
        fechaTipoCambio: '',
        respectoTipoCambio: [],
        bValidRespectoTipoCambio: null,
        monedaTipoCambio: [],
        bValidMonedaTipoCambio: null,
        importeTipoCambio: '',
        bValidImporteTipoCambio: null,
        factorTipoCambio: '',
        bValidFactorTipoCambio: null,
      };
      return { ...state, ...obj };
    },
    onSelectRowTCC: (state, action) => {
      var oParam = {
        selectRowTC: action.payload,
      };
      return { ...state, ...oParam };
    },
  },
});

export const {
  setListaTipoCambio,
  changeSearchTextTipoCambio,
  setListaMonedaTCC,
  onChangeModalTCC,
  setCleanModalTCC,
  onSelectRowTCC,
} = tipoCambioSlice.actions;
export default tipoCambioSlice.reducer;
