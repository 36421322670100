import { faTrash } from '@fortawesome/fontawesome-free-solid';
import { Button } from '../../../../../components';
import { RowButton } from '../../../../../styles';

export const columnAction = (onDeleteColumn) => [
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    headerName: '',
    width: 40,
    renderCell: (params) => (
      <RowButton>
        <Button
          action={(oEvent) => onDeleteColumn(params.row.idDetalleDerivacion)}
          icon={faTrash}
          isAction={false}
          isOnlyIcon={true}
          type="primary"
        />
      </RowButton>
    ),
  },
];
