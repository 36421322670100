import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {
  CrearConcepto,
  CrearEstructura,
  CrearFuncion,
  CrearPocision,
  CrearSistema,
  EditarConcepto,
  EditarEstructura,
  EditarFuncion,
  EditarPosicion,
  EditarSistema,
  ListarCampo,
  ListarConcepto,
  ListarEstructura,
  ListarFuncion,
  ListarPosicion,
  ListarSistema,
  SeguridadAuditoria,
  SeguridadPagos,
  ListarSocio,
  CrearLineaCredito,
  EditarLineaCredito,
  ListarLineaCredito,
  ListarPrestamo,
  CrearPrestamo,
  EditarPrestamo,
  Login,
  SignUpPage,
  ListarAplazamiento,
  CrearAplazamiento,
  EditarAplazamiento,
  ListarFlujoCaja,
  CrearFlujoCaja,
  VisualizarFlujoCaja,
  Notificaciones,
  SeguridadUsuario,
  ListarRegistroFuente,
  TabRegistroPropio,
  CrearRegistroPropio,
  EditarRegistroPropio,
  ListarGestion,
  ListarDepositoPlazo,
  CrearDepositoPlazo,
  EditarDepositoPlazo,
  ListarFactoring,
  CrearFactoring,
  EditarFactoring,
  EditarRegistroFuente,
  CrearTipoCambio,
  EditarTipoCambio,
  ListarTipoCambio,
  Personalizacion,
  ListarTipoCambioConf,
  SeguridadRol,
  ListarAutomatizacion,
  CrearAutomatizacion,
  EditarAutomatizacion,
  Documentos,
  ListarDerivacion,
  CrearDerivacion,
  EditarDerivacion,
  ListarAdelanto,
  RecoveryPassword,
  ChangePassword,
  ListarPosicionMaster,
  SeguridadPerfil,
  CrearFlujoCajaF,
} from '../pages';
import PublicRoutes from './PublicRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import Dashboard from './DashboardRoutes';

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/** Protected Routes */}

        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="dashboard" />} />
          {/* Rutas Redireccionadas de Configuracion */}

          <Route
            path="/dashboard/configuracion/funcion"
            element={<Navigate replace to="/dashboard/configuracion/funcion/listar" />}
          />
          <Route
            path="/dashboard/configuracion/sistema"
            element={<Navigate replace to="/dashboard/configuracion/sistema/listar" />}
          />
          <Route
            path="/dashboard/configuracion/personalizacion"
            element={<Navigate replace to="/dashboard/configuracion/personalizacion/listar" />}
          />
          <Route
            path="/dashboard/configuracion/campo"
            element={<Navigate replace to="/dashboard/configuracion/campo/listar" />}
          />
          <Route
            path="/dashboard/configuracion/concepto"
            element={<Navigate replace to="/dashboard/configuracion/concepto/listar" />}
          />
          <Route
            path="/dashboard/configuracion/posicion"
            element={<Navigate replace to="/dashboard/configuracion/posicion/listar" />}
          />
          <Route
            path="/dashboard/configuracion/estructura"
            element={<Navigate replace to="/dashboard/configuracion/estructura/listar" />}
          />
          {/* Rutas Redireccionadas de Configuracion */}
          <Route
            path="/dashboard/soluciones/linea_credito"
            element={<Navigate replace to="/dashboard/soluciones/linea_credito/listar" />}
          />
          <Route
            path="/dashboard/soluciones/aplazamiento"
            element={<Navigate replace to="/dashboard/soluciones/aplazamiento/listar" />}
          />
          <Route
            path="/dashboard/soluciones/factoring"
            element={<Navigate replace to="/dashboard/soluciones/factoring/listar" />}
          />
          <Route
            path="/dashboard/soluciones/prestamo"
            element={<Navigate replace to="/dashboard/soluciones/prestamo/listar" />}
          />
          {/* Rutas Redireccionadas de Flujo de Caja */}
          <Route
            path="/dashboard/flujo_caja/propio/listar"
            element={<Navigate replace to="/dashboard/flujo_caja/propio/listar/Listado_Propio" />}
          />

          <Route
            path="/dashboard/flujo_caja/reporte"
            element={<Navigate replace to="/dashboard/flujo_caja/reporte/listar" />}
          />

          <Route
            path="/dashboard/flujo_caja/gestion"
            element={<Navigate replace to="/dashboard/flujo_caja/gestion/listar" />}
          />

          <Route
            path="/dashboard/soluciones/deposito"
            element={<Navigate replace to="/dashboard/soluciones/deposito/listar" />}
          />
          <Route
            path="/dashboard/soluciones/tipoCambio"
            element={<Navigate replace to="/dashboard/soluciones/tipoCambio/listar" />}
          />

          {/* Rutas Fijas */}
          <Route path="dashboard/*" element={<Dashboard />}>
            <Route path="seguridad">
              <Route path="usuario" element={<SeguridadUsuario />} />
              <Route path="rol" element={<SeguridadRol />} />
              <Route path="perfil" element={<SeguridadPerfil />} />
              <Route path="auditoria" element={<SeguridadAuditoria />} />
              <Route path="pagos" element={<SeguridadPagos />} />
            </Route>

            <Route path="automatizacion">
              <Route path="listar" element={<ListarAutomatizacion />} />
              <Route path="crear" element={<CrearAutomatizacion />} />
              <Route path="editar/:id" element={<EditarAutomatizacion />} />
            </Route>

            <Route path="personalizacion" element={<Personalizacion />} />

            <Route path="configuracion">
              <Route path="sistema">
                <Route path="listar" element={<ListarSistema />} />
                <Route path="crear" element={<CrearSistema />} />
                <Route path="editar/:id" element={<EditarSistema />} />
              </Route>
              <Route path="campo">
                <Route path="listar" element={<ListarCampo />} />
              </Route>
              <Route path="funcion">
                <Route path="listar" element={<ListarFuncion />} />
                <Route path="crear" element={<CrearFuncion />} />
                <Route path="editar/:id" element={<EditarFuncion />} />
              </Route>
              <Route path="concepto">
                <Route path="listar" element={<ListarConcepto />} />
                <Route path="crear" element={<CrearConcepto />} />
                <Route path="editar/:id" element={<EditarConcepto />} />
              </Route>
              <Route path="posicion-master">
                <Route path="listar" element={<ListarPosicionMaster />} />
              </Route>
              <Route path="posicion">
                <Route path="listar" element={<ListarPosicion />} />
                <Route path="crear" element={<CrearPocision />} />
                <Route path="crear/:id" element={<CrearPocision />} />
                <Route path="editar/:id" element={<EditarPosicion />} />
              </Route>
              <Route path="estructura">
                <Route path="listar" element={<ListarEstructura />} />
                <Route path="crear" element={<CrearEstructura />} />
                <Route path="editar/:id" element={<EditarEstructura />} />
              </Route>
              <Route path="tipo_cambio">
                <Route path="listar" element={<ListarTipoCambioConf />} />
              </Route>
              <Route path="derivacion">
                <Route path="listar" element={<ListarDerivacion />} />
                <Route path="crear" element={<CrearDerivacion />} />
                <Route path="editar/:id" element={<EditarDerivacion />} />
              </Route>
            </Route>
            <Route path="socio_negocio">
              <Route path="socios" element={<ListarSocio />} />
              <Route path="documentos" element={<Documentos />} />
            </Route>
            <Route path="soluciones">
              <Route path="linea_credito">
                <Route path="listar" element={<ListarLineaCredito />} />
                <Route path="crear" element={<CrearLineaCredito />} />
                <Route path="editar/:id" element={<EditarLineaCredito />} />
              </Route>
              <Route path="prestamo">
                <Route path="listar" element={<ListarPrestamo />} />
                <Route path="crear" element={<CrearPrestamo />} />
                <Route path="editar/:id" element={<EditarPrestamo />} />
              </Route>
              <Route path="aplazamiento">
                <Route path="listar" element={<ListarAplazamiento />} />
                <Route path="crear" element={<CrearAplazamiento />} />
                <Route path="editar/:id" element={<EditarAplazamiento />} />
              </Route>
              <Route path="adelanto">
                <Route path="listar" element={<ListarAdelanto />} />
              </Route>
              <Route path="factoring">
                <Route path="listar" element={<ListarFactoring />} />
                <Route path="crear" element={<CrearFactoring />} />
                <Route path="editar/:id" element={<EditarFactoring />} />
              </Route>
              <Route path="deposito">
                <Route path="listar" element={<ListarDepositoPlazo />} />
                <Route path="crear" element={<CrearDepositoPlazo />} />
                <Route path="editar/:id" element={<EditarDepositoPlazo />} />
              </Route>

              <Route path="tipoCambio">
                <Route path="listar" element={<ListarTipoCambio />} />
                <Route path="crear" element={<CrearTipoCambio />} />
                <Route path="editar/:id" element={<EditarTipoCambio />} />
              </Route>
            </Route>
            <Route path="flujo_caja">
              <Route path="fuente">
                <Route path="listar" element={<ListarRegistroFuente />} />
                <Route path="editar/:id" element={<EditarRegistroFuente />} />
              </Route>
              <Route path="propio">
                <Route path="listar/:tab" element={<TabRegistroPropio />} />
                <Route path="crear" element={<CrearRegistroPropio />} />
                <Route path="crear/:id" element={<CrearRegistroPropio />} />
                <Route path="editar/:id" element={<EditarRegistroPropio />} />
              </Route>
              <Route path="reporte">
                <Route path="listar" element={<ListarFlujoCaja />} />
                {/* <Route path="crear" element={<CrearFlujoCaja />} /> */}
                {/* Cambio para mejora de tiempo en flujo de Caja */}
                {/* <Route path="crearf" element={<CrearFlujoCajaF />} /> */}
                <Route path="crear" element={<CrearFlujoCajaF />} />
                <Route path="crearf" element={<CrearFlujoCaja />} />
                <Route path="visualizar/:id" element={<VisualizarFlujoCaja />} />
              </Route>
              {/* <Route path="gestion" element={<OptimizadorGestion />} /> */}

              <Route path="gestion">
                <Route path="listar" element={<ListarGestion />} />
              </Route>
            </Route>
            <Route path="notificaciones" element={<Notificaciones />} />
          </Route>
        </Route>

        {/** Public Routes */}

        <Route path="/" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/login/:tenantUrl" element={<Login />} />
          <Route path="/signUp" element={<SignUpPage />} />
          <Route path="/recovery_password" element={<RecoveryPassword />} />
          <Route path="/change_password" element={<ChangePassword />} />
        </Route>
      </Routes>
    </Router>
  );
};
