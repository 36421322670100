import { AutoCompleteForm } from '../../styles';

export const AutoComplete = (props) => {
  return (
    // <AutoComplete
    //   options={options}
    //   onSelect={onSelect}
    //   onSearch={(text) => setOptions(getPanelValue(text))}
    //   placeholder="input here"
    // />
    <AutoCompleteForm
      options={props.options}
      value={props.value}
      onSearch={props.onSearch}
      placeholder={props.placeholder}
      width={props.width}
      {...props}
    />
  );
};
