export const dashboardData = [
  {
    value: 'LÍNEA CRÉDITO',
    label: 'LÍNEA CRÉDITO',
  },
  {
    value: 'PRÉSTAMO',
    label: 'PRÉSTAMO',
  },
  // {
  //   value: 'APLAZAMIENTO',
  //   label: 'APLAZAMIENTO',
  // },
  {
    value: 'FACTORING',
    label: 'FACTORING',
  },
  {
    value: 'INVERSIÓN',
    label: 'DEPOSITO',
  },
  {
    value: 'TIPO CAMBIO',
    label: 'TIPO CAMBIO',
  },
];
