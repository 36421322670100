import React from 'react';
import { Drawer } from 'antd';
import {
  AutoComplete,
  Button,
  LabelForm,
  SearchInputText,
  SelectForm,
  TableGrid,
  TitlePage,
} from '../../../../components';
import {
  ColForm,
  ContentOption,
  RowDoubleInput,
  RowFlex,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { onSetDrawerPermiso, rolInitialState } from '../../../../redux/states';
import { useDispatch, useSelector } from 'react-redux';
import { consultaPosibilidadesPermiso } from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

export const DrawerPermiso = (props) => {
  const [searchtexPermiso, setSearchtexPermiso] = React.useState('');
  const [filterPermiso, setFilterPermiso] = React.useState([]);
  const rolState = useSelector((store) => store.rol);
  const dispacth = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();

  const onChangeSearchPermiso = (e) => {
    if (e) {
      setSearchtexPermiso(e.target.value);
      const filterPermiso = rolState.listaPermiso.filter(function (item, index) {
        return (
          item.NombreVariable.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.ValorVariable.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      setFilterPermiso(filterPermiso);
    } else {
      setSearchtexPermiso('');
      setFilterPermiso([]);
    }
  };

  const onChangeTipoPermiso = (e) => {
    if (e.length > 0) {
      dispacth(onSetDrawerPermiso({ tipoPermiso: [e] }));
    } else dispacth(onSetDrawerPermiso({ tipoPermiso: [] }));
  };

  const onChangeVariablePermiso = (v) => {
    if (v) {
      dispacth(onSetDrawerPermiso({ variablePermiso: [v], optionValorPermiso: [] }));
    } else {
      dispacth(onSetDrawerPermiso({ variablePermiso: [], optionValorPermiso: [] }));
    }
  };

  const onChangeValorPermiso = (v) => {
    dispacth(onSetDrawerPermiso({ valorPermiso: v }));
  };

  const onSearchPosibilidad = async (value) => {
    let obj = {};

    obj.caract = rolState.variablePermiso[0].label;
    obj.cadena = value;

    const responsePermisoData = await callEndpoint(consultaPosibilidadesPermiso(obj));

    if (responsePermisoData.status == 200) {
      let lista = [];
      let listaSD = [];
      let { IDData } = responsePermisoData.data.respuesta;
      IDData.map((r) => {
        lista.push(r.IDData);
      });
      lista = [...new Set(lista)];
      lista.map((r) => {
        listaSD.push({ value: r });
      });
      dispacth(onSetDrawerPermiso({ optionValorPermiso: listaSD }));
    } else {
      dispacth(onSetDrawerPermiso({ optionValorPermiso: [] }));
    }
  };

  const validarDrawer = () => {
    let validado = false;
    if (rolState.tipoPermiso.length > 0) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }

    if (rolState.variablePermiso.length > 0 && rolState.variablePermiso[0] != null) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }

    if (rolState.valorPermiso.length > 0 && rolState.valorPermiso != null) {
      validado = true;
    } else {
      validado = false;
      return validado;
    }
    return validado;
  };

  const addDrawerTable = () => {
    if (validarDrawer()) {
      let uid = uuidv4();
      let lista = [...rolState.listaPermiso];
      let obj = {
        id: uid,
        TipoCaracteristica: rolState.tipoPermiso[0].value,
        NombreVariable: rolState.variablePermiso[0].value,
        ValorVariable: rolState.valorPermiso,
      };
      lista = [...lista, obj];
      dispacth(onSetDrawerPermiso({ listaPermiso: lista, optionValorPermiso: [] }));
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onClose = () => {
    setSearchtexPermiso('');
    setFilterPermiso([]);
    props.oncloseDrawerPermiso();
  };

  return (
    <Drawer
      title={<TitlePage title={`Administador de Permisos`} />}
      placement="right"
      width={'33%'}
      closable={false}
      onClose={onClose}
      open={props.openDrawerPermiso}
      footer={
        <RowFooterButton>
          <Button action={(e) => onClose()} name="Cancelar" isAction={true} type="warning" />
          <Button
            action={(e) => props.onAppliedPermiso()}
            name="Aplicar"
            isAction={true}
            type="secondary"
          />
        </RowFooterButton>
      }
    >
      <>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm style={{ marginLeft: '5px', marginBottom: '10px' }}>
            <LabelForm obligatorio={true} title={'Tipo (*): '}></LabelForm>
            <SelectForm
              width={'60%'}
              value={rolState.tipoPermiso}
              handleChange={(e, v) => onChangeTipoPermiso(v)}
              options={[{ value: 'Caracteristica', label: 'Caracteristica' }]}
              placeholder={'Seleccione Tipo'}
              isDisabled={true}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm style={{ marginLeft: '5px', marginBottom: '10px' }}>
            <LabelForm obligatorio={true} title={'Variable (*): '}></LabelForm>
            <SelectForm
              width={'60%'}
              value={rolState.variablePermiso}
              handleChange={(e, v) => onChangeVariablePermiso(v)}
              options={rolState.listaVariablePermiso}
              placeholder={'Seleccione Variable'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm style={{ marginLeft: '5px', marginBottom: '10px' }}>
            <LabelForm obligatorio={true} title={'Valor (*): '}></LabelForm>
            <RowDoubleInput>
              <AutoComplete
                width={'60%'}
                placeholder={'Ingrese'}
                options={rolState.optionValorPermiso}
                value={rolState.valorPermiso}
                onChange={onChangeValorPermiso}
                onSearch={(v) => onSearchPosibilidad(v)}
                disabled={rolState.variablePermiso.length > 0 ? false : true}
              />
              <Button
                width={'37%'}
                style={{ marginLeft: 'auto' }}
                action={(oEvent) => addDrawerTable()}
                name={'Adición'}
                isAction={true}
                type="primary"
              />
            </RowDoubleInput>
          </RowForm>
        </ColForm>

        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <LabelForm title={'Listado de Permisos : '}></LabelForm>
        </ColForm>

        <ContentOption>
          <RowFlex>
            <SearchInputText
              placeholder={'Buscar Permiso...'}
              filter={searchtexPermiso}
              onChange={(e) => onChangeSearchPermiso(e)}
            />
          </RowFlex>
        </ContentOption>
        <div className="ant-drawer-body" style={{ padding: '0px', height: '82%' }}>
          <TableGrid
            columns={props.columsPermisoData}
            rows={searchtexPermiso.length === 0 ? rolState.listaPermiso : filterPermiso}
            pageSize={15}
            getRowId={(row) => row.id}
          />
        </div>
      </>
    </Drawer>
  );
};
