import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
export const SubTitle = (props) => {
  return (
    <ContentSubtitle width={props.width}>
      <SubT weight={props.weight}>{props.subtitle}</SubT>
    </ContentSubtitle>
  );
};
const SubT = styled.span`
  font-family: ${theme.font.family};
  font-size: 14px;
  font-weight: ${({ weight }) => (weight ? weight : 'bold')};
  color: ${theme.colors.primary};
`;
const ContentSubtitle = styled.div`
  width: ${({ width }) => (width ? width : '')};

  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;
