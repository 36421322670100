import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
export const InputText = (props) => {
  return (
    <Input
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      validate={props.validate}
      width={props.width}
      onKeyPress={props.onKeyPress}
      disabled={props.disabled}
      type={props.type ? props.type : 'text'}
    />
  );
};
export const Input = styled.input`
  border: 2px solid ${theme.colors.line};
  background: ${theme.colors.white};
  /* border: none;
  transition: width 0.5s; */
  height: 32px;
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  padding: 0px 10px;
  width: ${({ width }) => (width ? width : '98%')};
  border-radius: 2px;
  border-color: ${({ validate }) =>
    validate == null
      ? theme.colors.line
      : validate === true
      ? theme.colors.line
      : theme.colors.warning};
  transition: ${({ validate }) =>
    validate == null
      ? theme.colors.line
      : validate === true
      ? theme.colors.line
      : 'border-color 0.5s ease-out'};
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      validate == null
        ? theme.colors.line
        : validate === true
        ? theme.colors.line
        : theme.colors.warning};
  }
`;
