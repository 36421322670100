import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  InputNumberFormat,
  LabelForm,
  LoadingSpinner,
  ModalConfirmation,
  SelectForm,
  SubTitle,
  TableGrid,
  Title,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import 'react-datepicker/dist/react-datepicker.css';
import {
  changeDescripcionSOLTC,
  changeVigenciaSOLTC,
  limpiarCamposSOLTC,
  selectedAgenteSOLTC,
  selectedEstadoSOLTC,
  setListaAgenteSOLTC,
  setListaEstadoSOLTC,
  setListaMonedaSOLTC,
  setValidCamposSOLTC,
  addDetalleConversionTableSOLTC,
  deleteRowConversionTableSOLTC,
  onChangeRowsConversionMonedaTableSOLTC,
  onChangeRowsConversionMonedaConversioTableSOLTC,
  onChangeRowsSelectTipoConversionTableSOLTC,
  onChangeRowsSelectMonedaTableSOLTC,
  onChangeRowsSelectMonedaConversionTableSOLTC,
  deleteAllRowConversionTableSOLTC,
  onChangeListaMonedaConversionSOLTC,
  selectedTipoFomularioSOLTC,
  limpiarEditCamposSOLTC,
  setDataInfoSOLTC,
  updateTitle,
  onChangeRowsSelectMonedaBaseTableSOLTC,
  onChangeRowsSelectMonedaDestinoTableSOLTC,
  onChangeRowsSelectMonedaRangoTableSOLTC,
  onChangeRowsFactorBaseTableSOLTC,
  onChangeRowsFactorDestinoTableSOLTC,
  onChangeRowsMinimoTableSOLTC,
  onChangeRowsMaximoTableSOLTC,
  onChangeRowsCambioBaseTableSOLTC,
  onChangeRowsCambioDestinoTableSOLTC,
  onCleanFormTipoCambio,
  editDetalleConversionTableSOLTC,
  onEditFormTipoCambio,
  onCreateFormTipoCambio,
  deleteRowTableSOLTC,
} from '../../../../redux/states';
import {
  editarSolucion,
  obtenerAgentes,
  obtenerInfoSolucion,
  obtenerListadoMoneda,
} from '../../../../services/public.service';
import {
  createAgenteSelectAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
  soluTipoCambioAdapter,
  soluTipoCambioEditAdapter,
} from '../../../../adapters';
import {
  EstadoSolucionesData,
  TipoFormularioTipoCambio,
  formatDateMMDDYYYY,
  theme,
} from '../../../../common';
import { stringFechaToDate } from '../../../../common';
import Swal from 'sweetalert2';
import { TiempoPeriodosData } from '../../../../common';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  ColFlex,
  ColForm,
  Content,
  ContentForm,
  DatePickerForm,
  RowDoubleInput,
  RowFlex,
  RowFooterButton,
  RowForm,
  TimePickerForm,
} from '../../../../styles';
import dayjs from 'dayjs';
import { faEdit, faMinus } from '@fortawesome/fontawesome-free-solid';
import { Drawer } from 'antd';

export const EditarTipoCambio = (props) => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.auth);
  let navigate = useNavigate();
  const { id } = useParams();
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const solTipoCambioState = useSelector((store) => store.solTipoCambio);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (personalizacionState.listaPersonalizada.length > 0 && authState.user.uid != '') {
      (async () => {
        await onObtInfoSolTipoCambio();
        await loadAgente();
        await loadMoneda();
        await loadEstado();
      })();
    }
  }, [personalizacionState.listaPersonalizada, authState.user]);

  const handleChangeDescripcion = (oEvent) => {
    var descripcion = oEvent.target.value;
    var validate = null;
    if (descripcion.length > 0) {
      validate = true;
    }
    var oParam = {
      descripcion: descripcion,
      bValidDescripcion: validate,
    };
    dispatch(changeDescripcionSOLTC(oParam));
  };

  const onObtInfoSolTipoCambio = async (row) => {
    dispatch(updateTitle({ title: 'Editar Sol. Tipo Cambio' }));
    let regManualesSOLTC = [];
    let selectRegManualesSOLTC = [];
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: id,
        },
      })
    );
    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];

        if (oSolucion) {
          const responseObtAgentes = await callEndpoint(obtenerAgentes());
          let aAgentes = [];
          if (responseObtAgentes != null) {
            if (responseObtAgentes.data) {
              let { aListaAgentes } = responseObtAgentes.data.respuesta;
              aListaAgentes.forEach((element) => {
                aAgentes.push(createAgenteSelectAdapter(element));
              });
            }
          }
          const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
          let aMonedas = [];
          if (responseObtMonedas != null) {
            if (responseObtMonedas.data) {
              let tipoMoneda = personalizacionState.listaPersonalizada.filter(
                (v) => v.DescPersonalizacion == 'texto_moneda'
              )[0].DatoPersonalizacion;
              let { aListaMoneda } = responseObtMonedas.data.respuesta;
              aListaMoneda.forEach((element) => {
                aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
              });
            }
          }

          let aEstadoSol = [];
          EstadoSolucionesData.forEach((element) => {
            aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
          });
          let aTiempoPeriodo = [];
          TiempoPeriodosData.forEach((element) => {
            aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
          });
          let fechaInicio = oSolucion.SOL_PERIODOINICIOP.split('_');
          let fechaFin = oSolucion.SOL_PERIODOFINP.split('_');
          let splitInicio = fechaInicio[1].split(':');
          let splitFin = fechaFin[1].split(':');
          let dateNow = new Date();
          let timeInicio = dateNow.setHours(parseInt(splitInicio[0]), parseInt(splitInicio[1]), 0);
          let timeFin = dateNow.setHours(parseInt(splitFin[0]), parseInt(splitFin[1]), 0);

          var oParam = {
            idSolucion: id,
            selectTipoFormulario: [
              {
                value: oSolucion.COD_TIPO_FORMULARIO,
                label: '',
              },
            ],
            descripcion: oSolucion.SOL_NOMBRESOLUCION,
            bValidDescripcion: true,
            selectAgente: [
              {
                value: oSolucion.SOL_IDAGENTE,
                label: oSolucion.SOL_AGENTE,
              },
            ],
            bValidAgente: true,
            listaAgente: aAgentes,
            dDateRange: [
              stringFechaToDate(oSolucion.SOL_PERIODOINICIOP, '.', '-'),
              stringFechaToDate(oSolucion.SOL_PERIODOFINP, '.', '-'),
            ],
            dDateRangeStart: fechaInicio[0],
            dDateRangeEnd: fechaFin[0],
            dDateTimeStart: dayjs(timeInicio),
            dDateTimeEnd: dayjs(timeFin),
            bValidFecha: true,
            bValidFechaStart: true,
            bValidFechaEnd: true,

            selectEstado: [
              {
                value: oSolucion.IDESTADO,
                label: oSolucion.DESCESTADO,
              },
            ],
            bValidEstado: true,
            listaEstado: aEstadoSol,
            selectTipo: [
              {
                value: oSolucion.CXV,
                label: oSolucion.CXV,
              },
            ],
            bValidTipo: true,
          };
          //
          if (oParam.selectTipoFormulario.length > 0) {
            let valor = oParam.selectTipoFormulario[0].value;
            if (valor == 'Tipo_1') {
              oParam.selectTipoFormulario[0].label = 'Tipo 1';
            }
          }
          let listaConversion = [];
          responseInfoSolucion.data.respuesta.aDetalleMonedaConversion.map((r) =>
            listaConversion.push(soluTipoCambioEditAdapter(r))
          );

          oParam.detalleConversion = listaConversion;
          oParam.listaMoneda = aMonedas;
          dispatch(setDataInfoSOLTC(oParam));
        }
      }
    }
    setLoadingDetalle(false);
  };

  const onAddMonedaConversion = () => {
    const { detalleConversion } = solTipoCambioState;

    let valid = true;

    let newEntry = {
      id: uuidv4(),
      FactorBase: '',
      bValidFactorBase: null,
      MonedaBase: '',
      bValidMonedaBase: null,
      FactorDestino: '',
      bValidFactorDestino: null,
      MonedaDestino: '',
      bValidMonedaDestino: null,
      FactorRango: '',
      bValidFactorRango: null,
      MonedaRango: '',
      bValidMonedaRango: null,
      cantidadMonedaBase: '',
      bValidCantidadMonedaBase: null,
      cantidadMonedaDestino: '',
      bValidCantidadMonedaDestino: null,
      Minimo: '',
      bValidMinimo: null,
      Maximo: '',
      bValidMaximo: null,
    };
    dispatch(addDetalleConversionTableSOLTC(newEntry));
  };

  const onOpenDrawer = (id) => {
    setOpenDrawer(true);
    if (id) {
      let datos = solTipoCambioState.detalleConversion.filter((r) => r.id === id);
      dispatch(onEditFormTipoCambio(datos[0]));
    } else {
      dispatch(onCreateFormTipoCambio(uuidv4()));
    }
  };

  const handleChangeAgente = (oEvent) => {
    var oParam = {};
    oParam.bValidAgente = null;
    oParam.selectAgente = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectAgente.push(obj);
      oParam.bValidAgente = true;
    }
    dispatch(selectedAgenteSOLTC(oParam));
  };

  const limpiarForm = () => {
    dispatch(deleteAllRowConversionTableSOLTC());
    dispatch(limpiarEditCamposSOLTC());
  };

  const handleChangeEstado = (oEvent) => {
    var oParam = {};
    oParam.bValidEstado = null;
    oParam.selectEstado = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectEstado.push(obj);
      oParam.bValidEstado = true;
    }
    dispatch(selectedEstadoSOLTC(oParam));
  };

  const handleChangeTipo = (oEvent) => {
    var oParam = {};
    oParam.bValidTipo = null;
    oParam.selectTipo = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipo.push(obj);
      oParam.bValidTipo = true;
    }
    dispatch(selectedEstadoSOLTC(oParam));
  };

  const camposObligatorios = () => {
    var oParam = {
      valid: false,
      oActualizarSolucion: {},
      mensaje: '',
    };
    let oParamValidCampos = {};

    if (solTipoCambioState.selectTipoFormulario.length > 0) {
      var selectTipoFormulario = solTipoCambioState.selectTipoFormulario[0];
      let tipoFormulario = selectTipoFormulario.value;
      if (tipoFormulario === 'Tipo_1') {
        oParamValidCampos.bValidDescripcion = true;
        oParamValidCampos.bValidAgente = true;
        oParamValidCampos.bValidFecha = true;
        oParamValidCampos.bValidEstado = true;
        oParamValidCampos.bValidDetalleConversion = true;
        oParamValidCampos.bValidDetalleConversion = true;

        if (solTipoCambioState.descripcion.length == 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (solTipoCambioState.selectAgente.length == 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (
          solTipoCambioState.dDateRangeStart == null ||
          solTipoCambioState.dDateRangeEnd == null
        ) {
          oParamValidCampos.bValidFecha = false;
        }

        if (solTipoCambioState.selectEstado.length == 0) {
          oParamValidCampos.bValidEstado = false;
        }

        if (solTipoCambioState.detalleConversion.length == 0) {
          oParamValidCampos.bValidDetalleConversion = false;
        }

        if (solTipoCambioState.detalleConversion.length > 0) {
          for (let index = 0; index < solTipoCambioState.detalleConversion.length; index++) {
            const element = solTipoCambioState.detalleConversion[index];
            if (
              !(
                element.MonedaBase.length > 0 &&
                element.MonedaDestino.length > 0 &&
                element.MonedaRango.length > 0 &&
                element.FactorBase > 0 &&
                element.FactorDestino > 0 &&
                element.bValidCantidadMonedaBase &&
                element.bValidCantidadMonedaDestino &&
                element.bValidMinimo &&
                element.bValidMaximo
              )
            ) {
              oParamValidCampos.bValidDetalleConversion = false;
              break;
            }
          }
        }

        if (
          oParamValidCampos.bValidDescripcion &&
          oParamValidCampos.bValidAgente &&
          oParamValidCampos.bValidFecha &&
          oParamValidCampos.bValidEstado &&
          oParamValidCampos.bValidDetalleConversion
        ) {
          oParam.valid = true;
          debugger;
          //Agregamos campos obligatorios
          oParam.oActualizarSolucion.COD_SOLUCION = 'TC';
          oParam.oActualizarSolucion.COD_TIPO_FORMULARIO = selectTipoFormulario.value;
          oParam.oActualizarSolucion.TIPO_FORMULARIO = selectTipoFormulario.label;

          oParam.oActualizarSolucion.SOL_NOMBRESOLUCION = solTipoCambioState.descripcion;
          oParam.oActualizarSolucion.SOL_IDAGENTE = solTipoCambioState.selectAgente[0].value;

          oParam.oActualizarSolucion.SOL_AGENTE = solTipoCambioState.selectAgente[0].label;
          oParam.oActualizarSolucion.IDESTADO = solTipoCambioState.selectEstado[0].value;
          oParam.oActualizarSolucion.SOL_PERIODOINICIOP = solTipoCambioState.dDateRangeStart;
          let hourStart = solTipoCambioState.dDateTimeStart.$d.getHours();
          let minuteStart = solTipoCambioState.dDateTimeStart.$d.getMinutes();
          oParam.oActualizarSolucion.SOL_HORAINICIOP =
            hourStart.toString().padStart(2, '0') + ':' + minuteStart.toString().padStart(2, '0');
          oParam.oActualizarSolucion.SOL_PERIODOFINP = solTipoCambioState.dDateRangeEnd;
          let hourEnd = solTipoCambioState.dDateTimeEnd.$d.getHours();
          let minuteEnd = solTipoCambioState.dDateTimeEnd.$d.getMinutes();
          oParam.oActualizarSolucion.SOL_HORAFINP =
            hourEnd.toString().padStart(2, '0') + ':' + minuteEnd.toString().padStart(2, '0');
          oParam.oActualizarSolucion.CXV = solTipoCambioState.selectTipo[0].value;
        } else {
          dispatch(setValidCamposSOLTC(oParamValidCampos));
          oParam.mensaje = 'Debe llenar todos los campos en (*) y la tabla.';
          return oParam;
        }
      }
    } else {
      oParam.mensaje = 'Se debe elegir un tipo formulario.';
      return oParam;
    }

    return oParam;
  };

  const limpiarCampos = () => {
    dispatch(limpiarCamposSOLTC());
    dispatch(updateTitle({ title: 'Editar Tipo de Cambio' }));
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    // setButtonAction(1);
    // setTabProceso(0);
    limpiarCampos();
    navigate('/dashboard/soluciones/tipoCambio/listar');
  };

  const onPressGrabar = async () => {
    let { valid, oActualizarSolucion, mensaje } = camposObligatorios();
    if (valid) {
      let listaDetalle = [];
      solTipoCambioState.detalleConversion.map((r) => listaDetalle.push(soluTipoCambioAdapter(r)));
      let oParam = {
        oActualizarSolucion: oActualizarSolucion,
        aDetalleMonedaConversion: listaDetalle,
        aRegManuales: [],
        aTeaMultiMoneda: [],
        listaFiltrosMultiplesAppliedTableAP: [],
      };
      oParam.oActualizarSolucion.ID_SOLUCION = solTipoCambioState.idSolucion;
      // return;
      const responseRegistrarSol = await callEndpoint(editarSolucion(oParam));
      if (responseRegistrarSol == null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseRegistrarSol.data) {
          if (responseRegistrarSol.data.cabecera.statusCode === 200) {
            // props.setButtonAction(0);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Solucion de Tipo de Cambio registrada correctamente.',
              showConfirmButton: false,
              timer: 2000,
            });

            navigate('/dashboard/soluciones/tipoCambio/listar');
          } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: mensaje,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    dispatch(setListaAgenteSOLTC({ listaAgente: aAgentes }));
  };

  const loadMoneda = async () => {
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion == 'texto_moneda'
        )[0].DatoPersonalizacion;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    dispatch(setListaMonedaSOLTC({ listaMoneda: aMonedas }));
  };

  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(setListaEstadoSOLTC({ listaEstado: aEstadoSol }));
  };

  const onDeleteRowDetalleConversion = async (id) => {
    const result = await Swal.fire({
      title: '¿Estas seguro que desea eliminar la moneda de conversión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });
    if (result.isConfirmed) {
      dispatch(
        deleteRowTableSOLTC({
          id: id,
        })
      );
    }
  };

  const onClearTablaConversiones = async () => {
    const result = await Swal.fire({
      title: '¿Estas seguro que desea eliminar limpiar la tabla de conversiones?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    });
    if (result.isConfirmed) {
      dispatch(deleteAllRowConversionTableSOLTC());
    }
  };

  const handleChangeTipoFormulario = (oEvent) => {
    var oParam = {};
    oParam.bValidselectTipoFormulario = null;
    oParam.selectTipoFormulario = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipoFormulario.push(obj);
      oParam.bValidselectTipoFormulario = true;
    }
    dispatch(selectedTipoFomularioSOLTC(oParam));
  };

  const handleChangeFechaStart = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent,
    };
    var sFechaInicio = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';

    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaInicio.getDate();
      let iMes = oParam.dFechaInicio.getMonth() + 1;
      let iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
      oParam.dDateRangeStart = sFechaInicio;
    }
    // oParam.sFechaInicio = sFechaInicio;
    // oParam.sFechaFinal = sFechaFinal;
    if (oParam.dFechaInicio != null) {
      oParam.bValidFechaStart = true;
      oParam.dDateRangeEnd = null;
      oParam.bValidFechaEnd = null;
      oParam.dDateTimeEnd = null;
    }
    dispatch(changeVigenciaSOLTC(oParam));
  };

  const handleChangeTimeStart = (oEvent) => {
    var oParam = {};
    let fecha = null;
    if (oEvent) {
      fecha = dayjs(oEvent);
    }
    oParam.dDateTimeStart = fecha;
    oParam.dDateTimeEnd = null;
    dispatch(changeVigenciaSOLTC(oParam));
  };

  const handleChangeFechaEnd = (oEvent) => {
    var oParam = {
      dFechaFin: oEvent,
    };
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';

    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
      oParam.dDateRangeEnd = sFechaFinal;
      oParam.dDateTimeEnd = null;
    }
    // oParam.sFechaInicio = sFechaInicio;
    // oParam.sFechaFinal = sFechaFinal;
    if (oParam.dFechaFin != null) {
      oParam.bValidFechaEnd = true;
    }
    dispatch(changeVigenciaSOLTC(oParam));
  };

  const handleChangeTimeEnd = (oEvent) => {
    let fecha = null;
    if (oEvent) {
      fecha = dayjs(oEvent);
      if (solTipoCambioState.dDateRangeStart == solTipoCambioState.dDateRangeEnd) {
        if (
          (solTipoCambioState.dDateTimeStart.$d.getHours() == fecha.$d.getHours() &&
            solTipoCambioState.dDateTimeStart.$d.getMinutes() != fecha.$d.getMinutes()) ||
          solTipoCambioState.dDateTimeStart.$d.getHours() < fecha.$d.getHours()
        ) {
          var oParam = {};
          oParam.dDateTimeEnd = fecha;
          dispatch(changeVigenciaSOLTC(oParam));
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'No puede poner la misma hora y fecha',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else if (solTipoCambioState.dDateRangeStart != solTipoCambioState.dDateRangeEnd) {
        var oParam = {};
        oParam.dDateTimeEnd = fecha;
        dispatch(changeVigenciaSOLTC(oParam));
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No puede poner la misma hora y fecha',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      var oParam = {};
      oParam.dDateTimeEnd = fecha;
      dispatch(changeVigenciaSOLTC(oParam));
    }
  };

  const obligatoriedadCampos = (selectTipoFormulario) => {
    let formulario = <></>;
    if (selectTipoFormulario.length > 0) {
      let tipoFormulario = selectTipoFormulario[0].value;
      switch (tipoFormulario) {
        case 'Tipo_1':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={solTipoCambioState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={solTipoCambioState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={solTipoCambioState.bValidAgente}
                    value={solTipoCambioState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={solTipoCambioState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={solTipoCambioState.bValidEstado}
                    value={solTipoCambioState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={solTipoCambioState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia desde (*):'}></LabelForm>
                  <RowDoubleInput>
                    <DatePickerForm
                      width={'100%'}
                      dateFormat="dd/MM/yyyy"
                      selectsRange={false}
                      value={solTipoCambioState.dDateRangeStart}
                      onChange={(evnt) => handleChangeFechaStart(evnt)}
                      isClearable={true}
                      placeholderText="Ingrese"
                      locale="es"
                      showMonthDropdown
                      showYearDropdown
                      portalId="root-portal"
                      wrapperClassName={'date-wrapper-60'}
                      validate={solTipoCambioState.bValidFechaStart}
                    />
                    <TimePickerForm
                      format={'HH:mm'}
                      value={solTipoCambioState.dDateTimeStart}
                      onChange={(event) => handleChangeTimeStart(event)}
                      width={'37%'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia hasta (*):'}></LabelForm>
                  <RowDoubleInput>
                    <DatePickerForm
                      width={'100%'}
                      dateFormat="dd/MM/yyyy"
                      selectsRange={false}
                      value={solTipoCambioState.dDateRangeEnd}
                      onChange={(evnt) => handleChangeFechaEnd(evnt)}
                      isClearable={true}
                      placeholderText="Ingrese"
                      locale="es"
                      showMonthDropdown
                      showYearDropdown
                      portalId="root-portal"
                      wrapperClassName={'date-wrapper-60'}
                      validate={solTipoCambioState.bValidFechaEnd}
                      disabled={solTipoCambioState.dDateRangeStart.length > 0 ? false : true}
                      minDate={new Date(formatDateMMDDYYYY(solTipoCambioState.dDateRangeStart))}
                    />
                    <TimePickerForm
                      format={'HH:mm'}
                      value={solTipoCambioState.dDateTimeEnd}
                      onChange={(event) => handleChangeTimeEnd(event)}
                      width={'37%'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Tipo (*):'}></LabelForm>
                  <SelectForm
                    bValid={solTipoCambioState.bValidTipo}
                    value={solTipoCambioState.selectTipo}
                    handleChange={(e, v) => handleChangeTipo(v)}
                    options={[
                      { value: 'Compra', label: 'Compra' },
                      { value: 'Venta', label: 'Venta' },
                    ]}
                    placeholder={'Seleccione Tipo'}
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        default:
          <></>;
      }
    }
    return formulario;
  };

  const handleChangeMonedaBase = async (oEvent) => {
    var oParam = {};
    oParam.bValidMonedaBase = null;
    oParam.MonedaBase = '';
    if (oEvent) {
      oParam.MonedaBase = oEvent.value;
      oParam.bValidMonedaBase = true;
    }
    dispatch(onChangeRowsSelectMonedaBaseTableSOLTC(oParam));
  };

  const handleChangeMonedaDestino = async (oEvent) => {
    var oParam = {};
    oParam.bValidMonedaDestino = null;
    oParam.MonedaDestino = '';
    if (oEvent) {
      oParam.MonedaDestino = oEvent.value;
      oParam.bValidMonedaDestino = true;
    }
    dispatch(onChangeRowsSelectMonedaDestinoTableSOLTC(oParam));
  };

  const handleChangeMonedaRango = async (oEvent) => {
    var oParam = {};
    oParam.bValidMonedaRango = null;
    oParam.MonedaRango = '';
    if (oEvent) {
      oParam.MonedaRango = oEvent.value;
      oParam.bValidMonedaRango = true;
    }
    dispatch(onChangeRowsSelectMonedaRangoTableSOLTC(oParam));
  };

  const handleChangeFactorBase = async (oEvent) => {
    var oParam = {};
    oParam.bValidFactorBase = null;
    oParam.FactorBase = '';
    if (oEvent) {
      oParam.FactorBase = oEvent.value;
      oParam.bValidMonedaBase = true;
    }
    dispatch(onChangeRowsFactorBaseTableSOLTC(oParam));
  };

  const handleChangeFactorDestino = async (oEvent) => {
    var oParam = {};
    oParam.bValidFactorDestino = null;
    oParam.FactorDestino = '';
    if (oEvent) {
      oParam.FactorDestino = oEvent.value;
      oParam.bValidMonedaDestino = true;
    }
    dispatch(onChangeRowsFactorDestinoTableSOLTC(oParam));
  };

  const handleChangeCantidadBase = (values) => {
    // var monto = oEvent.target.value;
    var cantidadMonedaBase = values.value;
    var validate = null;
    if (cantidadMonedaBase.length > 0) {
      validate = true;
    }

    let oParam = {
      cantidadMonedaBase: cantidadMonedaBase,
      bValidCantidadMonedaBase: validate,
    };

    dispatch(onChangeRowsCambioBaseTableSOLTC(oParam));
    // dispatch(changeMontoMaximoSOLTC(oParam));
  };

  const handleChangeCantidadDestino = (values) => {
    // var monto = oEvent.target.value;
    var cantidadMonedaDestino = values.value;
    var validate = null;
    if (cantidadMonedaDestino.length > 0) {
      validate = true;
    }

    let oParam = {
      cantidadMonedaDestino: cantidadMonedaDestino,
      bValidCantidadMonedaDestino: validate,
    };

    dispatch(onChangeRowsCambioDestinoTableSOLTC(oParam));
    // dispatch(changeMontoMaximoSOLTC(oParam));
  };

  const handleChangeMinimo = (values) => {
    // var monto = oEvent.target.value;
    var Minimo = values.value;
    var validate = null;
    if (Minimo.length > 0) {
      validate = true;
    }

    if (!ValidarRangoMinimo(values)) {
      validate = false;
    }

    let oParam = {
      Minimo: Minimo,
      bValidMinimo: validate,
    };

    dispatch(onChangeRowsMinimoTableSOLTC(oParam));
    // dispatch(changeMontoMaximoSOLTC(oParam));
  };

  const handleChangeMaximo = (values) => {
    // var monto = oEvent.target.value;

    var Maximo = values.value;
    var validate = null;
    if (Maximo.length > 0) {
      validate = true;
    }
    let validarRango = ValidarRangoMaximo(values);
    if (!validarRango) {
      validate = false;
    }

    let oParam = {
      Maximo: Maximo,
      bValidMaximo: validate,
    };

    dispatch(onChangeRowsMaximoTableSOLTC(oParam));
    // dispatch(changeMontoMaximoSOLTC(oParam));
  };

  const ValidarRangoMinimo = (values) => {
    let valido = true;
    if (
      solTipoCambioState.detalleConversion.filter(
        (r) =>
          r.id != solTipoCambioState.formMonedaCambio.id &&
          r.MonedaBase == solTipoCambioState.formMonedaCambio.MonedaBase &&
          r.MonedaDestino == solTipoCambioState.formMonedaCambio.MonedaDestino &&
          r.MonedaRango == solTipoCambioState.formMonedaCambio.MonedaRango
      ).length > 0
    ) {
      solTipoCambioState.detalleConversion
        .filter(
          (r) =>
            r.id != solTipoCambioState.formMonedaCambio.id &&
            r.MonedaBase == solTipoCambioState.formMonedaCambio.MonedaBase &&
            r.MonedaDestino == solTipoCambioState.formMonedaCambio.MonedaDestino &&
            r.MonedaRango == solTipoCambioState.formMonedaCambio.MonedaRango
        )
        .map((r) => {
          if (
            parseInt(r.Minimo) * parseInt(r.FactorRango) <=
              parseInt(values.value) * parseInt(solTipoCambioState.formMonedaCambio.FactorRango) &&
            parseInt(values.value) * parseInt(solTipoCambioState.formMonedaCambio.FactorRango) <=
              parseInt(r.Maximo) * parseInt(r.FactorRango)
          ) {
            valido = false;
            return;
          }
          return;
        });
    } else {
      if (
        parseInt(values.value) * parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >=
        parseInt(solTipoCambioState.formMonedaCambio.Maximo) *
          parseInt(solTipoCambioState.formMonedaCambio.FactorRango)
      ) {
        valido = false;
        return;
      }
    }

    return valido;
  };

  const ValidarRangoMaximo = (values) => {
    let valido = true;
    if (
      solTipoCambioState.detalleConversion.filter(
        (r) =>
          r.id != solTipoCambioState.formMonedaCambio.id &&
          r.MonedaBase == solTipoCambioState.formMonedaCambio.MonedaBase &&
          r.MonedaDestino == solTipoCambioState.formMonedaCambio.MonedaDestino &&
          r.MonedaRango == solTipoCambioState.formMonedaCambio.MonedaRango
      ).length > 0
    ) {
      solTipoCambioState.detalleConversion
        .filter(
          (r) =>
            r.id != solTipoCambioState.formMonedaCambio.id &&
            r.MonedaBase == solTipoCambioState.formMonedaCambio.MonedaBase &&
            r.MonedaDestino == solTipoCambioState.formMonedaCambio.MonedaDestino &&
            r.MonedaRango == solTipoCambioState.formMonedaCambio.MonedaRango
        )
        .map((r) => {
          if (
            parseInt(solTipoCambioState.formMonedaCambio.Minimo) *
              parseInt(solTipoCambioState.formMonedaCambio.FactorRango) <
            parseInt(r.Minimo) * parseInt(r.FactorRango)
          ) {
            if (
              parseInt(solTipoCambioState.formMonedaCambio.Minimo) *
                parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >=
                parseInt(values.value) *
                  parseInt(solTipoCambioState.formMonedaCambio.FactorRango) ||
              parseInt(values.value) * parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >=
                parseInt(r.Minimo) * parseInt(r.FactorRango)
            ) {
              valido = false;
              return;
            }
          } else if (
            parseInt(solTipoCambioState.formMonedaCambio.Maximo) *
              parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >
            parseInt(r.Maximo) * parseInt(r.FactorRango)
          ) {
            if (
              parseInt(solTipoCambioState.formMonedaCambio.Minimo) *
                parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >=
                parseInt(values.value) *
                  parseInt(solTipoCambioState.formMonedaCambio.FactorRango) ||
              parseInt(values.value) * parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >=
                parseInt(r.Maximo) * parseInt(r.FactorRango)
            ) {
              valido = false;
              return;
            }
          }
          return;
        });
    } else {
      if (
        parseInt(solTipoCambioState.formMonedaCambio.Minimo) *
          parseInt(solTipoCambioState.formMonedaCambio.FactorRango) >=
        parseInt(values.value) * parseInt(solTipoCambioState.formMonedaCambio.FactorRango)
      ) {
        valido = false;
        return;
      }
    }

    return valido;
  };

  const columns = [
    {
      field: 'FactorBase',
      headerName: 'Factor',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'cantidadMonedaBase',
      headerName: 'Conversion',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'MonedaBase',
      headerName: 'Moneda',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'FactorDestino',
      headerName: 'Factor',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'cantidadMonedaDestino',
      headerName: 'Conversion',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'MonedaDestino',
      headerName: 'Moneda',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'MonedaRango',
      headerName: 'Moneda',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'FactorRango',
      headerName: 'Factor',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Minimo',
      headerName: 'Minimo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Maximo',
      headerName: 'Maximo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Accion',
      headerName: '',
      width: 75,
      renderCell: (params) => (
        <>
          <Button
            icon={faEdit}
            action={(event) => onOpenDrawer(params.id)}
            isAction={false}
            type="primary"
            isOnlyIcon={true}
          />
          <Button
            icon={faMinus}
            action={(event) => onDeleteRowDetalleConversion(params.id)}
            isAction={false}
            type="primary"
            isOnlyIcon={true}
          />
        </>
      ),
    },
  ];

  const obligatoriedadCamposDetalle = (selectTipoFormulario) => {
    let formulario = <></>;
    if (selectTipoFormulario.length > 0) {
      let tipoFormulario = selectTipoFormulario[0].value;
      switch (tipoFormulario) {
        case 'Tipo_1':
          formulario = (
            <>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <SubTitle subtitle="2. Registros de Monedas de Conversión." />
                <RowFlex
                  style={{
                    alignItems: 'end',
                    justifyContent: 'end',
                    columnGap: 10,
                    marginBottom: 10,
                  }}
                >
                  <Button
                    action={(e) => onOpenDrawer()}
                    name="Agregar"
                    isAction={true}
                    type="primary"
                  />
                  <Button
                    action={(e) => onClearTablaConversiones()}
                    name="Limpiar Tabla"
                    isAction={false}
                    type="warning"
                  />
                </RowFlex>
                <TableGrid
                  experimentalFeatures={{ columnGrouping: true }}
                  columns={columns}
                  rows={solTipoCambioState.detalleConversion}
                  pageSize={15}
                  getRowId={(row) => row.id}
                  columnGroupingModel={[
                    {
                      groupId: 'BASE',
                      headerAlign: 'center',
                      children: [
                        ,
                        { field: 'FactorBase' },
                        { field: 'MonedaBase' },
                        { field: 'cantidadMonedaBase' },
                      ],
                    },
                    {
                      groupId: 'DESTINO',
                      headerAlign: 'center',
                      children: [
                        { field: 'FactorDestino' },
                        { field: 'MonedaDestino' },
                        { field: 'cantidadMonedaDestino' },
                      ],
                    },
                    {
                      groupId: 'RANGO',
                      headerAlign: 'center',
                      children: [
                        { field: 'MonedaRango' },
                        { field: 'FactorRango' },
                        { field: 'Minimo' },
                        { field: 'Maximo' },
                      ],
                    },
                  ]}
                />
                {/* <AddDeleteTableRowsCaracteristica
                  rowsData={solTipoCambioState.detalleConversion}
                  handleChangeMoneda={handleChangeMoneda}
                  handleChangeMonedaConversion={handleChangeMonedaConversion}
                  handleChangeTipo={handleChangeTipo}
                  handleChangeInputCantidadRangoDesde={handleChangeInputCantidadRangoDesde}
                  handleChangeInputCantidadMonedaConversion={
                    handleChangeInputCantidadMonedaConversion
                  }
                  addTableRows={onAddMonedaConversion}
                  onDeleteRowDetalleConversion={onDeleteRowDetalleConversion}
                  habilitarMonedasConversion={habilitarMonedasConversion}
                /> */}
              </ColForm>
            </>
          );
          break;
        default:
          <></>;
      }
    }
    return formulario;
  };
  const onCloseDrawer = () => {
    dispatch(onCleanFormTipoCambio());
    setOpenDrawer(false);
  };
  const onLimpiarDrawer = () => {
    dispatch(onCleanFormTipoCambio());
  };

  const onGrabarDrawer = () => {
    let find = solTipoCambioState.detalleConversion.filter(
      (r) => r.id === solTipoCambioState.formMonedaCambio.id
    );
    if (find.length > 0) {
      dispatch(editDetalleConversionTableSOLTC(solTipoCambioState.formMonedaCambio));
    } else {
      dispatch(addDetalleConversionTableSOLTC(solTipoCambioState.formMonedaCambio));
    }
    dispatch(onCleanFormTipoCambio());
    setOpenDrawer(false);
  };

  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : solTipoCambioState.idSolucion == null ? (
    <Content>No se encontró información</Content>
  ) : (
    <>
      <Content>
        <ColFlex>
          <ContentForm>
            <SubTitle subtitle="1. Registrar datos generales de la solución." />
            <RowForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Tipo Fomulario (*):'} />
                  <SelectForm
                    isNoClearable={true}
                    bValid={solTipoCambioState.bValidselectTipoFormulario}
                    value={solTipoCambioState.selectTipoFormulario}
                    handleChange={(e, v) => handleChangeTipoFormulario(v)}
                    options={TipoFormularioTipoCambio}
                    placeholder={'Seleccione Tipo Formulario'}
                  />
                </RowForm>
              </ColForm>
              {obligatoriedadCampos(solTipoCambioState.selectTipoFormulario)}

              {obligatoriedadCamposDetalle(solTipoCambioState.selectTipoFormulario)}
            </RowForm>
            <RowFooterButton>
              <Button
                action={(e) => onPressCancelar()}
                name="Cancelar"
                isAction={true}
                type="warning"
              />
              <Button
                action={(e) => limpiarForm(e)}
                name="Limpiar"
                isAction={true}
                type="primary"
              />
              <Button
                action={(e) => onPressGrabar(e)}
                name="Grabar"
                isAction={true}
                type="secondary"
              />
            </RowFooterButton>
          </ContentForm>
        </ColFlex>

        <ModalConfirmation
          showModal={showModalCancelar}
          closeModal={(e) => onCloseModalCancelar()}
          title="¿Confirmación?"
          subtitle="¿Esta seguro que desea cancelar la creación del la Solución de Tipo de Cambio?"
          action={(e) => onConfirmarConcepto(e)}
          labelButtonCancel="Cancelar"
          labelButtonConfirm="Confirmar"
        />
        {loading === true ? <LoadingSpinner /> : <></>}
      </Content>
      <Drawer
        title={<Title>Moneda Conversión</Title>}
        placement="right"
        width={'33%'}
        closable={false}
        onClose={onCloseDrawer}
        open={openDrawer}
        footer={
          <RowFooterButton>
            <Button
              action={(e) => onCloseDrawer()}
              name="Cancelar"
              isAction={true}
              type="warning"
            />
            <Button
              action={(e) => onLimpiarDrawer()}
              name="Limpiar"
              isAction={true}
              type="primary"
            />
            <Button
              action={(e) => onGrabarDrawer()}
              name="Guardar"
              isAction={true}
              type="secondary"
            />
          </RowFooterButton>
        }
      >
        <SubTitle subtitle={'Base'} />
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Factor'} />
            <SelectForm
              bValid={true}
              value={
                solTipoCambioState.formMonedaCambio.FactorBase
                  ? [
                      {
                        value: solTipoCambioState.formMonedaCambio.FactorBase,
                        label: solTipoCambioState.formMonedaCambio.FactorBase,
                      },
                    ]
                  : []
              }
              handleChange={(event, v) => handleChangeFactorBase(v)}
              options={[
                { value: 1, label: 1 },
                { value: 10, label: 10 },
                { value: 100, label: 100 },
                { value: 1000, label: 1000 },
                { value: 10000, label: 10000 },
              ]}
              placeholder={'Seleccione'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Conversion'} />

            <InputNumberFormat
              value={solTipoCambioState.formMonedaCambio.cantidadMonedaBase}
              validate={solTipoCambioState.formMonedaCambio.bValidCantidadMonedaBase}
              allowNegative={false}
              fixedDecimalScale={false}
              decimalScale={3}
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.event != null) {
                  handleChangeCantidadBase(values);
                }
              }}
              min={0}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Moneda'} />
            <SelectForm
              bValid={true}
              value={solTipoCambioState.listaMoneda.filter(
                (v) => v.value == solTipoCambioState.formMonedaCambio.MonedaBase
              )}
              handleChange={(event, v) => handleChangeMonedaBase(v)}
              options={solTipoCambioState.listaMoneda.filter(function (item, index) {
                return item;
              })}
              placeholder={'Seleccione'}
            />
          </RowForm>
        </ColForm>
        <SubTitle subtitle={'Destino'} />
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Factor'} />
            <SelectForm
              bValid={true}
              value={
                solTipoCambioState.formMonedaCambio.FactorDestino
                  ? [
                      {
                        value: solTipoCambioState.formMonedaCambio.FactorDestino,
                        label: solTipoCambioState.formMonedaCambio.FactorDestino,
                      },
                    ]
                  : []
              }
              handleChange={(event, v) => handleChangeFactorDestino(v)}
              options={[
                { value: 1, label: 1 },
                { value: 10, label: 10 },
                { value: 100, label: 100 },
                { value: 1000, label: 1000 },
                { value: 10000, label: 10000 },
              ]}
              placeholder={'Seleccione'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Conversion'} />

            <InputNumberFormat
              value={solTipoCambioState.formMonedaCambio.cantidadMonedaDestino}
              validate={solTipoCambioState.formMonedaCambio.bValidCantidadMonedaDestino}
              allowNegative={false}
              fixedDecimalScale={false}
              decimalScale={3}
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.event != null) {
                  handleChangeCantidadDestino(values);
                }
              }}
              min={0}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Moneda'} />
            <SelectForm
              bValid={true}
              value={solTipoCambioState.listaMoneda.filter(
                (v) => v.value == solTipoCambioState.formMonedaCambio.MonedaDestino
              )}
              handleChange={(event, v) => handleChangeMonedaDestino(v)}
              options={solTipoCambioState.listaMoneda.filter(
                (v) => v.value != solTipoCambioState.formMonedaCambio.MonedaBase
              )}
              placeholder={'Seleccione'}
            />
          </RowForm>
        </ColForm>
        <SubTitle subtitle={'Rango'} />

        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Moneda'} />

            <SelectForm
              bValid={true}
              value={solTipoCambioState.listaMoneda.filter(
                (v) => v.value == solTipoCambioState.formMonedaCambio.MonedaRango
              )}
              handleChange={(event, v) => handleChangeMonedaRango(v)}
              options={solTipoCambioState.listaMoneda.filter(
                (v) =>
                  v.value == solTipoCambioState.formMonedaCambio.MonedaBase ||
                  v.value == solTipoCambioState.formMonedaCambio.MonedaDestino
              )}
              placeholder={'Seleccione'}
              isDisabled={
                solTipoCambioState.formMonedaCambio.MonedaBase &&
                solTipoCambioState.formMonedaCambio.MonedaDestino
                  ? false
                  : true
              }
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Factor'} />

            <InputNumberFormat
              value={solTipoCambioState.formMonedaCambio.FactorRango}
              allowNegative={false}
              fixedDecimalScale={false}
              decimalScale={0}
              min={0}
              disabled={true}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Minimo'} />

            <InputNumberFormat
              value={solTipoCambioState.formMonedaCambio.Minimo}
              validate={solTipoCambioState.formMonedaCambio.bValidMinimo}
              allowNegative={false}
              decimalScale={0}
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.event != null) {
                  handleChangeMinimo(values);
                }
              }}
              min={0}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Maximo'} />
            <InputNumberFormat
              value={solTipoCambioState.formMonedaCambio.Maximo}
              validate={solTipoCambioState.formMonedaCambio.bValidMaximo}
              allowNegative={false}
              decimalScale={0}
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.event != null) {
                  handleChangeMaximo(values);
                }
              }}
              min={0}
              disabled={solTipoCambioState.formMonedaCambio.Minimo ? false : true}
            />
          </RowForm>
        </ColForm>
      </Drawer>
    </>
  );
};
