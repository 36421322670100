import React from 'react';
import { Modal } from 'antd';
import { Button, Input, LabelForm, SelectForm, TableGrid } from '../../../../../components';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';

import { ColForm, RowButton, RowForm, Title } from '../../../../../styles';

export const ModalSimulacion = (props) => {
  const columns = [
    {
      field: 'TIPO_REGISTRO',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'DESC_SOLUCION',
      headerName: 'Descripcion',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'MONEDA_SOLUCION',
      headerName: 'Moneda',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'FECHA_REGISTRO',
      headerName: 'Fecha',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'IMPORTE_REGISTRO',
      headerName: 'Importe',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  return (
    <>
      <Modal
        open={props.open}
        title={<Title>Simulación de Prestamo</Title>}
        closable={false}
        footer={[
          <Button
            action={(oEvent) => props.closeModal(oEvent)}
            name={'Cerrar'}
            isAction={true}
            type="warning"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Lista de simulación : '}></LabelForm>

            <TableGrid
              columns={columns}
              rows={props.lista}
              pageSize={10}
              getRowId={(row) => row.id}
            />
          </RowForm>
        </ColForm>
      </Modal>
    </>
  );
};
