import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFetchAndLoad } from '../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { PruebaCognito, login, signIn } from '../../services/public.service';
import {
  changeDataChangePassword,
  changePassword,
  changeTenant,
  changeUserName,
  limpiarCamposLogin,
  onLogin,
  onTenant,
} from '../../redux/states';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import logoPanel from '../../assets/images/zcf.png';
import { LoadingSpinner } from '../../components';
import { theme } from './../../common/constants';
import {
  BackgroundLogin,
  ButtonLogin,
  ColForm,
  ContainerInputLogin,
  ContainLogin,
  FormLogin,
  ImgLogin,
  InputLogin,
  LabelLogin,
  ModalLogin,
  RowForm,
} from '../../styles';
import UserPool from '../../UserPool';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const Login = () => {
  const loginState = useSelector((store) => store.login);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [showPassword, setShowPassword] = useState(false);
  const [disabledTenant, setDisabledTenant] = useState(false);
  const { tenantUrl } = useParams();

  useEffect(() => {
    if (tenantUrl) {
      let tenantDecode = atob(tenantUrl);
      dispatch(changeTenant({ tenant: tenantDecode }));
      setDisabledTenant(true);
    }
  }, []);

  const handlechangeTenant = (oEvent) => {
    let tenant = oEvent.target.value;
    let oParam = {
      tenant: tenant,
    };
    dispatch(changeTenant(oParam));
  };

  const handlechangeUserName = (oEvent) => {
    let userName = oEvent.target.value;
    let oParam = {
      userName: userName,
    };
    dispatch(changeUserName(oParam));
  };

  const handlechangePassword = (oEvent) => {
    let password = oEvent.target.value;
    let oParam = {
      password: password,
    };
    dispatch(changePassword(oParam));
  };

  const onPressIniciarSesion = async (event) => {
    event.preventDefault();

    const responseCognito = await signIn(loginState.userName, loginState.password);

    if (responseCognito.statusCode == 200) {
      if (responseCognito.name === 'Authentication') {
        localStorage.setItem('auth-token', responseCognito.result.AccessToken);
        localStorage.setItem('id-token', responseCognito.result.IdToken);
        localStorage.setItem('refresh-token', responseCognito.result.RefreshToken);

        // const resPruebaCognito = await callEndpoint(
        //   PruebaCognito(responseCognito.result.AccessToken)
        // );

        const responseLogin = await callEndpoint(
          login(
            {
              email: loginState.userName,
              password: loginState.password,
              tenant: loginState.tenant,
            },
            responseCognito.result.IdToken
          )
        );
        if (responseLogin != null) {
          if (responseLogin.data) {
            localStorage.setItem('x-token', responseLogin.data.token);
            localStorage.setItem('x-tenant', loginState.tenant);
            dispatch(onTenant(loginState.tenant));
            dispatch(limpiarCamposLogin());
            dispatch(
              onLogin({
                name: responseLogin.data.usuario,
                uid: responseLogin.data.id,
                isSuperAdmin: responseLogin.data.Admin,
                permisos: responseLogin.data.permisos,
                foto: responseLogin.data.foto,
              })
            );
            navigate('/dashboard');
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Usuario y/o clave incorrectos.',
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        navigate('/change_password');
        dispatch(changeDataChangePassword({ sessionCP: responseCognito.result }));
      }
    } else if (responseCognito.statusCode == 400) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Usuario y/o clave incorrectos.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <BackgroundLogin>
      <ContainLogin>
        <ModalLogin className="row no-gutter">
          <RowForm style={{ alignSelf: 'flex-start' }}>
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '15px 0px ' }}>
              <ImgLogin src={logoPanel} alt="Zcflow Logo" />
            </ColForm>
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
              <h2>Inicio de Sesión</h2>
            </ColForm>
            <FormLogin onSubmit={onPressIniciarSesion}>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Organización</LabelLogin>
                <ContainerInputLogin>
                  <InputLogin
                    placeholder="Ingrese organización"
                    type={'text'}
                    value={loginState.tenant}
                    onChange={(oEvent) => handlechangeTenant(oEvent)}
                    disabled={disabledTenant}
                  ></InputLogin>
                </ContainerInputLogin>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Usuario</LabelLogin>
                <ContainerInputLogin>
                  <InputLogin
                    placeholder="Ingrese usuario"
                    type={'text'}
                    value={loginState.userName}
                    onChange={(oEvent) => handlechangeUserName(oEvent)}
                  ></InputLogin>
                </ContainerInputLogin>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <LabelLogin>Contraseña</LabelLogin>
                <ContainerInputLogin>
                  <InputLogin
                    placeholder="Ingrese contraseña"
                    type={showPassword ? 'text' : 'password'}
                    value={loginState.password}
                    onChange={(oEvent) => handlechangePassword(oEvent)}
                  ></InputLogin>
                  {!showPassword ? (
                    <AiOutlineEye
                      className="login"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="login"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </ContainerInputLogin>
                {/* <a href="/recoveryPassword">Olvidaste contraseña</a> */}
                <a href="">Olvidaste contraseña</a>
              </ColForm>
              <ColForm
                style={{ marginTop: '20px', padding: '5px 0px ' }}
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <ButtonLogin type="submit">Ingresar</ButtonLogin>
              </ColForm>
            </FormLogin>
          </RowForm>
        </ModalLogin>
      </ContainLogin>
      {loading ? <LoadingSpinner /> : <></>}
    </BackgroundLogin>
  );
};
