export const initialAgregarNodo = {
  iId: '',
  sCadena: '',
  nombreNodo: '',
  iIdConceptoNodo: null,
  sConceptoNodo: '',
  error: false,
  isDisabled: false,
  disabledMirror: false,
};

export const initialEditarNodo = {
  iId: '',
  sCadena: '',
  nombreNodo: '',
  iIdConceptoNodo: null,
  sConceptoNodo: '',
  error: false,
};
