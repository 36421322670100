import { createSlice } from '@reduxjs/toolkit';
const initialState = { title: 'zCFlow' };
export const navBarSlice = createSlice({
  name: 'navBar',
  initialState: initialState,
  reducers: {
    updateTitle: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const { updateTitle } = navBarSlice.actions;
export default navBarSlice.reducer;
