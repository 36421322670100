import React, { useEffect, useState } from 'react';
import { TabBarCreacion } from '../../../../components';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ColFlex, ContentForm } from '../../../../styles';
import { ListarEtiqueta, ListarRegistroPropio } from './components';
import { updateTitle } from '../../../../redux/states';
import { useDispatch } from 'react-redux';

export const TabRegistroPropio = () => {
  const [showTabProceso, setShowTabProceso] = useState(true);
  const [tabProceso, setTabProceso] = useState(0);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { tab } = useParams();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar registro propios' }));
    if (tab == 'Listado_Propio') {
      onTabProcesoGeneral();
    } else if (tab == 'Listado_Etiquetas') {
      obTabCaracteristicas();
    }
  }, []);

  const onTabProcesoGeneral = () => {
    setTabProceso(0);
    navigate('/dashboard/flujo_caja/propio/listar/Listado_Propio');
  };

  const obTabCaracteristicas = () => {
    setTabProceso(1);
    navigate('/dashboard/flujo_caja/propio/listar/Listado_Etiquetas');
  };

  return (
    <ColFlex>
      {showTabProceso ? (
        <TabBarCreacion
          onClickTab1={onTabProcesoGeneral}
          tabProceso={tabProceso}
          labelTab1={'Listado'}
          onClickTab2={obTabCaracteristicas}
          labelTab2={'Etiquetas'}
        />
      ) : (
        <></>
      )}
      {tabProceso === 0 ? (
        <ColFlex style={{ padding: '15px' }}>
          <ListarRegistroPropio setShowTabProceso={setShowTabProceso} />
        </ColFlex>
      ) : (
        <ColFlex>
          <ListarEtiqueta setShowTabProceso={setShowTabProceso} />
        </ColFlex>
      )}
    </ColFlex>
  );
};
