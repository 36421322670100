import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const socioInitialState = {
  listaSocio: [],
  filterListaSocio: [],
  searchtextHomologacion: '',
  oModal: {
    nId: null,
    tNombreSocio: '',
    tDocumento: '',
    tContacto: '',
    tDireccion: '',
    tCorreo: '',
    tTelefono: '',
    tEditLogo: 0,
    tLogo: [],
  },
  listaDocumento: [],
  filterListaDocumento: [],
  searchtextHomologacionDocumento: '',
  oModalDocumento: {
    nId: null,
    tTipo: [],
    tDescripcion: '',
    tNombre: '',
    tDocumento: [],
    tFecha: '',
  },
  oModalComDocumento: [],
  listaComSocio: [],
  selectComSocio: [],
};
export const socioSlice = createSlice({
  name: 'socio',
  initialState: socioInitialState,
  reducers: {
    setListaSocio: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeSearchSocio: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaSocio = estadoInspeccionado.listaSocio.filter(function (item, index) {
        return (
          item.Nombre_Socio.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.Documento.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaSocio: filterListaSocio,
        searchtextHomologacion: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    changeModalSocio: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarModalSocio: (state, action) => {
      var obj = { oModal: socioInitialState.oModal };
      return { ...state, ...obj };
    },

    setListaDocumento: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeSearchDocumento: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaDocumento = estadoInspeccionado.listaSocio.filter(function (item, index) {
        return item.Documento.toLowerCase().includes(action.payload.toLowerCase());
      });
      var obj = {
        filterListaDocumento: filterListaDocumento,
        searchtextHomologacionDocumento: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    changeModalDocumento: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarModalDocumento: (state, action) => {
      var obj = { oModalDocumento: socioInitialState.oModalDocumento };
      return { ...state, ...obj };
    },
    changeModalComDocumento: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarModalComDocumento: (state, action) => {
      var obj = {
        oModalComDocumento: socioInitialState.oModalComDocumento,
        listaComSocio: socioInitialState.listaComSocio,
        selectComSocio: socioInitialState.selectComSocio,
      };
      return { ...state, ...obj };
    },
  },
});
export const {
  setListaSocio,
  changeSearchSocio,
  changeModalSocio,
  limpiarModalSocio,
  setListaDocumento,
  changeSearchDocumento,
  changeModalDocumento,
  limpiarModalDocumento,
  changeModalComDocumento,
  limpiarModalComDocumento,
} = socioSlice.actions;
export default socioSlice.reducer;
