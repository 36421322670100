import { createSlice } from '@reduxjs/toolkit';
import { MenuItems } from '../../common';
export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    MenuItems: MenuItems,
    activeSubMenu: '',
  },
  reducers: {
    selectActiveMenu: (state, action) => {
      return { ...state, ...action.payload };
    },
    expandListItems: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetActiveSubMenu: (state, { payload }) => {
      state.activeSubMenu = payload;
    },
    updateListItem: (state, action) => {
      state.MenuItems.map((item, i) => {
        if (item.id === action.payload) {
          item.onExpand = !item.onExpand;
        } else {
          item.onExpand = false;
        }
        return { ...item };
      });
      // return {
      //   ...state,
      //   MenuItems: state.MenuItems.map((content, i) =>
      //     content.id === action.payload
      //       ? { ...content, onExpand: !content.onExpand }
      //       : content
      //   ),
      // };
    },
    limpiarMenu: (state, action) => {
      return {
        ...state,
        ...{
          MenuItems: MenuItems,
          activeSubMenu: '',
        },
      };
    },
  },
});
export const {
  selectActiveMenu,
  expandListItems,
  updateListItem,
  onSetActiveSubMenu,
  limpiarMenu,
} = menuSlice.actions;
export default menuSlice.reducer;
