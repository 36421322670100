import React from 'react';
import { useEffect } from 'react';
import { LoadingForm } from '../../../components';
import { theme } from '../../../common';
import { CardDash, Title } from '../../../styles';
import Chart from 'react-apexcharts';

export const Balances = (props) => {
  const [dataSol, setDataSol] = React.useState([]);

  useEffect(() => {
    let listaData = [],
      datosFinal = [];
    if (props.data.length > 0) {
      props.data.map((a) => {
        if (a.TipoConcepto == 'Saldo') {
          let dato = a.Items.filter((a) => a.Caracteristica == 'Importe');
          listaData.push({ name: a.Posicion, importe: parseInt(dato[0].Valor) });
        }
        return a;
      });

      const dataSinDuplicados = listaData.reduce((acumulador, valorActual) => {
        const elementoYaExiste = acumulador.find((elemento) => elemento.name === valorActual.name);
        if (elementoYaExiste) {
          return acumulador.map((elemento) => {
            if (elemento.name === valorActual.name) {
              return {
                ...elemento,
                importe: elemento.importe + (valorActual.importe ? valorActual.importe : 0),
              };
            }

            return elemento;
          });
        }

        return [...acumulador, valorActual];
      }, []);

      dataSinDuplicados.sort((a, b) => b.importe - a.importe);
      dataSinDuplicados.map((b) => {
        datosFinal.push({ x: b.name, y: b.importe });
      });

      setDataSol(datosFinal);
    }
  }, [props.data]);

  // // chart
  const options = {
    chart: {
      fontFamily: theme.font.family,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    colors: theme.colorsDashboard,
    tooltip: {
      y: {
        formatter: function (value) {
          return new Intl.NumberFormat('en-US').format(value);
        },
      },
      style: {
        fontFamily: theme.font.family,
      },
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: true,
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  return (
    <CardDash title={<Title>{props.titulo}</Title>}>
      {props.loading ? (
        <LoadingForm />
      ) : (
        <>
          {/* <Chart options={options} series={[{ data: dataSol }]} type="treemap" height={300} /> */}
          <Chart options={options} series={[{ data: dataSol }]} type="treemap" height={300} />
        </>
      )}
    </CardDash>
  );
};
