import React from 'react';
import styled from 'styled-components';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { theme } from '../../common';
export const MultiSelectCheckboxes = (props) => {
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} seleccionadas`;
    }
  }
  return (
    <ContainerSelect width={props.width} isDisabled={props.disabled}>
      <CustomMultiSelectCheckboxes
        styles={{
          menu: (state) => ({
            color: theme.colors.text,
          }),
          // option: (state) => ({
          //   padding: '5px 10px',
          //   ':hover': {
          //     color: theme.colors.warning,
          //   },
          // }),
        }}
        // hide
        options={[...props.options]}
        placeholderButtonLabel={props.placeholderButtonLabel}
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={props.selectedOptions}
        onChange={props.onChange}
        setState={props.setSelectedOptions}
        disabled={props.disabled}
      />
    </ContainerSelect>
  );
};
const ContainerSelect = styled.div`
  width: ${({ width }) => (width ? width : '98%')};
  height: 32px;
  button.css-1r4vtzz,
  button.css-48ayfv {
    width: ${({ width }) => (width ? width : '98%')};
    color: ${theme.colors.text};
    height: 32px;
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    border: 1px solid ${theme.colors.line};
    box-shadow: none;
  }
  .css-1pcexqc-container {
    color: ${theme.colors.text};
  }
  .css-1jllj6i-control {
    min-height: 32px;
    margin: 4px;
    box-shadow: none;
    border-color: ${theme.colors.line};
  }
  .css-6y1x9t-control {
    min-height: 32px;
    margin: 4px;
    box-shadow: none;
    border-color: ${theme.colors.primary};

    :hover,
    :focus {
      border-color: ${theme.colors.primary};
    }
  }
  div {
    z-index: 20;
  }
`;
const CustomMultiSelectCheckboxes = styled(ReactMultiSelectCheckboxes)`
  .react-select-container {
    background: black;
  }
  .css-1pcexqc-container .sc-ddkEoM .bpsnvN {
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
  }
  .css-1vr111p-option {
    background: ${theme.colors.white} !important;
    color: ${theme.colors.text} !important;
    border: none !important;
    :hover {
      color: ${theme.colors.white} !important;

      background: ${theme.colors.tertiary} !important;
    }
  }
  .css-1qprcsu-option {
    background: ${theme.colors.primary} !important;
    color: ${theme.colors.white} !important;
    border: none !important;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : '')} !important;
    input[type='checkbox'] {
      accent-color: ${theme.colors.white} !important;
    }
    :hover {
      background: ${theme.colors.secondary} !important;
    }
  }
  .css-1vr111p-option:hover {
    background: rgba(0, 0, 0, 0.03); //#FFE090 !important;
  }
  .css-lluylu-group {
    input {
      pointer-events: ${({ disabled }) => (disabled ? 'none !important' : '')} !important;
    }
  }
  .css-1v99tuv {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ${theme.font.family};
    font-size: 1.3em !important;
    /* pointer-events : none !important; */
  }

  .css-ik6y5r {
    background-color: white !important;
  }
  div {
    z-index: 20;
  }
`;
