import { permisos } from '../../../../../common';
import { PBRFunction } from '../../../../../components';

export const items = (onEliminar) => [
  {
    label: 'Eliminar',
    key: '1',
    onClick: onEliminar,
    // disabled: PBRFunction([permisos.ELIMINAR_POSICION]),
  },
];
