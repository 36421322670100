import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const registroFuenteInitialState = {
  rowCount: 0,
  listaRF: [],
  listaIDRF: [],
  searchBool: false,
  searchText: '',
  searchArray: [],
  pageSize: 15,
  page: 0,
  openModalEliminarRF: false,
  openModalImportRF: false,
  // selectColumn: [],
  // columnSelector: [],
  // selectedRowRF: [],

  idRegistro: null,
  fMonto: '',
  dateRegistroF: '',
  dateFechaVigenciaRegistroF: '',
  fechaRegistro: '',
  dateSelectedRegistroF: '',
  bValidMonto: null,
  selectAccesoRF: false,
  selectConcepto: [],
  selectPosicion: [],
  listaConceptos: [],
  rowsKeyData: [],
  listaPosicionFuente: [],
  selectMonedaPago: [],
  selectMoneda: [],
  listaMoneda: [],
};
export const registroFuenteSlice = createSlice({
  name: 'registroFuente',
  initialState: registroFuenteInitialState,
  reducers: {
    setSelectConceptoFuente: (state, action) => {
      let estadoInspeccionado = current(state);

      return { ...estadoInspeccionado, ...action.payload };
    },

    setSelectPosicionFuente: (state, action) => {
      let estadoInspeccionado = current(state);

      return { ...estadoInspeccionado, ...action.payload };
    },

    setSelectMontoRF: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setSelectMonedaRF: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setSelectMonedaPagoRF: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setSelectFechaRF: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setSelectFechaVigenciaRF: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    limpiarCamposRegistroF: (state, action) => {
      let estadoInspeccionado = current(state);
      return {
        ...estadoInspeccionado,
        ...{
          idRegistro: null,
          fMonto: '',
          dateRegistroF: '',
          dateFechaVigenciaRegistroF: '',
          dateSelectedRegistroF: '',
          bValidMonto: null,
          selectAccesoRF: false,
          selectConcepto: [],
          selectPosicion: [],
          listaConceptos: [],
          rowsKeyData: [],
          listaPosicionFuente: [],
          selectMonedaPago: [],
          selectMoneda: [],
          listaMoneda: [],
        },
      };
    },
    setDataEditarRegistroF: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setRowKeyDataValorRF: (state, action) => {
      state.rowsKeyData.map((item, index) => {
        if (index === action.payload.index) {
          item.valor = action.payload.valor;
          item.validate = action.payload.validate;
        }
        return { ...item };
      });
    },
    setSelectedAccesoRF: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaRF: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaRF: action.payload.lista,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListaIDRF: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        rowCount: action.payload.rowCount,
        listaIDRF: action.payload.listaIDRF,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setFiltroListaRF: (state, action) => {
      return { ...state, ...action.payload };
    },
    setOpenModalsRF: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  setListaRF,
  setListaIDRF,
  setFiltroListaRF,
  setOpenModalsRF,
  setListarConceptosFuente,
  setSelectConceptoFuente,
  onSetListaPosicionFuente,
  setSelectPosicionFuente,
  setFilterListarRegistrosF,
  filterListarEtiquetasF,
  changeNombreRegistroF,
  setSelectMontoRF,
  setSelectMonedaRF,
  setSelectMonedaPagoRF,
  setSelectFechaRF,
  setSelectFechaVigenciaRF,
  limpiarCamposRegistroF,
  changeSearchTextFuente,
  setDataEditarRegistroF,
  setRowsKeyDataRegistroF,
  setRowKeyDataValorRF,
  setListaEtiquetasFuente,
  setSelectedListaEtiquetaFuente,
  setValueFileUploaderFuente,
  setLimpiarListarEtiquetasEliminadasFuente,
  setSelectedAccesoRF,
} = registroFuenteSlice.actions;
export default registroFuenteSlice.reducer;
