import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
import moment from 'moment';

export const gestionInitialState = {
  listaFlujoCajaSol: [],
  filterListaFlujoCajaSol: [],
};
export const gestionSlice = createSlice({
  name: 'gestion',
  initialState: gestionInitialState,
  reducers: {
    onSetListaFlujoCajaGestion: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const { onSetListaFlujoCajaGestion } = gestionSlice.actions;
