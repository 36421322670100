import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
export const TabBarCreacion = (props) => {
  return (
    <ContainerTabBar marginTopContentBar={props.marginTopContentBar}>
      <ContainerTab onClick={props.onClickTab1} active={props.tabProceso === 0 ? true : false}>
        <TextTab active={props.tabProceso === 0 ? true : false}>{props.labelTab1}</TextTab>
      </ContainerTab>
      <ContainerTab
        onClick={props.onClickTab2}
        active={props.tabProceso === 1 ? true : false}
        isDisabled={props.disabledTab2}
      >
        <TextTab active={props.tabProceso === 1 ? true : false}>{props.labelTab2}</TextTab>
      </ContainerTab>
    </ContainerTabBar>
  );
};
const ContainerTabBar = styled.div`
  margin-top: ${({ marginTopContentBar }) => marginTopContentBar ?? '0px'};
  display: flex;
  flex-direction: row;
`;
const ContainerTab = styled.div`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'none' : 'pointer')};
  background: white;
  padding: 3px;
  display: flex;
  flex-direction: column;
  width: 150px;
  border-width: 2px 2px 0px 2px;
  border-style: solid;
  font-weight: ${({ active }) => (active === true ? 'bold' : 'normal')};
  border-radius: 5px 5px 0px 0px;
  border-color: ${({ active }) => (active === true ? theme.colors.primary : '#a3a4a9')};
  margin-top: ${({ active }) => (active === false ? '5px' : '0px')};
  transition: margin-top 400ms linear;
`;
const TextTab = styled.span`
  text-align: center;
  color: ${({ active }) => (active === true ? theme.colors.primary : '#48494D')};
  /* font-family: UbuntuBold; */
  margin: 4px 0px 4px 0px;
  font-size: 12px !important;
`;
