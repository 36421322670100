import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LoadingSpinner, PBR, SearchInputText, TableGrid } from '../../../components';
import { useFetchAndLoad } from '../../../common/hooks';
import {
  ColFlex,
  Column,
  ContentButton,
  ContentOption,
  Row,
  RowAccion,
  RowButton,
  RowFlex,
} from '../../../styles';
import {
  cargaLogoSocio,
  crearSocio,
  editarSocio,
  eliminarSocio,
  obtenerSocio,
  obtenerUrlImg,
} from '../../../services/public.service';
import {
  changeModalSocio,
  changeSearchSocio,
  limpiarModalSocio,
  setListaSocio,
  updateTitle,
} from '../../../redux/states';
import { useEffect } from 'react';
import { faEdit, faTrash } from '@fortawesome/fontawesome-free-solid';
import { ModalSocio } from './components';
import Swal from 'sweetalert2';
import AWS from 'aws-sdk';

export const ListarSocio = () => {
  const dispatch = useDispatch();
  const socioState = useSelector((store) => store.socio);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Listar Socios' }));
      loadSocio();
    })();
  }, []);

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(changeSearchSocio(oEvent.target.value));
    } else {
      dispatch(changeSearchSocio(''));
    }
  };

  const loadSocio = async () => {
    const responseListaSocio = await callEndpoint(obtenerSocio());
    var listaSocio = [];
    for (let index = 0; index < responseListaSocio.data.respuesta.aLista.length; index++) {
      const element = responseListaSocio.data.respuesta.aLista[index];
      listaSocio.push(element);
    }
    dispatch(setListaSocio({ listaSocio: listaSocio }));
  };

  const columns = [
    {
      field: 'Nombre_Socio',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Documento',
      headerName: 'Número de identificación fiscal',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Nombre_Contacto',
      headerName: 'Contacto',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',
      headerName: '',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      width: 75,
      renderCell: (params) => (
        <>
          <RowButton>
            <Button
              action={(oEvent) => onPressOpenModal(oEvent, params.row)}
              icon={faEdit}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
            <Button
              action={(oEvent) => onDeleteSocio(oEvent, params.row)}
              icon={faTrash}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        </>
      ),
    },
  ];

  const onDeleteSocio = async (e, data) => {
    Swal.fire({
      title: `Seguro que desea eliminar a ${data.Nombre_Socio}`,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseSocioDelete = await callEndpoint(eliminarSocio({ IDSocio: data.IDSocio }));
        if (responseSocioDelete.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseSocioDelete.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadSocio();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseSocioDelete.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const onPressOpenModal = async (e, datos) => {
    if (datos) {
      var modal = {};
      if (datos.Logo.length > 0) {
        const splitLogo = datos.Logo.split('/');
        const name = splitLogo[splitLogo.length - 1];
        const res = await obtenerUrlImg(datos.Logo);
        const response = await fetch(res, {
          mode: 'no-cors',
        });
        const data = await response.blob();
        let foto = new File([data], name, {
          type: data.type,
        });

        modal.nId = datos.IDSocio;
        modal.tNombreSocio = datos.Nombre_Socio;
        modal.tDocumento = datos.Documento;
        modal.tContacto = datos.Nombre_Contacto;
        modal.tDireccion = datos.Direccion;
        modal.tCorreo = datos.Correo;
        modal.tTelefono = datos.Telefono;
        modal.tEditLogo = 0;
        modal.tLogo = [foto];
      } else {
        modal.nId = datos.IDSocio;
        modal.tNombreSocio = datos.Nombre_Socio;
        modal.tDocumento = datos.Documento;
        modal.tContacto = datos.Nombre_Contacto;
        modal.tDireccion = datos.Direccion;
        modal.tCorreo = datos.Correo;
        modal.tTelefono = datos.Telefono;
        modal.tEditLogo = 0;
        modal.tLogo = [];
      }

      dispatch(changeModalSocio({ oModal: modal }));
    }
    setOpenModal(true);
  };

  const limpiarModal = () => {
    dispatch(limpiarModalSocio());
  };

  const onPressCloseModal = () => {
    limpiarModal();
    setOpenModal(false);
  };

  const validarCamposModal = () => {
    let validar = false;
    if (socioState.oModal.tNombreSocio) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModal.tDocumento) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModal.tContacto) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModal.tDireccion) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModal.tCorreo) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModal.tTelefono) {
      validar = true;
    } else return (validar = false);

    return validar;
  };

  const onPressSaveModal = async () => {
    let validarDatos = false;
    validarDatos = await validarCamposModal();
    if (validarDatos) {
      if (socioState.oModal.nId) {
        let obj = {
          IDSocio: socioState.oModal.nId,
          Nombre_Socio: socioState.oModal.tNombreSocio,
          Documento: socioState.oModal.tDocumento,
          Nombre_Contacto: socioState.oModal.tContacto,
          Direccion: socioState.oModal.tDireccion,
          Correo: socioState.oModal.tCorreo,
          Telefono: socioState.oModal.tTelefono,
        };
        const responseEditarSocio = await callEndpoint(editarSocio(obj));
        setOpenModal(false);
        if (responseEditarSocio.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseEditarSocio.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });

          if (socioState.oModal.tEditLogo == 1) {
            const formData = new FormData();
            formData.append('file', socioState.oModal.tLogo[0].originFileObj);
            formData.append('IDSocio', socioState.oModal.nId);
            const responseCargarLogo = await callEndpoint(cargaLogoSocio(formData));
          }
          loadSocio();
          limpiarModal();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseEditarSocio.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        let obj = {
          Nombre_Socio: socioState.oModal.tNombreSocio,
          Documento: socioState.oModal.tDocumento,
          Nombre_Contacto: socioState.oModal.tContacto,
          Direccion: socioState.oModal.tDireccion,
          Correo: socioState.oModal.tCorreo,
          Telefono: socioState.oModal.tTelefono,
        };
        const responseRegistrarSocio = await callEndpoint(crearSocio(obj));
        setOpenModal(false);
        if (responseRegistrarSocio.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRegistrarSocio.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          if (socioState.oModal.tEditLogo == 1) {
            const formData = new FormData();
            formData.append('file', socioState.oModal.tLogo[0].originFileObj);
            formData.append('IDSocio', responseRegistrarSocio.data.respuesta.oRegistro);
            const responseCargarLogo = await callEndpoint(cargaLogoSocio(formData));
          }
          loadSocio();
          limpiarModal();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarSocio.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar Socio...'}
              filter={socioState.searchtextHomologacion}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Button
                  action={(e) => onPressOpenModal(e)}
                  name="Agregar"
                  isAction={true}
                  type="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns}
          rows={
            socioState.searchtextHomologacion.length === 0
              ? socioState.listaSocio
              : socioState.filterListaSocio
          }
          pageSize={15}
          getRowId={(row) => row.IDSocio}
        />
        <ModalSocio
          open={openModal}
          onPressClose={onPressCloseModal}
          onPressSave={onPressSaveModal}
        />
      </ColFlex>
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
