export function tieneNumero(cadena) {
  // Expresión regular para buscar números
  const regex = /\d/;

  // Testea si la cadena contiene al menos un número
  return regex.test(cadena);
}

export function tieneMayuscula(cadena) {
  // Expresión regular para buscar mayúsculas
  const regex = /[A-Z]/;

  // Testea si la cadena contiene al menos una mayúscula
  return regex.test(cadena);
}

export function tieneMinuscula(cadena) {
  // Expresión regular para buscar minúsculas
  const regex = /[a-z]/;

  // Testea si la cadena contiene al menos una minúscula
  return regex.test(cadena);
}

export function tieneCaracterEspecial(cadena) {
  // Expresión regular para buscar caracteres especiales
  const regex = /[!@#$%^&*(),.?":{}|<>]/;

  // Testea si la cadena contiene al menos un carácter especial
  return regex.test(cadena);
}

export function tieneMasDeOchoCaracteres(cadena) {
  // Verificar si la cadena tiene más de 8 caracteres
  return cadena.length > 8;
}
