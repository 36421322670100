export const listaTipoDeDocumentos = [
  { value: 'Estado finaciero auditado', label: 'Estado finaciero auditado' },
  { value: 'Estado finaciero sin auditar', label: 'Estado finaciero sin auditar' },
  { value: 'Ficha ruc', label: 'Ficha ruc' },
  { value: 'Vigencia de poder', label: 'Vigencia de poder' },
  { value: 'Copia literal', label: 'Copia literal' },
  {
    value: 'Beneficiarios accionista',
    label: 'Beneficiarios accionista (Beneficiario final)',
  },
];
