import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const conceptoInitialState = {
  //TablaBuscador
  searchtext: '',
  //formulario
  iIDCabecera: null,
  bValidTipo: null,
  selectTipo: [],
  sNombre: '',
  bValidNombre: null,
  selectRealProyectado: [],
  bValidRealProyectado: null,
  selectFuncion: [],
  bValidFuncion: null,
  aCaracteristicaRegistro: [],
  //Generales
  aCaracteristicaXfuncion: [],
  listaConceptos: [],
  filterListaConceptos: [],
  listaFunciones: [],
  //
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
};
export const conceptoSlice = createSlice({
  name: 'concepto',
  initialState: conceptoInitialState,
  reducers: {
    agregarConcepto: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarConcepto: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarConceptos: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListarConceptos: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaConceptos: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    filterListarConceptos: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaConceptos = estadoInspeccionado.listaConceptos.filter(function (
        item,
        index
      ) {
        return (
          item.sNombreAgrupador.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sTipoConcepto.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sReal_Proyectado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaConceptos: filterListaConceptos,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setCaracteristicaXfuncionConcepto: (state, action) => {
      // let estadoInspeccionado = current(state);
      // var obj = {
      //   aCaracteristicaXfuncion: action.payload,
      // };
      // return { ...estadoInspeccionado, ...obj };
      return { ...state, ...action.payload };
    },
    changeCaracteristicaXfuncionConcepto: (state, action) => {
      // let estadoInspeccionado = current(state);
      // var obj = {
      //   aCaracteristicaXfuncion: action.payload,
      // };
      // return { ...estadoInspeccionado, ...obj };
      state.aCaracteristicaXfuncion.map((item, index) => {
        if (item.sNombreFuente == action.payload.sNombreFuente) {
          item.selected = !item.selected;
        }
        return { ...item };
      });
    },
    changeTipoConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeNombreConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeRealProyectadoConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeFuncionConcepto: (state, action) => {
      // let estadoInspeccionado = current(state);
      // return { ...estadoInspeccionado, ...action.payload };
      return { ...state, ...action.payload };
    },
    setListaFuncionesConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    limpiarCamposConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      var oInicial = {
        searchtext: '',
        iIDCabecera: null,
        bValidTipo: null,
        selectTipo: [],
        sNombre: '',
        bValidNombre: null,
        selectRealProyectado: [],
        bValidRealProyectado: null,
        selectFuncion: [],
        bValidFuncion: null,
        aCaracteristicaRegistro: [],
        aCaracteristicaXfuncion: [],
        listaConceptos: [],
        filterListaConceptos: [],
      };
      return { ...estadoInspeccionado, ...oInicial };
    },
    setCaracteristicaRegistro: (state, action) => {
      // let estadoInspeccionado = current(state);
      // return { ...estadoInspeccionado, ...action.payload };
      return { ...state, ...action.payload };
    },
    loadEditarConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setUploadDataExcelConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    onOpenModalUploadDataExcelConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setValueFileUploaderConcepto: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    onAddRowTableCaracConcepto: (state, action) => {
      return {
        ...state,
        aCaracteristicaRegistro: state.aCaracteristicaRegistro.concat(action.payload.newItem),
      };
    },
    onDeleteRowTableCaracConcepto: (state, action) => {
      // var oState = current(state)
      // const aListaEtiquetasFinal = [...oState.aCaracteristicaRegistro]
      state.aCaracteristicaRegistro.splice(action.payload.index, 1);
      var obj = {
        aCaracteristicaRegistro: state.aCaracteristicaRegistro,
      };
      if (action.payload.selectCaracteristica.length > 0) {
        state.aCaracteristicaXfuncion.map((item, index) => {
          if (item.sNombreFuente == action.payload.selectCaracteristica[0].label) {
            item.selected = !item.selected;
          }
          return { ...item };
        });
      }
      //return { ...state, ...obj };
    },
    changeRowsSelectNombreDataConcepto: (state, action) => {
      state.aCaracteristicaRegistro.map((item, index) => {
        if (item.idConcepto === action.payload.index) {
          item.selectCaracteristica = action.payload.selectCaracteristica;
        }
        return { ...item };
      });
      // if (state.aCaracteristicaXfuncion[action.payload.index].IDCaracteristica != null) {
      //   state.aCaracteristicaXfuncion[action.payload.index].sAccion = 'M';
      // }
      // if(action.payload.selectCaracteristica.length>0){
      state.aCaracteristicaXfuncion.map((item, index) => {
        if (action.payload.selectCaracteristica.length > 0) {
          if (item.sNombreFuente == action.payload.selectCaracteristica[0].label) {
            item.selected = !item.selected;
          }
        }
        if (action.payload.data.selectCaracteristica.length > 0) {
          if (item.sNombreFuente == action.payload.data.selectCaracteristica[0].label) {
            item.selected = !item.selected;
          }
        }
        return { ...item };
      });
    },
    changeRowsSelectTipoDataConcepto: (state, action) => {
      state.aCaracteristicaRegistro.map((item, index) => {
        if (item.idConcepto === action.payload.index) {
          item.selectTipo = action.payload.selectTipo;
        }
        return { ...item };
      });
    },
  },
});
export const {
  agregarConcepto,
  editarConcepto,
  eliminarConceptos,
  setListarConceptos,
  filterListarConceptos,
  setCaracteristicaXfuncionConcepto,
  changeCaracteristicaXfuncionConcepto,
  setDetalleXfuncionConcepto,
  changeTipoConcepto,
  changeNombreConcepto,
  changeRealProyectadoConcepto,
  changeFuncionConcepto,
  setListaFuncionesConcepto,
  limpiarCamposConcepto,
  setCaracteristicaRegistro,
  loadEditarConcepto,
  setUploadDataExcelConcepto,
  onOpenModalUploadDataExcelConcepto,
  setValueFileUploaderConcepto,
  onAddRowTableCaracConcepto,
  onDeleteRowTableCaracConcepto,
  changeRowsSelectNombreDataConcepto,
  changeRowsSelectTipoDataConcepto,
} = conceptoSlice.actions;
export default conceptoSlice.reducer;
