import React, { useState } from 'react';
import { Modal, Popover, Upload } from 'antd';
import { Button, Input, LabelForm, SelectForm, TableGrid } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { faMinus } from '@fortawesome/fontawesome-free-solid';
import {
  onAddRowRolUsuario,
  setSelectRol,
  onDeleteRowUsuario,
  onSetModalUsuario,
} from '../../../../redux/states';
import Swal from 'sweetalert2';
import { ColForm, PasswordContainer, RowForm, RowDoubleInput, Title } from '../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  tieneCaracterEspecial,
  tieneMasDeOchoCaracteres,
  tieneMayuscula,
  tieneMinuscula,
  tieneNumero,
} from '../../../../common';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const ModalUsuario = (props) => {
  const usuarioState = useSelector((store) => store.usuario);
  const authState = useSelector((store) => store.auth);
  const rolState = useSelector((store) => store.rol);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const dispatch = useDispatch();
  let { t } = useTranslation();

  const handleChangeEmail = (v) => {
    let oParam = {};
    let validate = null;
    oParam.email = '';
    oParam.bValidEmail = validate;
    if (v) {
      oParam.email = v.target.value;
      if (v.target.value.indexOf('@') > 0) {
        oParam.bValidEmail = true;
      } else {
        oParam.bValidEmail = false;
      }
    }
    dispatch(onSetModalUsuario(oParam));
  };

  const handleChangeUsername = (v) => {
    let oParam = {};
    let validate = null;
    oParam.username = '';
    oParam.bValidUsername = validate;
    if (v) {
      oParam.username = v.target.value;
      oParam.bValidUsername = true;
    }
    dispatch(onSetModalUsuario(oParam));
  };
  const handleChangeOldPassword = (v) => {
    let oParam = {};
    oParam.oldPassword = '';
    if (v) {
      oParam.oldPassword = v.target.value;
      oParam.bValidOldPassword = true;
    }
    dispatch(onSetModalUsuario(oParam));
  };
  const handleChangePassword = (v) => {
    let oParam = {};
    oParam.password = '';
    if (v) {
      oParam.password = v.target.value;
      if (validarPassword(v.target.value)) {
        oParam.bValidPassword = true;
      } else {
        oParam.bValidPassword = false;
      }
    }
    dispatch(onSetModalUsuario(oParam));
  };
  const validarPassword = (v) => {
    let oParam = {};
    let validPasswordMust = [];
    validPasswordMust.push(tieneNumero(v));
    validPasswordMust.push(tieneMayuscula(v));
    validPasswordMust.push(tieneMinuscula(v));
    validPasswordMust.push(tieneCaracterEspecial(v));
    validPasswordMust.push(tieneMasDeOchoCaracteres(v));

    oParam.bValidPasswordMust = validPasswordMust;

    dispatch(onSetModalUsuario(oParam));

    return !validPasswordMust.includes(false);
  };
  const handleChangePasswordConfirm = (v) => {
    let oParam = {};
    oParam.passwordConfirm = '';
    if (v) {
      oParam.passwordConfirm = v.target.value;
      if (v.target.value == usuarioState.password) {
        oParam.bValidPasswordConfirm = true;
      } else {
        oParam.bValidPasswordConfirm = false;
      }
    }
    dispatch(onSetModalUsuario(oParam));
  };

  const changeFoto = (info) => {
    switch (info.file.status) {
      case 'uploading':
        onFileUploading(info);
        break;
      case 'done':
        onFileDone(info);
        break;
      case 'removed':
        onFileRemove(info);
        break;
    }
  };

  const onFileUploading = (info) => {
    let files = [...usuarioState.foto];
    if (props.multipleFiles) files.push(info.file);
    else files = [info.file];
    let valor = { foto: files, editFoto: 1 };

    dispatch(onSetModalUsuario(valor));
  };

  const onFileDone = (info) => {
    let files = [...usuarioState.foto];
    if (!props.multipleFiles) files = [info.file];
    let valor = { foto: files, editFoto: 1 };
    dispatch(onSetModalUsuario(valor));
  };

  const onFileRemove = (info) => {
    if (props.multipleFiles) {
      const filtered = usuarioState.foto.filter((x) => x.uid != info.file.uid);
      let valor = { foto: filtered, editFoto: 1 };
      dispatch(onSetModalUsuario(valor));
    } else {
      let valor = { foto: [], editFoto: 0 };
      dispatch(onSetModalUsuario(valor));
    }
  };

  const dummyRequest = (option) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  const passwordTooltip = () => {
    return (
      <>
        <li style={usuarioState.bValidPasswordMust[0] ? { color: 'green' } : {}}>
          Tener un número
        </li>
        <li style={usuarioState.bValidPasswordMust[1] ? { color: 'green' } : {}}>
          Tener un carácter en mayúscula
        </li>
        <li style={usuarioState.bValidPasswordMust[2] ? { color: 'green' } : {}}>
          Tener un carácter en minúscula
        </li>
        <li style={usuarioState.bValidPasswordMust[3] ? { color: 'green' } : {}}>
          Tener un carácter especial
        </li>
        <li style={usuarioState.bValidPasswordMust[4] ? { color: 'green' } : {}}>
          Tener mínimo 8 caracteres
        </li>
      </>
    );
  };

  const handleShowPassword = () => {
    let oParam = { bValidPasswordChange: !usuarioState.bValidPasswordChange };
    dispatch(onSetModalUsuario(oParam));
  };

  const onCloseModal = (oEvent) => {
    props.handleCloseModal(oEvent);
    setShowPassword(false);
    setShowPasswordConfirm(false);
  };
  return (
    <>
      <Modal
        open={props.open}
        title={
          <Title>
            {usuarioState.idUsuario
              ? t('usuario.modalUsuario.tituloEditar')
              : t('usuario.modalUsuario.tituloCrear')}
          </Title>
        }
        closable={false}
        footer={[
          <Button
            action={(oEvent) => onCloseModal(oEvent)}
            name={t('usuario.modalUsuario.botonCerrar')}
            isAction={true}
            type="warning"
          />,
          <Button
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.handleGuardarModal(oEvent)}
            name={t('usuario.modalUsuario.botonGuardar')}
            isAction={true}
            type="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={t('usuario.modalUsuario.label1')} obligatorio={true}></LabelForm>
            <Input
              disabled={usuarioState.idUsuario > 0 ? true : false}
              placeholder={t('usuario.modalUsuario.placeholder1')}
              value={usuarioState.email}
              onChange={(evnt) => handleChangeEmail(evnt)}
              validate={usuarioState.bValidEmail}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={t('usuario.modalUsuario.label2')} obligatorio={true}></LabelForm>
            <Input
              placeholder={t('usuario.modalUsuario.placeholder2')}
              value={usuarioState.username}
              onChange={(evnt) => handleChangeUsername(evnt)}
              validate={usuarioState.bValidUsername}
            />
          </RowForm>
        </ColForm>
        <ColForm
          style={
            usuarioState.idUsuario
              ? usuarioState.idUsuario == authState.user.uid
                ? {}
                : { display: 'none' }
              : { display: 'none' }
          }
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <RowForm>
            <Button
              style={{ marginLeft: '5px' }}
              action={(oEvent) => handleShowPassword(oEvent)}
              name={t('usuario.modalUsuario.botonMostrarPassword')}
              isAction={true}
              type="tertiary"
              disabled={
                usuarioState.idUsuario
                  ? usuarioState.idUsuario == authState.user.uid
                    ? false
                    : true
                  : true
              }
            />
          </RowForm>
        </ColForm>
        <ColForm
          style={
            usuarioState.idUsuario
              ? usuarioState.idUsuario == authState.user.uid
                ? usuarioState.bValidPasswordChange
                  ? { display: 'none' }
                  : {}
                : { display: 'none' }
              : { display: 'none' }
          }
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <RowForm>
            <LabelForm
              disabled={
                usuarioState.idUsuario
                  ? usuarioState.idUsuario == authState.user.uid
                    ? usuarioState.bValidPasswordChange
                    : true
                  : true
              }
              title={t('usuario.modalUsuario.label6')}
              obligatorio={true}
            ></LabelForm>
            <PasswordContainer>
              <Input
                placeholder={t('usuario.modalUsuario.placeholder6')}
                type={showOldPassword ? 'text' : 'password'}
                value={usuarioState.oldPassword}
                onChange={(oEvent) => handleChangeOldPassword(oEvent)}
                disabled={
                  usuarioState.idUsuario
                    ? usuarioState.idUsuario == authState.user.uid
                      ? usuarioState.bValidPasswordChange
                      : true
                    : true
                }
                validate={usuarioState.bValidOldPassword}
              ></Input>
              {!showOldPassword ? (
                <i
                  className="fa fa-eye-slash login-fa-eye"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye login-fa-eye"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  aria-hidden="true"
                ></i>
              )}
            </PasswordContainer>
          </RowForm>
        </ColForm>
        <ColForm
          style={
            usuarioState.idUsuario
              ? usuarioState.idUsuario == authState.user.uid
                ? usuarioState.bValidPasswordChange
                  ? { display: 'none' }
                  : {}
                : { display: 'none' }
              : {}
          }
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <RowForm>
            <LabelForm
              disabled={
                usuarioState.idUsuario
                  ? usuarioState.idUsuario == authState.user.uid
                    ? usuarioState.bValidPasswordChange
                    : true
                  : false
              }
              title={t('usuario.modalUsuario.label3')}
              obligatorio={true}
            ></LabelForm>
            <PasswordContainer>
              <Popover
                content={passwordTooltip}
                title={t('usuario.modalUsuario.popOverTitle')}
                trigger="click"
              >
                <Input
                  placeholder={t('usuario.modalUsuario.placeholder3')}
                  type={showPassword ? 'text' : 'password'}
                  value={usuarioState.password}
                  onChange={(oEvent) => handleChangePassword(oEvent)}
                  disabled={
                    usuarioState.idUsuario
                      ? usuarioState.idUsuario == authState.user.uid
                        ? usuarioState.bValidPasswordChange
                        : true
                      : false
                  }
                  validate={usuarioState.bValidPassword}
                ></Input>
              </Popover>
              {!showPassword ? (
                <AiOutlineEye className="login" onClick={() => setShowPassword(!showPassword)} />
              ) : (
                <AiOutlineEyeInvisible
                  className="login"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </PasswordContainer>
          </RowForm>
        </ColForm>
        <ColForm
          style={
            usuarioState.idUsuario
              ? usuarioState.idUsuario == authState.user.uid
                ? usuarioState.bValidPasswordChange
                  ? { display: 'none' }
                  : {}
                : { display: 'none' }
              : {}
          }
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <RowForm>
            <LabelForm
              disabled={
                usuarioState.idUsuario
                  ? usuarioState.idUsuario == authState.user.uid
                    ? usuarioState.bValidPasswordChange
                    : true
                  : false
              }
              title={t('usuario.modalUsuario.label4')}
              obligatorio={true}
            ></LabelForm>
            <PasswordContainer>
              <Input
                placeholder={t('usuario.modalUsuario.placeholder4')}
                type={showPasswordConfirm ? 'text' : 'password'}
                value={usuarioState.passwordConfirm}
                onChange={(oEvent) => handleChangePasswordConfirm(oEvent)}
                disabled={
                  usuarioState.idUsuario
                    ? usuarioState.idUsuario == authState.user.uid
                      ? usuarioState.bValidPasswordChange
                      : true
                    : false
                }
                validate={usuarioState.bValidPasswordConfirm}
              ></Input>
              {!showPasswordConfirm ? (
                <AiOutlineEye
                  className="login"
                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="login"
                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                />
              )}
            </PasswordContainer>
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <LabelForm title={t('usuario.modalUsuario.label5')}></LabelForm>
          <Upload.Dragger
            // accept=".xlsx, .xls" // TODO: segementar en una propiedad
            style={{ width: '98%' }}
            fileList={usuarioState.foto}
            onChange={changeFoto}
            customRequest={dummyRequest}
            multiple={false}
            disabled={false}
          >
            <p className="ant-upload-text" style={{ width: '98%' }}>
              {t('usuario.modalUsuario.placeholder5')}
            </p>
          </Upload.Dragger>
        </ColForm>
      </Modal>
    </>
  );
};
