import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeRowsMontoRestriccionDetalleFLujoCaja,
  onAddRestriccionDetalleFlujoCaja,
  changeRowsTipoRestriccionDetalleFLujoCaja,
  onChangeRowFPosicionFiltroDetalleFlujoCaja,
  onChangeRowFSetListaCaracteristicasFiltroDetalleFlujoCaja,
  onChangeRowFSetListaValoresFiltroDetalleFlujoCaja,
  onChangeRowFValoresFiltroDetalleFlujoCaja,
  onDeleteRestriccionDetalleFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onOpenModalCancelarCrearFlujoCaja,
  onOpenModalRestriccionesDetalleFlujoCaja,
  changeRowsMonedaRestriccionDetalleFLujoCaja,
  onLimpiarRestriccionDetalleFlujoCaja,
} from '../../../../../../redux/states';
import {
  Button,
  ButtonForm,
  Input,
  LabelForm,
  MultiSelectCheckboxes,
  SelectForm,
} from '../../../../../../components';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import {
  obtenerInfoFiltrosFlujoCajaCaracteristica,
  obtenerInfoFiltrosFlujoCajaValores,
} from '../../../../../../services/public.service';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
// import Select  from "@mui/material/Select";
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Swal from 'sweetalert2';
import collect from 'collect.js';
import { NumericFormat } from 'react-number-format';
import { theme } from '../../../../../../common';
export const ModalRestricciones = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { aRestriccionesDetalleFlujoCaja, aMonedasCrearFlujoCaja } = flujoCajaState;
  const onConfirmarEliminar = async (e) => {
    dispatch(onOpenModalCancelarCrearFlujoCaja(false));
  };
  const onAplicarRestricciones = async (e, props) => {
    dispatch(onOpenModalRestriccionesDetalleFlujoCaja(false));
  };
  const onCloseModal = (e) => {
    dispatch(onOpenModalRestriccionesDetalleFlujoCaja(false));
  };
  const eliminarRestriccion = (oEvent, item, index) => {
    var oParam = { item: item, index: index };
    dispatch(onDeleteRestriccionDetalleFlujoCaja(oParam));
  };
  const onLimpiarFiltros = (oEvent) => {
    dispatch(onLimpiarRestriccionDetalleFlujoCaja());
  };
  const agregarRestriccion = (oEvent) => {
    var oParam = {
      // iIdPosicion: 0,
      // sPosicion: "",
      selectTipoRegla: [],
      aListaTipoReglas: flujoCajaState.aListaTipoReglas,
      valor: '',
      selectMoneda: [],
      aListaMonedasReglas: flujoCajaState.aMonedasCrearFlujoCaja,
    };
    dispatch(onAddRestriccionDetalleFlujoCaja(oParam));
  };
  const handleChangeTipoRestriccion = async (index, oEvent) => {
    let selectTipoRegla = [];
    let aNombreCarac = [];
    let aIDPosicion = [];
    if (oEvent != null) {
      selectTipoRegla = oEvent; //result;
    }
    var oParam = { selectTipoRegla: selectTipoRegla, index: index };
    dispatch(changeRowsTipoRestriccionDetalleFLujoCaja(oParam));
  };
  const handleChangeMonto = (values, sourceInfo, index) => {
    dispatch(
      changeRowsMontoRestriccionDetalleFLujoCaja({
        index: index,
        valor: values.value,
      })
    );
  };
  const handleChangeMonedaReglas = (oEvent, index) => {
    let selectMoneda = [];
    let aIDPosicion = [];
    if (oEvent != null) {
      selectMoneda = oEvent; //result;
    }
    var oParam = { selectMoneda: selectMoneda, index: index };
    // dispatch(changeRowsMonedaRestriccionDetalleFLujoCaja(oParam))
    var posi = index;
    var valid = true;
    for (let index = 0; index < flujoCajaState.aRestriccionesDetalleFlujoCaja.length; index++) {
      const element = flujoCajaState.aRestriccionesDetalleFlujoCaja[index];
      if (posi !== index) {
        if (element.selectMoneda.value === oEvent.value) {
          valid = false;
          break;
        }
      }
    }
    if (valid) {
      dispatch(changeRowsMonedaRestriccionDetalleFLujoCaja(oParam));
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'La moneda ' + oEvent.label + ' ha sido seleccionada anteriomente.',
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };
  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalOpenRestriccionesDetalleFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Reglas</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        {aRestriccionesDetalleFlujoCaja.length === 0 ? (
          <RowFiltros>
            <LabelForm width={'200px'} title={'No se han agregado Reglas'}>
              No se han agregado Reglas
            </LabelForm>{' '}
          </RowFiltros>
        ) : (
          aRestriccionesDetalleFlujoCaja.map((item, index) => (
            <RowFiltros>
              <LabelForm width={'60px'} title={'Tipo:'}></LabelForm>
              <SelectForm
                isDisabled={true}
                placeholder="Seleccione Tipo"
                isClearable={true}
                isSearchable={true}
                bValid={true}
                name="sFuncion"
                options={item.aListaTipoReglas}
                handleChange={(evnt, v) => handleChangeTipoRestriccion(index, v)}
                value={item.selectTipoRegla}
                isMulti={false}
                width={'300px'}
              />
              <LabelForm width={'60px'} title={'Monto:'}></LabelForm>

              <InputNumberFormat
                placeholder="Ingrese..."
                value={item.valor}
                allowNegative={false}
                thousandSeparator={','}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={3}
                inputmode="numeric"
                onValueChange={(values, sourceInfo) => handleChangeMonto(values, sourceInfo, index)}
                disabled={true}
              />
              <SelectForm
                isDisabled={true}
                width={'250px'}
                bValid={true}
                value={item.selectMoneda}
                handleChange={(event, v) => handleChangeMonedaReglas(v, index)}
                options={item.aListaMonedasReglas}
                placeholder={'Seleccione Moneda'}
              ></SelectForm>
            </RowFiltros>
          ))
        )}
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <Button action={onCloseModal} name="Cancelar" isAction={false} type="warning" />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};
const RowFiltros = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const RowFiltrosTitulos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 45px;
  margin: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
`;
const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1200px !important;
  }
`;
const RowCabecera = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
const InputNumberFormat = styled(NumericFormat)`
  border: 1px solid white;
  transition: width 0.5s;
  height: 40px;
  font-family: ${theme.font.family};
  font-size: 12px;
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  width: ${({ width }) => (width ? width : '320px')};
  background-color: #f6f6f6;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      validate == null ? '#F6F6F6' : validate === true ? '#F6F6F6' : 'red'};
  }
  background: #f6f6f6;
  border-radius: 8px;
  border-color: ${({ validate }) =>
    validate == null ? 'none' : validate === true ? 'none' : 'red'};
  transition: ${({ validate }) =>
    validate == null ? 'none' : validate === true ? 'none' : 'border-color 0.5s ease-out'};
`;
