import React, { useEffect, useState } from 'react';
import {
  SearchInputText,
  LoadingSpinner,
  TableGrid,
  Button,
  PBR,
  ButtonAccion,
  LabelForm,
  SelectForm,
  Input,
} from '../../../../components';
import {
  crearCampoMejorado,
  crearHomoCampos,
  editarCampoMejorado,
  eliminarCamposMejorado,
  eliminarHomoCampos,
  generarMatrizCamposCabDetalle,
  listarCamposMJ,
  listarTipoDato,
  listarTipoSistema,
  obtenerCampoHomo,
  obtenerDetalleCampoMejorado,
  obtenerTipoSisDisponiblesXSistema,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListarCampos,
  setTipoSistemaDisponiblesCampos,
  changeSearchTextHomologacionCampo,
  setTipoDato,
  setNombreCampo,
  setSelectTipoDato,
  setCleanModalCampos,
  setDetalleCampo,
  setListaTipoSistemas,
  setSheetHomoCampo,
  setCleanSheetHomoCampo,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { exportToExcel } from '../../../../common';
import { ModalCrearCampo } from './components';
import { createTipoDatoAdapter } from '../../../../adapters/tipodato.adapter';
import {
  createTipoSistemaAdapter,
  createTipoSistemaCampoAdapter,
} from '../../../../adapters/tipoSistema.adapter';
import { faPlus } from '@fortawesome/fontawesome-free-solid';
import {
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
  ColFlex,
  Title,
  RowForm,
  ColForm,
  RowFooterButton,
} from '../../../../styles';
import { Drawer, Dropdown } from 'antd';
import { permisos } from '../../../../common';
import { items } from './misc/menuAccion';
import { v4 as uuidv4 } from 'uuid';
import { columns, columnsHomo } from './misc';

export const ListarCampo = (props) => {
  const [selectedCampo, setSelectedCampo] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSheetHomo, setOpenSheetHomo] = React.useState(false);
  const campoState = useSelector((store) => store.campo);
  const { loading, callEndpoint } = useFetchAndLoad();
  const titleExport = 'CampoData';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Campo' }));
    (async () => {
      loadCampos();
    })();
  }, []);

  const loadCampos = async () => {
    const responseListaCampos = await callEndpoint(listarCamposMJ());
    var listaCampos = [];
    for (let index = 0; index < responseListaCampos.data.respuesta.aListadoCampos.length; index++) {
      const element = responseListaCampos.data.respuesta.aListadoCampos[index];
      listaCampos.push(element);
    }
    dispatch(setListarCampos(listaCampos));
  };

  const loadTipoDato = async () => {
    const responseListaDatos = await callEndpoint(listarTipoDato());
    var listaTipoDato = [];
    for (let index = 0; index < responseListaDatos.data.respuesta.aTipoDato.length; index++) {
      const element = responseListaDatos.data.respuesta.aTipoDato[index];
      var obj = createTipoDatoAdapter(element);
      listaTipoDato.push(obj);
    }
    dispatch(setTipoDato(listaTipoDato));
  };

  const loadSistemaDisponible = async () => {
    const responseListaSistemas = await callEndpoint(obtenerTipoSisDisponiblesXSistema());
    var listaSistemas = [];
    for (
      let index = 0;
      index < responseListaSistemas.data.respuesta.aListaSistemasDisponibles.length;
      index++
    ) {
      const element = responseListaSistemas.data.respuesta.aListaSistemasDisponibles[index];
      var obj = createTipoSistemaCampoAdapter(element);
      listaSistemas.push(obj);
    }
    let oParam = {
      listaSistemas: listaSistemas,
    };
    dispatch(setTipoSistemaDisponiblesCampos(oParam));
  };

  const loadTipoSistemas = async () => {
    let listTypesSystem = [];
    const responseLoadTipoSistemas = await callEndpoint(listarTipoSistema());
    if (responseLoadTipoSistemas) {
      if (responseLoadTipoSistemas.data) {
        if (responseLoadTipoSistemas.data.cabecera.statusCode === 200) {
          responseLoadTipoSistemas.data.respuesta.aListaTipoSistemas.forEach((element) => {
            let obj = createTipoSistemaAdapter(element);
            obj.value = obj.IDTipoSistema;
            obj.label = obj.TipoSistema;
            listTypesSystem.push(obj);
          });
        }
      }
    }
    dispatch(setListaTipoSistemas({ listaTipoSistemas: listTypesSystem }));
  };

  const loadDetalleCampo = async (campo) => {
    const responseListaDatos = await callEndpoint(obtenerDetalleCampoMejorado(campo));
    var obj = {};
    obj.idCampoFrm = responseListaDatos.data.respuesta.oCabecera.idCampo;
    obj.nombreCampo = responseListaDatos.data.respuesta.oCabecera.nombreCampo;
    obj.selectTipoDato = responseListaDatos.data.respuesta.oCabecera.selectTipoDato;
    obj.listaTipoHomologados = responseListaDatos.data.respuesta.aDetalleHomologacion;

    for (let index = 0; index < obj.listaTipoHomologados.length; index++) {
      const element = obj.listaTipoHomologados[index].selectTipoSistema[0];
      obj.listaTipoHomologados[index].bValidTipoSistema = null;
      obj.listaTipoHomologados[index].selectTipoSistema[0].value = element.idTipoSistema;
      obj.listaTipoHomologados[index].selectTipoSistema[0].label = element.nombreSistema;
    }
    dispatch(setDetalleCampo(obj));
  };

  const loadDetalleCampoHomo = async (campo) => {
    const responseListaDatos = await callEndpoint(obtenerDetalleCampoMejorado(campo));
    var obj = [];

    responseListaDatos.data.respuesta.aDetalleHomologacion.map((r) => {
      let objData = r.selectTipoSistema[0];
      objData.value = objData.idTipoSistema;
      objData.label = objData.nombreSistema;

      obj.push(objData);
    });

    dispatch(setSheetHomoCampo({ listaSisHomo: obj }));
  };

  const loadCampoHomo = async (campo) => {
    const responseListaCampoHomo = await callEndpoint(obtenerCampoHomo({ idCampo: campo }));
    let obj = [];

    if (responseListaCampoHomo.data) {
      responseListaCampoHomo.data.respuesta.aListadoCampos.map((r) => {
        obj.push(r);
      });
    }
    dispatch(setSheetHomoCampo({ listaCampoHomo: obj }));
  };

  const findCampo = (row) => {
    return !!selectedCampo.find((p) => p.idCampo === row.idCampo);
  };

  const filterCampo = (row) => {
    return selectedCampo.filter((p) => p.idCampo !== row.idCampo);
  };

  const handleSelectRow = (row) => {
    const filteredPeople = findCampo(row) ? filterCampo(row) : [...selectedCampo, row];
    setSelectedCampo(filteredPeople);
  };

  const eliminarCampoHomo = async (e, data) => {
    let oParam = {},
      lista = [];
    lista.push({
      idCampo: campoState.idCampoHomo,
      idTipoSistema: data.IDTipoSistema,
      IDCampoDetalle: data.IDCampoDetalle,
    });

    oParam.aDetalleEliminacion = lista;

    const responseEliminarSistemaCampo = await callEndpoint(eliminarHomoCampos(oParam));

    if (responseEliminarSistemaCampo.data.cabecera.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: responseEliminarSistemaCampo.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
      await loadCampoHomo(campoState.idCampoHomo);

      dispatch(setCleanSheetHomoCampo());
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: responseEliminarSistemaCampo.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleOpenModal = async (e, data) => {
    await loadTipoDato();
    await loadSistemaDisponible();
    await loadTipoSistemas();
    if (data) {
      await loadDetalleCampo(data.idCampo);
      setOpenModal(true);
    } else setOpenModal(true);
  };

  const handleOpenSheetHomo = async (e, data) => {
    dispatch(setSheetHomoCampo({ idCampoHomo: data.idCampo, campoHomo: data.nombreCampo }));
    await loadDetalleCampoHomo(data.idCampo);

    await loadTipoSistemas();
    await loadCampoHomo(data.idCampo);

    setOpenSheetHomo(true);
  };

  const handleCloseModal = () => {
    limpiarModalCampos();
    setOpenModal(false);
  };

  const validarCamposModal = () => {
    let validarDatos = false;
    if (campoState.nombreCampo) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (campoState.selectTipoDato.length > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    // if (campoState.listaTipoHomologados.length > 0) {
    //   validarDatos = true;
    //   for (let index = 0; index < campoState.listaTipoHomologados.length; index++) {
    //     if (campoState.listaTipoHomologados[index].valor) {
    //       validarDatos = true;
    //     } else {
    //       validarDatos = false;
    //       return validarDatos;
    //     }
    //     if (campoState.listaTipoHomologados[index].selectTipoSistema.length > 0) {
    //       validarDatos = true;
    //     } else {
    //       validarDatos = false;
    //       return validarDatos;
    //     }
    //   }
    // } else {
    //   validarDatos = false;
    //   return validarDatos;
    // }
    return validarDatos;
  };

  const handleGuardarCampo = async () => {
    var oCabecera = {};
    let validarDatos = false;
    validarDatos = await validarCamposModal();
    if (validarDatos) {
      if (campoState.idCampoFrm) {
        oCabecera.idCampo = campoState.idCampoFrm;
        oCabecera.nombreCampo = campoState.nombreCampo;
        oCabecera.idTipoCampo = 2;
        oCabecera.selectTipoDato = campoState.selectTipoDato;
        var aDetalleHomologacion = campoState.listaTipoHomologados;
        let obj = {
          oCabecera: oCabecera,
          aDetalleHomologacion: aDetalleHomologacion,
        };
        const responseRegistrarCampo = await callEndpoint(editarCampoMejorado(obj));
        setOpenModal(false);
        if (responseRegistrarCampo.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRegistrarCampo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadCampos();
          limpiarModalCampos();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegistrarCampo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        let filterCampo = campoState.listaCampos.filter((item, index) => {
          return item.nombreCampo == campoState.nombreCampo;
        });
        if (filterCampo.length == 0) {
          oCabecera.nombreCampo = campoState.nombreCampo;
          oCabecera.idTipoCampo = 2;
          oCabecera.selectTipoDato = campoState.selectTipoDato;
          var aDetalleHomologacion = campoState.listaTipoHomologados;
          let obj = {
            oCabecera: oCabecera,
            aDetalleHomologacion: aDetalleHomologacion,
          };
          const responseRegistrarCampo = await callEndpoint(crearCampoMejorado(obj));
          setOpenModal(false);
          if (responseRegistrarCampo.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseRegistrarCampo.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            loadCampos();
            limpiarModalCampos();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarCampo.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'El Nombre del Campo ya existe',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const eliminarCampo = async (e, data) => {
    var obj = [];
    obj.push({ idCampo: data.idCampo });

    const responseEliminarCampo = await callEndpoint(
      eliminarCamposMejorado({ aListadoCamposEliminar: obj })
    );
    if (responseEliminarCampo.data.cabecera.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: responseEliminarCampo.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
      loadCampos();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: responseEliminarCampo.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const limpiarModalCampos = () => {
    dispatch(setCleanModalCampos());
  };

  const handleChangeCampo = (e) => {
    let campo = e.target.value;
    let validate = null;
    if (campo.length > 0) {
      validate = true;
    }
    let oParam = {
      nombreCampo: campo,
      bValidNombreCampo: validate,
    };
    dispatch(setNombreCampo(oParam));
  };

  const handleChangeTipoDato = (e) => {
    let oParam = {};
    let validate = null;
    oParam.selectTipoDato = [];
    oParam.bValidTipoDato = validate;
    if (e) {
      oParam.selectTipoDato = [e];
      oParam.bValidTipoDato = true;
    }
    dispatch(setSelectTipoDato(oParam));
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(changeSearchTextHomologacionCampo(oEvent.target.value));
    } else {
      dispatch(changeSearchTextHomologacionCampo(''));
    }
  };

  const onExportExcel = async () => {
    const responseExcelCampo = await callEndpoint(generarMatrizCamposCabDetalle());
    exportToExcel(
      responseExcelCampo.data.respuesta.detalleMatriz,
      titleExport,
      responseExcelCampo.data.respuesta.cabeceraMatriz
    );
  };
  const onDismissHomo = () => {
    setOpenSheetHomo(!openSheetHomo);
    let oParam = {};
    oParam.valorOriginalHomo = '';
    oParam.bValidValorOriginalHomo = null;
    oParam.listaSistemasHomo = [];
    dispatch(setSheetHomoCampo(oParam));
  };
  const changeValorOriCampoHomo = (e) => {
    let oParam = {};
    oParam.valorOriginalHomo = '';
    oParam.bValidValorOriginalHomo = null;
    if (e.target.value != '') {
      oParam.valorOriginalHomo = e.target.value;
      oParam.bValidValorOriginalHomo = true;
    }

    dispatch(setSheetHomoCampo(oParam));
  };
  const changeSistemaCampoHomo = (e, id) => {
    let oParam = {};
    let listaSistemaNew = [];
    let listaSistema = [...campoState.listaSistemasHomo];

    oParam.sistemaHomo = [];
    oParam.bValidSistemaHomo = null;
    if (e) {
      oParam.sistemaHomo = [e];
      oParam.bValidSistemaHomo = true;
    }
    listaSistema.map((r) => {
      if (r.id == id) {
        listaSistemaNew.push({
          ...r,
          sistemaHomo: oParam.sistemaHomo,
          bValidSistemaHomo: oParam.bValidSistemaHomo,
        });
      } else {
        listaSistemaNew.push(r);
      }
    });
    dispatch(setSheetHomoCampo({ listaSistemasHomo: listaSistemaNew }));
  };

  const changeValorCambioCampoHomo = (e, id) => {
    let oParam = {};
    let listaSistemaNew = [];
    let listaSistema = [...campoState.listaSistemasHomo];

    oParam.valorCambioHomo = '';
    oParam.bValidValorCambioHomo = null;
    if (e.target.value != '') {
      oParam.valorCambioHomo = e.target.value;
      oParam.bValidValorCambioHomo = true;
    }

    listaSistema.map((r) => {
      if (r.id == id) {
        listaSistemaNew.push({
          ...r,
          valorCambioHomo: oParam.valorCambioHomo,
          bValidValorCambioHomo: oParam.bValidValorCambioHomo,
        });
      } else {
        listaSistemaNew.push(r);
      }
    });
    dispatch(setSheetHomoCampo({ listaSistemasHomo: listaSistemaNew }));
  };

  const addSistemaHomo = () => {
    let uuid = uuidv4();
    let listaSistemaHomoNew = [];
    listaSistemaHomoNew = [
      ...campoState.listaSistemasHomo,
      {
        id: uuid,
        sistemaHomo: [],
        bValidSistemaHomo: null,
        valorCambioHomo: '',
        bValidValorCambioHomo: null,
      },
    ];
    dispatch(setSheetHomoCampo({ listaSistemasHomo: listaSistemaHomoNew }));
  };

  const validarDatosSistemaHomo = () => {
    let validado = false;

    if (campoState.bValidValorOriginalHomo) {
      validado = true;
    } else {
      return (validado = false);
    }
    if (campoState.listaSistemasHomo.length > 0) {
      campoState.listaSistemasHomo.map((r) => {
        if (r.bValidSistemaHomo) {
          validado = true;
        } else {
          return (validado = false);
        }
        if (r.bValidValorCambioHomo) {
          validado = true;
        } else {
          return (validado = false);
        }
      });
    } else {
      return (validado = false);
    }
    return validado;
  };

  const addTableSheetHomo = async () => {
    let validarDatos = false;

    validarDatos = validarDatosSistemaHomo();
    if (validarDatos) {
      let oParam = {},
        listaEnvio = [];
      campoState.listaCampoHomo.map((r) => {
        let buscado = campoState.listaSistemasHomo.filter((r1) => r1.id == r.IDCampoDetalle);
        if (buscado.length == 0) {
          listaEnvio.push({
            idCampo: campoState.idCampoHomo,
            idTipoSistema: r.IDTipoSistema,
            nombreValorBase: r.NombreCampoBase,
            nombreValorHomo: r.NombreCampoHomo,
          });
        }
      });

      campoState.listaSistemasHomo.map((r) => {
        listaEnvio.push({
          idCampo: campoState.idCampoHomo,
          idTipoSistema: r.sistemaHomo[0].value,
          nombreValorBase: r.valorCambioHomo,
          nombreValorHomo: campoState.valorOriginalHomo,
        });
      });

      oParam.aDetalleHomologacion = listaEnvio;

      const responseRegistrarSistemaCampo = await callEndpoint(crearHomoCampos(oParam));

      if (responseRegistrarSistemaCampo.data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseRegistrarSistemaCampo.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        await loadCampoHomo(campoState.idCampoHomo);

        dispatch(setCleanSheetHomoCampo());
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseRegistrarSistemaCampo.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debe llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handlePutValueSheet = (e, v) => {
    let oParam = {};
    oParam.valorOriginalHomo = v.NombreCampoHomo;
    oParam.bValidValorOriginalHomo = true;

    let lista = [];

    campoState.listaCampoHomo.map((r) => {
      if (v.NombreCampoHomo == r.NombreCampoHomo) {
        lista.push({
          id: r.IDCampoDetalle,
          sistemaHomo: [{ value: r.IDTipoSistema, label: r.TipoSistema }],
          valorCambioHomo: r.NombreCampoBase,
          bValidSistemaHomo: true,
          bValidValorCambioHomo: true,
        });
      }
    });

    oParam.listaSistemasHomo = lista;
    dispatch(setSheetHomoCampo(oParam));
  };

  const sistemaAddHomo = () => {
    return campoState.listaSistemasHomo.map((r) => {
      return (
        <>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title={'Sistema'}></LabelForm>
              <SelectForm
                placeholder={'Seleccione Tipo Sistema'}
                options={campoState.listaSisHomo}
                value={r.sistemaHomo}
                validate={r.bValidSistemaHomo}
                handleChange={(e, v) => changeSistemaCampoHomo(v, r.id)}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <RowForm>
              <LabelForm title={'Valor Sistema'}></LabelForm>
              <Input
                placeholder="Ingrese"
                value={r.valorCambioHomo}
                validate={r.bValidValorCambioHomo}
                onChange={(e) => changeValorCambioCampoHomo(e, r.id)}
              />
            </RowForm>
          </ColForm>
        </>
      );
    });
  };
  const onEliminarCampos = async () => {
    var obj = [];

    selectedCampo.map((r) => {
      obj.push({ idCampo: r.idCampo });
    });

    const responseEliminarCampo = await callEndpoint(
      eliminarCamposMejorado({ aListadoCamposEliminar: obj })
    );
    if (responseEliminarCampo.data.cabecera.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: responseEliminarCampo.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
      loadCampos();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: responseEliminarCampo.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="350px"
            placeholder={'Buscar Campo...'}
            filter={campoState.searchtextHomologacion}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <Dropdown
                trigger={['click']}
                menu={{ items: items(onExportExcel, onEliminarCampos) }}
              >
                <ButtonAccion />
              </Dropdown>
              <PBR permiso={[permisos.CREAR_CAMPOS]}>
                <Button action={handleOpenModal} name="Agregar" isAction={true} type="primary" />
              </PBR>
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={columns(findCampo, handleSelectRow, handleOpenSheetHomo, handleOpenModal)}
        rows={
          campoState.searchtextHomologacion.length === 0
            ? campoState.listaCampos
            : campoState.filterListaCampos
        }
        pageSize={15}
        getRowId={(row) => row.idCampo}
      />
      <Drawer
        title={<Title>Homologación de datos</Title>}
        placement="right"
        width={'43%'}
        closable={false}
        onClose={onDismissHomo}
        open={openSheetHomo}
        footer={
          <RowFooterButton>
            <Button
              action={(e) => onDismissHomo()}
              name="Cancelar"
              isAction={true}
              type="warning"
            />
            {/*
            <Button
              action={(e) => onApplyAllAdminCampos()}
              name="Seleccionar Todos"
              isAction={true}
              type="primary"
            />

             //   <Button
          //     action={(e) => onApplyAdminCampos()}
          //     name="Aplicar"
          //     isAction={true}
          //     type="secondary"
          //   />
        */}
          </RowFooterButton>
        }
      >
        <ColFlex>
          <RowForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Campo'}></LabelForm>
                <Input placeholder="Ingrese" value={campoState.campoHomo} disabled />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm title={'Valor Homologado'}></LabelForm>
                <Input
                  placeholder="Ingrese"
                  value={campoState.valorOriginalHomo}
                  validate={campoState.bValidValorOriginalHomo}
                  onChange={(e) => changeValorOriCampoHomo(e)}
                />
              </RowForm>
            </ColForm>
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ alignSelf: 'flex-end' }}>
              <RowForm style={{ justifyContent: 'flex-end' }}>
                <Button
                  icon={faPlus}
                  isOnlyIcon={true}
                  isAction={true}
                  type="secondary"
                  action={addSistemaHomo}
                />
              </RowForm>
            </ColForm>
            {sistemaAddHomo()}
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: '20px' }}>
              <Button
                width={'49%'}
                name="Agregar"
                isAction={true}
                type="secondary"
                action={addTableSheetHomo}
              />
            </ColForm>
            <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
              <TableGrid
                columns={columnsHomo(handlePutValueSheet, eliminarCampoHomo)}
                rows={campoState.listaCampoHomo}
                pageSize={15}
                getRowId={(row) => row.IDCampoDetalle}
              />
            </ColForm>
          </RowForm>
        </ColFlex>
      </Drawer>
      <ModalCrearCampo
        open={openModal}
        handleCloseModal={handleCloseModal}
        handleChangeCampo={handleChangeCampo}
        handleGuardarCampo={handleGuardarCampo}
        handleChangeTipoDato={handleChangeTipoDato}
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
