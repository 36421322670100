import React, { useEffect } from 'react';
import {
  SearchInputText,
  LoadingSpinner,
  TableGrid,
  Button,
  ButtonAccion,
} from '../../../../components';
import {
  ObtenerTipoCambio,
  RegistrarTipoCambio,
  obtenerListadoMoneda,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListaTipoCambio,
  changeSearchTextTipoCambio,
  setListaMonedaTCC,
  setCleanModalTCC,
  onChangeModalTCC,
  onSelectRowTCC,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import { ModalTipoCambio } from './components';
import { faEdit, faMinus } from '@fortawesome/fontawesome-free-solid';
import {
  ContentButton,
  ContentOption,
  RowAccion,
  RowButton,
  RowFlex,
  ColFlex,
  MenuAccion,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { createMonedaAdapter, listaTipoCambioAdapter } from '../../../../adapters';
import Swal from 'sweetalert2';
import { items } from './misc';

export const ListarTipoCambioConf = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const authState = useSelector((store) => store.auth);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const tipoCambioState = useSelector((store) => store.tipoCambio);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.user.uid && personalizacionState.listaPersonalizada.length > 0) {
      dispatch(updateTitle({ title: 'Listado de tipos de cambio' }));
      (async () => {
        await loadTipoCambio();
        await loadMoneda();
      })();
    }
  }, [authState.user, personalizacionState.listaPersonalizada]);

  const loadTipoCambio = async () => {
    const responselistaTipoCambio = await callEndpoint(ObtenerTipoCambio());
    var listaTipoCambio = [];
    for (let index = 0; index < responselistaTipoCambio.data.respuesta.aListadoTC.length; index++) {
      const element = responselistaTipoCambio.data.respuesta.aListadoTC[index];
      listaTipoCambio.push(listaTipoCambioAdapter(element));
    }
    listaTipoCambio.sort((a, b) => a.Habilitado - b.Habilitado);
    listaTipoCambio.reverse();

    dispatch(setListaTipoCambio({ listaTipoCambio: listaTipoCambio }));
  };

  const loadMoneda = async () => {
    let aListaMoneda = [];
    var { data } = await callEndpoint(obtenerListadoMoneda());
    let tipoMoneda = personalizacionState.listaPersonalizada.filter(
      (v) => v.DescPersonalizacion == 'texto_moneda'
    )[0].DatoPersonalizacion;
    data.respuesta.aListaMoneda.forEach(function (item, index) {
      var o = createMonedaAdapter(item, tipoMoneda);
      aListaMoneda.push(o);
    });
    dispatch(setListaMonedaTCC(aListaMoneda));
  };

  const columns = [
    {
      field: 'FechaRegistro',
      headerName: 'Fecha',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'MonedaBase',
      headerName: 'Moneda Base',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'NombreMoneda',
      headerName: 'Moneda Destino',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Factor',
      headerName: 'Factor',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Importe',
      headerName: 'Importe',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Formula',
      headerName: 'Formula',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.Habilitado
            ? `1 ${params.row.MonedaBase} = ${params.row.Factor * params.row.Importe} ${
                params.row.NombreMoneda
              }`
            : ''}
        </>
      ),
    },
    {
      field: 'edit',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      headerName: '',
      width: 75,
      renderCell: (params) => (
        <RowButton>
          {/* <PBR permiso={[permisos.EDITAR_CAMPOS]}> */}
          <Button
            action={(oEvent) => handleOpenModal(oEvent, params.row)}
            icon={faEdit}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
            disabled={params.row.Habilitado ? false : true}
          />
          {/* </PBR> */}
        </RowButton>
      ),
    },
  ];

  const handleOpenModal = async (e, data) => {
    if (data) {
      var obj = {};

      let selectRespecto = tipoCambioState.listaMoneda.filter(
        (v) => v.sAbreviatura == data.MonedaBase
      );
      let selectMoneda = tipoCambioState.listaMoneda.filter(
        (v) => v.sAbreviatura == data.NombreMoneda
      );
      obj.fechaTipoCambio = data.FechaRegistro;
      obj.respectoTipoCambio = selectRespecto;
      obj.monedaTipoCambio = selectMoneda;
      obj.importeTipoCambio = data.Importe;
      obj.factorTipoCambio = data.Factor;

      dispatch(onChangeModalTCC(obj));
      setOpenModal(true);
    } else setOpenModal(true);
  };

  const handleCloseModal = () => {
    limpiarModal();
    setOpenModal(false);
  };

  const validarModal = () => {
    let validarDatos = false;
    if (tipoCambioState.fechaTipoCambio) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (tipoCambioState.respectoTipoCambio.length > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (tipoCambioState.monedaTipoCambio.length > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    debugger;
    if (parseFloat(tipoCambioState.importeTipoCambio) > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (parseInt(tipoCambioState.factorTipoCambio) > 0) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    return validarDatos;
  };

  const handleGuardarModal = async () => {
    var oCabecera = {};
    let validarDatos = false;
    validarDatos = await validarModal();
    if (validarDatos) {
      oCabecera.IDUsuario = authState.user.uid;
      oCabecera.MonedaBase = tipoCambioState.respectoTipoCambio[0].sAbreviatura;
      oCabecera.FechaRegisto = tipoCambioState.fechaTipoCambio;
      oCabecera.Moneda = tipoCambioState.monedaTipoCambio[0].sAbreviatura;
      oCabecera.Importe = tipoCambioState.importeTipoCambio;
      oCabecera.Factor = tipoCambioState.factorTipoCambio;

      const responseRegistrartipoCambio = await callEndpoint(RegistrarTipoCambio(oCabecera));
      setOpenModal(false);
      if (responseRegistrartipoCambio.data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseRegistrartipoCambio.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        loadTipoCambio();
        limpiarModal();
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseRegistrartipoCambio.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const eliminarCampo = async (e, data) => {
    var obj = [];
    obj.push({ MonedaBase: data.MonedaBase, Moneda: data.NombreMoneda });
    // const responseEliminarTipoCambio = await callEndpoint(
    //   eliminarCamposMejorado({ aListadoCamposEliminar: obj })
    // );
    // if (responseEliminarTipoCambio.data.cabecera.statusCode === 200) {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'success',
    //     title: responseEliminarTipoCambio.data.cabecera.descripcion,
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   loadTipoCambio();
    // } else {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: responseEliminarTipoCambio.data.cabecera.descripcion,
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  const limpiarModal = () => {
    dispatch(setCleanModalTCC());
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(changeSearchTextTipoCambio(oEvent.target.value));
    } else {
      dispatch(changeSearchTextTipoCambio(''));
    }
  };
  const onCrearCopiaTC = (e) => {
    if (tipoCambioState.selectRowTC.length == 1) {
      let data = tipoCambioState.listaTipoCambio.filter(
        (r) => r.idTipoCambio == tipoCambioState.selectRowTC
      )[0];

      var obj = {};

      let selectRespecto = tipoCambioState.listaMoneda.filter(
        (v) => v.sAbreviatura == data.MonedaBase
      );
      let selectMoneda = tipoCambioState.listaMoneda.filter(
        (v) => v.sAbreviatura == data.NombreMoneda
      );
      obj.fechaTipoCambio = data.FechaRegistro;
      obj.respectoTipoCambio = selectRespecto;
      obj.monedaTipoCambio = selectMoneda;
      obj.importeTipoCambio = data.Importe;
      obj.factorTipoCambio = data.Factor;

      dispatch(onChangeModalTCC(obj));
      setOpenModal(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se debe seleccionar solo una fila para copiar',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            width="350px"
            placeholder={'Buscar Tipo de cambio...'}
            filter={tipoCambioState.searchtextHomologacion}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <Dropdown trigger={['click']} menu={{ items: items(onCrearCopiaTC) }}>
                <ButtonAccion />
              </Dropdown>
              {/* <PBR permiso={[permisos.CREAR_CAMPOS]}> */}
              <Button action={handleOpenModal} name="Agregar" isAction={true} type="primary" />
              {/* </PBR> */}
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={columns}
        rows={
          tipoCambioState.searchtextHomologacion.length === 0
            ? tipoCambioState.listaTipoCambio
            : tipoCambioState.filterListaTipoCambio
        }
        pageSize={15}
        getRowId={(row) => row.idTipoCambio}
        checkboxSelection={true}
        onSelectionModelChange={(oEvent) => {
          dispatch(onSelectRowTCC(oEvent));
        }}
        selectionModel={tipoCambioState.selectRowTC}
      />
      <ModalTipoCambio
        open={openModal}
        handleCloseModal={handleCloseModal}
        handleGuardarModal={handleGuardarModal}
      ></ModalTipoCambio>
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
