import { v4 as uuidv4 } from 'uuid';

export const listaTipoCambioAdapter = (user) => {
  let uid = uuidv4();
  return {
    idTipoCambio: uid,
    MonedaBase: user.MonedaBase,
    FechaRegistro: user.FechaRegistro,
    NombreMoneda: user.NombreMoneda,
    Importe: user.Importe,
    Habilitado: user.Habilitado,
    ...user,
  };
};
