import { permisos } from '../../../../../common';
import { PBRFunction } from '../../../../../components';

export const items = (onExportExcel, onEliminarCampos) => [
  {
    label: 'Exportar',
    key: '1',
    onClick: onExportExcel,
  },
  {
    label: 'Eliminar',
    key: '2',
    onClick: onEliminarCampos,
    disabled: PBRFunction([permisos.ELIMINAR_CAMPOS]),
  },
];
