import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';

export const loginlInitialState = {
  userName: '',
  password: '',
  tenant: '',
  userNameCP: '',
  passwordCP: '',
  passwordCofirmCP: '',
  sessionCP: '',
  bValidPasswordCP: null,
  bValidPasswordMustCP: [null, null, null, null, null],
  bValidPasswordConfirmCP: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: loginlInitialState,
  reducers: {
    changeUserName: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeTenant: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changePassword: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    limpiarCamposLogin: (state, action) => {
      return {
        ...state,
        ...{
          userName: '',
          password: '',
        },
      };
    },
    changeDataChangePassword: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  changeUserName,
  changeTenant,
  changePassword,
  limpiarCamposLogin,
  changeDataChangePassword,
} = loginSlice.actions;
export default loginSlice.reducer;
