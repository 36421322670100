import { v4 as uuidv4 } from 'uuid';

export const listaAutomatizacionAdapter = (r) => {
  return {
    IDJob: r.IDJob,
    ...r,
  };
};

export const listaValueAutomatizacionAdapter = (r) => {
  return {
    IDJob: r.IDJob,
    value: r.IDJob,
    label: r.Nombre,
    ...r,
  };
};
