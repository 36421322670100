import styled from 'styled-components';
import { theme } from '../common';
import { Card } from 'antd';

export const CardDash = styled(Card)`
  height: ${({ size }) =>
    size ? (size === 'large' ? '402px' : size === 'medium' ? '200px' : '98px') : '400px'};
  margin: ${({ size }) =>
    size
      ? size === 'large'
        ? '4px'
        : size === 'medium'
        ? '2px 4px 2px 4px'
        : '1px 4px 1px 4px'
      : '4px'};
  /* overflow: hidden; */
  .ant-card-head {
    border: none;
  }
`;

export const CardTree = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? theme.colors.line : theme.colors.white)};
  height: 100%;
  border-radius: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-right: 8px;
  background-color: ${theme.colors.white};
  .sc-cKkZIC .ciLVik {
    height: 50px !important;
  }
`;
export const CardTreeBody = styled.div`
  background-color: white;
  width: 100%;
  height: 0px; /* changed */
  font-size: 12px;
  line-height: 12px;
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out; /* added */
  overflow: auto; /* added */
  span {
    padding: 12px 0;
  }
  .accodion {
    padding: 10px 15px 15px; /* changed */
    width: 100%;
  }
  &.show {
    height: fit-content;
  }
  align-items: start;
  /* overflow-y: auto; */
  max-height: 62vh;
`;

export const CardForm = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? theme.colors.line : theme.colors.white)};
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  margin: 8px;
`;
export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100vh;
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -40;
  font-size: 18px;
  color: ${theme.colors.black};
  margin: 16px;
`;
export const CardContainer = styled.div`
  margin-bottom: 20px;
  max-height: 20vh;
  overflow: inherit;
`;
export const CardItem = styled.div`
  text-align: initial;
  display: flex;
  flex-direction: row;
  margin: 5px 5px 5px 15px;
  span {
    font-family: ${theme.font.family};
    font-size: 12px;
  }
`;
export const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 10px 0px;
`;

export const ParamCard = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? '#FAFAFA' : 'white')};
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  overflow: auto;
  margin: 1px;
  background-color: #f8fafd;
  max-width: 95vw;
  .sc-cKkZIC .ciLVik {
    height: 50px !important;
  }
`;
export const ParamCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  background-color: ${theme.colors.primary};
  height: 32px !important;
  color: ${theme.colors.white};
`;
export const ParamCardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -40;
  font-size: 12px;
  color: ${theme.colors.white};
  margin: 5px 14px 5px 14px;
  align-items: center;
  font-size: 1em !important;
`;

export const ParamCardBody = styled.div`
  background-color: ${theme.colors.white};
  height: 0px; /* changed */
  font-size: 12px;
  line-height: 10px;
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out; /* added */
  overflow: hidden; /* added */
  max-height: 250px;
  align-items: start;
  overflow-y: auto;
  .accodion {
    padding: 10px 15px 15px; /* changed */
    width: 100%;
  }
  &.show {
    height: ${({ setHeight }) => setHeight}px;
  }
  width: 100%;
`;
