export const items = (onClickVerPerfil, onClickCerrarSesion) => [
  {
    label: 'Ver Perfil',
    key: '1',
    onClick: onClickVerPerfil,
  },
  {
    type: 'divider',
  },
  {
    label: 'Cerrar Sesión',
    key: '2',
    onClick: onClickCerrarSesion,
  },
];
