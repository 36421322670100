import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const inversionInitialState = {
  //TablaBuscador
  searchtext: '',
  //tabla
  listaInversion: [],
  filterListaInversion: [],
  //formulario
  idSolucion: null,
  selectTipoFormulario: [],
  bValidselectTipoFormulario: null,
  descripcion: '',
  bValidDescripcion: null,
  selectAgente: [],
  bValidAgente: null,
  listaAgente: [],
  dDateRange: [null, null],
  bValidFecha: null,
  monto: '',
  bValidMonto: null,
  noPeriodos: '',
  bValidPeriodos: null,
  montoMaximo: '',
  bValidMontoMaximo: null,
  montoMinimo: '',
  bValidMontoMinimo: null,
  cantPeriodos: '',
  bValidCantPeriodos: null,
  selectTiempoCantPeriodos: [],
  bValidSelectTiempoCantPeriodos: null,
  selectMoneda: [],
  bValidMoneda: null,
  listaMoneda: [],
  listaTiempo: [],
  tea: '',
  bValidTea: null,
  apariciones: '',
  bValidApariciones: null,
  selectEstado: [],
  bValidEstado: null,
  listaEstado: [],
  //
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
  selectTipoPago: [],
  bValidTipoPago: null,
};
export const inversionSlice = createSlice({
  name: 'inversion',
  initialState: inversionInitialState,
  reducers: {
    agregarInversion: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarInversion: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarInversions: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaInversion: (state, action) => {
      return { ...state, ...action.payload };
    },
    //Metodos del Formuario de Creacion
    selectedTipoFomularioIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeDescripcionIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedAgenteIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaAgenteIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeVigenciaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoMaximoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoMinimoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeCantPeriodosIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoDesembolsoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedMonedaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMonedaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoGraciaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoGraciaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoEntreCuotasIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoCantPeriodosIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeTeaIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeFrecMaxIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoFrecMaxIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeAparicionesIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMinimoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMaximoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedEstadoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEstadoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    filterListarInversion: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaInversion = estadoInspeccionado.listaInversion.filter(function (
        item,
        index
      ) {
        return (
          item.codigo.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.nombre.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.estado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaInversion: filterListaInversion,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setDataInfoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaTiempoIN: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarCamposIN: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          monto: '',
          bValidMonto: null,
          noPeriodos: '',
          bValidPeriodos: null,
          montoMaximo: '',
          bValidMontoMaximo: null,
          montoMinimo: '',
          bValidMontoMinimo: null,
          cantPeriodos: '',
          bValidCantPeriodos: null,
          selectTiempoCantPeriodos: [],
          bValidSelectTiempoCantPeriodos: null,
          selectMoneda: [],
          bValidMoneda: null,
          tea: '',
          bValidTea: null,
          apariciones: '',
          bValidApariciones: null,
          selectEstado: [],
          bValidEstado: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    limpiarFormIN: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          monto: '',
          bValidMonto: null,
          noPeriodos: '',
          bValidPeriodos: null,
          montoMaximo: '',
          bValidMontoMaximo: null,
          montoMinimo: '',
          bValidMontoMinimo: null,
          cantPeriodos: '',
          bValidCantPeriodos: null,
          selectTiempoCantPeriodos: [],
          bValidSelectTiempoCantPeriodos: null,
          selectMoneda: [],
          bValidMoneda: null,
          tea: '',
          bValidTea: null,
          apariciones: '',
          bValidApariciones: null,
          selectEstado: [],
          bValidEstado: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    setValidCamposIN: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  agregarInversion,
  editarInversion,
  eliminarInversions,
  setListaInversion,
  selectedTipoFomularioIN,
  changeDescripcionIN,
  selectedAgenteIN,
  setListaAgenteIN,
  changeVigenciaIN,
  changeMontoMaximoIN,
  changeMontoMinimoIN,
  changeCantPeriodosIN,
  changeMontoDesembolsoIN,
  selectedMonedaIN,
  setListaMonedaIN,
  changePeriodoGraciaIN,
  selectedTiempoPeriodoGraciaIN,
  changePeriodoCutoaIN,
  selectedTiempoCantPeriodosIN,
  changeTeaIN,
  changeFrecMaxIN,
  selectedTiempoFrecMaxIN,
  changeAparicionesIN,
  changeMinimoIN,
  changeMaximoIN,
  selectedEstadoIN,
  setListaEstadoIN,
  filterListarInversion,
  limpiarCamposIN,
  setDataInfoIN,
  setListaTiempoIN,
  limpiarFormIN,
  setValidCamposIN,
} = inversionSlice.actions;
export default inversionSlice.reducer;
