export function formatStringToDate(date) {
  let SplitDate = date.split('-');
  return SplitDate[2] + '/' + SplitDate[1] + '/' + SplitDate[0].substring(2, 4);
}

export function formatMes(mesFecha) {
  let mes = '';

  switch (mesFecha) {
    case 'enero':
      mes = '01';
      break;
    case 'febrero':
      mes = '02';
      break;
    case 'marzo':
      mes = '03';
      break;
    case 'abril':
      mes = '04';
      break;
    case 'mayo':
      mes = '05';
      break;
    case 'junio':
      mes = '06';
      break;
    case 'julio':
      mes = '07';
      break;
    case 'agosto':
      mes = '08';
      break;
    case 'septiembre':
      mes = '09';
      break;
    case 'octubre':
      mes = '10';
      break;
    case 'noviembre':
      mes = '11';
      break;
    case 'diciembre':
      mes = '12';
      break;
    default:
      mes = '';
  }
  return mes;
}
