import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { theme } from '../../common';
export const ButtonCardFlujoCaja = (props) => (
  <ButtonStandar
    onClick={props.action}
    isAction={props.isAction}
    colorTextHover={props.colorTextHover}
  >
    {props.icon ? (
      <IconButton colorTextHover={props.colorTextHover}>
        <FontAwesomeIcon icon={props.icon} />
      </IconButton>
    ) : (
      <></>
    )}
    {props.name ? <span>{props.name}</span> : <></>}
  </ButtonStandar>
);
const IconButton = styled.div`
  color: white;
  align-items: center;
`;
const ButtonStandar = styled.button`
  //font-family: UbuntuBold;
  font-size: 12px !important;
  background: transparent; /*${({ isAction }) => (isAction ? theme.colors.primary : '#183838')}; */
  padding: 6px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent; /*${({ isAction }) =>
    isAction ? theme.colors.primary : '#183838'}; */
  gap: 10px;
  span {
    color: white !important;
  }
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    transform: scale(1.09);
    span {
      color: ${({ colorTextHover }) =>
        colorTextHover ? colorTextHover : theme.colors.primary} !important;
    }
    ${IconButton} {
      transform: scale(1.09);
      color: ${({ colorTextHover }) =>
        colorTextHover ? colorTextHover : theme.colors.primary} !important;
    }
  }
`;
