import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const dashboardInitialState = {
  listaValores: [],
  listaSoluciones: [],
  listaVersiones: [],
  listaAgente: [],
  moneda: '',
};
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardInitialState,
  reducers: {
    onSetListarDashboard: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeMonedaDashboard: (state, action) => {
      return { ...state, ...action.payload };
    },
    onCleanDashboard: (state, action) => {
      return { ...state, ...dashboardInitialState };
    },
  },
});
export const { onSetListarDashboard, onChangeMonedaDashboard, onCleanDashboard } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
