import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';
import { theme } from '../common';

export const TableContain = styled.div`
  max-height: 80vh;
  overflow: auto;
  position: relative;
  white-space: nowrap;
  max-width: 99vw;
  overflow: auto;
`;

export const NumberTable = styled(NumericFormat)`
  display: 'block';
  width: '24px';
  height: '24px';
  text-align: center;
`;
export const NumberTotalTable = styled(NumericFormat)`
  color: ${({ isNegative }) =>
    isNegative ? `${theme.colors.warning} !important` : `${theme.colors.green} !important`};
`;

export const TableFC = styled.table`
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  td,
  th {
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    border: 1px solid ${theme.colors.line};
    min-width: 25px !important;
    overflow: auto !important;
  }
  tr:hover {
    background-color: ${theme.colors.line};
  }
  thead tr,
  td {
    text-align: center !important;
  }
  thead td {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${theme.colors.primary};
    font-weight: 100;
    color: ${theme.colors.white};
    font-family: ${theme.font.family};
    font-size: ${theme.font.size} !important;
    text-align: center;
  }

  thead td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    padding-left: 0px;
    border-left: 0px;
  }
  tbody td:first-child {
    padding-left: 0px;
    border-left: 0px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    /* background-color: ${theme.colors.quaternary}; */
    color: ${theme.colors.primary};
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    text-align: start !important;
    font-weight: bold;
    a {
      font-weight: bold;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family};
      font-size: ${theme.font.size};
    }
  }
  tfoot td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    /* background-color: ${theme.colors.quinary};
    color: ${theme.colors.white}; */
    color: ${theme.colors.primary};
    background-color: ${theme.colors.cardBackground};
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    font-weight: bold;
  }
  .otros {
    background-color: #ffffff !important;
  }
  .manual {
    color: ${theme.colors.primary} !important;
  }
  .noManual {
    color: ${theme.colors.secondary} !important;
  }

  .redips-drag {
    cursor: grab;
    margin: auto;
    z-index: 10;
    background-color: transparent;
    text-align: center;
    width: 87px;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    width: auto;
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
  }

  #redips-drag {
    width: 99%;
  }
  #redips-drag img {
    margin: 1px;
  }
  .contenidoTable {
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    font-weight: 600;
    text-align: center;
    color: ${theme.colors.text};
    background-color: ${theme.colors.white};
  }
  tbody td {
    background-color: ${theme.colors.white};
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    color: ${theme.colors.text};
  }
  .totales {
    font-family: ${theme.font.family};
    font-size: ${theme.font.size};
    font-weight: bold;
    text-align: center;
    background-color: ${theme.colors.cardBackground};
  }
  .soluciones {
    font-family: ${theme.font.family};
    font-weight: bold !important;
    font-size: ${theme.font.size};
    text-align: center;
    color: ${theme.colors.text};
    background-color: ${theme.colors.white};
  }
  .redips-mark-Totales {
    color: ${theme.colors.text};
    background-color: ${theme.colors.backgroundTot};
    font-weight: bold;
    text-align: start !important;
    font-size: ${theme.font.size} !important;
  }
  .redips-mark-Soluciones {
    color: ${theme.colors.text} !important;
    font-weight: bold !important;
    text-align: start !important;
    font-size: ${theme.font.size} !important;
    font-weight: 500;
  }
`;
