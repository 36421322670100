import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const prestamoInitialState = {
  //TablaBuscador
  searchtext: '',
  //tabla
  listaPrestamo: [],
  filterListaPrestamo: [],
  //formulario
  idSolucion: null,
  selectTipoFormulario: [],
  bValidselectTipoFormulario: null,
  descripcion: '',
  bValidDescripcion: null,
  selectAgente: [],
  bValidAgente: null,
  listaAgente: [],
  dDateRange: [null, null],
  bValidFecha: null,
  monto: '',
  bValidMonto: null,
  cantCuota: '',
  bValidCantCuota: null,
  montoDes: '',
  bValidMontoDes: null,
  selectMoneda: [],
  bValidMoneda: null,
  listaMoneda: [],
  listaTiempo: [],
  peGracia: '',
  bValidPeGracia: null,
  bValidTiempoPeGracia: null,
  selectTiempoPeGracia: [],
  peCuotas: '',
  bValidCuotas: null,
  bValidTiempoPeCuotas: null,
  selectTiempoPeCuotas: [],
  tea: '',
  bValidTea: null,
  frecMax: '',
  bValidFrecMax: null,
  bValidTiempoFrecMax: null,
  selectTiempoFrecMax: [],
  apariciones: '',
  bValidApariciones: null,
  minimo: '',
  bValidMinimo: null,
  maximo: '',
  bValidMaximo: null,
  selectEstado: [],
  bValidEstado: null,
  selectFormaPago: [],
  bValidFormaPago: null,
  listaEstado: [],
  //
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
};
export const prestamoSlice = createSlice({
  name: 'prestamo',
  initialState: prestamoInitialState,
  reducers: {
    agregarPrestamo: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarPrestamo: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarPrestamos: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeCantCuotaLC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaPrestamo: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTipoFomularioPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    //Metodos del Formuario de Creacion
    changeDescripcionPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedAgentePR: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaAgentePR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeVigenciaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeCantCuotaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoDesembolsoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedMonedaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMonedaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoGraciaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoGraciaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoCutoaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoCuotaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeTeaPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeFrecMaxPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoFrecMaxPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeAparicionesPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMinimoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMaximoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedEstadoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEstadoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    filterListarPrestamo: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaPrestamo = estadoInspeccionado.listaPrestamo.filter(function (item, index) {
        return (
          item.codigo.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.nombre.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.estado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaPrestamo: filterListaPrestamo,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setDataInfoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaTiempoPR: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarCamposPR: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          idSolucion: null,
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          monto: '',
          bValidMonto: null,
          cantCuota: '',
          bValidCantCuota: null,
          montoDes: '',
          bValidMontoDes: null,
          selectMoneda: [],
          bValidMoneda: null,
          peGracia: '',
          bValidPeGracia: null,
          bValidTiempoPeGracia: null,
          selectTiempoPeGracia: [],
          peCuotas: '',
          bValidCuotas: null,
          bValidTiempoPeCuotas: null,
          selectTiempoPeCuotas: [],
          tea: '',
          bValidTea: null,
          frecMax: '',
          bValidFrecMax: null,
          bValidTiempoFrecMax: null,
          selectTiempoFrecMax: [],
          apariciones: '',
          bValidApariciones: null,
          minimo: '',
          bValidMinimo: null,
          maximo: '',
          bValidMaximo: null,
          selectEstado: [],
          bValidEstado: null,
          selectFormaPago: [],
          bValidFormaPago: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    limpiarFormPR: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          monto: '',
          bValidMonto: null,
          cantCuota: '',
          bValidCantCuota: null,
          montoDes: '',
          bValidMontoDes: null,
          selectMoneda: [],
          bValidMoneda: null,
          peGracia: '',
          bValidPeGracia: null,
          bValidTiempoPeGracia: null,
          selectTiempoPeGracia: [],
          peCuotas: '',
          bValidCuotas: null,
          bValidTiempoPeCuotas: null,
          selectTiempoPeCuotas: [],
          tea: '',
          bValidTea: null,
          frecMax: '',
          bValidFrecMax: null,
          bValidTiempoFrecMax: null,
          selectTiempoFrecMax: [],
          apariciones: '',
          bValidApariciones: null,
          minimo: '',
          bValidMinimo: null,
          maximo: '',
          bValidMaximo: null,
          selectEstado: [],
          bValidEstado: null,
          selectFormaPago: [],
          bValidFormaPago: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    setValidCamposPR: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  agregarPrestamo,
  editarPrestamo,
  eliminarPrestamos,
  setListaPrestamo,
  selectedTipoFomularioPR,
  changeDescripcionPR,
  selectedAgentePR,
  setListaAgentePR,
  changeVigenciaPR,
  changeMontoPR,
  changeCantCuotaPR,
  changeMontoDesembolsoPR,
  selectedMonedaPR,
  setListaMonedaPR,
  changePeriodoGraciaPR,
  selectedTiempoPeriodoGraciaPR,
  changePeriodoCutoaPR,
  selectedTiempoPeriodoCuotaPR,
  changeTeaPR,
  changeFrecMaxPR,
  selectedTiempoFrecMaxPR,
  changeAparicionesPR,
  changeMinimoPR,
  changeMaximoPR,
  selectedEstadoPR,
  setListaEstadoPR,
  filterListarPrestamo,
  limpiarCamposPR,
  setDataInfoPR,
  setListaTiempoPR,
  limpiarFormPR,
  setValidCamposPR,
} = prestamoSlice.actions;
export default prestamoSlice.reducer;
