export const filtroReporteAdapter = (item) => ({
  label: item.DescPosicion,
  value: item.IDPosicion,
  IdPosicion: item.IDPosicion,
  ...item,
});

export const filtroCaracReporteAdapter = (item) => ({
  label: item.sNombreCampo,
  value: item.sNombreCampo,
  NombreCampo: item.sNombreCampo,
});
