import React from 'react';
import { Modal } from 'antd';
import { Button, Input, LabelForm } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';

import { ColForm, RowForm, Title } from '../../../../../styles';
import { setModalDetallePM } from '../../../../../redux/states';

export const ModalDetallePM = (props) => {
  const posicionMasterState = useSelector((store) => store.posicionMaster);
  const dispatch = useDispatch();

  const onChangeNombreDetalle = (e) => {
    let nombre = e.target.value;

    let oParam = {
      nombreDetallePM: nombre,
    };
    dispatch(setModalDetallePM(oParam));
  };
  return (
    <>
      <Modal
        open={props.open}
        title={
          <Title>
            {posicionMasterState.idDetallePM ? 'Guardar Posición master' : 'Crear Posición master'}
          </Title>
        }
        closable={false}
        footer={[
          <Button
            action={(oEvent) => props.onCloseModal(oEvent)}
            name={'Cerrar'}
            isAction={true}
            type="warning"
          />,
          <Button
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.onGuardarModal(oEvent)}
            name={'Guardar'}
            isAction={true}
            type="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Nombre Posición (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={posicionMasterState.nombreDetallePM}
              onChange={(evnt) => onChangeNombreDetalle(evnt)}
            />
          </RowForm>
        </ColForm>
      </Modal>
    </>
  );
};
