import * as React from 'react'
import Box from '@mui/material/Box'
import {styled} from '@mui/material/styles'
import {DataGrid} from '@mui/x-data-grid'
import {makeStyles, createStyles} from '@material-ui/core/styles'
export const TableGridStyle = (props) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#316286 !important',
          color: 'white',
        },
      },
    }),
  )
  const classes = useStyles()
  return (
    <StyledDataGrid
      components={props.components}
      className={props.components}
      rows={props.components}
      columns={props.components}
      disableColumnSelector
      disableSelectionOnClick
      autoHeight
      pageSize={props.components}
      localeText={props.components}
      rowsPerPageOptions={props.components}
      getRowId={props.components}
      stickyHeader={'true'}
    />
  )
}
const StyledDataGrid = styled(DataGrid)(({theme}) => ({
  border: 0,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  // ...customCheckbox(theme),
}))
