import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const funcionesInitialState = {
  //TablaBuscador
  searchtext: '',
  //Campos
  searchtextCampos: '',
  //formulario
  listadoFunciones: [],
  idFuncion: null,
  name: '',
  bValidName: null,
  selectTypeFunction: [],
  bValidSelectTypeFunction: null,
  listTypeFunction: [],
  listAvailableFields: [],
  listFilterAvailableFields: [],
  tempSelectAmdminCampos: [],
  selectAmdminCampos: [],
  selectAmdminCamposFinal: [],
  listTypeSystemService: [],
  availableTypeSystemFunction: [],
  deleteRowAvailableTypeSystemFunction: [],
};
export const funcionesSlice = createSlice({
  name: 'funciones',
  initialState: funcionesInitialState,
  reducers: {
    changeNameFunction: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectTypeFunction: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListTypeFunction: (state, action) => {
      return { ...state, ...action.payload };
    },
    onAddRowtableTypeSystemFunction: (state, action) => {
      return {
        ...state,
        listTypeSystemService: state.listTypeSystemService.concat(action.payload.newItem),
      };
    },
    setListarFunciones: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listadoFunciones: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setAvailableTypeSystemFunction: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeRowsSelectAvailableTypeSystemFunction: (state, action) => {
      state.listTypeSystemService.map((item, index) => {
        if (item.idAvailableTypeSystemFunction === action.payload.index) {
          item.selectTypeSystem = action.payload.selectTypeSystem;
          if (item.idAvailableTypeSystemFunction) {
            item.action = 'M';
          }
        }
        return { ...item };
      });
    },
    changeRowsChangeServiceName: (state, action) => {
      state.listTypeSystemService.map((item, index) => {
        if (item.idAvailableTypeSystemFunction === action.payload.index) {
          item.serviceName = action.payload.serviceName;
          item.bValidServiceName = action.payload.bValidServiceName;
          if (item.idAvailableTypeSystemFunction) {
            item.action = 'M';
          }
        }
        return { ...item };
      });
    },
    onDeleteRowAvailableTypeSystemFunction: (state, action) => {
      // state.listTypeSystemService.splice(action.payload.index, 1);
      let listTypeSystemService = [];
      let deleteRowAvailableTypeSystemFunction = [];
      state.listTypeSystemService.map(function (item, index) {
        if (item.idAvailableTypeSystemFunction != action.payload.index) {
          listTypeSystemService.push(item);
        } else {
          if (item.idAvailableTypeSystemFunction) {
            deleteRowAvailableTypeSystemFunction.push(item);
          }
        }
      });
      return {
        ...state,
        deleteRowAvailableTypeSystemFunction: [
          ...state.deleteRowAvailableTypeSystemFunction,
          ...deleteRowAvailableTypeSystemFunction,
        ],
        listTypeSystemService: listTypeSystemService,
      };
    },
    onSetDeleteAvailableTypeSystemFunction: (state, action) => {
      let deleteRowAvailableTypeSystemFunction = [];
      state.listTypeSystemService.map(function (item, index) {
        if (item.idAvailableTypeSystemFunction) {
          deleteRowAvailableTypeSystemFunction.push(item);
        }
      });
      return {
        ...state,
        deleteRowAvailableTypeSystemFunction: [
          ...state.deleteRowAvailableTypeSystemFunction,
          ...deleteRowAvailableTypeSystemFunction,
        ],
        listTypeSystemService: [],
      };
    },
    setHeaderDetailBoardFunction: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearFieldFormFunction: (state, action) => {
      var oInicial = {
        searchtext: '',
        name: '',
        bValidName: null,
        selectTypeFunction: [],
        bValidSelectTypeFunction: null,
        listTypeSystemService: [],
        deleteRowAvailableTypeSystemFunction: [],
        selectAmdminCampos: [],
        selectAmdminCamposFinal: [],
      };
      return { ...state, ...oInicial };
    },
    clearAllFieldsFunction: (state, action) => {
      var oInicial = {
        searchtext: '',
        idFunction: null,
        name: '',
        bValidName: null,
        selectTypeFunction: [],
        bValidSelectTypeFunction: null,
        listTypeSystemService: [],
        deleteRowAvailableTypeSystemFunction: [],
        selectAmdminCampos: [],
        selectAmdminCamposFinal: [],
      };
      return { ...state, ...oInicial };
    },
    filterListFunctions: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListFunctions = estadoInspeccionado.listadoFunciones.filter(function (item) {
        return (
          item.sCodigoFuncion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sNombreFuncion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sTipoFuncion.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListFunctions: filterListFunctions,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListAvailableFields: (state, action) => {
      return { ...state, ...action.payload };
    },
    setFilterListAvailableFields: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeFindAdminCampo: (state, action) => {
      let person = action.payload;
      const filteredPeople = !!state.tempSelectAmdminCampos.find(
        (p) => p.IDCampoHomo === person.IDCampoHomo
      )
        ? state.tempSelectAmdminCampos.filter((p) => p.IDCampoHomo !== person.IDCampoHomo)
        : [...state.tempSelectAmdminCampos, person];
      let oParam = {
        tempSelectAmdminCampos: filteredPeople,
      };
      return { ...state, ...oParam };
    },
    setSelectAmdminCampos: (state, action) => {
      var oParam = {
        selectAmdminCampos: action.payload,
      };
      return { ...state, ...oParam };
    },
    setSelectAmdminCamposFinal: (state, action) => {
      var oParam = {
        selectAmdminCamposFinal: action.payload,
      };
      return { ...state, ...oParam };
    },
  },
});
export const {
  changeNameFunction,
  onSelectTypeFunction,
  setListTypeFunction,
  onAddRowtableTypeSystemFunction,
  setListarFunciones,
  setAvailableTypeSystemFunction,
  changeRowsSelectAvailableTypeSystemFunction,
  changeRowsChangeServiceName,
  onDeleteRowAvailableTypeSystemFunction,
  onSetDeleteAvailableTypeSystemFunction,
  setHeaderDetailBoardFunction,
  clearFieldFormFunction,
  clearAllFieldsFunction,
  filterListFunctions,
  setListAvailableFields,
  setFilterListAvailableFields,
  changeFindAdminCampo,
  setSelectAmdminCampos,
  setSelectAmdminCamposFinal,
} = funcionesSlice.actions;
export default funcionesSlice.reducer;
