import { faEdit, faList, faMinus, faTrash } from '@fortawesome/fontawesome-free-solid';
import { Button } from '../../../../components';
import { RowButton } from '../../../../styles';

export const columns = (
  handleOpenModalRol,
  handleOpenModalUsuarioEdit,
  handleDeleteUsuario,
  t,
  id
) => [
  {
    field: 'Usuario',
    headerName: t('usuario.listaUsuario.1'),
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'Correo',
    headerName: t('usuario.listaUsuario.2'),
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',
    headerName: t('usuario.listaUsuario.3'),
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'left',
    width: 75,
    renderCell: (params) =>
      !params.row.Admin ? (
        <RowButton>
          <Button
            action={(oEvent) => handleOpenModalRol(oEvent, params.row)}
            icon={faList}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
          />
        </RowButton>
      ) : (
        <></>
      ),
  },
  {
    field: 'edite',
    headerName: '',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    width: 75,
    renderCell: (params) =>
      id != params.id ? (
        !params.row.Admin ? (
          <RowButton>
            <Button
              action={(oEvent) => handleOpenModalUsuarioEdit(oEvent, params.row)}
              icon={faEdit}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
            <Button
              action={(oEvent) => handleDeleteUsuario(oEvent, params.row)}
              icon={faTrash}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        ) : (
          <></>
        )
      ) : (
        <RowButton>
          <Button
            action={(oEvent) => handleOpenModalUsuarioEdit(oEvent, params.row)}
            icon={faEdit}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
          />
        </RowButton>
      ),
  },
];

export const columnsRol = (deleteTableRows, t) => [
  {
    field: 'selectRol',
    headerName: t('usuario.modalRol.listaRol.1'),
    flex: 1,
    renderCell: (params) => <>{params.value[0].label}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <RowButton>
        <Button
          action={() => deleteTableRows(params.id)}
          icon={faMinus}
          isAction={false}
          isOnlyIcon={true}
          type="primary"
        />
      </RowButton>
    ),
  },
];
