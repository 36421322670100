import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { faListUl } from '@fortawesome/fontawesome-free-solid';
import { Button } from '../Button';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import Swal from 'sweetalert2';
export const BasicPopover = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    const aServicios = props.aServicios;
    if (aServicios.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se encontraron servicios disponibles.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
            Open Popover
          </Button> */}

      <Button
        action={(e) => handleClick(e, props)}
        icon={faListUl}
        isOnlyIcon={true}
        isAction={false}
        type="primary"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {props.aServicios.map((value) => (
            <Container>
              <ListItem
                key={value.IDEtiqueta}
                disableGutters
                // secondaryAction={
                //     <IconButton aria-label="comment">
                //     <CommentIcon />
                //     </IconButton>
                // }
              >
                <ListItemText primary={`${value.NombreEtiqueta}`} />
              </ListItem>
            </Container>
          ))}
        </List>
      </Popover>
    </div>
  );
};
const Container = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;
