import { faEdit, faTrash } from '@fortawesome/fontawesome-free-solid';
import { Button } from '../../../../components';

export const columns = (onEditar, onDelete) => [
  {
    field: 'Nombre',
    headerName: 'Nombre',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'Descripcion',
    headerName: 'Descripcion',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerName: '',
    width: 75,
    renderCell: (params) => (
      <>
        <Button
          action={(e) => onEditar(params.row)}
          icon={faEdit}
          isAction={false}
          isOnlyIcon={true}
          type="primary"
        />
        <Button
          action={(e) => onDelete(params.row)}
          icon={faTrash}
          isAction={false}
          isOnlyIcon={true}
          type="primary"
        />
      </>
    ),
  },
];
