import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { Button, TableGrid } from '../../../../../../../components';

export const ModalDetalleCombinacionSolucion = ({
  listaCombinacionSoluciones,
  modalDetalleCombinacionSolucion,
  onClose,
}) => {
  const columns = [
    {
      field: 'tipoSolucion',
      headerName: 'Tipo Solución',
      flex: 2,
      tipo: 'String',
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 5,
      tipo: 'String',
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'importe',
      headerName: 'Importe',
      flex: 3,
      tipo: 'String',
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  return (
    <ModalComponent
      backdrop="static"
      keyboard={false}
      show={modalDetalleCombinacionSolucion}
      onHide={onClose}
    >
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Detalle de Combinación de Soluciones</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        {/* Tabla de Combinacion de Soluciones */}
        <TableGrid
          rows={listaCombinacionSoluciones}
          columns={columns}
          pageSize={15}
          getRowId={(row) => row.idCombinacionSolucion}
        />
      </ModalComponent.Body>
      <ModalComponent.Footer>
        <Button action={onClose} name="Cerrar" isAction={false} type="warning" />
      </ModalComponent.Footer>
    </ModalComponent>
  );
};

const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1200px;
  }
`;
