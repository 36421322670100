export const TipoSolucionesData = [
  {
    Id: 1,
    Codigo: 'LC',
    Nombre: 'Línea de Crédito',
    Habilitado: 1,
  },
  {
    Id: 2,
    Codigo: 'PR',
    Nombre: 'Préstamo',
    Habilitado: 1,
  },
  {
    Id: 3,
    Codigo: 'FC',
    Nombre: 'Factoring',
    Habilitado: 1,
  },
  {
    Id: 4,
    Codigo: 'AP',
    Nombre: 'Aplazamiento',
    Habilitado: 1,
  },
  // {
  //   Id: 5,
  //   Codigo: 'DISN',
  //   Nombre: 'Disgregado N',
  //   Habilitado: 1,
  // },
  {
    Id: 6,
    Codigo: 'IN',
    Nombre: 'Deposito',
    // Nombre: 'Inversión',
    Habilitado: 1,
  },
  // {
  //   Id: 7,
  //   Codigo: 'DISP',
  //   Nombre: 'Digregado P',
  //   Habilitado: 1,
  // },
  {
    Id: 8,
    Codigo: 'TC',
    Nombre: 'Tipo de Cambio',
    Habilitado: 1,
  },
];
