export const createCamposHomologadosColumnasAdapter = (user) => ({
  /*
  IDCampoHomo: user.IDCampoHomo,
  NombreCampoHomo: user.NombreCampoHomo,
  NombreCampoAlias: user.NombreCampoAlias,
  IDOrdenFila: user.IDOrdenFila,
  Obligatorio: user.Obligatorio,
  */
  Habilitado: user.Habilitado,

  value: user.NombreCampoHomo,
  label: user.NombreCampoAlias,
  idTipo: user.IDTipoDato, //1,
  tipo: user.TipoDato, //'String',
  seleccionada: 0,
  obligatorio: user.Obligatorio,
});
