export const EstadoSolucionesData = 
[
    {
        "IdEstado": 1,
        "CodEstado" : "SOL_1",
        "Estado" : "Confirmado",
        "Habilitado" : 1
    },
    {
        "IdEstado": 2,
        "CodEstado" : "SOL_2",
        "Estado" : "Por Confirmar",
        "Habilitado" : 1
    }
];