export const createEstructuraAdapter = (user) => ({
  iIDEstructura: user.iIDEstructura,
  sCodigoEstructura: user.sCodigoEstructura,
  sNombreEstructura: user.sNombreEstructura,
  sDetalleEstructura: user.sDetalleEstructura,
  iHabilitado: user.iHabilitado,
  iEsPrincipal: user.iEsPrincipal,
  sEsPrincipal: user.sEsPrincipal,
});

export const listaEstructuraAdapter = (user) => ({
  value: user.iIDEstructura,
  label: user.sNombreEstructura,
  ...user,
});
