export const listaTipoAutomatizacion = [{ value: 1, label: 'Actualización de datos' }];

export const listaEstadoAutomatizacion = [
  { value: 'Activo', label: 'Activo' },
  { value: 'Inactivo', label: 'Inactivo' },
];

export const listaModalidadAutomatizacion = [
  { value: 1, label: 'Unica posicion' },
  { value: 2, label: 'Por grupo de posiciones' },
  { value: 3, label: 'Una estructura' },
  { value: 4, label: 'Api tercero' },
];

export const listaFrecuenciaAutomatizacion = [
  { value: 'U', label: 'Unica Ejecución' },
  { value: 'H', label: 'Por hora' },
  { value: 'D', label: 'Por Dia' },
  { value: 'S', label: 'Por semana' },
  { value: 'M', label: 'Por mes' },
  { value: 'A', label: 'Por año' },
];
