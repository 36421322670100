import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';

export const ContainerForm = (props) => (
  <FieldsetForm>
    <LegendForm>{props.title}</LegendForm>
    {props.children}
  </FieldsetForm>
);

let FieldsetForm = styled.fieldset`
  border: 2px solid ${theme.colors.line};
  border-radius: 3px;
  padding: 12px;
  margin: 3px;
`;
let LegendForm = styled.legend`
  color: ${theme.colors.primary};
  float: none;
  width: auto;
  padding: 5px;
  margin-bottom: auto;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  text-align: initial;
`;
