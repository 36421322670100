export const createMonedaAdapter = (user, c) => {
  return {
    ...user,
    value: user.iIDTipoMoneda,
    label: c
      ? c === 'codigo'
        ? user.sAbreviatura
        : c === 'codigo-nombre'
        ? user.sAbreviatura + '-' + user.sNombreMoneda
        : user.sNombreMoneda
      : user.sNombreMoneda,
  };
};

export const createMonedaAbreviaturaAdapter = (user, c) => ({
  ...user,
  value: user.sAbreviatura,
  label: c
    ? c === 'codigo'
      ? user.sAbreviatura
      : c === 'codigo-nombre'
      ? user.sAbreviatura + '-' + user.sNombreMoneda
      : user.sNombreMoneda
    : user.sNombreMoneda,
});
