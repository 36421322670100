import React, { useState, useEffect } from 'react';
import {
  Button,
  SearchInputText,
  LoadingSpinner,
  ModalConfirmation,
  TableGrid,
  PBR,
  ButtonAccion,
} from '../../../../components';
import {
  clearAllFieldsFunction,
  filterListFunctions,
  setListarFunciones,
  updateTitle,
} from '../../../../redux/states';
import { useDispatch, useSelector } from 'react-redux';
import { faEdit } from '@fortawesome/fontawesome-free-solid';
import { createListadoFunciones } from '../../../../adapters';
import { useFetchAndLoad } from '../../../../common/hooks';
import Swal from 'sweetalert2';
import {
  eliminarFunciones,
  obtenerListadoFunciones,
  getInfoFunction,
} from '../../../../services/public.service';
import { useNavigate } from 'react-router-dom';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
  MenuAccion,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { permisos } from '../../../../common';
import { items } from './misc';
export const ListarFuncion = (props) => {
  const [showModalEliminarFunciones, setShowModalEliminarFunciones] = useState(false);
  const [selectedFunciones, setSelectedFunciones] = useState([]);
  const funcionState = useSelector((store) => store.funciones);
  const { loading, callEndpoint } = useFetchAndLoad();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Listar Función' }));

      loadFunciones();
    })();
  }, []);

  const loadFunciones = async () => {
    const responseFunciones = await callEndpoint(obtenerListadoFunciones());
    var listadoFunciones = [];
    for (
      let index = 0;
      index < responseFunciones.data.respuesta.aListaConfigFunciones.length;
      index++
    ) {
      const element = responseFunciones.data.respuesta.aListaConfigFunciones[index];
      var obj = createListadoFunciones(element);
      listadoFunciones.push(obj);
    }
    dispatch(setListarFunciones(listadoFunciones));
  };

  const findFunciones = (person) => {
    return !!selectedFunciones.find(
      (p) => p.iIDFConexionAgrupador === person.iIDFConexionAgrupador
    );
  };

  const filterFunciones = (person) => {
    return selectedFunciones.filter(
      (p) => p.iIDFConexionAgrupador !== person.iIDFConexionAgrupador
    );
  };

  const handleChange = (person) => {
    const filteredPeople = findFunciones(person)
      ? filterFunciones(person)
      : [...selectedFunciones, person];
    setSelectedFunciones(filteredPeople);
  };

  const onClickEliminarFunciones = () => {
    if (selectedFunciones.length > 0) {
      setShowModalEliminarFunciones(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onCloseModalEliminarFuncion = () => {
    setShowModalEliminarFunciones(false);
  };

  const onAgregarfuncion = () => {
    dispatch(clearAllFieldsFunction());
    navigate('/dashboard/configuracion/funcion/crear');
  };

  const onConfirmarEliminarFunciones = async () => {
    var aListaRegistroEliminar = [];
    selectedFunciones.forEach((element) => {
      var o = {};
      o.iIDFConexionAgrupador = element.iIDFConexionAgrupador;
      aListaRegistroEliminar.push(o);
    });
    const responseEliminarRegistroM = await callEndpoint(
      eliminarFunciones({ aListaRegistroEliminar: aListaRegistroEliminar })
    );
    setShowModalEliminarFunciones(false);
    if (responseEliminarRegistroM == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseEliminarRegistroM.data.cabecera.statusCode === 200) {
        loadFunciones();
        setSelectedFunciones([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseEliminarRegistroM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseEliminarRegistroM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onClickEditarConcepto = async (row) => {
    // dispatch(updateTitle({ title: "Editar Concepto" }));
    dispatch(clearAllFieldsFunction());

    const { data } = await callEndpoint(getInfoFunction({ idFuncion: row.iIDFConexionAgrupador }));
    if (data) {
      navigate('/dashboard/configuracion/funcion/editar/' + row.iIDFConexionAgrupador);
    }
  };
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListFunctions(oEvent.target.value));
    } else {
      dispatch(filterListFunctions(''));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <CheckboxStyle
          size="small"
          checked={findFunciones(params.row)}
          onChange={() => handleChange(params.row)}
        />
      ),
    },
    {
      field: 'sCodigoFuncion',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'sNombreFuncion',
      headerName: 'Nombre Función',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    // {
    //   field: 'sTipoFuncion',
    //   headerName: 'Tipo Función',
    //   flex: 1,
    //   renderCell: (params) => <>{params.value}</>,
    // },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <PBR permiso={[permisos.EDITAR_FUNCION]}>
          <Button
            action={(e) => onClickEditarConcepto(params.row)}
            icon={faEdit}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
          />
        </PBR>
      ),
    },
  ];

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar Función...'}
              filter={funcionState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <PBR permiso={[permisos.ELIMINAR_FUNCION]}>
                  <Dropdown trigger={['click']} menu={{ items: items(onClickEliminarFunciones) }}>
                    <ButtonAccion />
                  </Dropdown>
                </PBR>
                <PBR permiso={[permisos.CREAR_FUNCION]}>
                  <Button action={onAgregarfuncion} name="Agregar" isAction={true} type="primary" />
                </PBR>
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        {
          <TableGrid
            columns={columns}
            rows={
              funcionState.searchtext.length === 0
                ? funcionState.listadoFunciones
                : funcionState.filterListFunctions
            }
            pageSize={15}
            getRowId={(row) => row.iIDFConexionAgrupador}
          />
        }
      </ColFlex>
      <ModalConfirmation
        showModal={showModalEliminarFunciones}
        closeModal={(e) => onCloseModalEliminarFuncion()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?, Esto hara que se elimine los conceptos y posiciones asignadas a la/las funciones"
        action={(e) => onConfirmarEliminarFunciones(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
