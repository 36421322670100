import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
export const NoData = (props) => {
  return (
    <Content>
      <Label>{props.title}</Label>
    </Content>
  );
};
const Content = styled.div`
  width: '100%'; //150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Label = styled.span`
  font-family: ${theme.font.family};
  font-size: 12px;
`;
