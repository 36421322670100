import {createSlice} from '@reduxjs/toolkit'
export const instituacionInitialState = {
  //TablaBuscador
  searchtext: '',
  //formulario
  listaInstitucion: [],
  listaEmpresa: [],
  selectEmpresa: [],
  bValidSelectEmpresa: null,
  tipo: '',
  bValidTipo: null,
  institucion: '',
  bValidInstitucion: null,
  listaProducto: [],
  selectProducto: [],
  bValidSelectProducto: null,
}
export const institucionSlice = createSlice({
  name: 'institucion',
  initialState: instituacionInitialState,
  reducers: {
    agregarInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    editarInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    eliminarInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    //Change
    changeEmpresaInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    changeTipoInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    changeInstitucionInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
    changeProductoInstitucion: (state, action) => {
      return {...state, ...action.payload}
    },
  },
})
export const {} = institucionSlice.actions
export default institucionSlice.reducer
