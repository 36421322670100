export const listarSocioCompartido = (row) => ({
  key: row.IDSocio,
  title: row.Nombre_Socio,
  children: datosExtraSocio(),
  ...row,
});

const datosExtraSocio = () => [
  {
    key: '1f',
    title: 'Factoring',
    children: [
      { key: '1f.1', title: 'EEFF' },
      { key: '1f.2', title: 'Vigencia de poder' },
      { key: '1f.3', title: 'Ficha ruc' },
      { key: '1f.4', title: 'Dni representantes' },
    ],
  },
  {
    key: '1p',
    title: 'Prestamo',
    children: [
      { key: '1p.1', title: 'EEFF' },
      { key: '1p.2', title: 'Vigencia de poder' },
      { key: '1p.3', title: 'Ficha ruc' },
      { key: '1p.4', title: 'Dni representantes' },
    ],
  },
];
