export const createObtenerInfoFiltrosFlujoCajaCabeceraAdapter = (user) => ({
    IdPosicion:user.IdPosicion,
    value : user.IdPosicion,
    CodigoPosicion:user.CodigoPosicion,
    NombrePosicion:user.NombrePosicion,
    label : user.NombrePosicion,
    IDConcepto : user.IDConcepto,
    NombreConcepto:user.NombreConcepto,
    TipoConcepto:user.TipoConcepto
})
