import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import { theme } from '../common';
import { Box } from '@mui/material';

export const AppBar = styled(MuiAppBar)`
  width: 100vw;
  /* width: ${({ open }) => (!open ? '100vw' : `calc(100vw - 300px)`)}; */
  min-height: 45px !important;
  height: 45px;
  background: white;
  color: ${theme.colors.text};
  transition: all 0.5s;
  z-index: 12;
`;

export const ListStyle = styled(List)`
  padding-top: 20px;
  overflow-y: auto;
  height: calc(100vh - 65px);
`;

export const BoxStyle = styled(Box)`
  /* width: ${({ open }) => (!open ? '100vw' : `calc(100vw - 300px)`)}; */
  width: 100vw;
  height: 100vh;
  overflow: auto;
  transition: all 5ms;
`;
