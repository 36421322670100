import { Col } from 'antd';
import styled from 'styled-components';

export const ColFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColForm = styled(Col)`
  margin-bottom: 5px;
  column-gap: 5px;
`;
export const ColDash = styled(Col)``;
export const ColFlexForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: space-between;
`;
