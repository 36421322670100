import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import { theme } from '../common';

export const Title = styled.h2`
  margin: 0px 0px 0px;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.5;
  font-family: ${theme.font.family};
  display: block;
  color: ${theme.colors.primary};
`;
export const SubTitle = styled.h2`
  margin: 0px 0px 0px;
  font-size: 16px;
  line-height: 1.5;
  font-family: ${theme.font.family};
  display: block;
  margin-right: 4px;
  color: ${theme.colors.primary};
`;
export const ColumnRoute = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-flow: row nowrap;
`;

export const ButtonRoute = styled.button`
  font-family: UbuntuBold;
  font-size: 12px;
  background: ${({ isAction }) => (isAction ? '#E8B639' : '#183838')};
  padding: 8px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isAction }) => (isAction ? '#E8B639' : '#183838')};
  gap: 10px;
  span {
    color: black;
  }
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const IconRouteForm = styled.div`
  color: ${({ isAction }) => (isAction ? '#E8B639 !important' : '#fff !important')};
  align-items: center;
`;

export const DrawerSideRoute = styled.div`
  height: 100vh;
  transition: all 1s;
  background-color: white;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px;
  position: relative;
`;

export const DivImgOculto = styled.div`
  height: 45px;
  width: 45px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
`;

export const MenuNameRoute = styled.span`
  margin: 0px;
  font-size: 14px;
  font-family: ${theme.font.family}, sans-serif;
  font-weight: 400;
  width: 100%;
  color: #48494d;
`;
export const MenuNameItemRoute = styled.a`
  margin: 0px !important;
  font-size: 14px !important;
  font-family: ${theme.font.family}, sans-serif !important;
  font-weight: 400 !important;
  width: 100% !important;
  color: black;
  &:hover {
    color: black;
  }
`;
export const ContentListItemRoute = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
`;
export const CustomListItemRoute = styled(ListItem)`
  &:hover {
    text-decoration: none;
    background-color: ${theme.colors.primary} !important;
    color: white !important;
    border-radius: 5px !important;
    padding: 8px 16px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 9px;
    cursor: pointer;
  }
  &:hover div svg,
  &:hover span {
    color: white !important;
  }
`;

export const CustomListItemOptionsRoute = styled(ListItem)`
  margin-left: 40px;
  padding-left: 42px !important;
  cursor: pointer;
`;
export const SubMenuListRoute = styled.div`
  li {
    width: 80%;
  }
  li a {
    color: #48494d;
  }
  &:hover li {
    background-color: ${theme.colors.primary} !important;
    border-radius: 5px !important;
  }
  &:hover li a {
    color: ${theme.colors.white} !important;
  }
`;

export const TextCenter = styled.div`
  text-align: center;
`;
export const TextSpan = styled.span`
  font-family: ${theme.font.family};
  font-size: 12px;
`;

export const IconButtonDash = styled(IconButton)`
  margin-right: 10px !important;
  border-radius: 10px !important;
  svg {
    color: ${theme.colors.text};
  }
`;
