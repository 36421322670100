export const buildQueryUrlService = (url, oParam) => {
  let finalUrl = url;
  if (oParam != null) {
    var keys = Object.keys(oParam);
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      if (oParam[element]) {
        if (index === 0) {
          finalUrl = finalUrl + '?' + element + '=' + oParam[element];
        } else {
          finalUrl = finalUrl + '&' + element + '=' + oParam[element];
        }
      }
    }
  }
  return finalUrl;
};
