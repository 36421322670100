import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  limpiarMenu,
  onLogin,
  onLogout,
  onTenant,
  setPersonalizacion,
  updateListItem,
} from '../redux/states';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Container } from '@mui/material';
import Collapse from '@material-ui/core/Collapse';
import { Dropdown, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/fontawesome-free-solid';
import { ModalConfirmation, PBR, ContainerRoutes } from '../components';
import {
  CrearConcepto,
  CrearEstructura,
  CrearFuncion,
  CrearPocision,
  CrearSistema,
  EditarConcepto,
  EditarEstructura,
  EditarFuncion,
  EditarPosicion,
  EditarSistema,
  ListarCampo,
  ListarConcepto,
  ListarEstructura,
  ListarFuncion,
  ListarPosicion,
  ListarSistema,
  ListarSocio,
  CrearLineaCredito,
  EditarLineaCredito,
  ListarLineaCredito,
  ListarPrestamo,
  CrearPrestamo,
  EditarPrestamo,
  ListarAplazamiento,
  CrearAplazamiento,
  EditarAplazamiento,
  ListarDepositoPlazo,
  CrearDepositoPlazo,
  EditarDepositoPlazo,
  ListarFlujoCaja,
  CrearFlujoCaja,
  VisualizarFlujoCaja,
  DashboardDetalle,
  Notificaciones,
  SeguridadUsuario,
  TabRegistroPropio,
  CrearRegistroPropio,
  EditarRegistroPropio,
  ListarRegistroFuente,
  CrearFactoring,
  EditarFactoring,
  ListarFactoring,
  CrearTipoCambio,
  EditarTipoCambio,
  ListarTipoCambio,
  EditarRegistroFuente,
  ListarGestion,
  Personalizacion,
  ListarTipoCambioConf,
  SeguridadRol,
  ListarAutomatizacion,
  CrearAutomatizacion,
  EditarAutomatizacion,
  Documentos,
  ListarDerivacion,
  CrearDerivacion,
  EditarDerivacion,
  ListarAdelanto,
  ListarPosicionMaster,
  SeguridadPerfil,
  CrearFlujoCajaF,
} from '../pages';
import {
  AppBar,
  BoxStyle,
  ColumnRoute,
  ContentListItemRoute,
  CustomListItemOptionsRoute,
  CustomListItemRoute,
  DrawerSideRoute,
  IconButtonDash,
  ListStyle,
  MenuNameItemRoute,
  MenuNameRoute,
  RowFlex,
  SubMenuListRoute,
  SubTitle,
  Title,
  DivImgOculto,
} from '../styles';
import logoPanel from '../assets/images/zcf.png';
import logoIco from '../assets/images/icoZCF.png';
import logoIco2 from '../assets/images/icoZCF2.png';
import {
  obtenerPersonalizacion,
  obtenerUrlImg,
  reNewToken,
  refreshSignIn,
} from '../services/public.service';
import { theme, useFetchAndLoad } from '../common';
import { items } from './misc/menuUsuario';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const menuState = useSelector((store) => store.menu);
  const authState = useSelector((store) => store.auth);
  const [showModalCerrarSesion, setShowModalCerrarSesion] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [inactive, setInactive] = React.useState(false);
  const navBarState = useSelector((store) => store.navBar);
  const { callEndpoint } = useFetchAndLoad();
  const [fotoUsuario, setFotoUsuario] = React.useState('');
  const { t } = useTranslation();

  useEffect(() => {
    checkAuthToken();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  useEffect(() => {
    if (authState.user.foto && authState.user.foto !== '') {
      (async () => {
        const foto = await obtenerUrlImg(authState.user.foto);
        setFotoUsuario(foto);
      })();
    }
  }, [authState.user]);

  const loadPersonalizacion = async (id) => {
    const responsePersonalizacion = await callEndpoint(obtenerPersonalizacion({ IDUsuario: id }));
    var listaPersonalizacion = [];
    for (
      let index = 0;
      index < responsePersonalizacion.data.respuesta.aListadoPer.length;
      index++
    ) {
      const element = responsePersonalizacion.data.respuesta.aListadoPer[index];
      listaPersonalizacion.push(element);
    }
    if (listaPersonalizacion.length === 0) {
      listaPersonalizacion.push({ DescPersonalizacion: 'Moneda', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({ DescPersonalizacion: 'Periodo', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({ DescPersonalizacion: 'Formato', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({
        DescPersonalizacion: 'texto_moneda',
        DatoPersonalizacion: 'nombre',
      });
    }
    if (listaPersonalizacion.filter((v) => v.DescPersonalizacion === 'Lenguaje').length > 0) {
      i18next.changeLanguage(
        listaPersonalizacion.filter((v) => v.DescPersonalizacion === 'Lenguaje')[0]
          .DatoPersonalizacion
      );
    }
    dispatch(setPersonalizacion(listaPersonalizacion));
  };

  const checkAuthToken = async () => {
    try {
      let tokenRefresh = localStorage.getItem('refresh-token');
      const responseCognito = await refreshSignIn(tokenRefresh);

      if (responseCognito.statusCode === 200) {
        localStorage.setItem('auth-token', responseCognito.result.AccessToken);
        localStorage.setItem('id-token', responseCognito.result.IdToken);

        const { data } = await callEndpoint(reNewToken());
        localStorage.setItem('x-token', data.token);
        localStorage.setItem('token-init-date', new Date().getTime());
        let tenant = localStorage.getItem('x-tenant');
        let foto = '';
        if (data.foto.length > 0) {
          foto = data.foto[0];
        }
        dispatch(
          onLogin({
            name: data.usuario,
            uid: data.id,
            logged: true,
            isSuperAdmin: data.Admin,
            user: data.id,
            permisos: data.permisos,
            foto: foto,
          })
        );
        await loadPersonalizacion(data.id);
        dispatch(onTenant(tenant));
      } else {
        localStorage.clear();
        dispatch(onLogout());
        navigate('/login');
      }
    } catch (error) {
      localStorage.clear();
      dispatch(onLogout());
      navigate('/login');
    }
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClick = (oEvent, menuItem, index) => {
    dispatch(updateListItem(menuItem.id));
  };

  const onClickVerPerfil = () => {
    navigate('/dashboard/seguridad/perfil');
  };

  const onClickCerrarSesion = () => {
    setShowModalCerrarSesion(true);
  };

  const onCloseModalCerrarSesion = () => {
    setShowModalCerrarSesion(false);
  };

  const cerrarSesion = async (e) => {
    await dispatch(limpiarMenu());
    await dispatch(onLogout());
    await localStorage.clear();
    navigate('/login');
  };

  return (
    <div className="App">
      <DrawerSideRoute className={!open ? 'd-nonemeWidth' : ''} open={open}>
        <div
          className={!open ? 'd-nonemeWidth' : ''}
          style={{
            width: '300px',
            height: '45px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 450ms',
          }}
        >
          <img src={logoPanel} alt="Italian Trulli" style={{ height: '75%' }}></img>
        </div>
        <ListStyle component="nav">
          {menuState.MenuItems.map((menuItem, index) => (
            <PBR permiso={menuItem.permiso} key={index}>
              <RowFlex>
                <ContentListItemRoute>
                  <CustomListItemRoute
                    key={index}
                    button
                    onClick={(e) => {
                      if (inactive) {
                        setInactive(false);
                      } else {
                        handleClick(e, menuItem, index);

                        if (menuItem.subMenus.length === 0) {
                          navigate(menuItem.to);
                        }
                      }
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={menuItem.icon} />
                    </ListItemIcon>
                    {open ? <MenuNameRoute>{t(menuItem.name)}</MenuNameRoute> : <></>}

                    {menuItem.subMenus.length === 0 ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          justifyContent: 'end',
                          alignContent: 'end',
                          display: 'flex',
                        }}
                      >
                        {menuItem.onExpand ? (
                          <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                        ) : (
                          <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        )}
                      </div>
                    )}
                  </CustomListItemRoute>

                  {menuItem.subMenus.length === 0 ? (
                    <></>
                  ) : (
                    <Collapse in={menuItem.onExpand} timeout="auto" unmountOnExit>
                      {menuItem.subMenus && menuItem.subMenus.length > 0 ? (
                        <List component="li" disablePadding>
                          {menuItem.subMenus.map((menu, index) => (
                            <PBR permiso={menu.permiso} key={index}>
                              <SubMenuListRoute
                                key={index}
                                onClick={(e) => {
                                  navigate(menu.to);
                                }}
                              >
                                <CustomListItemOptionsRoute>
                                  {open ? (
                                    <MenuNameItemRoute
                                      href={menu.to}
                                      onClick={(oEvent) => {
                                        oEvent.preventDefault();
                                        return false;
                                      }}
                                    >
                                      {t(menu.name)}
                                    </MenuNameItemRoute>
                                  ) : (
                                    <></>
                                  )}
                                </CustomListItemOptionsRoute>
                              </SubMenuListRoute>
                            </PBR>
                          ))}
                        </List>
                      ) : null}
                    </Collapse>
                  )}
                </ContentListItemRoute>
              </RowFlex>
            </PBR>
          ))}
        </ListStyle>
      </DrawerSideRoute>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open} className={open ? 'd-nonemeApp' : ''}>
          <Container style={{ margin: 0, width: '100%', maxWidth: '100%', paddingLeft: '15px' }}>
            <RowFlex style={{ width: '100%' }}>
              <DivImgOculto className={open ? 'd-nonemeAll' : ''} open={open}>
                <img src={logoIco} alt="Italian Trulli" style={{ height: '75%' }}></img>
              </DivImgOculto>
              <Toolbar
                sx={{ minHeight: '45px !important', height: '45px', width: '100%' }}
                disableGutters
              >
                <IconButtonDash
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <img src={logoIco2} alt="Italian Trulli" style={{ height: '25px' }}></img>
                </IconButtonDash>
                <Title>{navBarState.title}</Title>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 0 }}>
                  <ColumnRoute style={{ alignItems: 'center' }}>
                    <div style={{ marginRight: '10px', textAlign: 'end' }}>
                      <SubTitle>
                        {t('saludo').toUpperCase()} {authState.user.name.toUpperCase()}
                      </SubTitle>
                      <span
                        style={{
                          fontSize: '10px',
                          marginRight: '4px',
                          color: theme.colors.primary,
                          fontWeight: 'bold',
                        }}
                      >
                        {authState.tenant.toUpperCase()}
                      </span>
                    </div>
                    <Dropdown
                      trigger={['click']}
                      menu={{ items: items(onClickVerPerfil, onClickCerrarSesion) }}
                      style={{
                        'align-self': 'flex-end',
                      }}
                    >
                      {fotoUsuario === '' ? (
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          color={theme.colors.primary}
                          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                        />
                      ) : (
                        <Avatar src={fotoUsuario} />
                      )}
                    </Dropdown>
                  </ColumnRoute>
                </Box>
              </Toolbar>
            </RowFlex>
          </Container>
        </AppBar>
        <BoxStyle
          component="div"
          sx={{
            flexGrow: 2,
            p: 5,
            height: 'calc(100vh - 45px)',
            padding: '20px 40px 40px 40px',
            marginTop: '45px',
          }}
          className={open ? 'd-nonemeApp' : ''}
          open={open}
        >
          <Routes>
            <Route path="/" element={<DashboardDetalle />} />
            {/* Seguridad */}
            <Route path="seguridad">
              <Route path="usuario" element={<ContainerRoutes children={<SeguridadUsuario />} />} />
              <Route path="rol" element={<ContainerRoutes children={<SeguridadRol />} />} />
              <Route path="perfil" element={<ContainerRoutes children={<SeguridadPerfil />} />} />
              {/* <Route
                path="auditoria"
                element={<ContainerRoutes children={<SeguridadAuditoria />} />}
              />
              <Route path="pagos" element={<ContainerRoutes children={<SeguridadPagos />} />} /> */}
            </Route>

            {/* Automatizacion */}
            <Route path="automatizacion">
              <Route
                path="listar"
                element={<ContainerRoutes children={<ListarAutomatizacion />} />}
              />
              <Route
                path="crear"
                element={<ContainerRoutes children={<CrearAutomatizacion />} />}
              />
              <Route
                path="editar/:id"
                element={<ContainerRoutes children={<EditarAutomatizacion />} />}
              />
            </Route>
            {/* Personalizacion */}

            <Route
              path="personalizacion"
              element={<ContainerRoutes children={<Personalizacion />} />}
            />
            {/* Configuracion */}

            <Route path="configuracion">
              <Route path="sistema">
                <Route path="listar" element={<ContainerRoutes children={<ListarSistema />} />} />
                <Route path="crear" element={<ContainerRoutes children={<CrearSistema />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarSistema />} />}
                />
              </Route>
              <Route path="campo">
                <Route path="listar" element={<ContainerRoutes children={<ListarCampo />} />} />
              </Route>
              <Route path="funcion">
                <Route path="listar" element={<ContainerRoutes children={<ListarFuncion />} />} />
                <Route path="crear" element={<ContainerRoutes children={<CrearFuncion />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarFuncion />} />}
                />
              </Route>
              <Route path="concepto">
                <Route path="listar" element={<ContainerRoutes children={<ListarConcepto />} />} />
                <Route path="crear" element={<ContainerRoutes children={<CrearConcepto />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarConcepto />} />}
                />
              </Route>
              <Route path="posicion-master">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarPosicionMaster />} />}
                />
              </Route>
              <Route path="posicion">
                <Route path="listar" element={<ContainerRoutes children={<ListarPosicion />} />} />
                <Route path="crear" element={<ContainerRoutes children={<CrearPocision />} />} />
                <Route
                  path="crear/:id"
                  element={<ContainerRoutes children={<CrearPocision />} />}
                />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarPosicion />} />}
                />
              </Route>
              <Route path="estructura">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarEstructura />} />}
                />
                <Route path="crear" element={<ContainerRoutes children={<CrearEstructura />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarEstructura />} />}
                />
              </Route>
              <Route path="tipo_cambio">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarTipoCambioConf />} />}
                />
              </Route>
              <Route path="derivacion">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarDerivacion />} />}
                />
                <Route path="crear" element={<ContainerRoutes children={<CrearDerivacion />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarDerivacion />} />}
                />
              </Route>
            </Route>
            {/* Socio Negocio */}

            <Route path="socio_negocio">
              <Route path="socios" element={<ContainerRoutes children={<ListarSocio />} />} />
              <Route path="documentos" element={<ContainerRoutes children={<Documentos />} />} />
            </Route>
            {/* Soluciones */}

            <Route path="soluciones">
              <Route path="linea_credito">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarLineaCredito />} />}
                />
                <Route
                  path="crear"
                  element={<ContainerRoutes children={<CrearLineaCredito />} />}
                />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarLineaCredito />} />}
                />
              </Route>
              <Route path="prestamo">
                <Route path="listar" element={<ContainerRoutes children={<ListarPrestamo />} />} />
                <Route path="crear" element={<ContainerRoutes children={<CrearPrestamo />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarPrestamo />} />}
                />
              </Route>
              <Route path="disgregada" element={<ContainerRoutes children={<>disgregada</>} />} />
              <Route path="aplazamiento">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarAplazamiento />} />}
                />
                <Route
                  path="crear"
                  element={<ContainerRoutes children={<CrearAplazamiento />} />}
                />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarAplazamiento />} />}
                />
              </Route>
              <Route path="adelanto">
                <Route path="listar" element={<ContainerRoutes children={<ListarAdelanto />} />} />
              </Route>
              <Route path="factoring">
                <Route path="listar" element={<ContainerRoutes children={<ListarFactoring />} />} />
                <Route path="crear" element={<ContainerRoutes children={<CrearFactoring />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarFactoring />} />}
                />
              </Route>
              <Route path="deposito">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarDepositoPlazo />} />}
                />
                <Route
                  path="crear"
                  element={<ContainerRoutes children={<CrearDepositoPlazo />} />}
                />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarDepositoPlazo />} />}
                />
              </Route>

              <Route path="tipoCambio">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarTipoCambio />} />}
                />
                <Route path="crear" element={<ContainerRoutes children={<CrearTipoCambio />} />} />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarTipoCambio />} />}
                />
              </Route>
            </Route>
            {/* Flujo Caja */}

            <Route path="flujo_caja">
              <Route path="fuente">
                <Route
                  path="listar"
                  element={<ContainerRoutes children={<ListarRegistroFuente />} />}
                />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarRegistroFuente />} />}
                />
              </Route>
              <Route path="propio">
                <Route
                  path="listar/:tab/*"
                  element={<ContainerRoutes children={<TabRegistroPropio />} />}
                />
                <Route
                  path="crear"
                  element={<ContainerRoutes children={<CrearRegistroPropio />} />}
                />
                <Route
                  path="crear/:id"
                  element={<ContainerRoutes children={<CrearRegistroPropio />} />}
                />
                <Route
                  path="editar/:id"
                  element={<ContainerRoutes children={<EditarRegistroPropio />} />}
                />
              </Route>
              <Route path="reporte">
                <Route path="listar" element={<ContainerRoutes children={<ListarFlujoCaja />} />} />
                {/* <Route path="crear" element={<ContainerRoutes children={<CrearFlujoCaja />} />} /> */}
                {/* Cambio para mejora de tiempo en flujo de Caja */}
                {/* <Route path="crearf" element={<ContainerRoutes children={<CrearFlujoCajaF />} />} /> */}

                <Route path="crear" element={<ContainerRoutes children={<CrearFlujoCajaF />} />} />
                {/* Cambio para mejora de tiempo en flujo de Caja */}
                <Route path="crearf" element={<ContainerRoutes children={<CrearFlujoCaja />} />} />

                <Route
                  path="visualizar/:id"
                  element={<ContainerRoutes children={<VisualizarFlujoCaja />} />}
                />
              </Route>
              <Route path="gestion">
                <Route path="listar" element={<ContainerRoutes children={<ListarGestion />} />} />
              </Route>
            </Route>
            {/* Notificaciones */}

            <Route
              path="notificaciones"
              element={<ContainerRoutes children={<Notificaciones />} />}
            />
          </Routes>
        </BoxStyle>
      </Box>
      <ModalConfirmation
        showModal={showModalCerrarSesion}
        closeModal={(e) => onCloseModalCerrarSesion()}
        title="¿Confirmación?"
        subtitle="¿Desea cerrar sesión?"
        action={(e) => cerrarSesion(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
    </div>
  );
};

export default Dashboard;
