import React from 'react';
import { Card, CardContent, Typography, Stack, Box, styled } from '@mui/material';
import { Divider } from 'antd';
import { theme } from '../../common';

const DashboardCard = ({
  title,
  subtitle,
  children,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
}) => {
  return (
    <CardDash
      // sx={{
      //   padding: 0,
      //   overflow: 'unset',
      // }}
      elevation={9}
      variant={undefined}
    >
      {cardheading ? (
        <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent>
      ) : (
        <CardContent sx={{ p: '10px' }}>
          {title ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems={'center'}
              mb={2}
            >
              <Box sx={{ p: '10px 0px 0px 10px' }}>
                {title ? (
                  <Typography
                    variant="h6"
                    style={{ fontWeight: '600', color: '#484545', fontFamily: theme.font.family }}
                  >
                    {title}
                  </Typography>
                ) : (
                  ''
                )}

                {subtitle ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {subtitle}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              {action}
            </Stack>
          ) : null}
          <Divider className="my-2" />
          <CardContent sx={{ p: '0px 20px', paddingBottom: '0px !important' }}>
            {children}
          </CardContent>
        </CardContent>
      )}

      {middlecontent}
      {footer}
    </CardDash>
  );
};

export default DashboardCard;

const CardDash = styled(Card)`
  padding: 0;
  margin: 0 calc(100% * 0.009);
  height: calc(100% - 1.5rem);
  overflow: unset;
  border-radius: 5px;
  position: relative;
  /* height: 450px; */

  /*  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 2px;
    background: linear-gradient(
      150deg,
      ${theme.colors.secondary} 21%,
      ${theme.colors.tertiary} 50%,
      ${theme.colors.primary} 86%
    );
    -webkit-mask: linear-gradient(${theme.colors.white} 0 0) content-box,
      linear-gradient(${theme.colors.white} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }*/
`;
