import React from 'react';
import { Modal } from 'antd';
import { Button, LabelForm, SelectForm, TableGrid } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { faMinus } from '@fortawesome/fontawesome-free-solid';
import { onAddRowRolUsuario, setSelectRol, onDeleteRowUsuario } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { ColForm, RowButton, RowDoubleInput, RowForm, Title } from '../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { columnsRol } from '../misc';

export const ModalRol = (props) => {
  const usuarioState = useSelector((store) => store.usuario);
  const rolState = useSelector((store) => store.rol);
  const dispatch = useDispatch();
  let { t } = useTranslation();

  const addTableRows = () => {
    if (usuarioState.selectRol.length > 0) {
      var uuid = uuidv4();
      dispatch(
        onAddRowRolUsuario({
          newItem: {
            usuarioRolId: uuid,
            selectRol: usuarioState.selectRol,
            bValidRol: null,
            action: 'N',
            estado: 0,
          },
        })
      );
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado un rol',
        showConfirmButton: false,
        timer: 700,
      });
    }
  };

  const deleteTableRows = (id) => {
    dispatch(
      onDeleteRowUsuario({
        index: id,
      })
    );
  };

  const handleChangeRol = (v) => {
    let oParam = {};
    let validate = null;
    oParam.selectRol = [];
    oParam.bValidRol = validate;
    if (v) {
      if (!usuarioState.listaUsuarioRol.some((f) => f.selectRol[0].rolID == v.RolID)) {
        oParam.selectRol = [v];
        oParam.bValidRol = true;
      }
    }
    dispatch(setSelectRol(oParam));
  };

  return (
    <>
      <Modal
        open={props.open}
        title={
          <Title>{t('usuario.modalRol.titulo', { user: usuarioState.usuarioRolNombre })}</Title>
        }
        closable={false}
        footer={[
          <Button
            action={(oEvent) => props.handleCloseModal(oEvent)}
            name={t('usuario.modalRol.botonCerrar')}
            isAction={true}
            type="warning"
          />,
          <Button
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.handleGuardarModal(oEvent)}
            name={t('usuario.modalRol.botonGuardar')}
            isAction={true}
            type="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={t('usuario.modalRol.label1')}></LabelForm>
            <RowDoubleInput>
              <SelectForm
                width={'60%'}
                validate={usuarioState.bValidselectRol}
                value={usuarioState.selectRol}
                handleChange={(e, v) => handleChangeRol(v)}
                options={rolState.listaRol}
                placeholder={t('usuario.modalRol.placeholder1')}
              />
              <Button
                width={'37%'}
                style={{ marginLeft: 'auto' }}
                action={(oEvent) => addTableRows()}
                name={t('usuario.modalRol.boton1')}
                isAction={true}
                type="primary"
              />
            </RowDoubleInput>
          </RowForm>
        </ColForm>

        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <LabelForm title={t('usuario.modalRol.labelTitulo')}></LabelForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <TableGrid
            columns={columnsRol(deleteTableRows, t)}
            rows={usuarioState.listaUsuarioRol}
            pageSize={10}
            getRowId={(row) => row.usuarioRolId}
          />
        </ColForm>
      </Modal>
    </>
  );
};
