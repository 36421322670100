import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Input,
  InputNumberFormat,
  LabelForm,
  SelectForm,
  SubTitle,
  ModalConfirmation,
  Button,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import 'react-datepicker/dist/react-datepicker.css';
import {
  changeAparicionesIN,
  changeDescripcionIN,
  changeMontoMinimoIN,
  changeMontoMaximoIN,
  changeCantPeriodosIN,
  changeTeaIN,
  changeVigenciaIN,
  limpiarCamposIN,
  selectedAgenteIN,
  selectedEstadoIN,
  selectedMonedaIN,
  selectedTiempoCantPeriodosIN,
  setListaAgenteIN,
  setListaEstadoIN,
  setListaMonedaIN,
  setListaTiempoIN,
  updateTitle,
  limpiarFormIN,
  selectedTipoFomularioIN,
  setValidCamposIN,
} from '../../../../redux/states';
import {
  obtenerAgentes,
  obtenerListadoMoneda,
  registrarSolucion,
} from '../../../../services/public.service';
import {
  createAgenteSelectAdapter,
  createEstadoSolucionSelectAdapter,
  createMonedaAbreviaturaAdapter,
  createTiempoPeriodoAdapter,
} from '../../../../adapters';
import { EstadoSolucionesData, TipoFormularioInversion } from '../../../../common';
import { dateTo_dd_mm_yyyy } from '../../../../common';
import Swal from 'sweetalert2';
import { TiempoPeriodosData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import {
  ColFlex,
  ColForm,
  Content,
  ContentForm,
  DatePickerForm,
  RowDoubleInput,
  RowFooterButton,
  RowForm,
} from '../../../../styles';

export const CrearDepositoPlazo = (props) => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const inversionState = useSelector((store) => store.inversion);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear deposito' }));
  }, []);

  useEffect(() => {
    (async () => {
      await loadAgente();
      await loadMoneda();
      await loadEstado();
      await loadTiempoPeriodo();
    })();
  }, []);

  const handleChangeDescripcion = (oEvent) => {
    var descripcion = oEvent.target.value;
    var validate = null;
    if (descripcion.length > 0) {
      validate = true;
    }
    var oParam = {
      descripcion: descripcion,
      bValidDescripcion: validate,
    };
    dispatch(changeDescripcionIN(oParam));
  };

  const handleChangeAgente = (oEvent) => {
    var oParam = {};
    oParam.bValidAgente = null;
    oParam.selectAgente = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectAgente.push(obj);
      oParam.bValidAgente = true;
    }
    dispatch(selectedAgenteIN(oParam));
  };

  const handleChangeMontoMaximo = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var montoMaximo = values.value;
    var validate = null;
    if (montoMaximo.length > 0) {
      validate = true;
    }
    var oParam = {
      montoMaximo: montoMaximo,
      bValidMontoMaximo: validate,
    };
    dispatch(changeMontoMaximoIN(oParam));
  };

  const handleChangeMontoMinimo = (values, sourceInfo) => {
    // var monto = oEvent.target.value;
    var montoMinimo = values.value;
    var validate = null;
    if (montoMinimo.length > 0) {
      validate = true;
    }
    var oParam = {
      montoMinimo: montoMinimo,
      bValidMontoMinimo: validate,
    };
    dispatch(changeMontoMinimoIN(oParam));
  };

  const handleChangeCantPeriodos = (values, sourceInfo) => {
    // var cantPeriodos = oEvent.target.value;
    var cantPeriodos = values.value;
    // var tea = inversionState.tea;
    var validate = null;
    if (cantPeriodos.length > 0) {
      validate = true;
      // tea  = ""
    }
    var oParam = {
      cantPeriodos: cantPeriodos,
      bValidCantPeriodos: validate,
      // tea : tea
    };
    dispatch(changeCantPeriodosIN(oParam));
  };

  const handleChangeMoneda = (oEvent) => {
    var oParam = {};
    oParam.bValidMoneda = null;
    oParam.selectMoneda = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectMoneda.push(obj);
      oParam.bValidMoneda = true;
    }
    dispatch(selectedMonedaIN(oParam));
  };

  const handleChangeTiempoCantPeriodos = (oEvent) => {
    var oParam = {};
    oParam.bValidSelectTiempoCantPeriodos = null;
    oParam.selectTiempoCantPeriodos = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTiempoCantPeriodos.push(obj);
      oParam.bValidSelectTiempoCantPeriodos = true;
    }
    dispatch(selectedTiempoCantPeriodosIN(oParam));
  };

  const handleChangeTea = (values, sourceInfo) => {
    //var tea = oEvent.target.value;
    var tea = values.value;
    var validate = null;
    if (tea.length > 0) {
      validate = true;
    }
    var oParam = {
      tea: tea,
      bValidTea: validate,
    };
    dispatch(changeTeaIN(oParam));
  };

  const handleChangeApariciones = (values, sourceInfo) => {
    //var apariciones = oEvent.target.value;
    var apariciones = values.value;
    var validate = null;
    if (apariciones.length > 0) {
      validate = true;
    }
    var oParam = {
      apariciones: apariciones,
      bValidApariciones: validate,
    };
    dispatch(changeAparicionesIN(oParam));
  };

  const handleChangeTipoFormulario = (oEvent) => {
    var oParam = {};
    oParam.bValidselectTipoFormulario = null;
    oParam.selectTipoFormulario = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipoFormulario.push(obj);
      oParam.bValidselectTipoFormulario = true;
    } else {
      dispatch(limpiarFormIN());
    }
    dispatch(selectedTipoFomularioIN(oParam));
  };

  const limpiarForm = () => {
    dispatch(limpiarFormIN());
  };

  const handleChangeTipoPago = (oEvent) => {
    var oParam = {};
    oParam.bValidSelectTipoPago = null;
    oParam.selectTipoPago = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectTipoPago.push(obj);
      oParam.bValidTipoPago = true;
    }
    dispatch(selectedEstadoIN(oParam));
  };

  const handleChangeEstado = (oEvent) => {
    var oParam = {};
    oParam.bValidEstado = null;
    oParam.selectEstado = [];
    if (oEvent) {
      let obj = { ...oEvent, ...{ value: oEvent.value, label: oEvent.label } };
      oParam.selectEstado.push(obj);
      oParam.bValidEstado = true;
    }
    dispatch(selectedEstadoIN(oParam));
  };

  const camposObligatorios = () => {
    var oParan = {
      valid: false,
      oRegistrarSolucion: {},
      mensaje: '',
    };
    var oParamValidCampos = {};
    if (inversionState.selectTipoFormulario.length > 0) {
      var selectTipoFormulario = inversionState.selectTipoFormulario[0];
      let tipoFormulario = selectTipoFormulario.value;
      if (tipoFormulario === 'Tipo_1') {
        if (inversionState.descripcion.length == 0) {
          oParamValidCampos.bValidDescripcion = false;
        }
        if (inversionState.selectAgente.length == 0) {
          oParamValidCampos.bValidAgente = false;
        }
        if (inversionState.dDateRange[0] == null || inversionState.dDateRange[1] == null) {
          oParamValidCampos.bValidFecha = false;
        }
        if (inversionState.montoMaximo.length == 0) {
          oParamValidCampos.bValidMontoMaximo = false;
        }
        if (inversionState.montoMinimo.length == 0) {
          oParamValidCampos.bValidMontoMinimo = false;
        }
        if (inversionState.selectMoneda.length == 0) {
          oParamValidCampos.bValidMoneda = false;
        }
        if (inversionState.selectEstado.length == 0) {
          oParamValidCampos.bValidEstado = false;
        }
        if (inversionState.tea.length == 0) {
          oParamValidCampos.bValidTea = false;
        }
        if (inversionState.cantPeriodos.length == 0) {
          oParamValidCampos.bValidCantPeriodos = false;
        }
        if (inversionState.selectTiempoCantPeriodos.length == 0) {
          oParamValidCampos.bValidSelectTiempoCantPeriodos = false;
        }
        if (inversionState.selectTipoPago.length == 0) {
          oParamValidCampos.bValidSelectTipoPago = false;
        }
        //Campos obligatorios
        if (
          inversionState.descripcion.length !== 0 &&
          inversionState.selectAgente.length !== 0 &&
          inversionState.dDateRange[0] != null &&
          inversionState.dDateRange[1] != null &&
          inversionState.montoMaximo.length !== 0 &&
          inversionState.montoMinimo.length !== 0 &&
          inversionState.selectMoneda.length !== 0 &&
          inversionState.selectEstado.length !== 0 &&
          inversionState.tea.length !== 0 &&
          inversionState.cantPeriodos.length !== 0 &&
          inversionState.selectTiempoCantPeriodos.length !== 0 &&
          inversionState.selectTipoPago.length !== 0
        ) {
          if (parseFloat(inversionState.tea) === 0) {
            oParan.mensaje = 'La TEA(%) debe ser mayo a 0';
            return oParan;
          } else if (
            parseFloat(inversionState.montoMinimo) >= parseFloat(inversionState.montoMaximo)
          ) {
            oParan.mensaje = 'El mínimo debe ser menor al monto.';
            return oParan;
          } else {
            oParan.valid = true;
            oParan.oRegistrarSolucion['COD_TIPO_FORMULARIO'] = selectTipoFormulario.value;
            oParan.oRegistrarSolucion['TIPO_FORMULARIO'] = selectTipoFormulario.label;
            oParan.oRegistrarSolucion['COD_SOLUCION'] = 'IN';
            oParan.oRegistrarSolucion['SOL_NOMBRESOLUCION'] = inversionState.descripcion;
            oParan.oRegistrarSolucion['SOL_IDAGENTE'] = parseFloat(
              inversionState.selectAgente[0].value
            );
            oParan.oRegistrarSolucion['SOL_AGENTE'] = inversionState.selectAgente[0].label;
            oParan.oRegistrarSolucion['SOL_PERIODOINICIOP'] = dateTo_dd_mm_yyyy(
              inversionState.dDateRange[0],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_PERIODOFINP'] = dateTo_dd_mm_yyyy(
              inversionState.dDateRange[1],
              '.'
            );
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIALMIN'] = parseFloat(
              inversionState.montoMinimo
            );
            oParan.oRegistrarSolucion['SOL_IMPORTEINICIAL'] = parseFloat(
              inversionState.montoMaximo
            );
            //oParan.oRegistrarSolucion["SOL_CANTIDADCUOTASP"] = parseFloat(inversionState.cantPeriodos);
            oParan.oRegistrarSolucion['SOL_NUMERO_PERIODO'] = parseFloat(
              inversionState.cantPeriodos
            );
            oParan.oRegistrarSolucion['SOL_NUMERO_PERIODO_PERIODO'] =
              inversionState.selectTiempoCantPeriodos[0].value;
            oParan.oRegistrarSolucion['SOL_MONEDA'] = inversionState.selectMoneda[0].value;
            oParan.oRegistrarSolucion['IDESTADO'] = parseFloat(
              inversionState.selectEstado[0].value
            );
            oParan.oRegistrarSolucion['SOL_TEAP'] = inversionState.tea;
            oParan.oRegistrarSolucion['TIPO_PAGO'] = parseFloat(
              inversionState.selectTipoPago[0].value
            );
          }
        } else {
          dispatch(setValidCamposIN(oParamValidCampos));
          oParan.mensaje = 'Debe llenar todos los campos en (*).';
          return oParan;
        }
        //campos opcionales
        if (inversionState.apariciones.length !== 0) {
          oParan.oRegistrarSolucion['SOL_APARICIONES'] = parseFloat(inversionState.apariciones);
        }
      }
    }
    return oParan;
  };

  const limpiarCampos = () => {
    dispatch(limpiarCamposIN());
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    // setButtonAction(1);
    // setTabProceso(0);
    limpiarCampos();
    navigate('/dashboard/soluciones/deposito/listar');
  };

  const onPressGrabar = async () => {
    let { valid, oRegistrarSolucion, mensaje } = camposObligatorios();
    if (valid) {
      var oParam = {
        oRegistrarSolucion: oRegistrarSolucion,
        aRegManuales: [],
        aTeaMultiMoneda: [],
        listaFiltrosMultiplesAppliedTableAP: [],
      };
      const responseRegistrarSol = await callEndpoint(registrarSolucion(oParam));
      if (responseRegistrarSol == null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseRegistrarSol.data) {
          if (responseRegistrarSol.data.cabecera.statusCode === 200) {
            // props.setButtonAction(0);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Solucion de inversión registrada correctamente.',
              showConfirmButton: false,
              timer: 2000,
            });
            // dispatch(loadPrestamo());
            // await props.loadPrestamo();
            navigate('/dashboard/soluciones/deposito/listar');
          } else if (responseRegistrarSol.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarSol.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: mensaje,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());
    let aAgentes = [];
    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;
        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    dispatch(setListaAgenteIN({ listaAgente: aAgentes }));
  };

  const loadMoneda = async () => {
    const responseObtMonedas = await callEndpoint(obtenerListadoMoneda());
    let aMonedas = [];
    if (responseObtMonedas != null) {
      if (responseObtMonedas.data) {
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion == 'texto_moneda'
        )[0].DatoPersonalizacion;
        let { aListaMoneda } = responseObtMonedas.data.respuesta;
        aListaMoneda.forEach((element) => {
          aMonedas.push(createMonedaAbreviaturaAdapter(element, tipoMoneda));
        });
      }
    }
    dispatch(setListaMonedaIN({ listaMoneda: aMonedas }));
  };

  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(setListaEstadoIN({ listaEstado: aEstadoSol }));
  };

  const loadTiempoPeriodo = async () => {
    let aTiempoPeriodo = [];
    TiempoPeriodosData.forEach((element) => {
      aTiempoPeriodo.push(createTiempoPeriodoAdapter(element));
    });
    dispatch(setListaTiempoIN({ listaTiempo: aTiempoPeriodo }));
  };

  const handleChangeFecha = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    // oParam.sFechaInicio = sFechaInicio;
    // oParam.sFechaFinal = sFechaFinal;
    oParam.dDateRange = [oEvent[0], oEvent[1]];
    if (oParam.dFechaFin != null && oParam.dFechaInicio != null) {
      oParam.bValidFecha = null;
    }
    dispatch(changeVigenciaIN(oParam));
  };

  const obligatoriedadCampos = (selectTipoFormulario) => {
    var formulario = <></>;
    if (selectTipoFormulario.length > 0) {
      let tipoFormulario = selectTipoFormulario[0].value;
      switch (tipoFormulario) {
        case 'Tipo_1':
          formulario = (
            <>
              {/* campos obligatorios */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Descripción (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={inversionState.descripcion}
                    onChange={(evnt) => handleChangeDescripcion(evnt)}
                    validate={inversionState.bValidDescripcion}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Socio de negocio (*):'}></LabelForm>
                  <SelectForm
                    bValid={inversionState.bValidAgente}
                    value={inversionState.selectAgente}
                    handleChange={(e, v) => handleChangeAgente(v)}
                    options={inversionState.listaAgente}
                    placeholder={'Seleccione Socio'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Estado (*):'}></LabelForm>
                  <SelectForm
                    bValid={inversionState.bValidEstado}
                    value={inversionState.selectEstado}
                    handleChange={(e, v) => handleChangeEstado(v)}
                    options={inversionState.listaEstado}
                    placeholder={'Seleccione Estado'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Vigencia (*):'}></LabelForm>
                  <DatePickerForm
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={inversionState.dDateRange[0]}
                    endDate={inversionState.dDateRange[1]}
                    onChange={(evnt) => handleChangeFecha(evnt)}
                    isClearable={true}
                    placeholderText="Ingrese"
                    locale="es"
                    showMonthDropdown
                    showYearDropdown
                    portalId="root-portal"
                    validate={inversionState.bValidFecha}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'TEA (%) (*):'}></LabelForm>
                  <InputNumberFormat
                    value={inversionState.tea}
                    validate={inversionState.bValidTea}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) => handleChangeTea(values, sourceInfo)}
                    max={100.0}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={inversionState.bValidMoneda}
                    value={inversionState.selectMoneda}
                    handleChange={(e, v) => handleChangeMoneda(v)}
                    options={inversionState.listaMoneda}
                    placeholder={'Seleccione Moneda'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe máximo (*):'}></LabelForm>
                  <InputNumberFormat
                    value={inversionState.montoMaximo}
                    validate={inversionState.bValidMontoMaximo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoMaximo(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Importe mínimo (*):'}></LabelForm>
                  <InputNumberFormat
                    value={inversionState.montoMinimo}
                    validate={inversionState.bValidMontoMinimo}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    onValueChange={(values, sourceInfo) =>
                      handleChangeMontoMinimo(values, sourceInfo)
                    }
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Cantidad de Periodos (*):'}></LabelForm>
                  <RowDoubleInput>
                    <InputNumberFormat
                      width={'37%'}
                      value={inversionState.cantPeriodos}
                      validate={inversionState.bValidCantPeriodos}
                      allowNegative={false}
                      fixedDecimalScale={false}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) =>
                        handleChangeCantPeriodos(values, sourceInfo)
                      }
                    />
                    <SelectForm
                      width={'60%'}
                      bValid={inversionState.bValidSelectTiempoCantPeriodos}
                      value={inversionState.selectTiempoCantPeriodos}
                      handleChange={(e, v) => handleChangeTiempoCantPeriodos(v)}
                      options={inversionState.listaTiempo}
                      placeholder={'Seleccione Tiempo'}
                    />
                  </RowDoubleInput>
                </RowForm>
              </ColForm>

              {/* campos no obligatorios     */}
              <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
                <RowForm>
                  <LabelForm title={'Tipo de pago :'}></LabelForm>
                  <SelectForm
                    bValid={inversionState.bValidSelectTipoPago}
                    value={inversionState.selectTipoPago}
                    handleChange={(e, v) => handleChangeTipoPago(v)}
                    options={[
                      { value: 1, label: 'Plazo fijo' },
                      { value: 2, label: 'Sin plazo' },
                    ]}
                    placeholder={'Seleccione tipo de pago'}
                  />
                </RowForm>
              </ColForm>
            </>
          );
          break;
        default:
          <></>;
      }
    }
    return formulario;
  };

  return (
    <Content>
      <ColFlex>
        <SubTitle subtitle="1. Registrar datos generales de la solución." />
        <ContentForm>
          <RowForm>
            <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Tipo Fomulario (*):'}></LabelForm>
                <SelectForm
                  isNoClearable={true}
                  bValid={inversionState.bValidselectTipoFormulario}
                  value={inversionState.selectTipoFormulario}
                  handleChange={(e, v) => handleChangeTipoFormulario(v)}
                  options={TipoFormularioInversion}
                  placeholder={'Seleccione Tipo Formulario'}
                />
              </RowForm>
            </ColForm>
            {obligatoriedadCampos(inversionState.selectTipoFormulario)}
          </RowForm>
          <RowFooterButton>
            <Button
              action={(e) => onPressCancelar()}
              name="Cancelar"
              isAction={true}
              type="warning"
            />
            <Button action={(e) => limpiarForm()} name="Limpiar" isAction={true} type="primary" />
            <Button
              action={(e) => onPressGrabar()}
              name="Grabar"
              isAction={true}
              type="secondary"
            />
          </RowFooterButton>
        </ContentForm>
      </ColFlex>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la inversión?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
