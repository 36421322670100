import Swal from 'sweetalert2';

export const swalAlertConfirm = async ({
  title,
  icon,
  showCancelButton,
  confirmButtonColor,
  cancelButtonColor,
  cancelButtonText,
  confirmButtonText,
}) => {
  const result = await Swal.fire({
    title: title,
    icon: icon,
    showCancelButton: showCancelButton,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: cancelButtonColor,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmButtonText,
  });

  return result;
};

export const swalAlertInformation = async ({ position, icon, title, showConfirmButton, timer }) => {
  const result = await Swal.fire({
    position: position,
    icon: icon,
    title: title,
    showConfirmButton: showConfirmButton,
    timer: timer,
  });

  return result;
};
