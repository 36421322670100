import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';

export const ContainerRoutes = (props) => {
  const renderTitle = () => {
    return <></>;
  };
  return (
    <CardContainerRoutes>
      {renderTitle()}
      {props.children}
    </CardContainerRoutes>
  );
};

const CardContainerRoutes = styled.div`
  flex-direction: column;
  padding: 30px;
  /* margin-top: 40px; */
  background: ${theme.colors.white};
  /* height: fit-content; */
  border-radius: 8px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  /* position: relative;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 2px;
    background: linear-gradient(
      150deg,
      ${theme.colors.secondary} 21%,
      ${theme.colors.tertiary} 50%,
      ${theme.colors.primary} 86%
    );
    -webkit-mask: linear-gradient(${theme.colors.white} 0 0) content-box,
      linear-gradient(${theme.colors.white} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  } */
`;
