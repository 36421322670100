import { Selected } from '../common';

export const createCaracteristicaAdapter = (row) => ({
  value: row.iIDHomologacion,
  label: row.sNombreBase,
  sNombreFuente: row.sNombreBase,
  sNombreBase: row.sNombreBase,
  sCodigoFuncion: row.sCodigoFuncion,
  // sNombreBase:row.sNombreBase,
  iIDHomologacion: row.iIDHomologacion,
  iIDFConexionAgrupador: row.iIDFConexionAgrupador,
  iHabilitado: row.iHabilitado,
  bObligatorio:
    row.sNombreBase === 'MonedaPago' ||
    row.sNombreBase === 'MonedaImporte' ||
    row.sNombreBase === 'Importe' ||
    row.sNombreBase === 'FechaVcto'
      ? true
      : false,
});

export const createCaracteristicaTotalAdapter = (row) => ({
  value: row.idCampo,
  label: row.nombreCampo,
  sNombreFuente: row.nombreCampo,
  sNombreBase: row.nombreCampo,
  iIDFConexionAgrupador: row.idCampo,
  iHabilitado: row.habilitado,
  bObligatorio:
    row.nombreCampo === 'MonedaPago' ||
    row.nombreCampo === 'MonedaImporte' ||
    row.nombreCampo === 'Importe' ||
    row.nombreCampo === 'FechaVcto' ||
    row.nombreCampo === 'Sistema' ||
    row.nombreCampo === 'Sociedad'
      ? true
      : false,
});
export const createCaracteristicaReglaAdapter = (row) => ({
  value: row.NombreCampoAlias,
  label: row.NombreCampoHomo,
});

export const createCaracteristicaTabla = (row) => ({
  field: row,
  headerName: row,
  flex: 1,
  minWidth: 160,
  renderCell: (params) => <>{params.value}</>,
  seleccionado: Selected.FALSE,
  columna: row,
  parsed: row,
  restricted: Selected.FALSE,
});
