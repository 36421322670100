import { faEdit } from '@fortawesome/fontawesome-free-solid';
import { Button } from '../../../../../components';
import { CheckboxStyle } from '../../../../../styles';

export const columns = (onEditar, findDerivada, onCheckRow) => [
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <CheckboxStyle
        checked={findDerivada(params.row)}
        onChange={() => onCheckRow(params.row)}
        size="small"
      />
    ),
  },
  {
    field: 'NombreDerivada',
    headerName: 'Nombre',
    flex: 1,
    renderCell: (params) => <>{params.value}</>,
  },

  {
    field: 'edit',
    disableColumnMenu: true,
    disableFilters: true,
    sortable: false,
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <>
        <Button
          action={(e) => onEditar(params.row)}
          icon={faEdit}
          isAction={false}
          isOnlyIcon={true}
          type="primary"
        />
      </>
    ),
  },
];
