import { formatString_DDMMYYYY_TO_YYYYMMDD } from '../common/helper';
export const createPrestamoAdapter = (user) => ({
  idPrestamo: user.IDSolucionBase,
  codigo: user.COD_SOLUCION_IDT,
  nombre: user.SOL_AGENTE,
  descripcion: user.SOL_NOMBRESOLUCION,
  tipo: user.COD_SOLUCION,
  fechaVigencia: user.SOL_PERIODOINICIOP + ' - ' + user.SOL_PERIODOFINP,
  fechaInico: user.SOL_PERIODOINICIOP,
  fechaFin: user.SOL_PERIODOFINP,
  fechaInicioFormat: formatString_DDMMYYYY_TO_YYYYMMDD(user.SOL_PERIODOINICIOP, '.'),
  fechaFinFormat: formatString_DDMMYYYY_TO_YYYYMMDD(user.SOL_PERIODOFINP, '.'),
  idEstado: user.IDESTADO,
  estado: user.DESCESTADO,
});
