import React, { useEffect, useState } from 'react';
import {
  Input,
  LabelForm,
  TabBarCreacion,
  SubTitle,
  ModalConfirmation,
  Button,
  TableGrid,
  SelectForm,
} from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { RealProyecto, TipoConcepto } from '../../../../common';
import {
  listarCamposMJ,
  listarCaracteristicasXfuncion,
  listarFunciones,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  createCaracteristicaAdapter,
  createCaracteristicaTotalAdapter,
  createSelectFuncionAdapter,
} from '../../../../adapters';
import { registrarConcepto } from '../../../../services/public.service';
import {
  changeRowsSelectNombreDataConcepto,
  changeRowsSelectTipoDataConcepto,
  onAddRowTableCaracConcepto,
  onDeleteRowTableCaracConcepto,
  updateTitle,
} from '../../../../redux/states';
import {
  changeFuncionConcepto,
  changeNombreConcepto,
  changeRealProyectadoConcepto,
  changeTipoConcepto,
  limpiarCamposConcepto,
  setCaracteristicaRegistro,
  setCaracteristicaXfuncionConcepto,
  setListaFuncionesConcepto,
} from '../../../../redux/states';
import Swal from 'sweetalert2';
import collect from 'collect.js';
import { useNavigate } from 'react-router-dom';

import {
  ColForm,
  Content,
  ContentForm,
  RowFooterButton,
  ColFlex,
  RowForm,
} from '../../../../styles';
import { v4 as uuidv4 } from 'uuid';
import { columnsForm } from '../misc';

export const CrearConcepto = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const conceptoState = useSelector((store) => store.concepto);
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [tabProceso, setTabProceso] = useState(0);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear Concepto' }));
  }, []);

  useEffect(() => {
    (async () => {
      const responseListarFunciones = await callEndpoint(listarFunciones());
      var listaFuncionesConcepto = [];
      for (
        let index = 0;
        index < responseListarFunciones.data.respuesta.aListaConfigFunciones.length;
        index++
      ) {
        const element = responseListarFunciones.data.respuesta.aListaConfigFunciones[index];
        listaFuncionesConcepto.push(createSelectFuncionAdapter(element));
      }
      dispatch(setListaFuncionesConcepto({ listaFunciones: listaFuncionesConcepto }));
      await caracteristicasTotales();
    })();
  }, []);

  const addTableRows = () => {
    let uid = uuidv4();
    const rowsInput = {
      idConcepto: uid,
      IDCaracteristica: null,
      selectCaracteristica: [],
      selectTipo: [{ label: 'Detalle', value: 'Detalle' }],
      enabledDelete: true,
    };

    dispatch(onAddRowTableCaracConcepto({ newItem: rowsInput }));
  };

  const deleteTableRows = (data, id) => {
    const rows = [...conceptoState.aCaracteristicaRegistro];
    const index = rows.findIndex((val) => val.idConcepto == id);

    dispatch(
      onDeleteRowTableCaracConcepto({
        index: index,
        selectCaracteristica: data.selectCaracteristica,
      })
    );
  };

  const handleChangeTipoTable = (data, id, value) => {
    dispatch(changeRowsSelectTipoDataConcepto({ index: id, selectTipo: [value] }));
  };

  const handleChangeNombreTable = (data, id, value) => {
    dispatch(
      changeRowsSelectNombreDataConcepto({
        index: id,
        selectCaracteristica: [value],
        data: data,
      })
    );
  };

  const onTabProcesoGeneral = () => {
    setTabProceso(0);
  };

  const obTabCaracteristicas = () => {
    onPressSiguiente();
  };

  const handleChangeTipo = (value) => {
    var oParam = {};
    oParam.bValidTipo = null;
    oParam.selectTipo = [];
    if (value) {
      oParam.selectTipo = [value];
      oParam.bValidTipo = true;
    }
    dispatch(changeTipoConcepto(oParam));
  };

  const handleChangeNombre = (oEvent) => {
    var sNombre = oEvent.target.value;
    var validate = null;
    if (sNombre.length > 0) {
      validate = true;
    }
    var oParam = {
      sNombre: sNombre,
      bValidNombre: validate,
    };
    dispatch(changeNombreConcepto(oParam));
  };

  const handleChangeRealProyectado = (value) => {
    var oParam = {};
    oParam.selectRealProyectado = [];
    oParam.bValidRealProyectado = null;
    if (value) {
      oParam.selectRealProyectado = [value];
      oParam.bValidRealProyectado = true;
    }
    dispatch(changeRealProyectadoConcepto(oParam));
  };

  const caracteristicasTotales = async () => {
    var caracteristicasXfuncion = [];
    var rowsInputXdefault = [];
    const responseListarCaracteristicasXfuncion = await callEndpoint(listarCamposMJ());

    if (responseListarCaracteristicasXfuncion.data) {
      for (
        let index = 0;
        index < responseListarCaracteristicasXfuncion.data.respuesta.aListadoCampos.length;
        index++
      ) {
        const element = responseListarCaracteristicasXfuncion.data.respuesta.aListadoCampos[index];
        var obj = createCaracteristicaTotalAdapter(element);
        obj.selected = true;
        obj.IDCaracteristica = null;
        obj.sAccion = 'S';
        caracteristicasXfuncion.push(obj);
      }
    }
    if (caracteristicasXfuncion.length > 0) {
      caracteristicasXfuncion.forEach(function (item, index) {
        let uid = uuidv4();
        var o = {};
        o.idConcepto = uid;
        o.IDCaracteristica = null;
        o.selectCaracteristica = [];
        o.selectTipo = [];
        o.enabledDelete = true;
        o.orden = 1;
        var y = {};
        y.sNombreFuente = item.label;
        y.iIDFConexionAgrupador = item.iIDFConexionAgrupador;
        y.iIDHomologacion = item.value;
        y.label = item.label;
        y.value = item.value;
        o.selectCaracteristica.push(y);
        o.selectTipo.push({ label: 'Detalle', value: 'Detalle' });
        if (
          item.label == 'MonedaPago' ||
          item.label == 'MonedaImporte' ||
          item.label == 'Importe' ||
          item.label == 'FechaVcto' ||
          item.label == 'Sistema' ||
          item.label == 'Sociedad'
        ) {
          o.enabledDelete = false;
          o.orden = 0;
        }
        rowsInputXdefault.push(o);
      });
    }
    dispatch(
      setCaracteristicaXfuncionConcepto({
        aCaracteristicaXfuncion: caracteristicasXfuncion,
      })
    );

    dispatch(
      setCaracteristicaRegistro({
        aCaracteristicaRegistro: collect(rowsInputXdefault).sortBy('orden').all(),
        //  rowsInputXdefault
      })
    );
  };

  const handleChangeFuncion = async (value) => {
    var oParam = {};
    oParam.selectFuncion = [];
    oParam.bValidFuncion = null;
    var caracteristicasXfuncion = [];
    var rowsInputXdefault = [];
    if (value) {
      oParam.selectFuncion = [value];
      oParam.bValidFuncion = true;
      const responseListarCaracteristicasXfuncion = await callEndpoint(
        listarCaracteristicasXfuncion(oParam.selectFuncion)
      );
      if (responseListarCaracteristicasXfuncion.data) {
        for (
          let index = 0;
          index <
          responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion.length;
          index++
        ) {
          const element =
            responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion[index];
          var obj = createCaracteristicaAdapter(element);
          obj.selected = true;
          obj.IDCaracteristica = null;
          obj.sAccion = 'S';
          caracteristicasXfuncion.push(obj);
        }
      }
    }
    if (caracteristicasXfuncion.length > 0) {
      caracteristicasXfuncion.forEach(function (item, index) {
        let uid = uuidv4();
        var o = {};
        o.idConcepto = uid;
        o.IDCaracteristica = null;
        o.selectCaracteristica = [];
        o.selectTipo = [];
        o.enabledDelete = true;
        o.orden = 1;
        var y = {};
        y.sNombreFuente = item.label;
        y.iIDFConexionAgrupador = item.iIDFConexionAgrupador;
        y.iIDHomologacion = item.value;
        y.label = item.label;
        y.value = item.value;
        o.selectCaracteristica.push(y);
        o.selectTipo.push({ label: 'Detalle', value: 'Detalle' });
        if (
          item.label == 'MonedaPago' ||
          item.label == 'MonedaImporte' ||
          item.label == 'Importe' ||
          item.label == 'FechaVcto'
        ) {
          o.enabledDelete = false;
          o.orden = 0;
        }
        rowsInputXdefault.push(o);
      });
      // setRowsData(rowsInputXdefault);
    }
    let aCaract = caracteristicasXfuncion.filter(
      (item) =>
        item.sNombreFuente !== 'MonedaPago' &&
        item.sNombreFuente !== 'MonedaImporte' &&
        item.sNombreFuente !== 'Importe' &&
        item.sNombreFuente !== 'FechaVcto'
    );
    dispatch(
      setCaracteristicaXfuncionConcepto({
        aCaracteristicaXfuncion: caracteristicasXfuncion,
      })
    );

    dispatch(
      setCaracteristicaRegistro({
        aCaracteristicaRegistro: collect(rowsInputXdefault).sortBy('orden').all(),
        //  rowsInputXdefault
      })
    );
    dispatch(changeFuncionConcepto(oParam));
  };

  const onPressSiguiente = () => {
    if (conceptoState.sNombre === '') {
      dispatch(changeNombreConcepto({ bValidNombre: false }));
    }
    if (conceptoState.selectTipo.length === 0) {
      dispatch(changeTipoConcepto({ bValidTipo: false }));
    }
    // if (conceptoState.selectFuncion.length === 0) {
    //   dispatch(changeFuncionConcepto({ bValidFuncion: false }));
    // }
    if (conceptoState.selectRealProyectado.length === 0) {
      dispatch(changeRealProyectadoConcepto({ bValidRealProyectado: false }));
    }
    if (
      conceptoState.selectTipo.length > 0 &&
      // conceptoState.selectFuncion.length > 0 &&
      conceptoState.selectRealProyectado.length > 0 &&
      conceptoState.sNombre !== ''
    ) {
      if (conceptoState.aCaracteristicaXfuncion.length > 0) {
        setTabProceso(1);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No posee características registradas.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, completar todos los campos del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const limpiarCampos = (oEvent) => {
    dispatch(limpiarCamposConcepto());
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };

  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    dispatch(limpiarCamposConcepto());
    navigate('/dashboard/configuracion/concepto/listar');
  };

  const onPressGrabar = async (props) => {
    var aRegistroConceptos = [];
    var valid = true;
    for (let index = 0; index < conceptoState.aCaracteristicaRegistro.length; index++) {
      const item = conceptoState.aCaracteristicaRegistro[index];
      if (item.selectCaracteristica.length == 0 || item.selectTipo.length == 0) {
        valid = false;
      } else {
        var o = {
          NombreCampo: item.selectCaracteristica[0].sNombreFuente,
          TipoAtributo: item.selectTipo[0].value,
          TipoDato: '',
          Habilitado: 1,
        };
        aRegistroConceptos.push(o);
      }
    }
    if (!valid) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No todos los registros tienen el nombre o tipo lleno.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    debugger;
    let filtroKeyXDetalle =
      conceptoState.selectFuncion.length > 0
        ? aRegistroConceptos.filter((item) => item.TipoAtributo === 'Key')
        : [1];
    if (filtroKeyXDetalle.length > 0) {
      var oRegistrarCabecera = {};
      oRegistrarCabecera.sNombre = conceptoState.sNombre;
      oRegistrarCabecera.sTipoConcepto = conceptoState.selectTipo[0].value;
      oRegistrarCabecera['Real_Proyectado'] = conceptoState.selectRealProyectado[0].value;
      oRegistrarCabecera.IDFConexion =
        conceptoState.selectFuncion.length > 0 ? conceptoState.selectFuncion[0].value : 0; //'3';
      var oParam = {
        oRegistrarCabecera: oRegistrarCabecera,
        aRegistrarDetalleConcepto: aRegistroConceptos,
      };
      const responseRegistrarConcepto = await callEndpoint(
        registrarConcepto({
          oRegistrarCabecera: oParam.oRegistrarCabecera,
          aRegistrarDetalleConcepto: oParam.aRegistrarDetalleConcepto,
        })
      );
      if (responseRegistrarConcepto == null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseRegistrarConcepto.data) {
          if (responseRegistrarConcepto.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Concepto registrado correctamente.',
              showConfirmButton: false,
              timer: 2000,
            });
            navigate('/dashboard/configuracion/concepto/listar');
          } else if (responseRegistrarConcepto.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: responseRegistrarConcepto.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarConcepto.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Debe tener al menos un tipo Key.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <Content>
      <TabBarCreacion
        onClickTab1={onTabProcesoGeneral}
        tabProceso={tabProceso}
        labelTab1={'General'}
        onClickTab2={obTabCaracteristicas}
        labelTab2={'Caracteristicas'}
      />
      {tabProceso === 0 ? (
        <ColFlex>
          <SubTitle subtitle="1. Registrar datos generales del concepto." />
          <ContentForm>
            <RowForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Nombre (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={conceptoState.sNombre}
                    onChange={(evnt) => handleChangeNombre(evnt)}
                    validate={conceptoState.bValidNombre}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Tipo (*):'}></LabelForm>
                  <SelectForm
                    validate={conceptoState.bValidTipo}
                    value={conceptoState.selectTipo}
                    handleChange={(e, v) => handleChangeTipo(v)}
                    options={TipoConcepto}
                    placeholder={'Seleccione tipo'}
                  />
                </RowForm>
              </ColForm>

              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Real/Plan (*):'}></LabelForm>
                  <SelectForm
                    validate={conceptoState.bValidRealProyectado}
                    value={conceptoState.selectRealProyectado}
                    handleChange={(e, v) => handleChangeRealProyectado(v)}
                    options={RealProyecto}
                    placeholder={'Seleccione R/P'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm title={'Función :'}></LabelForm>
                  <SelectForm
                    validate={conceptoState.bValidFuncion}
                    value={conceptoState.selectFuncion}
                    handleChange={(e, v) => handleChangeFuncion(v)}
                    options={conceptoState.listaFunciones}
                    placeholder={'Seleccione Función'}
                  />
                </RowForm>
              </ColForm>

              <RowFooterButton>
                <Button
                  action={(e) => onPressCancelar()}
                  name="Cancelar"
                  isAction={true}
                  type="warning"
                />
                <Button
                  action={(e) => limpiarCampos(e)}
                  name="Limpiar"
                  isAction={true}
                  type="primary"
                />
                <Button
                  action={(e) => onPressSiguiente()}
                  name="Siguiente"
                  isAction={true}
                  type="secondary"
                />
              </RowFooterButton>
            </RowForm>
          </ContentForm>
        </ColFlex>
      ) : (
        <ColFlex>
          <SubTitle subtitle="2. Registrar características de los conceptos." />
          <ContentForm>
            <TableGrid
              columns={columnsForm(
                handleChangeNombreTable,
                handleChangeTipoTable,
                deleteTableRows,
                addTableRows,
                conceptoState
              )}
              rows={conceptoState.aCaracteristicaRegistro}
              pageSize={15}
              getRowId={(row) => row.idConcepto}
            />
            <RowFooterButton>
              <Button
                action={(e) => onPressCancelar()}
                name="Cancelar"
                isAction={true}
                type="warning"
              />
              <Button
                action={(e) => onPressGrabar(props)}
                name="Grabar"
                isAction={true}
                type="secondary"
              />
            </RowFooterButton>
          </ContentForm>
        </ColFlex>
      )}
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación del concepto?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
