import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ColumnSelector,
  LoadingSpinner,
  SearchInputText,
  TableGrid,
} from '../../../components';
import { useFetchAndLoad } from '../../../common/hooks';
import { eliminarJob, obtenerJob } from '../../../services/public.service';
import { useNavigate } from 'react-router-dom';
import { faEdit, faTrash } from '@fortawesome/fontawesome-free-solid';
import { ColFlex, ContentButton, ContentOption, RowAccion, RowFlex } from '../../../styles';
import {
  updateTitle,
  filterListarAutomatizaciones,
  setListarAutomatizacion,
} from '../../../redux/states';
import { listaAutomatizacionAdapter } from '../../../adapters';
import { columns } from './misc/column';

export const ListarAutomatizacion = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const automatizacionState = useSelector((store) => store.automatizacion);
  const { loading, callEndpoint } = useFetchAndLoad();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Lista de Automatizaciones' }));
    (async () => {
      loadAutomatizacion();
    })();
  }, []);

  const loadAutomatizacion = async () => {
    const responseListarAutomatizacion = await callEndpoint(obtenerJob());
    var listaAutomatizacion = [];
    for (let i = 0; i < responseListarAutomatizacion.data.respuesta.aDetalleJob.length; i++) {
      const ele = responseListarAutomatizacion.data.respuesta.aDetalleJob[i];
      listaAutomatizacion.push(listaAutomatizacionAdapter(ele));
    }
    dispatch(setListarAutomatizacion({ listaAutomatizaciones: listaAutomatizacion }));
  };

  const onDelete = async (row) => {
    Swal.fire({
      title: `Quiere eliminar el job  ${row.Nombre}?`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        var respuesta = await callEndpoint(eliminarJob({ IDJob: row.IDJob }));
        if (respuesta.data) {
          var { data } = respuesta;
          if (data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: respuesta.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            loadAutomatizacion();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: respuesta.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const onEditar = async (row) => {
    navigate('/dashboard/Automatizacion/editar/' + row.IDJob);
  };

  const onAgregarAutomatizacion = async () => {
    navigate('/dashboard/Automatizacion/crear');
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarAutomatizaciones(oEvent.target.value));
    } else {
      dispatch(filterListarAutomatizaciones(''));
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar Automatizacion...'}
              filter={automatizacionState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Button
                  action={onAgregarAutomatizacion}
                  name="Agregar"
                  isAction={true}
                  type="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns(onEditar, onDelete)}
          rows={
            automatizacionState.searchtext.length === 0
              ? automatizacionState.listaAutomatizaciones
              : automatizacionState.filterListaAutomatizaciones
          }
          pageSize={15}
          getRowId={(row) => row.IDJob}
        />
      </ColFlex>
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
