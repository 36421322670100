export const createFlujoCajaAdapter = (user) => ({
  IDFlujoGeneral: user.IDFlujoGeneral,
  CodigoFlujoCaja: user.CodigoFlujoCaja,
  NombreFlujoGeneral: user.NombreFlujoGeneral,
  DetalleFlujoGeneral: user.DetalleFlujoGeneral,
  FechaOptimizacion: user.FechaOptimizacion,
  NombreVersion: user.NombreVersion,
  NombreEstructura: user.NombreEstructura,
  Habilitado: user.Habilitado,
})
