import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import {
  listarPosiciones,
  obtenerCaracPosTotal,
  obtenerListadoEtiquetas,
  obtenerListadoMoneda,
  obtenerPlantillaExcelRegManual,
} from '../../services/public.service';
import ExcelJS from 'exceljs';
export const exportToExcelRegistroManual = async (apiData) => {
  try {
    let workbook = new ExcelJS.Workbook();
    var caracteristicas = await obtenerCaracPosTotal().call;
    //Creamos la pestana Registro manual
    let worksheetRegManual = workbook.addWorksheet('RegistrosManuales');
    let aCabecera = [
      'Posicion',
      'Importe',
      'Fecha',
      'MonedaImporte',
      'MonedaPago',
      'Vigencia',
      'Etiquetas',
    ];
    aCabecera = [...aCabecera, ...caracteristicas.data.respuesta.aCaracPosSinRep];
    aCabecera = aCabecera.filter((r) => r != 'FechaVcto');
    aCabecera = [...new Set(aCabecera)];

    var titleRowRegManual = worksheetRegManual.addRow(aCabecera);
    titleRowRegManual.font = {
      name: 'Comic Sans MS',
      family: 2,
      size: 12,
      underline: 'none',
      bold: true,
    };
    apiData.forEach((element) => {
      const listaData = [],
        data = [];
      aCabecera.forEach((v, i) => {
        switch (v) {
          case 'Posicion':
            data.push(element['NombrePosicion']);
            break;
          case 'Fecha':
            data.push(element['FechaVcto']);
            break;
          case 'Etiquetas':
            let etiqueta = '';
            element['aListaEtiquetas'].map((r, i) => {
              if (i > 0) {
                etiqueta += '|';
              }
              etiqueta += r.NombreEtiqueta;
              return etiqueta;
            });
            data.push(etiqueta);
            break;
          default:
            data.push(element[v]);
        }
      });

      data.map((r) => {
        if (r) {
          listaData.push(r.toString());
        } else listaData.push('-');
        return r;
      });

      worksheetRegManual.addRow(listaData);
    });
    var aCabeceraStyle = [];
    aCabecera.forEach((element) => {
      aCabeceraStyle.push({ width: 20 });
    });
    worksheetRegManual.columns = aCabeceraStyle;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'RegistrosManuales.xlsx');
    });
  } catch (error) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Occurió un error en la descarga del Excel RegistrosManuales.xlsx',
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
