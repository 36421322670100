import React from 'react';
import styled from 'styled-components';
import { theme } from './../../common/constants';
export const HeaderCard = (props) => {
  return (
    <Content color={props.color}>
      <Title>{props.title}</Title>
    </Content>
  );
};
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.text};
  margin: 10px 14px 10px 14px;
  align-items: center;
`;
const Title = styled.span`
  font-size: 1em !important;
`;
// export { Content as HeaderCardContent , Title as HeaderCardTitle };
