import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const posicionInitialState = {
  //TablaBuscador
  searchtext: '',
  //formulario
  bValidPosicion: null,
  sPosicion: '',
  iIdPosicion: null,
  bValidConcepto: null,
  selectConcepto: [],
  listaConceptos: [],
  bValidPosicionMaster: null,
  selectPosicionMaster: [],
  listaPosicionMaster: [],
  bValidDerivada: null,
  selectDerivada: [],
  listaDerivada: [],
  aDatosKey: [],
  aDatosKeyCrearPosicion: [],
  rowsKeyData: [],
  listaPosiciones: [],
  //Editar posicion
  selectPrincipalPosicion: [],
  bValidPrincipalPosicion: null,
  isDisabledPrincipalPosicion: true,
  listaPrincipalPosicion: [],
  selectSecundariaPosicion: [],
  bValidSecundariaPosicion: null,
  isDisabledSecundariaPosicion: true,
  listaSecundariaPosicion: [],
  //Agregado Sebas
  aListadoFuncionesComp: [],
  rowsInputPosicionCarac: [],
  aListaCabeceraAgrupado: null,
};
export const posicionSlice = createSlice({
  name: 'posicion',
  initialState: posicionInitialState,
  reducers: {
    agregarPosicion: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarPosicion: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarPosiciones: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListarPosiciones: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaPosiciones: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListarConceptosPosicion: (state, action) => {
      let estadoInspeccionado = current(state);
      var obj = {
        listaConceptos: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    filterListarPosiciones: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaPosiciones = estadoInspeccionado.listaPosiciones.filter(function (
        item,
        index
      ) {
        return (
          item.sNombrePosicion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sNombreConcepto.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.TipoConcepto.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.Real_Proyectado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaPosiciones: filterListaPosiciones,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    changeConceptoPosicion: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeNombrePosicion: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    changeNamePosicion: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setCabeceraEditar: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setListaDatosKeyPosicion: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    limpiarCamposPosicion: (state, action) => {
      // let estadoInspeccionado = current(state);
      return {
        ...state,
        ...{
          searchtext: '',
          bValidPosicion: null,
          sPosicion: '',
          bValidConcepto: null,
          selectConcepto: [],
          bValidPosicionMaster: null,
          selectPosicionMaster: [],
          aDatosKey: [],
          aDatosKeyCrearPosicion: [],
          rowsKeyData: [],
          selectPrincipalPosicion: [],
          bValidPrincipalPosicion: null,
          isDisabledPrincipalPosicion: true,
          // listaPrincipalPosicion : [],
          selectSecundariaPosicion: [],
          bValidSecundariaPosicion: null,
          isDisabledSecundariaPosicion: true,
          // listaSecundariaPosicion : [],
        },
      };
    },
    setListaFuncionPosicion: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setListarCaracteristicas: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setListarCabeceraAgrupado: (state, action) => {
      let estadoInspeccionado = current(state);
      return { ...estadoInspeccionado, ...action.payload };
    },
    setListarPrincipalPosicion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListarSecundariaPosicion: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePosicionMaster: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  setListarPosiciones,
  setListarConceptosPosicion,
  agregarPosicion,
  editarPosicion,
  eliminarPosiciones,
  filterListarPosiciones,
  changeConceptoPosicion,
  changeNombrePosicion,
  setListaDatosKeyPosicion,
  limpiarCamposPosicion,
  setListaFuncionPosicion,
  setListarCaracteristicas,
  setListarCabeceraAgrupado,
  changeNamePosicion,
  setCabeceraEditar,
  setListarPrincipalPosicion,
  setListarSecundariaPosicion,
  changePosicionMaster,
} = posicionSlice.actions;
export default posicionSlice.reducer;
