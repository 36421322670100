import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../';
import { faShareAlt, faTrash } from '@fortawesome/fontawesome-free-solid';
import useOutsideClick from '../../common/hooks/useOutsideClick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../common';

export const InputTextAutocompleteNewUpdDel = ({
  pholder,
  data,
  onSelected,
  onChange,
  value,
  text_align,
  width,
  onDelete,
}) => {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');

  const ref = useRef();

  useOutsideClick(ref, () => {
    // alert('You clicked outside');

    setIsHideSuggs(true);
  });

  const handler = (e) => {
    let aEncontrado = data.filter((i) =>
      i.nombre.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (aEncontrado.length == 0) {
      aEncontrado.push({
        id: null,
        nombre: "Agregar '" + e.target.value + "'",
        valor: e.target.value,
      });
    }
    setSugesstions(
      aEncontrado
      // data.filter((i) => i.nombre.toLowerCase().includes(e.target.value.toLowerCase())),
    );
  };
  const handlerAuto = (e) => {
    if (e) {
      let suggestions = data.filter((i) => i.nombre.toLowerCase().includes(e.toLowerCase()));
      if (suggestions.length > 0) {
        setIsHideSuggs(false);
        setSugesstions(suggestions);
      } else {
        setIsHideSuggs(true);
        setSugesstions([]);
      }
    } else {
      setIsHideSuggs(false);
      setSugesstions(data);
    }
  };

  const handlerAutoMejorado = (oEvent, e) => {
    if (oEvent.type == 'blur') {
      setIsHideSuggs(false);
      return;
    }
    if (e.trim().length > 0) {
      let suggestions = data.filter((i) => i.nombre.toLowerCase().includes(e.toLowerCase()));
      if (suggestions.length > 0) {
        setIsHideSuggs(false);
        setSugesstions(suggestions);
      }
    } else {
      setIsHideSuggs(true);
      setSugesstions([]);
    }
  };

  const handleChange = (e) => {
    const input = e.target.value;
    setIsHideSuggs(false);
    setSelectedVal(input);
    onChange(input);
    if (input.length === 0) {
      setIsHideSuggs(false);
      setSugesstions(data);
    }
  };
  const hideSuggs = (value) => {
    onSelected(value);
    setSelectedVal(value);
    setIsHideSuggs(true);
    setSugesstions([]);
  };

  const close = () => {
    setIsHideSuggs(true);
  };

  const onDeleteAndClose = (item) => {
    onDelete(item);
    setSugesstions([]);
    setIsHideSuggs(true);
  };

  return (
    <ContentSuggestionAuto>
      <formControlAuto ref={ref}>
        <Input
          placeholder={pholder}
          type="search"
          value={value}
          onChange={handleChange}
          onKeyUp={handler}
          onFocus={(event) => handlerAuto(value)}
          text_align={text_align}
          width={width}
        />
      </formControlAuto>
      {suggestions.length == 0 ? (
        <></>
      ) : (
        <ContentSuggestions
          className="suggestions"
          isHideSuggs={isHideSuggs}
          text_align={text_align}
          width={width}
        >
          {
            suggestions.map((item, idx) => (
              <div
                key={'' + item + idx}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{ width: '100%' }}
                  onClick={() => {
                    const { id, valor, compartido } = item;
                    hideSuggs({
                      id: id,
                      nombre: valor,
                      compartido: compartido,
                    });
                  }}
                >
                  {item.nombre}
                  &nbsp;&nbsp;
                  {item.compartido ? (
                    <FontAwesomeIcon icon={faShareAlt} style={{ color: '#949494' }} />
                  ) : (
                    ''
                  )}
                </div>
                {item.id !== null ? (
                  <div onClick={() => onDeleteAndClose(item)}>
                    <Button icon={faTrash} isAction={false} isOnlyIcon={true} type={'primary'} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))
            // )
          }
        </ContentSuggestions>
      )}
    </ContentSuggestionAuto>
  );
};
const ContentSuggestionAuto = styled.div`
  height: 32px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;

const ContentSuggestions = styled.div`
  margin-top: 33px !important;
  width: ${({ width }) => (width ? width : '320px')};
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
  position: absolute;
  border: 1 solid ${theme.colors.line}!important;
  border-radius: 2px !important;
  position: absolute;
  z-index: 99999 !important;
  overflow-y: ${({ isHideSuggs }) => (isHideSuggs ? '' : 'scroll')};
  max-height: ${({ isHideSuggs }) => (isHideSuggs ? '0px' : '300px')};
  display: ${({ isHideSuggs }) => (isHideSuggs ? 'none' : 'block')};
  div {
    cursor: pointer;
    display: flex;
    padding-left: 5px;
    height: 32px;
    align-items: center;
  }
  div:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.tertiary};
    div {
      color: ${theme.colors.white};
      background-color: ${theme.colors.tertiary};
    }
    svg {
      color: ${theme.colors.white};
    }
  }
`;

const Input = styled.input`
  border-radius: 2px;
  border: 1px solid ${theme.colors.white};
  transition: width 0.5s;
  height: 32px;
  font-family: ${theme.font.family};
  font-size: ${theme.font.size};
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  width: ${({ width }) => (width ? width : '320px')};
  background: ${theme.colors.white};
  text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
  border-color: ${({ validate }) =>
    validate == null
      ? theme.colors.line
      : validate === true
      ? theme.colors.line
      : theme.colors.warning};
  transition: ${({ validate }) =>
    validate == null
      ? theme.colors.line
      : validate === true
      ? theme.colors.line
      : 'border-color 0.5s ease-out'};
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      validate == null
        ? theme.colors.line
        : validate === true
        ? theme.colors.line
        : theme.colors.warning};
  }
`;
export default InputTextAutocompleteNewUpdDel;
