import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/fontawesome-free-solid';
import {
  SearchInputText,
  Button,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  ButtonAccion,
} from '../../../../components';
import {
  obtenerSoluciones,
  eliminarSoluciones,
  obtenerInfoSolucion,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  onOpenModalUploadDataExcelConcepto,
  setUploadDataExcelConcepto,
  setListaInversion,
  limpiarCamposIN,
  filterListarInversion,
} from '../../../../redux/states';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import readXlsxFile from 'read-excel-file';
import { MapImportDataConcepto } from '../../../../common';
import { createInversionAdapter } from '../../../../adapters/createInversion.adapter';
import { useNavigate } from 'react-router-dom';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  MenuAccion,
  RowAccion,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { items } from './misc';

export const ListarDepositoPlazo = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [selectedInversion, setSelectedInversion] = useState([]);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const inversionState = useSelector((store) => store.inversion);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar deposito' }));

    (async () => {
      loadInversion();
    })();
  }, []);

  const loadInversion = async () => {
    const responseListaInversion = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['IN'],
      })
    );
    var listaInversion = [];
    if (responseListaInversion != null) {
      if (responseListaInversion.data) {
        if (responseListaInversion.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaInversion.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaInversion.data.respuesta.aListaSolucion[index];
            var obj = createInversionAdapter(element);
            listaInversion.push(obj);
          }
        }
      }
    }
    dispatch(setListaInversion({ listaInversion: listaInversion }));
  };

  const findInversion = (person) => {
    return !!selectedInversion.find((p) => p.idInversion === person.idInversion);
  };
  const filterConcepto = (person) => {
    return selectedInversion.filter((p) => p.idInversion !== person.idInversion);
  };
  const handleChange = (person) => {
    const filteredPeople = findInversion(person)
      ? filterConcepto(person)
      : [...selectedInversion, person];
    setSelectedInversion(filteredPeople);
  };
  const onClickEditarConcepto = async (row) => {
    const responseInfoSolucion = await callEndpoint(
      obtenerInfoSolucion({
        oSolucion: {
          IDSolucionBase: row.idInversion,
        },
      })
    );
    if (responseInfoSolucion != null) {
      if (responseInfoSolucion.data) {
        var oSolucion = responseInfoSolucion.data.respuesta.aInfoSolucion[0];
        if (oSolucion) {
          navigate('/dashboard/soluciones/deposito/editar/' + row.idInversion);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: '',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
      }
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <CheckboxStyle
              size="small"
              checked={findInversion(params.row)}
              onChange={() => handleChange(params.row)}
            />
          }
        </>
      ),
    },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <>
          {
            <Button
              action={(oEvent) => onClickEditarConcepto(params.row)}
              icon={faEdit}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          }
        </>
      ),
    },
  ];

  const mapColumnsDownloadExcel = {
    sNombreAgrupador: 'Nombre',
    sTipoConcepto: 'Tipo',
    sReal_Proyectado: 'Real/Proyectado',
  };

  const onAgregarConcepto = () => {
    // setButtonAction(1);
    dispatch(limpiarCamposIN());
    navigate('/dashboard/soluciones/deposito/crear');
  };

  const onClickEliminarConcepto = () => {
    if (selectedInversion.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarInversion(oEvent.target.value));
    } else {
      dispatch(filterListarInversion(''));
    }
  };

  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };

  const onConfirmarEliminar = async () => {
    var aListaSolucionEliminar = [];
    selectedInversion.forEach((element) => {
      var o = {};
      o.idSolucion = element.idInversion;
      aListaSolucionEliminar.push(o);
    });
    const responseListaInversion = await callEndpoint(
      eliminarSoluciones({ aListaSolucionEliminar: aListaSolucionEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaInversion == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaInversion.data.cabecera.statusCode === 200) {
        loadInversion();
        setSelectedInversion([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaInversion.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaInversion.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const handleChangeFile = async (e) => {
    let response = await readXlsxFile(e.target.files[0]);
    var mapObj = MapImportDataConcepto;
    let formato = true;
    if (response.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha cargado información en la importación de data de conceptos.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let cabecera = response[0];
      for (let index = 0; index < cabecera.length; index++) {
        const element = cabecera[index];
        if (mapObj[element]) {
        } else {
          formato = false;
          break;
        }
      }
    }
    if (!formato) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'El excel no cumple con el formato de columnas predeterminada.Por favor, descargue la plantilla.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let uploadDataExcel = [];
      for (let index = 1; index < response.length; index++) {
        const row = response[index];
        const emptyObject = {
          Id: 0,
          FilaAgrupada: 0,
          Nombre: '',
          TipoConcepto: '',
          Real_Proyectado: '',
          IdFuncion: 0,
          NombreCampo: '',
          TipoAtributo: '',
        };
        var obj = { ...emptyObject };
        obj.Id = row[0];
        obj.FilaAgrupada = row[1];
        obj.Nombre = row[2];
        obj.TipoConcepto = row[3];
        obj.Real_Proyectado = row[4];
        obj.IdFuncion = row[5];
        obj.NombreCampo = row[6];
        obj.TipoAtributo = row[7];
        uploadDataExcel.push(obj);
      }
      dispatch(setUploadDataExcelConcepto({ uploadDataExcel: uploadDataExcel }));
      dispatch(onOpenModalUploadDataExcelConcepto({ modalOpenUploadDataExcel: true }));
    }
  };

  return (
    <ColFlex>
      <ContentOption>
        <RowFlex>
          <SearchInputText
            placeholder={'Buscar inversiones...'}
            filter={inversionState.searchtext}
            onChange={(e) => onChangeSearch(e)}
          />
          <ContentButton>
            <RowAccion>
              <Dropdown trigger={['click']} menu={{ items: items(onClickEliminarConcepto) }}>
                <ButtonAccion />
              </Dropdown>

              <Button action={onAgregarConcepto} name="Agregar" isAction={true} type="primary" />
            </RowAccion>
          </ContentButton>
        </RowFlex>
      </ContentOption>
      <TableGrid
        columns={columns}
        rows={
          inversionState.searchtext.length === 0
            ? inversionState.listaInversion
            : inversionState.filterListaInversion
        }
        pageSize={15}
        getRowId={(row) => row.idInversion}
      />
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {/* <ModalUploadExcelConcepto
        setButtonAction={setButtonAction}
        setTabProceso={setTabProceso}
      /> */}
      {loading === true ? <LoadingSpinner /> : <></>}
    </ColFlex>
  );
};
