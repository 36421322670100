import * as React from 'react';
import { useSelector } from 'react-redux';

export const PBR = (props) => {
  const authState = useSelector((store) => store.auth);

  const renderContent = () => {
    if (
      authState.user.isSuperAdmin ||
      authState.user.permisos.some((v) => props.permiso.includes(v))
    ) {
      return <>{props.children}</>;
    }
    return <></>;
  };
  return props.permiso.length > 0 ? (
    authState.user ? (
      renderContent()
    ) : (
      <></>
    )
  ) : (
    <>{props.children}</>
  );
};
export const PBRFunction = (permiso) => {
  const authState = useSelector((store) => store.auth);
  const renderContent = () => {
    if (authState.user.isSuperAdmin || authState.user.permisos.some((v) => permiso.includes(v))) {
      return false;
    }
    return true;
  };
  return permiso.length > 0 ? (authState.user ? renderContent() : true) : false;
};
