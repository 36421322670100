import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { Button, Input, LabelForm } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { changeModalSocio } from '../../../../redux/states';
import { ColForm, RowForm, Title } from '../../../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/fontawesome-free-solid';

export const ModalSocio = (props) => {
  const socioState = useSelector((store) => store.socio);
  const [selectedFileList, setSelectedFileList] = useState([]);
  const dispatch = useDispatch();

  const changeNombreSocio = (event) => {
    let valor = { tNombreSocio: event.target.value };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const changeDocumento = (event) => {
    let valor = { tDocumento: event.target.value };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const changeContacto = (event) => {
    let valor = { tContacto: event.target.value };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const changeDireccion = (event) => {
    let valor = { tDireccion: event.target.value };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const changeCorreo = (event) => {
    let valor = { tCorreo: event.target.value };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const changeTelefono = (event) => {
    let valor = { tTelefono: event.target.value };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const changeLogo = (info) => {
    switch (info.file.status) {
      case 'uploading':
        onFileUploading(info);
        break;
      case 'done':
        onFileDone(info);
        break;
      case 'removed':
        onFileRemove(info);
        break;
    }

    // let valor = { tLogo: event.target.value };
    // let modal = socioState.oModal;
    // modal = { ...modal, ...valor };
    // dispatch(changeModalSocio({ oModal: modal }));
  };

  const onFileUploading = (info) => {
    let files = [...socioState.oModal.tLogo];
    if (props.multipleFiles) files.push(info.file);
    else files = [info.file];
    let valor = { tLogo: files, tEditLogo: 1 };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const onFileDone = (info) => {
    let files = [...socioState.oModal.tLogo];
    if (!props.multipleFiles) files = [info.file];
    let valor = { tLogo: files, tEditLogo: 1 };
    let modal = socioState.oModal;
    modal = { ...modal, ...valor };
    dispatch(changeModalSocio({ oModal: modal }));
  };

  const onFileRemove = (info) => {
    if (props.multipleFiles) {
      const filtered = socioState.oModal.tLogo.filter((x) => x.uid != info.file.uid);
      let valor = { tLogo: filtered, tEditLogo: 1 };
      let modal = socioState.oModal;
      modal = { ...modal, ...valor };
      dispatch(changeModalSocio({ oModal: modal }));
    } else {
      let valor = { tLogo: [], tEditLogo: 0 };
      let modal = socioState.oModal;
      modal = { ...modal, ...valor };
      dispatch(changeModalSocio({ oModal: modal }));
    }
  };

  const dummyRequest = (option) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  return (
    <>
      <Modal
        open={props.open}
        title={<Title>{socioState.oModal.nId ? 'Guardar Socio' : 'Crear Socio'}</Title>}
        closable={false}
        footer={[
          <Button
            action={(oEvent) => props.onPressClose(oEvent)}
            name={'Cerrar'}
            isAction={true}
            type="warning"
          />,
          <Button
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.onPressSave(oEvent)}
            name={'Guardar'}
            isAction={true}
            type="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Nombre socio (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModal.tNombreSocio}
              onChange={(evnt) => changeNombreSocio(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm
              obligatorio={true}
              title={'Número de identificación fiscal (*): '}
            ></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModal.tDocumento}
              onChange={(evnt) => changeDocumento(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Contacto (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModal.tContacto}
              onChange={(evnt) => changeContacto(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Dirección (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModal.tDireccion}
              onChange={(evnt) => changeDireccion(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Correo (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModal.tCorreo}
              onChange={(evnt) => changeCorreo(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm obligatorio={true} title={'Telefono (*): '}></LabelForm>
            <Input
              placeholder="Ingrese"
              value={socioState.oModal.tTelefono}
              onChange={(evnt) => changeTelefono(evnt)}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <LabelForm obligatorio={true} title={'Logo (*): '}></LabelForm>
          <Upload.Dragger
            // accept=".xlsx, .xls" // TODO: segementar en una propiedad
            style={{ width: '98%' }}
            fileList={socioState.oModal.tLogo}
            onChange={changeLogo}
            customRequest={dummyRequest}
            multiple={false}
            disabled={false}
          >
            <p className="ant-upload-text" style={{ width: '98%' }}>
              Click o arrastra un archivo a esta zona para subirlo
            </p>
          </Upload.Dragger>
        </ColForm>
      </Modal>
    </>
  );
};
