import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { theme } from '../../common';
export const ButtonExpanded = (props) => (
  <ButtonExpandedStandar onClick={props.action} isAction={props.isAction}>
    <ContainerIconExpand className={props.expandedParametroEntrada ? 'rotateimg180' : ''}>
      {props.icon ? (
        <IconButtonExpanded>
          <FontAwesomeIcon icon={props.icon} />
        </IconButtonExpanded>
      ) : (
        <></>
      )}
      {props.name ? <span>{props.name}</span> : <></>}
    </ContainerIconExpand>
  </ButtonExpandedStandar>
);
const ButtonExpandedStandar = styled.button`
  font-family: UbuntuBold;
  font-size: 12px;
  background: transparent;
  border: transparent;
  padding: 6px;
  gap: 10px;
  span {
    color: white;
  }
  height: 31px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;
const IconButtonExpanded = styled.div`
  color: ${({ isAction }) =>
    isAction ? '${theme.colors.primary} !important' : 'white !important'};
  align-items: center;
`;
const ContainerIconExpand = styled.div`
  font-family: ${theme.font.family};
  color: white;
  font-size: 18px;
  transition: all 0.5s ease-in-out;
  &.rotateimg180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out; /* added */
  }
`;
