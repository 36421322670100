import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/fontawesome-free-solid';
import {
  SearchInputText,
  Button,
  ModalConfirmation,
  LoadingSpinner,
  TableGrid,
  PBR,
  ButtonAccion,
} from '../../../../components';
import { eliminarSistemas, listarSistemas } from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterListarSistemas,
  setListarSistemas,
  limpiarCamposSistema,
} from '../../../../redux/states';
import { createSistemaAdapter } from '../../../../adapters';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { exportToExcel } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Divider } from 'antd';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowFlex,
  MenuAccion,
} from '../../../../styles';
import { permisos } from '../../../../common';
import { items } from './misc';
export const ListarSistema = (props) => {
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [selectedSistema, setSelectedSistema] = useState([]);
  const sistemaState = useSelector((store) => store.sistema);
  const { loading, callEndpoint } = useFetchAndLoad();
  const titleExport = 'SistemaData';
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      loadSistemas();
    })();
  }, []);

  useEffect(() => {
    dispatch(updateTitle({ title: 'Listar Sistema' }));
  }, []);

  const loadSistemas = async () => {
    const responseListaSistemas = await callEndpoint(listarSistemas());
    var listaSistemas = [];
    for (
      let index = 0;
      index < responseListaSistemas.data.respuesta.aListaSistemas.length;
      index++
    ) {
      const element = responseListaSistemas.data.respuesta.aListaSistemas[index];
      var obj = createSistemaAdapter(element);
      listaSistemas.push(obj);
    }
    dispatch(setListarSistemas(listaSistemas));
  };

  const findSistema = (person) => {
    return !!selectedSistema.find((p) => p.IDSistema === person.IDSistema);
  };

  const filterSistema = (person) => {
    return selectedSistema.filter((p) => p.IDSistema !== person.IDSistema);
  };

  const handleSelectRow = (person) => {
    const filteredPeople = findSistema(person)
      ? filterSistema(person)
      : [...selectedSistema, person];
    setSelectedSistema(filteredPeople);
  };

  const onClickEditarSistema = async (row) => {
    navigate('/dashboard/configuracion/sistema/editar/' + row.IDSistema);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <CheckboxStyle
          checked={findSistema(params.row)}
          onChange={() => handleSelectRow(params.row)}
          size="small"
        />
      ),
    },
    {
      field: 'CodigoSistema',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'AliasSistema',
      headerName: 'Alias',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'TipoSistema',
      headerName: 'Tipo de Sistema',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <PBR permiso={[permisos.EDITAR_SISTEMAS]}>
          <Button
            action={(e) => onClickEditarSistema(params.row)}
            icon={faEdit}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
          />
        </PBR>
      ),
    },
  ];
  const mapColumnsDownloadExcel = {
    CodigoSistema: 'Código',
    AliasSistema: 'Álias del Sistema',
    TipoSistema: 'Tipo de Sistema',
  };
  const onAgregarSistema = () => {
    dispatch(limpiarCamposSistema());
    navigate('/dashboard/configuracion/sistema/crear');
  };

  const onClickEliminarSistema = () => {
    if (selectedSistema.length > 0) {
      setShowModalEliminar(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado nigún registro.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(filterListarSistemas(oEvent.target.value));
    } else {
      dispatch(filterListarSistemas(''));
    }
  };
  const onCloseModalEliminar = () => {
    setShowModalEliminar(false);
  };
  const onConfirmarEliminar = async () => {
    var aListaSistemasEliminar = [];
    selectedSistema.forEach((element) => {
      var o = {};
      o.IDSistema = element.IDSistema;
      aListaSistemasEliminar.push(o);
    });
    const responseListaSistemas = await callEndpoint(
      eliminarSistemas({ aListaSistemasEliminar: aListaSistemasEliminar })
    );
    setShowModalEliminar(false);
    if (responseListaSistemas == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseListaSistemas.data.cabecera.statusCode === 200) {
        loadSistemas();
        setSelectedSistema([]);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseListaSistemas.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseListaSistemas.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar sistemas...'}
              filter={sistemaState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(
                      onClickEliminarSistema,
                      exportToExcel,
                      sistemaState.searchtext.length === 0
                        ? sistemaState.listaSistemas
                        : sistemaState.filterListaSistemas,
                      titleExport,
                      mapColumnsDownloadExcel
                    ),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                <PBR permiso={[permisos.CREAR_SISTEMAS]}>
                  <Button action={onAgregarSistema} name="Agregar" isAction={true} type="primary" />
                </PBR>
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        {
          <TableGrid
            columns={columns}
            rows={
              sistemaState.searchtext.length === 0
                ? sistemaState.listaSistemas
                : sistemaState.filterListaSistemas
            }
            pageSize={15}
            getRowId={(row) => row.IDSistema}
          />
        }
      </ColFlex>
      <ModalConfirmation
        showModal={showModalEliminar}
        closeModal={(e) => onCloseModalEliminar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea eliminar el/los registros seleccionados?"
        action={(e) => onConfirmarEliminar(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Aceptar"
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
