import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
export const CustomButtonForm = (props) => (
  <Button onClick={props.action} isAction={props.isAction} hexColor={props.hexColor}>
    {/* {
            props.icon ? 
            <IconButton hexColor={props.hexColor} >
                <FontAwesomeIcon icon={props.icon} className="highlight" />
            </IconButton> : <></>
        } */}
    {props.name ? <span>{props.name}</span> : <></>}
  </Button>
);
const Button = styled.button`
  font-family: UbuntuBold;
  font-size: 12px;
  background: ${({ hexColor }) => hexColor};
  padding: 8px 20px 8px 20px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ hexColor }) => hexColor};
  gap: 10px;
  span {
    color: white;
  }
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background: white;
    transform: scale(1.09);
    span {
      color: ${({ hexColor }) => hexColor};
    }
  }
`;
const IconButton = styled.div`
  align-items: center;
`;
