import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { GlobalStyle } from './styles';
import { store } from './redux/store';
import { AppRouter } from './routes';
import './i18n';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <GlobalStyle />
    <AppRouter></AppRouter>
  </Provider>
);
