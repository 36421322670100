import React from 'react';
import styled from 'styled-components';
import { theme } from '../../common';
export const TitlePage = (props) => {
  return <Title color={props.color}>{props.title}</Title>;
};
export const Title = styled.h1`
  font-size: 26px;
  font-family: ${theme.font.family};
  color: ${({ color }) => (color ? color : 'black')};
`;
