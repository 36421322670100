import { permisos } from '../../../../../common';
import { PBRFunction } from '../../../../../components';

export const items = (
  onClickCrearCopia,
  onClickEliminarConcepto,
  exportToExcel,
  apiData,
  fileName,
  mapColumnsDownloadExcel
) => [
  {
    label: 'Crear copia',
    key: '1',
    onClick: onClickCrearCopia,
  },
  {
    label: 'Eliminar',
    key: '2',
    onClick: onClickEliminarConcepto,
    disabled: PBRFunction([permisos.ELIMINAR_ESTRUCTURA]),
  },
  {
    label: 'Exportar',
    key: '3',
    onClick: () => exportToExcel(apiData, fileName, mapColumnsDownloadExcel),
  },
];
