import React from 'react';
import styled, { keyframes } from 'styled-components';
export const LoadingSpinner = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

const rotate = keyframes`
 0% {
      -webkit-transform: rotate(0deg) scale(0.8);
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% {
      -webkit-transform: rotate(360deg) scale(1.2);
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotate(720deg) scale(0.8);
      -moz-transform: rotate(720deg) scale(0.8);
    }
`;
const ballFirst = keyframes`
  0% {
      box-shadow: 30px 0 0 #CE7CF9;
    }
    50% {
      box-shadow: 0 0 0 #CE7CF9;
      margin-bottom: 0;
      -webkit-transform: translate(15px, 15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #CE7CF9;
      margin-bottom: 10px;
    }`;
const ballSecond = keyframes`
    0% {
      box-shadow: 30px 0 0 #BE32F7;
    }
    50% {
      box-shadow: 0 0 0 #BE32F7;
      margin-top: -20px;
      -webkit-transform: translate(15px, 15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #BE32F7;
      margin-top: 0;
    }`;
const Loader = styled.div`
  animation: ${rotate} 1s infinite;
  height: 50px;
  width: 50px;
  :before,
  :after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }
  :before {
    animation: ${ballFirst} 1s infinite;
    background-color: #0e1986;
    box-shadow: 30px 0 0 #ce7cf9;
    margin-bottom: 10px;
  }
  :after {
    animation: ${ballSecond} 1s infinite;
    background-color: #7f8bfa;
    box-shadow: 30px 0 0 #be32f7;
  }
`;
const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999999;
  background-color: #00000071;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
`;
