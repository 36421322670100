import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const ModalTable = styled(Modal)`
  .modal-dialog {
    max-width: 1200px !important;
  }
`;
export const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 1100px !important;
  }
  .modal-body {
    &:first-child {
      justify-content: center !important;
    }
  }
`;
