import {createSlice} from '@reduxjs/toolkit'
import {current} from '@reduxjs/toolkit'
export const sistemaInitialState = {
  //TablaBuscador
  searchtext: '',
  //formulario
  idSistema: null,
  alias: '',
  bValidAlias: null,
  selectTipoSistema: [],
  bValidSelectTipoSistema: null,
  //Parametros con SAP
  idSap: '',
  bValidIdSap: null,
  noInstanciaSap: '',
  bValidNoIntancia: null,
  sapRouterSap: '',
  bValidSapRouterSap: null,
  servicioSap: '',
  bValidServicioSap: null,
  mandanteSap: '',
  bValidMandanteSap: null,
  //Parametros para ORacle
  usuarioOracle: '',
  bValidUsuarioOracle: null,
  //Parámetros para B1
  usuarioBusinessOne: '',
  bValidUsuarioBusinessOne: null,
  listaTipoSistemas: [],
  //Generales
  listaSistemas: [],
  filterListaSistemas: [],
  //
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
}
export const sistemaSlice = createSlice({
  name: 'sistema',
  initialState: sistemaInitialState,
  reducers: {
    agregarSistema: (state, action) => {
      return {...state, ...action.payload}
    },
    editarSistema: (state, action) => {
      return {...state, ...action.payload}
    },
    eliminarSistemas: (state, action) => {
      return {...state, ...action.payload}
    },
    setListarSistemas: (state, action) => {
      let estadoInspeccionado = current(state)
      var obj = {
        listaSistemas: action.payload,
      }
      return {...estadoInspeccionado, ...obj}
    },
    filterListarSistemas: (state, action) => {
      let estadoInspeccionado = current(state)
      const filterListaSistemas = estadoInspeccionado.listaSistemas.filter(function (item, index) {
        return (
          item.CodigoSistema.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.AliasSistema.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.TipoSistema.toLowerCase().includes(action.payload.toLowerCase())
        )
      })
      var obj = {
        filterListaSistemas: filterListaSistemas,
        searchtext: action.payload,
      }
      return {...estadoInspeccionado, ...obj}
    },
    changeAliasSistema: (state, action) => {
      return {...state, ...action.payload}
    },
    changeTipoSistemaSistema: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaTipoSistemas: (state, action) => {
      return {...state, ...action.payload}
    },
    loadEditarSistema: (state, action) => {
      let estadoInspeccionado = current(state)
      return {...estadoInspeccionado, ...action.payload}
    },
    setUploadDataExcelSistema: (state, action) => {
      let estadoInspeccionado = current(state)
      return {...estadoInspeccionado, ...action.payload}
    },
    onOpenModalUploadDataExcelSistema: (state, action) => {
      let estadoInspeccionado = current(state)
      return {...estadoInspeccionado, ...action.payload}
    },
    limpiarCamposSistema: (state, action) => {
      let oLimpiar = {
        idSistema: null,
        alias: '',
        selectTipoSistema: [],
        idSap: '',
        noInstanciaSap: '',
        sapRouterSap: '',
        servicioSap: '',
        mandanteSap: '',
        bValidAlias: null,
        bValidSelectTipoSistema: null,
        bValidIdSap: null,
        bValidNoIntancia: null,
        bValidSapRouterSap: null,
        bValidServicioSap: null,
        bValidMandanteSap: null,
      }
      return {...state, ...oLimpiar}
    },
    limpiarCamposFormSistema: (state, action) => {
      let oLimpiar = {
        alias: '',
        selectTipoSistema: [],
        idSap: '',
        noInstanciaSap: '',
        sapRouterSap: '',
        servicioSap: '',
        mandanteSap: '',
        bValidAlias: null,
        bValidSelectTipoSistema: null,
        bValidIdSap: null,
        bValidNoIntancia: null,
        bValidSapRouterSap: null,
        bValidServicioSap: null,
        bValidMandanteSap: null,
      }
      return {...state, ...oLimpiar}
    },
    limpiarCamposPostSelcTipoSistema: (state, action) => {
      let oLimpiar = {
        idSap: '',
        noInstanciaSap: '',
        sapRouterSap: '',
        servicioSap: '',
        mandanteSap: '',
        bValidIdSap: null,
        bValidNoIntancia: null,
        bValidSapRouterSap: null,
        bValidServicioSap: null,
        bValidMandanteSap: null,
      }
      return {...state, ...oLimpiar}
    },
    changeValueParametersTypeSystem: (state, action) => {
      return {...state, ...action.payload}
    },
  },
})
export const {
  agregarSistema,
  editarSistema,
  eliminarSistemas,
  setListarSistemas,
  filterListarSistemas,
  changeAliasSistema,
  changeTipoSistemaSistema,
  setListaTipoSistemas,
  loadEditarSistema,
  setUploadDataExcelSistema,
  onOpenModalUploadDataExcelSistema,
  limpiarCamposSistema,
  limpiarCamposFormSistema,
  limpiarCamposPostSelcTipoSistema,
  changeValueParametersTypeSystem,
} = sistemaSlice.actions
export default sistemaSlice.reducer
