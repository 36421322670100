import React, { useState } from 'react';
import { Button, Input, LabelForm, LoadingSpinner } from '../../../components';
import { ColForm, ContentForm, PasswordContainer, RowFooterButton, RowForm } from '../../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../common/hooks';
import {
  onChangeUsuarioPerfil,
  setCleanPerfil,
  setListarUsuarioRol,
  updateTitle,
} from '../../../redux/states';
import {
  cambiarPassUsu,
  cargaFotoUusuario,
  changePasswordC,
  editarUsuario,
  listarUsuario,
  obtenerDetalleUsuario,
  obtenerUrlImg,
} from '../../../services/public.service';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { Popover, Upload } from 'antd';
import {
  tieneCaracterEspecial,
  tieneMasDeOchoCaracteres,
  tieneMayuscula,
  tieneMinuscula,
  tieneNumero,
} from '../../../common';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const SeguridadPerfil = () => {
  const authState = useSelector((store) => store.auth);
  const perfilState = useSelector((store) => store.perfil);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [multipleFiles, setMultipleFiles] = useState(false);

  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let { t } = useTranslation();
  useEffect(() => {
    dispatch(updateTitle({ title: t('perfil.titulo') }));
  }, []);
  useEffect(() => {
    (async () => {
      if (authState.user) {
        loadUsuario();
        loadDetalleUsuario(authState.user.uid);
      }
    })();
  }, [authState.user]);

  const loadUsuario = async () => {
    const responseListaUsuario = await callEndpoint(listarUsuario());
    let oParam = {};
    for (let index = 0; index < responseListaUsuario.data.listadousuarios.length; index++) {
      const element = responseListaUsuario.data.listadousuarios[index];
      if (element.IDUsuario == authState.user.uid) {
        if (element.Foto.length > 0) {
          const splitFoto = element.Foto.split('/');
          const name = splitFoto[splitFoto.length - 1];
          const res = await obtenerUrlImg(element.Foto);
          const response = await fetch(res, {
            mode: 'no-cors',
          });
          const data = await response.blob();
          let foto = new File([data], name, {
            type: data.type,
          });

          oParam.idUsuario = element.IDUsuario;
          oParam.email = element.Correo;
          oParam.bValidEmail = true;
          oParam.username = element.Usuario;
          oParam.bValidUsername = true;
          oParam.foto = [foto];
        } else {
          oParam.idUsuario = element.IDUsuario;
          oParam.email = element.Correo;
          oParam.bValidEmail = true;
          oParam.username = element.Usuario;
          oParam.bValidUsername = true;
          oParam.foto = [];
        }
      }
    }
    dispatch(onChangeUsuarioPerfil(oParam));
  };

  const loadDetalleUsuario = async (id) => {
    const responseListaUsuario = await callEndpoint(obtenerDetalleUsuario({ id: id }));
    var listaUsuario = [];
    for (let index = 0; index < responseListaUsuario.data.respuesta.aLista.length; index++) {
      const element = responseListaUsuario.data.respuesta.aLista[index];
      listaUsuario.push(element);
    }
    dispatch(setListarUsuarioRol(listaUsuario));
  };

  const validarEditarUsuario = () => {
    let validarDatos = false;
    if (perfilState.bValidEmail) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (perfilState.bValidUsername) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    return validarDatos;
  };

  const validarCambioPass = () => {
    let validarDatos = false;
    if (!perfilState.bValidPasswordChange) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (perfilState.bValidOldPassword) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    if (perfilState.bValidPassword) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }
    if (perfilState.bValidPasswordConfirm) {
      validarDatos = true;
    } else {
      validarDatos = false;
      return validarDatos;
    }

    return validarDatos;
  };

  const onSave = async () => {
    let validarDatos = validarEditarUsuario();
    if (validarDatos) {
      let obj = {
        email: perfilState.email,
        usuario: perfilState.username,
      };
      const responseEditarUsuario = await callEndpoint(editarUsuario(obj));
      if (responseEditarUsuario.data) {
        if (responseEditarUsuario.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Se edito correctamente el usuario',
            showConfirmButton: false,
            timer: 2000,
          });
          if (perfilState.idUsuario == authState.user.uid) {
            let validarCambio = validarCambioPass();
            if (validarCambio) {
              const accessToken = window.localStorage.getItem('auth-token');
              const responseChangeCognito = await changePasswordC(
                accessToken,
                perfilState.oldPassword,
                perfilState.password
              );
              let objPass = {
                email: perfilState.email,
                password: perfilState.password,
              };
              const responseChangeBD = await callEndpoint(cambiarPassUsu(objPass));
            }
            dispatch(setCleanPerfil());
          }
          if (perfilState.editFoto == 1) {
            const formData = new FormData();
            formData.append('file', perfilState.foto[0].originFileObj);
            formData.append('IDUsuario', perfilState.idUsuario);
            const responseCargarFoto = await callEndpoint(cargaFotoUusuario(formData));
          }
          // setOpenModalUsuario(false);
          // limpiarModalUsuario();
          // loadUsuario();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseEditarUsuario.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: responseEditarUsuario.response.data.msg,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const handleChangeEmail = (v) => {
    let oParam = {};
    let validate = null;
    oParam.email = '';
    oParam.bValidEmail = validate;
    if (v) {
      oParam.email = v.target.value;
      if (v.target.value.indexOf('@') > 0) {
        oParam.bValidEmail = true;
      } else {
        oParam.bValidEmail = false;
      }
    }
    dispatch(onChangeUsuarioPerfil(oParam));
  };

  const handleChangeUsername = (v) => {
    let oParam = {};
    let validate = null;
    oParam.username = '';
    oParam.bValidUsername = validate;
    if (v) {
      oParam.username = v.target.value;
      oParam.bValidUsername = true;
    }
    dispatch(onChangeUsuarioPerfil(oParam));
  };
  const handleChangeOldPassword = (v) => {
    let oParam = {};
    oParam.oldPassword = '';
    if (v) {
      oParam.oldPassword = v.target.value;
      oParam.bValidOldPassword = true;
    }
    dispatch(onChangeUsuarioPerfil(oParam));
  };
  const handleChangePassword = (v) => {
    let oParam = {};
    oParam.password = '';
    if (v) {
      oParam.password = v.target.value;
      if (validarPassword(v.target.value)) {
        oParam.bValidPassword = true;
      } else {
        oParam.bValidPassword = false;
      }
    }
    dispatch(onChangeUsuarioPerfil(oParam));
  };
  const validarPassword = (v) => {
    let oParam = {};
    let validPasswordMust = [];
    validPasswordMust.push(tieneNumero(v));
    validPasswordMust.push(tieneMayuscula(v));
    validPasswordMust.push(tieneMinuscula(v));
    validPasswordMust.push(tieneCaracterEspecial(v));
    validPasswordMust.push(tieneMasDeOchoCaracteres(v));

    oParam.bValidPasswordMust = validPasswordMust;

    dispatch(onChangeUsuarioPerfil(oParam));

    return !validPasswordMust.includes(false);
  };
  const handleChangePasswordConfirm = (v) => {
    let oParam = {};
    oParam.passwordConfirm = '';
    if (v) {
      oParam.passwordConfirm = v.target.value;
      if (v.target.value == perfilState.password) {
        oParam.bValidPasswordConfirm = true;
      } else {
        oParam.bValidPasswordConfirm = false;
      }
    }
    dispatch(onChangeUsuarioPerfil(oParam));
  };

  const changeFoto = (info) => {
    switch (info.file.status) {
      case 'uploading':
        onFileUploading(info);
        break;
      case 'done':
        onFileDone(info);
        break;
      case 'removed':
        onFileRemove(info);
        break;
    }
  };

  const onFileUploading = (info) => {
    let files = [...perfilState.foto];
    if (multipleFiles) files.push(info.file);
    else files = [info.file];
    let valor = { foto: files, editFoto: 1 };

    dispatch(onChangeUsuarioPerfil(valor));
  };

  const onFileDone = (info) => {
    let files = [...perfilState.foto];
    if (!multipleFiles) files = [info.file];
    let valor = { foto: files, editFoto: 1 };
    dispatch(onChangeUsuarioPerfil(valor));
  };

  const onFileRemove = (info) => {
    if (multipleFiles) {
      const filtered = perfilState.foto.filter((x) => x.uid != info.file.uid);
      let valor = { foto: filtered, editFoto: 1 };
      dispatch(onChangeUsuarioPerfil(valor));
    } else {
      let valor = { foto: [], editFoto: 0 };
      dispatch(onChangeUsuarioPerfil(valor));
    }
  };

  const dummyRequest = (option) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  const passwordTooltip = () => {
    return (
      <>
        <li style={perfilState.bValidPasswordMust[0] ? { color: 'green' } : {}}>Tener un número</li>
        <li style={perfilState.bValidPasswordMust[1] ? { color: 'green' } : {}}>
          Tener un carácter en mayúscula
        </li>
        <li style={perfilState.bValidPasswordMust[2] ? { color: 'green' } : {}}>
          Tener un carácter en minúscula
        </li>
        <li style={perfilState.bValidPasswordMust[3] ? { color: 'green' } : {}}>
          Tener un carácter especial
        </li>
        <li style={perfilState.bValidPasswordMust[4] ? { color: 'green' } : {}}>
          Tener mínimo 8 caracteres
        </li>
      </>
    );
  };

  const handleShowPassword = () => {
    let oParam = { bValidPasswordChange: !perfilState.bValidPasswordChange };
    dispatch(onChangeUsuarioPerfil(oParam));
  };

  return (
    <>
      <ContentForm>
        <RowForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm title={t('perfil.label1')} obligatorio={true}></LabelForm>
              <Input
                disabled={true}
                placeholder={t('perfil.placeholder1')}
                value={perfilState.email}
                onChange={(evnt) => handleChangeEmail(evnt)}
                validate={perfilState.bValidEmail}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <LabelForm title={t('perfil.label2')} obligatorio={true}></LabelForm>
              <Input
                placeholder={t('perfil.placeholder2')}
                value={perfilState.username}
                onChange={(evnt) => handleChangeUsername(evnt)}
                validate={perfilState.bValidUsername}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowForm>
              <Button
                style={{ marginLeft: '5px' }}
                action={(oEvent) => handleShowPassword(oEvent)}
                name={t('perfil.botonMostrarPassword')}
                isAction={true}
                type="tertiary"
              />
            </RowForm>
          </ColForm>
          <ColForm
            style={perfilState.bValidPasswordChange ? { display: 'none' } : {}}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <RowForm>
              <LabelForm
                disabled={perfilState.bValidPasswordChange}
                title={t('perfil.label6')}
                obligatorio={true}
              ></LabelForm>
              <PasswordContainer>
                <Input
                  placeholder={t('perfil.placeholder6')}
                  type={showOldPassword ? 'text' : 'password'}
                  value={perfilState.oldPassword}
                  onChange={(oEvent) => handleChangeOldPassword(oEvent)}
                  disabled={perfilState.bValidPasswordChange}
                  validate={perfilState.bValidOldPassword}
                ></Input>
                {!showOldPassword ? (
                  <i
                    className="fa fa-eye-slash login-fa-eye"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye login-fa-eye"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    aria-hidden="true"
                  ></i>
                )}
              </PasswordContainer>
            </RowForm>
          </ColForm>
          <ColForm
            style={perfilState.bValidPasswordChange ? { display: 'none' } : {}}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <RowForm>
              <LabelForm
                disabled={perfilState.bValidPasswordChange}
                title={t('perfil.label3')}
                obligatorio={true}
              ></LabelForm>
              <PasswordContainer>
                <Popover content={passwordTooltip} title={t('perfil.popOverTitle')} trigger="click">
                  <Input
                    placeholder={t('perfil.placeholder3')}
                    type={showPassword ? 'text' : 'password'}
                    value={perfilState.password}
                    onChange={(oEvent) => handleChangePassword(oEvent)}
                    disabled={perfilState.bValidPasswordChange}
                    validate={perfilState.bValidPassword}
                  ></Input>
                </Popover>
                {!showPassword ? (
                  <AiOutlineEye className="login" onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <AiOutlineEyeInvisible
                    className="login"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </PasswordContainer>
            </RowForm>
          </ColForm>
          <ColForm
            style={perfilState.bValidPasswordChange ? { display: 'none' } : {}}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <RowForm>
              <LabelForm
                disabled={perfilState.bValidPasswordChange}
                title={t('perfil.label4')}
                obligatorio={true}
              ></LabelForm>
              <PasswordContainer>
                <Input
                  placeholder={t('perfil.placeholder4')}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  value={perfilState.passwordConfirm}
                  onChange={(oEvent) => handleChangePasswordConfirm(oEvent)}
                  disabled={perfilState.bValidPasswordChange}
                  validate={perfilState.bValidPasswordConfirm}
                ></Input>
                {!showPasswordConfirm ? (
                  <AiOutlineEye
                    className="login"
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="login"
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  />
                )}
              </PasswordContainer>
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
            <LabelForm title={t('perfil.label5')}></LabelForm>
            <Upload.Dragger
              style={{ width: '98%' }}
              fileList={perfilState.foto}
              onChange={changeFoto}
              customRequest={dummyRequest}
              multiple={false}
              disabled={false}
            >
              <p className="ant-upload-text" style={{ width: '98%' }}>
                {t('perfil.placeholder5')}
              </p>
            </Upload.Dragger>
          </ColForm>
          <RowFooterButton>
            <Button action={onSave} name={'Guardar'} isAction={true} type="secondary" />
          </RowFooterButton>
        </RowForm>
        {loading === true ? <LoadingSpinner /> : <></>}
      </ContentForm>
    </>
  );
};
