import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { Button, Input, LabelForm, SelectForm, TableGrid } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { changeModalDocumento, changeModalSocio } from '../../../../redux/states';
import { ColForm, RowButton, RowForm, Title } from '../../../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faInbox } from '@fortawesome/fontawesome-free-solid';
import { listaTipoDeDocumentos } from '../../../../common';
import { obtenerFile, obtenerUrlImg } from '../../../../services/public.service';

export const ModalArchivo = (props) => {
  const onDownload = async (e, data) => {
    let url = await obtenerUrlImg(data.URLDocu);
    obtenerFile(url);
  };

  const columns = [
    {
      field: 'NombreArchivo',
      headerName: 'Archivo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',
      headerName: '',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <Button
              action={(oEvent) => onDownload(oEvent, params.row)}
              icon={faDownload}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        </>
      ),
    },
  ];

  return (
    <Modal
      open={props.open}
      title={<Title>{'Descargar Documento'}</Title>}
      closable={false}
      footer={[
        <Button
          action={(oEvent) => props.onPressClose(oEvent)}
          name={'Cerrar'}
          isAction={true}
          type="warning"
        />,
      ]}
    >
      <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
        <RowForm>
          <LabelForm title={'Archivos'} />
        </RowForm>
      </ColForm>
      <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
        <RowForm>
          <TableGrid
            columns={columns}
            rows={props.listaArchivo}
            pageSize={15}
            getRowId={(row) => row.IDDocumento}
          />
        </RowForm>
      </ColForm>
    </Modal>
  );
};
