import React, { useEffect, useState } from 'react';
import { faUserCheck } from '@fortawesome/fontawesome-free-solid';
import { MenuItem } from '../index';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { MenuItems } from '../../common';
import { selectActiveMenu, selectActiveSubMenu } from '../../redux/states';
/**
 * @author
 * @function SideMenu
 **/
const SideMenu = (props) => {
  const dispatch = useDispatch();
  const [inactive, setInactive] = useState(false);
  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }
    props.onCollapse(inactive);
  }, [inactive]);
  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    dispatch(selectActiveSubMenu(null));
    document.querySelectorAll('.sub-menu').forEach((el) => {
      el.classList.remove('active');
    });
  };
  /*just a little improvement over click function of menuItem
    Now no need to use expand state variable in MenuItem component
  */
  useEffect(() => {
    let menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach((el) => {
      el.addEventListener('click', () => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        Array.prototype.slice
          .call(menuItems)
          .filter((e) => e !== el)
          .forEach((e) => {
            e.classList.remove('active');
          });
        if (el.classList.contains('active')) {
          el.classList.remove('active');
          next.classList.remove('active');
        } else {
          el.classList.add('active');
          next.classList.add('active');
        }
      });
    });
  }, []);
  return (
    <div className={`side-menu ${inactive ? 'inactive' : ''}`}>
      <div className="top-section">
        <div className="contenedorCabecera">
          <span class="dot"></span>
          <h1>Seidor</h1>
        </div>
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? (
            <i class="bi bi-arrow-right-square-fill"></i>
          ) : (
            <i class="bi bi-arrow-left-square-fill"></i>
          )}
        </div>
      </div>
      <div className="divider"></div>
      <div className="main-menu">
        <ul>
          {MenuItems.map((menuItem, index) => (
            <MenuItem
              icon={menuItem.icon}
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              onClick={() => {
                if (inactive) {
                  setInactive(false);
                } else {
                  dispatch(selectActiveMenu(menuItem));
                }
              }}
            />
          ))}
        </ul>
        <MenuInferior>
          <MenuIcon>
            <FontAwesomeIcon icon={faUserCheck} />
          </MenuIcon>
          <span>Sesión</span>
        </MenuInferior>
      </div>
    </div>
  );
};
const MenuInferior = styled.div`
  display: block;
  cursor: pointer;
`;
const MenuIcon = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
`;
export default SideMenu;
