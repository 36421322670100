import styled from 'styled-components';
import { Row } from 'antd';
export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowForm = styled(Row)`
  align-items: center;
`;

export const RowDoubleInput = styled(Row)`
  align-items: center;
  column-gap: 5px;
  width: 100%;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 50px;
`;

export const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 12px;
`;
export const RowFooterButton = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  width: 100%;
  /* align-self: flex-end; */
`;
export const RowAccion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  column-gap: 15px;
`;
