import React, { useEffect, useState } from 'react';
import {
  Input,
  LabelForm,
  TabBarCreacion,
  SubTitle,
  LoadingSpinner,
  ModalConfirmation,
  Button,
  TableGrid,
  SelectForm,
} from '../../../../components';
import { TipoOptions, theme } from '../../../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFuncionConcepto,
  changeNombreConcepto,
  changeRealProyectadoConcepto,
  changeRowsSelectNombreDataConcepto,
  changeRowsSelectTipoDataConcepto,
  changeTipoConcepto,
  limpiarCamposConcepto,
  loadEditarConcepto,
  onAddRowTableCaracConcepto,
  onDeleteRowTableCaracConcepto,
  setCaracteristicaRegistro,
  setCaracteristicaXfuncionConcepto,
  setListaFuncionesConcepto,
  updateTitle,
} from '../../../../redux/states';
import { RealProyecto, TipoConcepto } from '../../../../common';
import {
  listarCamposMJ,
  listarCaracteristicasXfuncion,
  listarFunciones,
  modificarConcepto,
  obtenerCabDetalleConcepto,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  createCaracteristicaAdapter,
  createCaracteristicaTotalAdapter,
  createSelectFuncionAdapter,
} from '../../../../adapters';
import Swal from 'sweetalert2';
import collect from 'collect.js';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  ColForm,
  Column,
  ContentForm,
  RowFooterButton,
  Content,
  RowButton,
  ColFlex,
  RowForm,
} from '../../../../styles';

import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';
import { v4 as uuidv4 } from 'uuid';
import { columnsForm } from '../misc';
export const EditarConcepto = (props) => {
  const conceptoState = useSelector((store) => store.concepto);
  const [rowsDataCarDel, setRowsDataCarDel] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  // 0 Sin acción - 1 Agregar - 2 Editar
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const [tabProceso, setTabProceso] = useState(0);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    onClickEditarConcepto();
  }, []);

  useEffect(() => {
    (async () => {
      const responseListarFunciones = await callEndpoint(listarFunciones());
      var listaFuncionesConcepto = [];
      for (
        let index = 0;
        index < responseListarFunciones.data.respuesta.aListaConfigFunciones.length;
        index++
      ) {
        const element = responseListarFunciones.data.respuesta.aListaConfigFunciones[index];
        listaFuncionesConcepto.push(createSelectFuncionAdapter(element));
      }

      dispatch(setListaFuncionesConcepto({ listaFunciones: listaFuncionesConcepto }));
    })();
  }, []);

  const addTableRows = () => {
    let uid = uuidv4();
    const rowsInput = {
      idConcepto: uid,
      IDCaracteristica: null,
      selectCaracteristica: [],
      selectTipo: [{ label: 'Detalle', value: 'Detalle' }],
      sAccion: 'N',
      enabledDelete: true,
    };
    // setRowsData([...rowsData, rowsInput]);
    dispatch(onAddRowTableCaracConcepto({ newItem: rowsInput }));
  };
  const deleteTableRows = (data, id) => {
    const rows = [...conceptoState.aCaracteristicaRegistro];
    const index = rows.findIndex((val) => val.idConcepto == id);
    var oEliminado = rows.splice(index, 1);
    if (oEliminado[0].sAccion === 'C' || oEliminado[0].sAccion === 'M') {
      setRowsDataCarDel([...rowsDataCarDel, ...oEliminado]);
    }
    dispatch(
      onDeleteRowTableCaracConcepto({
        index: index,
        selectCaracteristica: data.selectCaracteristica,
      })
    );
  };

  const handleChangeTipoTable = (data, id, value) => {
    var selectTipo = [];
    if (value) {
      selectTipo = [value];
    }
    dispatch(changeRowsSelectTipoDataConcepto({ index: id, selectTipo: selectTipo }));
  };

  const handleChangeNombreTable = (data, id, value) => {
    var selectCaracteristica = [];
    if (value) {
      selectCaracteristica = [value];
    }
    dispatch(
      changeRowsSelectNombreDataConcepto({
        index: id,
        selectCaracteristica: selectCaracteristica,
        data: data,
      })
    );
  };

  const onClickEditarConcepto = async (row) => {
    debugger;
    dispatch(updateTitle({ title: 'Editar Concepto' }));
    const { data } = await callEndpoint(obtenerCabDetalleConcepto({ iId: id }));
    var oCabecera = data.respuesta.oCabecera;
    if (data.respuesta.oCabecera) {
      var aDetalle = data.respuesta.aDetalle;
      debugger;
      var oConcepto = {
        iIDCabecera: oCabecera.IDConcepto,
        bValidTipo: true,
        selectTipo: [{ value: oCabecera.TipoConcepto, label: oCabecera.TipoConcepto }],
        sNombre: oCabecera.NombreConcepto,
        bValidNombre: true,
        selectRealProyectado:
          oCabecera.Real_Proyectado != 'Proyectado'
            ? [{ value: oCabecera.Real_Proyectado, label: oCabecera.Real_Proyectado }]
            : [{ value: oCabecera.Real_Proyectado, label: 'Plan' }],
        bValidRealProyectado: true,
        selectFuncion:
          oCabecera.IDFConexion != null && oCabecera.NombreFuncion != null
            ? [{ value: oCabecera.IDFConexion, label: oCabecera.NombreFuncion }]
            : [],
        bValidFuncion: true,
        aCaracteristicaRegistro: [],
      };
      var caracteristicasXfuncion = [];
      if (!oCabecera.IDFConexion && !oCabecera.NombreFuncion) {
        var rowsInputXdefault = [];
        const responseListarCaracteristicasXfuncion = await callEndpoint(listarCamposMJ());

        if (responseListarCaracteristicasXfuncion.data) {
          for (
            let index = 0;
            index < responseListarCaracteristicasXfuncion.data.respuesta.aListadoCampos.length;
            index++
          ) {
            const element =
              responseListarCaracteristicasXfuncion.data.respuesta.aListadoCampos[index];
            var obj = createCaracteristicaTotalAdapter(element);
            obj.selected = aDetalle.find((r) => r.Nombre == obj.label) ? true : false;
            obj.IDCaracteristica = null;
            obj.sAccion = 'S';
            caracteristicasXfuncion.push(obj);
          }
        }
        // if (caracteristicasXfuncion.length > 0) {
        //   aDetalle.forEach(function (item, index) {
        //     let uid = uuidv4();
        //     var o = {};
        //     o.idConcepto = uid;
        //     o.IDCaracteristica = null;
        //     o.selectCaracteristica = [];
        //     o.selectTipo = [];
        //     o.enabledDelete = true;
        //     o.orden = 1;
        //     var y = {};
        //     y.sNombreFuente = item.label;
        //     y.iIDFConexionAgrupador = item.iIDFConexionAgrupador;
        //     y.iIDHomologacion = item.value;
        //     y.label = item.label;
        //     y.value = item.value;
        //     o.selectCaracteristica.push(y);
        //     o.selectTipo.push({ label: 'Detalle', value: 'Detalle' });
        //     if (
        //       item.label == 'MonedaPago' ||
        //       item.label == 'MonedaImporte' ||
        //       item.label == 'Importe' ||
        //       item.label == 'FechaVcto' ||
        //       item.label == 'Sistema' ||
        //       item.label == 'Sociedad'
        //     ) {
        //       o.enabledDelete = false;
        //       o.orden = 0;
        //     }
        //     rowsInputXdefault.push(o);
        //   });
        // }
        let aCaract = caracteristicasXfuncion.filter(
          (item) =>
            item.sNombreFuente !== 'MonedaPago' &&
            item.sNombreFuente !== 'MonedaImporte' &&
            item.sNombreFuente !== 'Importe' &&
            item.sNombreFuente !== 'FechaVcto' &&
            item.sNombreFuente !== 'Sistema' &&
            item.sNombreFuente !== 'Sociedad'
        );
        debugger;
        aDetalle.forEach((element) => {
          let uid = uuidv4();
          var o = {};
          o.idConcepto = uid;
          o.selectCaracteristica = [];
          o.selectTipo = [];
          var oSelectC = {};
          oSelectC.iIDHomologacion = element.IDCaracteristica;
          oSelectC.iIDFConexionAgrupador = oCabecera.IDFConexion;
          oSelectC.sNombreFuente = element.Nombre;
          oSelectC.sTipoConcepto = oCabecera.TipoConcepto;
          oSelectC.value = element.IDCaracteristica;
          oSelectC.label = element.Nombre;
          o.selectCaracteristica.push(oSelectC);
          o.orden = 1;
          o.enabledDelete = true;
          var oSelectT = {};
          oSelectT.idTipo = element.Tipo;
          oSelectT.sTipo = element.Tipo;
          oSelectT.value = element.Tipo;
          oSelectT.label = element.Tipo;
          o.selectTipo.push(oSelectT);
          o.sAccion = 'C';
          o.IDCaracteristica = element.IDCaracteristica;
          if (
            element.Nombre == 'MonedaPago' ||
            element.Nombre == 'MonedaImporte' ||
            element.Nombre == 'Importe' ||
            element.Nombre == 'FechaVcto' ||
            element.Nombre == 'Sistema' ||
            element.Nombre == 'Sociedad'
          ) {
            o.enabledDelete = false;
            o.orden = 0;
          }
          oConcepto.aCaracteristicaRegistro.push(o);
          var aEnconrtado = aCaract.filter((item2) => element.Nombre == item2.sNombreFuente);
          if (aEnconrtado.length > 0) {
            aEnconrtado[0].selected = true;
          }
        });

        dispatch(loadEditarConcepto(oConcepto));
        // dispatch(
        //   setCaracteristicaRegistro({
        //     aCaracteristicaRegistro: collect(rowsInputXdefault).sortBy('orden').all(),
        //     //  rowsInputXdefault
        //   })
        // );
        dispatch(
          setCaracteristicaRegistro({
            aCaracteristicaRegistro: oConcepto.aCaracteristicaRegistro,
          })
        );
      } else {
        const responseListarCaracteristicasXfuncion = await callEndpoint(
          listarCaracteristicasXfuncion(oConcepto.selectFuncion)
        );
        for (
          let index = 0;
          index <
          responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion.length;
          index++
        ) {
          const element =
            responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion[index];
          var obj = createCaracteristicaAdapter(element);
          obj.selected = aDetalle.find((r) => r.Nombre == obj.label) ? true : false;
          obj.IDCaracteristica = null;
          obj.sAccion = 'S';
          caracteristicasXfuncion.push(obj);
        }
        let aCaract = caracteristicasXfuncion.filter(
          (item) =>
            item.sNombreFuente !== 'MonedaPago' &&
            item.sNombreFuente !== 'MonedaImporte' &&
            item.sNombreFuente !== 'Importe' &&
            item.sNombreFuente !== 'FechaVcto' &&
            item.sNombreFuente !== 'Sistema' &&
            item.sNombreFuente !== 'Sociedad'
        );
        aDetalle.forEach((element) => {
          let uid = uuidv4();
          var o = {};
          o.idConcepto = uid;
          o.selectCaracteristica = [];
          o.selectTipo = [];
          var oSelectC = {};
          oSelectC.iIDHomologacion = element.IDCaracteristica;
          oSelectC.iIDFConexionAgrupador = oCabecera.IDFConexion;
          oSelectC.sNombreFuente = element.Nombre;
          oSelectC.sTipoConcepto = oCabecera.TipoConcepto;
          oSelectC.value = element.IDCaracteristica;
          oSelectC.label = element.Nombre;
          o.selectCaracteristica.push(oSelectC);
          o.orden = 1;
          o.enabledDelete = true;
          var oSelectT = {};
          oSelectT.idTipo = element.Tipo;
          oSelectT.sTipo = element.Tipo;
          oSelectT.value = element.Tipo;
          oSelectT.label = element.Tipo;
          o.selectTipo.push(oSelectT);
          o.sAccion = 'C';
          o.IDCaracteristica = element.IDCaracteristica;
          if (
            element.label == 'MonedaPago' ||
            element.label == 'MonedaImporte' ||
            element.label == 'Importe' ||
            element.label == 'FechaVcto' ||
            element.label == 'Sistema' ||
            element.label == 'Sociedad'
          ) {
            o.enabledDelete = false;
            o.orden = 0;
          }
          oConcepto.aCaracteristicaRegistro.push(o);
          var aEnconrtado = aCaract.filter((item2) => element.Nombre == item2.sNombreFuente);
          if (aEnconrtado.length > 0) {
            aEnconrtado[0].selected = true;
          }
        });
        dispatch(loadEditarConcepto(oConcepto));
        dispatch(
          setCaracteristicaRegistro({
            aCaracteristicaRegistro: oConcepto.aCaracteristicaRegistro,
          })
        );
      }
      debugger;
      dispatch(
        setCaracteristicaXfuncionConcepto({
          aCaracteristicaXfuncion: caracteristicasXfuncion,
        })
      );
    }
    setLoadingDetalle(false);
  };

  const onTabProcesoGeneral = () => {
    setTabProceso(0);
  };
  const obTabCaracteristicas = () => {
    onPressSiguiente();
  };
  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const handleChangeTipo = (value) => {
    var oParam = {};
    oParam.bValidTipo = null;
    oParam.selectTipo = [];
    if (value) {
      oParam.selectTipo = [value];
      oParam.bValidTipo = true;
    }
    dispatch(changeTipoConcepto(oParam));
  };
  const handleChangeNombre = (oEvent) => {
    var sNombre = oEvent.target.value;
    var validate = null;
    if (sNombre.length > 0) {
      validate = true;
    }
    var oParam = {
      sNombre: sNombre,
      bValidNombre: validate,
    };
    dispatch(changeNombreConcepto(oParam));
  };
  const handleChangeRealProyectado = (value) => {
    var oParam = {};
    oParam.selectRealProyectado = [];
    oParam.bValidRealProyectado = null;
    if (value) {
      oParam.selectRealProyectado = [value];
      oParam.bValidRealProyectado = true;
    }
    dispatch(changeRealProyectadoConcepto(oParam));
  };

  const handleChangeFuncion = async (value) => {
    var oParam = {};
    oParam.selectFuncion = [];
    oParam.bValidFuncion = null;
    var caracteristicasXfuncion = [];
    var rowsInputXdefault = [];
    if (value) {
      oParam.selectFuncion = [value];
      oParam.bValidFuncion = true;
      const responseListarCaracteristicasXfuncion = await callEndpoint(
        listarCaracteristicasXfuncion(oParam.selectFuncion)
      );
      if (responseListarCaracteristicasXfuncion.data) {
        for (
          let index = 0;
          index <
          responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion.length;
          index++
        ) {
          const element =
            responseListarCaracteristicasXfuncion.data.respuesta.aCaracteristicasXfuncion[index];
          var obj = createCaracteristicaAdapter(element);
          obj.selected = true;
          obj.IDCaracteristica = null;
          obj.sAccion = 'S';
          caracteristicasXfuncion.push(obj);
        }
      }
    }
    if (caracteristicasXfuncion.length > 0) {
      caracteristicasXfuncion.forEach(function (item, index) {
        let uid = uuidv4();
        var o = {};
        o.idConcepto = uid;
        o.IDCaracteristica = null;
        o.selectCaracteristica = [];
        o.selectTipo = [];
        o.enabledDelete = true;
        o.orden = 1;
        var y = {};
        y.sNombreFuente = item.label;
        y.iIDFConexionAgrupador = item.iIDFConexionAgrupador;
        y.iIDHomologacion = item.value;
        y.label = item.label;
        y.value = item.value;
        o.selectCaracteristica.push(y);
        o.selectTipo.push({ label: 'Detalle', value: 'Detalle' });
        if (
          item.label == 'MonedaPago' ||
          item.label == 'MonedaImporte' ||
          item.label == 'Importe' ||
          item.label == 'FechaVcto'
        ) {
          o.enabledDelete = false;
          o.orden = 0;
        }
        rowsInputXdefault.push(o);
      });
    }
    let aCaract = caracteristicasXfuncion.filter(
      (item) =>
        item.sNombreFuente !== 'MonedaPago' &&
        item.sNombreFuente !== 'MonedaImporte' &&
        item.sNombreFuente !== 'Importe' &&
        item.sNombreFuente !== 'FechaVcto'
    );
    dispatch(
      setCaracteristicaXfuncionConcepto({
        aCaracteristicaXfuncion: caracteristicasXfuncion,
      })
    );
    dispatch(
      setCaracteristicaRegistro({
        aCaracteristicaRegistro: collect(rowsInputXdefault).sortBy('orden').all(),
      })
    );
    dispatch(changeFuncionConcepto(oParam));
  };
  const onPressSiguiente = () => {
    if (conceptoState.sNombre === '') {
      dispatch(changeNombreConcepto({ bValidNombre: false }));
    }
    if (conceptoState.selectTipo.length === 0) {
      dispatch(changeTipoConcepto({ bValidTipo: false }));
    }
    // if (conceptoState.selectFuncion.length === 0) {
    //   dispatch(changeFuncionConcepto({ bValidFuncion: false }));
    // }
    if (conceptoState.selectRealProyectado.length === 0) {
      dispatch(changeRealProyectadoConcepto({ bValidRealProyectado: false }));
    }
    if (
      conceptoState.selectTipo.length > 0 &&
      // conceptoState.selectFuncion.length > 0 &&
      conceptoState.selectRealProyectado.length > 0 &&
      conceptoState.sNombre !== ''
    ) {
      if (conceptoState.aCaracteristicaXfuncion.length > 0) {
        setTabProceso(1);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No posee características registradas.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, completar todos los campos del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const limpiarCampos = (oEvent) => {
    dispatch(limpiarCamposConcepto());
  };
  const onPressGrabar = async (props, rowsDataCarDel) => {
    var aRegistroConceptos = [];
    const rowsData = [...conceptoState.aCaracteristicaRegistro];
    var valid = true;
    for (let index = 0; index < conceptoState.aCaracteristicaRegistro.length; index++) {
      const item = conceptoState.aCaracteristicaRegistro[index];
      if (item.selectCaracteristica.length == 0 || item.selectTipo.length == 0) {
        valid = false;
      } else {
        var o = {
          iIDFConexion: item.selectCaracteristica[0].iIDFConexionAgrupador,
          IDAgrupador: null,
          sNombreAgrupador: conceptoState.sNombre,
          NombreCampo: item.selectCaracteristica[0].sNombreFuente,
          TipoAtributo: item.selectTipo[0].value,
          TipoDato: '',
          sTipoConcepto: conceptoState.selectTipo[0].value,
          sReal_Proyectado: conceptoState.selectRealProyectado[0].value,
          Habilitado: 1,
        };
        aRegistroConceptos.push(o);
      }
    }
    if (!valid) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No todos los registros tienen el nombre o tipo lleno.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    debugger;
    let filtroKeyXDetalle =
      conceptoState.selectFuncion.length > 0
        ? aRegistroConceptos.filter((item) => item.TipoAtributo === 'Key')
        : [1];
    if (filtroKeyXDetalle.length > 0) {
      var oModificarCabecera = {};
      oModificarCabecera.sNombreAgrupador = conceptoState.sNombre;
      oModificarCabecera.sTipoConcepto = conceptoState.selectTipo[0].value;
      oModificarCabecera['sReal_Proyectado'] = conceptoState.selectRealProyectado[0].value;
      oModificarCabecera['iIDFConexion'] =
        conceptoState.selectFuncion.length > 0 ? conceptoState.selectFuncion[0].value : 0;
      oModificarCabecera['iIDCabecera'] = conceptoState.iIDCabecera;
      var oParam = {
        oModificarCabecera: oModificarCabecera,
        aModificarDetalleConcepto: aRegistroConceptos,
      };
      let modifMCaract = rowsData.filter((item) => item.sAccion === 'M');
      let modifNCaract = rowsData.filter((item) => item.sAccion === 'N');
      let paramEditarConcepto = {
        aCaracReg: modifNCaract,
        aCaracModif: modifMCaract,
        aCaracDel: rowsDataCarDel,
      };
      const responseRegistrarConcepto = await await callEndpoint(
        modificarConcepto({
          oModificarCabecera: oParam.oModificarCabecera,
          aModificarDetalleConcepto: oParam.aModificarDetalleConcepto,
          aModificarFlag: paramEditarConcepto,
        })
      );
      if (responseRegistrarConcepto == null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseRegistrarConcepto.data) {
          if (responseRegistrarConcepto.data.cabecera.statusCode === 200) {
            dispatch(limpiarCamposConcepto());
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseRegistrarConcepto.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            navigate('/dashboard/configuracion/concepto/listar');
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseRegistrarConcepto.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 4000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Debe tener al menos un tipo Key.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    dispatch(limpiarCamposConcepto());
    navigate('/dashboard/configuracion/concepto/listar');
  };

  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : conceptoState.iIDCabecera == null ? (
    <Content>No se encontró información</Content>
  ) : (
    <Content>
      <TabBarCreacion
        onClickTab1={onTabProcesoGeneral}
        tabProceso={tabProceso}
        labelTab1={'General'}
        onClickTab2={obTabCaracteristicas}
        labelTab2={'Caracteristicas'}
      />
      {tabProceso === 0 ? (
        <ColFlex>
          <SubTitle subtitle="1. Editar datos generales del concepto :" />
          <ContentForm>
            <RowForm style={{ columnGap: '5px' }}>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Nombre (*):'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={conceptoState.sNombre}
                    onChange={(evnt) => handleChangeNombre(evnt)}
                    validate={conceptoState.bValidNombre}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Tipo (*):'}></LabelForm>
                  <SelectForm
                    validate={conceptoState.bValidTipo}
                    value={conceptoState.selectTipo}
                    handleChange={(e, v) => handleChangeTipo(v)}
                    options={TipoConcepto}
                    dataOption={TipoConcepto}
                    placeholder={'Seleccione tipo'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Real/Plan (*):'}></LabelForm>
                  <SelectForm
                    validate={conceptoState.bValidRealProyectado}
                    value={conceptoState.selectRealProyectado}
                    handleChange={(e, v) => handleChangeRealProyectado(v)}
                    options={RealProyecto}
                    placeholder={'Seleccione R/P'}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm title={'Función :'}></LabelForm>
                  <SelectForm
                    validate={conceptoState.bValidFuncion}
                    value={conceptoState.selectFuncion}
                    handleChange={(e, v) => handleChangeFuncion(v)}
                    options={conceptoState.listaFunciones}
                    placeholder={'Seleccione Función'}
                  />
                </RowForm>
              </ColForm>
              <RowFooterButton>
                <Button
                  action={(e) => onPressCancelar()}
                  name="Cancelar"
                  isAction={true}
                  type="warning"
                />
                <Button
                  action={(e) => limpiarCampos(e)}
                  name="Limpiar"
                  isAction={true}
                  type="primary"
                />
                <Button
                  action={onPressSiguiente}
                  name="Siguiente"
                  isAction={true}
                  type="secondary"
                />
              </RowFooterButton>
            </RowForm>
          </ContentForm>
        </ColFlex>
      ) : (
        <ColFlex>
          <SubTitle subtitle="2. Editar características de los conceptos :" />
          <ContentForm>
            <TableGrid
              columns={columnsForm(
                handleChangeNombreTable,
                handleChangeTipoTable,
                deleteTableRows,
                addTableRows,
                conceptoState
              )}
              rows={conceptoState.aCaracteristicaRegistro}
              pageSize={15}
              getRowId={(row) => row.idConcepto}
            />
            <RowFooterButton>
              <Button
                action={(e) => onPressCancelar()}
                name="Cancelar"
                isAction={true}
                type="warning"
              />

              <Button
                action={(e) => onPressGrabar(props, rowsDataCarDel)}
                name="Grabar"
                isAction={true}
                type="secondary"
              />
            </RowFooterButton>
          </ContentForm>
        </ColFlex>
      )}
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la edición del concepto?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
