import { v4 as uuidv4 } from 'uuid';

export const soluTipoCambioAdapter = (r) => ({
  FactorMonedaBase: r.FactorBase,
  FactorMonedaDestino: r.FactorDestino,
  selectMonedaRango: r.MonedaRango,
  rangoDesde: r.Minimo,
  rangoHasta: r.Maximo,
  selectMoneda: r.MonedaBase,
  cantidadMonedaBase: r.cantidadMonedaBase,
  cantidadMonedaDestino: r.cantidadMonedaDestino,
  selectMonedaConversion: r.MonedaDestino,
});

export const soluTipoCambioEditAdapter = (r) => {
  let uid = uuidv4();
  return {
    id: uid,
    FactorBase: r.FactorMonedaBase,
    FactorDestino: r.FactorMonedaDestino,
    FactorRango:
      r.selectMonedaRango === r.selectMoneda ? r.FactorMonedaBase : r.FactorMonedaDestino,
    MonedaRango: r.selectMonedaRango,
    Minimo: r.rangoDesde,
    Maximo: r.rangoHasta,
    MonedaBase: r.selectMoneda,
    cantidadMonedaBase: r.cantidadMonedaBase,
    cantidadMonedaDestino: r.cantidadMonedaDestino,
    MonedaDestino: r.selectMonedaConversion,
    bValidCantidadMonedaBase: true,
    bValidCantidadMonedaDestino: true,
    bValidMinimo: true,
    bValidMaximo: true,
  };
};
