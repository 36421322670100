import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const estructuraInitialState = {
  searchtext: '',
  modalOpenEliminarEstructura: false,
  oModalRespuestaServicioEstructura: {
    statusCode: 0,
    type: '',
    title: '',
    subtitle: '',
    modalOpen: false,
  },
  loadingEstructura: false,
  listaEstructuras: [],
  filterListaEstructuras: [],
  successDeleteEstructura: 0,
  modalAgregarEstructura: false,
  modalAgregarNombreNodoSaldo: false,
  modalEditarNombreNodoSaldo: false,
  modalAgregarNombreNodoIngreso: false,
  modalEditarNombreNodoIngreso: false,
  modalAgregarNombreNodoEgreso: false,
  modalEditarNombreNodoEgreso: false,
  oConceptosCrearEstructura: {
    // "sCodigo" : "",
    iIDEstructura: null,
    bValidCodigo: null,
    sNombre: '',
    bValidNombre: null,
    sDetalle: '',
    bValidDetalle: null,
    oSelectTipoEstructura: [],
    bValidTipoEstructura: null,
    bPrincipal: false,
  },
  bEnabledPrincipal: true,
  oEstructuraPrincial: null,
  aListaConceptosXtipoSaldo: [],
  aListaConceptosXtipoIngreso: [],
  aListaConceptosXtipoEgreso: [],
  listadoSubAgrupadorCrearEstructura: [],
  listaTipoConceptos: [],
  listaConceptosEstructura: [],
  oSaldo: {
    treeData: [],
    searchString: '',
    searchFocusIndex: -1,
    searchFoundCount: 0,
  },
  oIngreso: {
    treeData: [],
    searchString: '',
    searchFocusIndex: -1,
    searchFoundCount: 0,
  },
  oEgreso: {
    treeData: [],
    searchString: '',
    searchFocusIndex: -1,
    searchFoundCount: 0,
  },
  selectPosicionAsignada: [],
  aListaConceptosXtipoEstructura: [],
};
export const estructuraSlice = createSlice({
  name: 'estructura',
  initialState: estructuraInitialState,
  reducers: {
    onOpenModalEliminarEstructura: (state, { payload }) => {
      state.modalOpenEliminarEstructura = payload;
    },
    onStartLoadingEstructura: (state, { payload }) => {
      state.loadingEstructura = payload;
    },
    onSetEstructuras: (state, { payload }) => {
      state.listaEstructuras = payload;
    },
    filterListarEstructuras: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaEstructuras = estadoInspeccionado.listaEstructuras.filter(function (
        item,
        index
      ) {
        return (
          item.sNombreEstructura.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sDetalleEstructura.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.sEsPrincipal.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaEstructuras: filterListaEstructuras,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    onSuccessDeleteEstructura: (state, { payload }) => {
      state.successDeleteEstructura = state.successDeleteEstructura + 1;
    },
    onOpenModalAgregarNombreNodoSaldo: (state, { payload }) => {
      state.modalAgregarNombreNodoSaldo = payload;
    },
    onOpenModalEditarNombreNodoSaldo: (state, { payload }) => {
      state.modalEditarNombreNodoSaldo = payload;
    },
    onOpenModalAgregarNombreNodoIngreso: (state, { payload }) => {
      state.modalAgregarNombreNodoIngreso = payload;
    },
    onOpenModalEditarNombreNodoIngreso: (state, { payload }) => {
      state.modalEditarNombreNodoIngreso = payload;
    },
    onOpenModalAgregarNombreNodoEgreso: (state, { payload }) => {
      state.modalAgregarNombreNodoEgreso = payload;
    },
    onOpenModalAgregarEstructura: (state, { payload }) => {
      state.modalAgregarEstructura = payload;
    },
    onSetDataModalAgregarEstructura: (state, { payload }) => {
      return { ...state, ...payload };
    },
    onOpenModalEditarNombreNodoEgreso: (state, { payload }) => {
      state.modalEditarNombreNodoEgreso = payload;
    },
    onSetConceptosXtipoSaldoCrearEstructura: (state, { payload }) => {
      state.aListaConceptosXtipoSaldo = payload;
    },
    onSetConceptosXtipoIngresoCrearEstructura: (state, { payload }) => {
      state.aListaConceptosXtipoIngreso = payload;
    },
    onSetConceptosXtipoEgresoCrearEstructura: (state, { payload }) => {
      state.aListaConceptosXtipoEgreso = payload;
    },
    onSetSubAgrupadorCrearEstructura: (state, { payload }) => {
      state.listadoSubAgrupadorCrearEstructura = payload;
    },
    onChangeTipoCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.oSelectTipoEstructura =
        payload.oConceptosCrearEstructura.oSelectTipoEstructura;
      state.oConceptosCrearEstructura.bValidTipoEstructura =
        payload.oConceptosCrearEstructura.bValidTipoEstructura;
    },
    onSetValidTipoEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.bValidTipoEstructura = payload;
    },
    onChangeNombreCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.sNombre = payload.sNombre;
      state.oConceptosCrearEstructura.bValidNombre = payload.bValidNombre;
    },
    onSetValidNombreCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.bValidNombre = payload;
    },
    onChangeDetalleCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.sDetalle = payload.sDetalle;
      state.oConceptosCrearEstructura.bValidDetalle = payload.bValidDetalle;
    },
    onChangeTipoEstructuraCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.bPrincipal = payload;
    },
    onSetValidDetalleCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura.bValidDetalle = payload;
    },
    onLimpiarCamposCrearEstructura: (state, { payload }) => {
      state.oConceptosCrearEstructura = {
        iIDEstructura: null,
        bValidCodigo: null,
        sNombre: '',
        bValidNombre: null,
        sDetalle: '',
        bValidDetalle: null,
        oSelectTipoEstructura: [],
        bValidTipoEstructura: null,
        bPrincipal: false,
      };
      state.oSaldo = {
        treeData: [],
        searchString: '',
        searchFocusIndex: -1,
        searchFoundCount: 0,
      };
      state.oIngreso = {
        treeData: [],
        searchString: '',
        searchFocusIndex: -1,
        searchFoundCount: 0,
      };
      state.oEgreso = {
        treeData: [],
        searchString: '',
        searchFocusIndex: -1,
        searchFoundCount: 0,
      };
      state.selectPosicionAsignada = [];
    },
    onSetRespuestaServicioEstructura: (state, { payload }) => {
      state.oModalRespuestaServicioEstructura = {
        ...state.oModalRespuestaServicioEstructura,
        ...payload,
      };
    },
    onLimpiarRespuestaServicioEstructura: (state, { payload }) => {
      state.oModalRespuestaServicioEstructura = {
        statusCode: 0,
        type: '',
        title: '',
        subtitle: '',
        modalOpen: false,
      };
    },
    onSetListaTipoConceptos: (state, { payload }) => {
      state.listaTipoConceptos = payload;
    },
    onSetListaConceptosEstructura: (state, { payload }) => {
      state.listaConceptosEstructura = payload;
    },
    onSetEstructuraPrincipal: (state, { payload }) => {
      state.oEstructuraPrincial = payload;
    },
    onSetDetalleEditarEstructura: (state, action) => {
      // state.oConceptosCrearEstructura = payload.oConceptosCrearEstructura;
      return { ...state, ...action.payload };
    },
    onSetDetalleCrearCopiaEstructura: (state, action) => {
      // state.oConceptosCrearEstructura = payload.oConceptosCrearEstructura;
      return { ...state, ...action.payload };
    },
  },
});
export const {
  onOpenModalEliminarEstructura,
  onStartLoadingEstructura,
  onSetEstructuras,
  onSuccessDeleteEstructura,
  onOpenModalAgregarNombreNodoSaldo,
  onOpenModalEditarNombreNodoSaldo,
  onOpenModalAgregarNombreNodoIngreso,
  onOpenModalEditarNombreNodoIngreso,
  onSetSubAgrupadorCrearEstructura,
  onOpenModalAgregarNombreNodoEgreso,
  onOpenModalEditarNombreNodoEgreso,
  onChangeCodigoCrearEstructura,
  onSetValidCodigoCrearEstructura,
  onChangeNombreCrearEstructura,
  onSetValidNombreCrearEstructura,
  onChangeDetalleCrearEstructura,
  onSetValidDetalleCrearEstructura,
  onSetConceptosXtipoSaldoCrearEstructura,
  onSetConceptosXtipoIngresoCrearEstructura,
  onSetConceptosXtipoEgresoCrearEstructura,
  onLimpiarCamposCrearEstructura,
  onSetRespuestaServicioEstructura,
  onLimpiarRespuestaServicioEstructura,
  onChangeTipoCrearEstructura,
  onSetValidTipoEstructura,
  onSetListaTipoConceptos,
  onSetListaConceptosEstructura,
  onChangeTipoEstructuraCrearEstructura,
  onSetEstructuraPrincipal,
  filterListarEstructuras,
  onSetDetalleEditarEstructura,
  onSetDetalleCrearCopiaEstructura,
  onOpenModalAgregarEstructura,
  onSetDataModalAgregarEstructura,
} = estructuraSlice.actions;
