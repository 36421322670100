import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { changeNodeAtPath } from 'react-sortable-tree';
import { useDispatch, useSelector } from 'react-redux';
import { ColForm, RowForm, Title } from '../../../../../styles';
import { InputText, Button, SelectForm, LabelForm } from '../../../../../components';
import Swal from 'sweetalert2';

export const ModalEditarNodo = (props) => {
  const estructuraState = useSelector((store) => store.estructura);
  const { aListaConceptosXtipoEstructura } = estructuraState;

  const onCloseModal = (e) => {
    props.setOpen(false);
  };

  const onEditar = (e) => {
    var sNombreNodoSaldo = '';
    if (props.formEditarNodo.nombreNodo === '' && props.formEditarNodo.iIdConceptoNodo == null) {
      Swal.fire({
        icon: 'warning',
        title: 'Seleccione el concepto o Ingrese el nombre.',
      });
    } else {
      if (props.formEditarNodo.nombreNodo === '') {
        sNombreNodoSaldo = props.formEditarNodo.sConceptoNodo;
      } else {
        sNombreNodoSaldo = props.formEditarNodo.nombreNodo;
      }
      const UPDATE_NODE = {
        title: sNombreNodoSaldo,
        iIdConceptoNodo: props.formEditarNodo.iIdConceptoNodo,
        sConceptoNodo: props.formEditarNodo.sConceptoNodo,
        expanded: false,
      };
      var path = props.newRowInfoTree.path;
      props.setTreeState({
        ...props.treeState,
        treeData: changeNodeAtPath({
          treeData: props.treeState.treeData,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: { ...props.newRowInfoTree.node, ...UPDATE_NODE },
        }),
      });
      props.setOpen(false);
    }
  };

  const handleChangeConceptoXtipoSaldo = (oEvent) => {
    var oParam = {};
    oParam.iIdConceptoNodo = null;
    oParam.sConceptoNodo = '';
    oParam.nombreNodo = '';
    oParam.error = false;
    if (oEvent) {
      oParam.iIdConceptoNodo = oEvent.value;
      oParam.sConceptoNodo = oEvent.label;
      oParam.nombreNodo = oEvent.label;
      if (
        props.formEditarNodo.nodeInfo.node.children &&
        props.formEditarNodo.nodeInfo.node.children.length > 0
      ) {
        var aListaPosicionesDiversas = props.formEditarNodo.nodeInfo.node.children.filter(function (
          item,
          index
        ) {
          return (
            'P' === item.tipo &&
            item.iIdConceptoNodo !== oEvent.value &&
            item.iIdConceptoNodo != null
          ); // GNM@7DIC
        });
        var aListaHijo = props.formEditarNodo.nodeInfo.node.children.filter(function (item, index) {
          return 'C' === item.tipo;
        });
        if (aListaPosicionesDiversas.length > 0) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text:
              'Existen carpetas u posiciones que no tienen relación al concepto ' +
              props.formEditarNodo.nodeInfo.node.sConceptoNodo,
          });
        } else {
          if (aListaHijo.length > 0) {
            var bConceptoDiverso = validarConceptosCadenaArbol(aListaHijo, false, oEvent.value);
            var msg;
            if (props.formEditarNodo.nodeInfo.node.iIdConceptoNodo) {
              msg =
                'Existen carpetas o posiciones que no tienen relación al concepto ' +
                props.formEditarNodo.nodeInfo.node.sConceptoNodo;
            } else {
              msg = 'Existen carpetas o posiciones con diversos conceptos a ' + oEvent.label;
            }
            if (bConceptoDiverso) {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: msg,
              });
            } else {
              props.setFormEditarNodo({ ...props.formEditarNodo, ...oParam });
            }
          }
        }
      } else {
        props.setFormEditarNodo({ ...props.formEditarNodo, ...oParam });
      }
    } else {
      if (props.formEditarNodo.nombreNodo !== '') {
        oParam.error = false;
      }

      props.setFormEditarNodo({ ...props.formEditarNodo, ...oParam });
    }
  };

  const handleChangeNombreNodoSaldo = (oEvent) => {
    var sNombreNodoSaldo = oEvent.target.value;
    props.setFormEditarNodo({
      ...props.formEditarNodo,
      nombreNodo: sNombreNodoSaldo,
      error: false,
    });
  };

  const validarConceptosCadenaArbol = (aListaHijos, bConceptoDiverso, iIdConceptoNodo) => {
    if (!bConceptoDiverso) {
      for (let index = 0; index < aListaHijos.length; index++) {
        const element = aListaHijos[index];
        if (element.iIdConceptoNodo != iIdConceptoNodo && element.iIdConceptoNodo != null) {
          bConceptoDiverso = true;
          return bConceptoDiverso;
        } else {
          if (element.children == null) {
            validarConceptosCadenaArbol([], bConceptoDiverso, iIdConceptoNodo);
          } else {
            validarConceptosCadenaArbol(element.children, bConceptoDiverso, iIdConceptoNodo);
          }
        }
      }
    }
  };

  return (
    <Modal
      open={props.open}
      title={<Title>Editar Carpeta</Title>}
      closable={false}
      footer={[
        <Button action={onCloseModal} name="Cancelar" isAction={true} type="warning" />,
        <Button
          style={{ marginLeft: '5px' }}
          action={onEditar}
          type="secondary"
          isAction={true}
          name="Aceptar"
        />,
      ]}
    >
      <>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Concepto :'} />

            <SelectForm
              value={
                props.formEditarNodo.iIdConceptoNodo == null
                  ? []
                  : [
                      {
                        value: props.formEditarNodo.iIdConceptoNodo,
                        label: props.formEditarNodo.sConceptoNodo,
                      },
                    ]
              }
              handleChange={(e, v) => handleChangeConceptoXtipoSaldo(v)}
              options={aListaConceptosXtipoEstructura}
              placeholder={'Seleccione Concepto'}
              isDisabled={props.formEditarNodo.isDisabled}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Nombre :'} />
            <InputText
              placeholder="Ingrese..."
              value={props.formEditarNodo.nombreNodo}
              onChange={(evnt) => handleChangeNombreNodoSaldo(evnt)}
            />
          </RowForm>
        </ColForm>
      </>
    </Modal>
  );
};
