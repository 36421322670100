import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export const CustomDateRangePicker = (props) => (
  <ContainerDatePicker>
    <DatePickerFecha
      width={props.width}
      dateFormat="dd/MM/yyyy"
      selectsRange={true}
      startDate={props.startDate}
      endDate={props.endDate}
      onChange={props.onChange}
      isClearable={true}
      placeholderText="Ingrese"
      locale="es"
      showMonthDropdown
      showYearDropdown
      portalId="root-portal"
      validate={props.validate}
    />
  </ContainerDatePicker>
);
const ContainerDatePicker = styled.div`
  display: flex;
  align-items: center;
`;
const DatePickerFecha = styled(DatePicker)`
  width: ${({ width }) => (width ? width : '320px')}; //320px !important;
  display: flex;
  align-items: center;
  border: 1px solid white;
  transition: width 0.5s;
  height: 40px;
  font-size: 12px;
  margin-left: 0px;
  padding: ${({ active }) => (true ? '0 10px' : '0px')};
  opacity: ${({ active }) => (true ? '1' : '0')};
  width: ${({ active }) => (true ? '320px' : '0px')};
  &:focus {
    outline: none !important;
    border-color: ${({ validate }) =>
      validate == null ? '#F6F6F6' : validate === true ? '#F6F6F6' : 'red'};
  }
  background: #f6f6f6;
  text-align: center;
  border-radius: 8px;
  border-color: ${({ validate }) =>
    validate == null ? 'none' : validate === true ? 'none' : 'red'};
  transition: ${({ validate }) =>
    validate == null ? 'none' : validate === true ? 'none' : 'border-color 0.5s ease-out'};
`;
