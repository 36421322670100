import { createSlice, current } from '@reduxjs/toolkit';
export const automatizacionInitialState = {
  searchtext: '',
  listaAutomatizaciones: [],
  filterListaAutomatizaciones: [],
  //Formulario
  idJob: '',
  bValidTipo: null,
  selectTipo: [],
  bValidEstado: null,
  selectEstado: [],
  sNombre: '',
  bValidNombre: null,
  sDetalle: '',
  bValidDetalle: null,
  bValidModalidad: null,
  selectModalidad: [],
  listaModalidadDatos: [],
  bValidModalidadDatos: null,
  selectModalidadDatos: [],
  bValidFrecuencia: null,
  selectFrecuencia: [],
  sFrecuenciaNumero: '',
  bValidFrecuenciaNumero: null,
  dFechaInicio: [],
  bValidFechaInicio: null,
  dDateTimeInicio: '',
  dFechaFin: [],
  bValidFechaFin: null,
  dDateTimeFin: '',
  bValidDependencias: null,
  selectDependencias: [],
};
export const automatizacionSlice = createSlice({
  name: 'automatizacion',
  initialState: automatizacionInitialState,
  reducers: {
    filterListarAutomatizaciones: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaAutomatizaciones = estadoInspeccionado.listaAutomatizaciones.filter(
        function (item, index) {
          return (
            item.sNombreAutomatizacion.toLowerCase().includes(action.payload.toLowerCase()) ||
            item.sDetalleAutomatizacion.toLowerCase().includes(action.payload.toLowerCase()) ||
            item.sEsPrincipal.toLowerCase().includes(action.payload.toLowerCase())
          );
        }
      );
      var obj = {
        filterListaAutomatizaciones: filterListaAutomatizaciones,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setListarAutomatizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDatosCrearAutomatizacion: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaModalidadDatos: (state, action) => {
      let obj = {
        listaModalidadDatos: action.payload,
      };
      return { ...state, ...obj };
    },
    setLimpiarAutomatizacion: (state, action) => {
      return { ...state, ...automatizacionInitialState };
    },
  },
});
export const {
  filterListarAutomatizaciones,
  setListarAutomatizacion,
  setDatosCrearAutomatizacion,
  setListaModalidadDatos,
  setLimpiarAutomatizacion,
} = automatizacionSlice.actions;
export default automatizacionSlice.reducer;
