import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
export const authInitialState = {
  status: 'checking',
  user: {
    name: '',
    uid: '',
    logged: false,
    user: '',
    foto: '',
    permisos: [],
  },
  tenant: '',
  errorMessage: undefined,
  logged: true,
};
export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    onChecking: (state) => {
      state.status = 'checking';
      state.user = authInitialState.user;
      state.errorMessage = undefined;
      state.logged = undefined;
    },
    onLogin: (state, { payload }) => {
      state.status = 'authenticated';
      state.user = payload;
      state.errorMessage = undefined;
      state.logged = true;
    },
    onTenant: (state, { payload }) => {
      state.tenant = payload;
    },
    onLogout: (state, { payload }) => {
      state.status = 'not-authenticated';
      state.user = authInitialState.user;
      state.errorMessage = payload;
      state.logged = undefined;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = undefined;
    },
  },
});
export const { onChecking, onLogin, onTenant, onLogout, clearErrorMessage } = authSlice.actions;
export default authSlice.reducer;
