import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  onOpenModalVersionFlujoCaja,
  onSelectVersionFlujoCaja,
  onSetDetalleVersionFlujoCaja,
  onSetParametrosFlujoCaja,
} from '../../../../../../redux/states';
import { Button, LabelForm, LoadingSpinner, SelectForm } from '../../../../../../components';
import {
  deleteVersionFlujoCaja,
  obtenerDetalleVersionFlujoCaja,
} from '../../../../../../services/public.service';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import { createDetalleVersionFlujoCajaAdapter } from '../../../../../../adapters/detalleVersionFlujoCaja.adapter';
import { ColForm, RowForm } from '../../../../../../styles';
import Swal from 'sweetalert2';
import { theme } from '../../../../../../common';

export const ModalVersion = (props) => {
  const dispatch = useDispatch();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { modalOpenVersionFlujoCaja, listaVersionesFlujoCaja } = flujoCajaState;

  const onCloseModal = (e) => {
    dispatch(onOpenModalVersionFlujoCaja(false));
  };

  const handleChangeVersion = async (oEvent) => {
    var oParam = {};
    oParam.bValidVersionFlujoCaja = null;
    oParam.selectVersionFlujoCaja = [];
    if (oEvent) {
      oParam.selectVersionFlujoCaja = [{ value: oEvent.value, label: oEvent.label }];
      oParam.bValidVersionFlujoCaja = true;
      const responseObtenerDetalleVersionFlujoCaja = await callEndpoint(
        obtenerDetalleVersionFlujoCaja({
          oIDVersion: {
            IDVersion: oEvent.value,
          },
        })
      );
      var aListaDetalle = responseObtenerDetalleVersionFlujoCaja.data.respuesta.aListaDetalle;
      var oDetalleversion = {};
      if (aListaDetalle.length > 0) {
        oDetalleversion = createDetalleVersionFlujoCajaAdapter(aListaDetalle[0]);
        dispatch(onSetDetalleVersionFlujoCaja({ oDetalleversion: oDetalleversion }));
      }
    }
    dispatch(onSelectVersionFlujoCaja(oParam));
  };

  const onAplicarVersion = (oEvent) => {
    let oDetalleversion = flujoCajaState.oDetalleversion;
    var aFechaInicio = oDetalleversion.sFechaInicio.split('/');
    let aFechaFin = oDetalleversion.sFechaFin.split('/');
    let selectEtiquetaFlujoCaja = [];
    if (oDetalleversion.ListaEtiquetas.length > 0) {
      var aListaEtiquetas = oDetalleversion.ListaEtiquetas.split('-');
      aListaEtiquetas.forEach((element, index) => {
        var aEncontrado = flujoCajaState.listarEtiquetasFlujoCaja.filter(
          (item) => item.IDEtiqueta === parseInt(element, 10)
        );
        if (aEncontrado.length > 0) {
          selectEtiquetaFlujoCaja.push(aEncontrado[0]);
        }
      });
    }
    var oCabeceras = {
      sVersion: oDetalleversion.sNombreVersion,
      iIdEstructura: oDetalleversion.iIdEstructuraBase,
      sEstructura: oDetalleversion.sEstructuraBase,
      bValidEstructura: true,
      sNombre: oDetalleversion.sNombreVersion,
      bValidNombre: true,
      iIdFechaPeriodo: oDetalleversion.iIdPeridiocidad,
      sCodFechaPeriodo: oDetalleversion.sCodFechaPeriodo,
      sFechaPeriodo: oDetalleversion.sPeridiocidad,
      bValidFechaPeriodo: true,
      iIdMoneda: oDetalleversion.iIdMoneda,
      sMoneda: oDetalleversion.sMoneda,
      sAbreviatura: oDetalleversion.sAbreviatura ?? '',
      bValidMoneda: true,
      dDateRange: [
        new Date(aFechaInicio[2] + '/' + aFechaInicio[1] + '/' + aFechaInicio[0]),
        new Date(aFechaFin[2] + '/' + aFechaFin[1] + '/' + aFechaFin[0]),
      ],
      sFechaInicio: oDetalleversion.sFechaInicio,
      sFechaFinal: oDetalleversion.sFechaFin,
      bValidFecha: true,
      selectEtiquetaFlujoCaja: selectEtiquetaFlujoCaja,
    };
    // return;
    dispatch(onSetParametrosFlujoCaja(oCabeceras));
    dispatch(onOpenModalVersionFlujoCaja(false));
  };

  const requestDeleteVersion = (e, data) => {
    Swal.fire({
      title: '¿Desea eliminar la versión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        var oParam = {
          DeleteVersionFlujoCaja: {
            iIdVersion: data.iIdVersion,
          },
        };
        var responseCrearVersionFlujoCaja = await callEndpoint(deleteVersionFlujoCaja(oParam));
        if (responseCrearVersionFlujoCaja == null) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          if (responseCrearVersionFlujoCaja.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            await props.onPressBuscarVersion();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={modalOpenVersionFlujoCaja}
      onHide={onCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Listado de Versiones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm width={'80px'} title={'Codigo :'} />
            {/* <SelectVersionOptions
              width={'320px'}
              bValid={true}
              oSelect={flujoCajaState.selectVersionFlujoCaja}
              onChange={(e) => handleChangeVersion(e)}
              options={listaVersionesFlujoCaja}
              placeholder={'Seleccione Versión'}
              onPressBuscarVersion={props.onPressBuscarVersion}
              colorIcon="grey"
            ></SelectVersionOptions> */}
            <SelectForm
              width={'320px'}
              bValid={true}
              value={flujoCajaState.selectVersionFlujoCaja}
              handleChange={(e, v) => handleChangeVersion(v)}
              options={listaVersionesFlujoCaja}
              placeholder={'Seleccione Versión'}
              onPressBuscarVersion={props.onPressBuscarVersion}
              colorIcon="grey"
              buttonDelete={true}
              handleDelete={requestDeleteVersion}
            />
          </RowForm>
        </ColForm>
      </Modal.Body>
      <Modal.Footer>
        <Button action={onAplicarVersion} name="Aceptar" isAction={true} type="primary" />
        <Button action={onCloseModal} name="Cancelar" isAction={false} type="warning" />
      </Modal.Footer>

      {loading ? <LoadingSpinner /> : <></>}
    </Modal>
  );
};
