export const listaAcceso = [
  { codigo: '7c49df50-915d-4145-a2fe-acb0a48d2bb2', descripcion: 'Usuario - Ver' },
  { codigo: 'a1c0d803-669f-4e83-b032-650fcf8574a8', descripcion: 'Rol - Ver' },
  { codigo: '1474b009-012f-401a-97c6-00df18957fe1', descripcion: 'Automatizacion - Ver' },
  { codigo: 'f2e0cd22-34e5-4955-aa0b-2be9225a9ca4', descripcion: 'Personalizacion - Ver' },
  { codigo: '380f98b4-9896-4b11-b853-35faa8d50de0', descripcion: 'Sistemas - Ver' },
  { codigo: 'e3523222-f208-42e7-a81f-909fe0abbffb', descripcion: 'Sistemas - Crear' },
  { codigo: '0c6c1bc9-207c-44a1-9856-1924980aa815', descripcion: 'Sistemas - Editar' },
  { codigo: '1900fd25-c6a8-4735-9299-00f5448a8566', descripcion: 'Sistemas - Eliminar' },
  { codigo: 'c16a57f3-845b-4b0b-bd3c-6e1fa06305f3', descripcion: 'Campos - Ver' },
  { codigo: '8ae98557-538b-4f61-8133-1b26700a9395', descripcion: 'Campos - Crear' },
  { codigo: '28f498f4-39ce-4f3e-8cf0-615875f4f84c', descripcion: 'Campos - Editar' },
  { codigo: '63f19fc0-9ff3-4d6e-bfcf-e61224a3e5ed', descripcion: 'Campos - Eliminar' },
  { codigo: '26b44b41-c6bb-4b34-98b0-c22811557de8', descripcion: 'Funcion - Ver' },
  { codigo: '0aae0e6c-eb0c-4608-bdd6-38203160827f', descripcion: 'Funcion - Crear' },
  { codigo: 'e5a18ed0-7406-44ea-92ae-1c28276f5c73', descripcion: 'Funcion - Editar' },
  { codigo: '48e288b7-5b37-405f-a8fc-96b5de79df57', descripcion: 'Funcion - Eliminar' },
  { codigo: '91ecb586-d874-44a3-acc4-21835f98ffb6', descripcion: 'Concepto - Ver' },
  { codigo: '144bb075-eaab-45b8-abc7-b96e6c4e68e6', descripcion: 'Concepto - Crear' },
  { codigo: '6ccf291a-9f00-470d-a90b-67f407ef7477', descripcion: 'Concepto - Editar' },
  { codigo: 'e8c93da3-c244-4295-a245-e2ac32804957', descripcion: 'Concepto - Eliminar' },
  { codigo: '2d68ef0c-aa67-423d-9e38-7da202188100', descripcion: 'Posicion - Ver' },
  { codigo: 'd4d439e2-b8fb-458a-9dcd-b345558652dc', descripcion: 'Posicion - Crear' },
  { codigo: 'ef053a08-2d61-411c-8af7-4e3bf26bc263', descripcion: 'Posicion - Editar' },
  { codigo: 'c548fb9a-92cb-4769-ac5b-d77fe9dbc9c6', descripcion: 'Posicion - Eliminar' },
  { codigo: '88d5e84e-bec8-4252-a8b1-c272acba147b', descripcion: 'Estructura - Ver' },
  { codigo: '13f42e9b-8f23-4d67-84da-b2719ee1171e', descripcion: 'Estructura - Crear' },
  { codigo: '5ba7a6be-2b25-4d2b-90a2-e1674420878f', descripcion: 'Estructura - Editar' },
  { codigo: '03c74a1c-20fe-4293-afaf-ddb1faef5b05', descripcion: 'Estructura - Eliminar' },
  { codigo: '98114ee3-dcec-4481-8ac7-4f27f460fd3b', descripcion: 'TipoCambio - Ver' },
  { codigo: '63b70dda-3d4b-46c5-b9bd-f9c99b914fe3', descripcion: 'Derivacion - Ver' },
  { codigo: 'd8311eda-0d8b-4096-9da7-dc14dc52b5a5', descripcion: 'Socios - Ver' },
  { codigo: '9d7379d8-6738-4547-bf98-300907b05f57', descripcion: 'Documentos - Ver' },
  { codigo: 'a0fdaf1b-7bad-4cda-b9c1-cfa6f27b6e90', descripcion: 'Soluciones - Ver' },
  { codigo: '88a647b1-d07c-4da1-aa52-d16f289f63ed', descripcion: 'Soluciones - Crear' },
  { codigo: '2b17a7b1-e8b8-45dc-aba8-54d97124b85d', descripcion: 'Soluciones - Editar' },
  { codigo: 'a293ce9a-f61c-4c02-8f5a-4136b3cf778c', descripcion: 'Soluciones - Eliminar' },
  { codigo: 'c9777b45-2082-498f-a369-52a7fcb6b269', descripcion: 'Flujo de Caja - Ver' },
  { codigo: '0cd1642d-edf8-4d95-96ee-f33ce92db6a2', descripcion: 'Flujo de Caja - Crear' },
  { codigo: '05a48963-b4f9-435b-9b87-d104288bf35c', descripcion: 'Flujo de Caja - Editar' },
  { codigo: '53e14e24-5255-40f9-ad5d-a95cc239605e', descripcion: 'Flujo de Caja - Eliminar' },
  { codigo: '82eb0e75-0262-46a7-985f-f443bb5ae2c7', descripcion: 'Registro fuente - Ver' },
  { codigo: 'c39ff281-baeb-46b1-bdf0-65997d2227e5', descripcion: 'Registro propio - Ver' },
  { codigo: '710859f2-edd0-4bb4-b3e0-9613d3524ce4', descripcion: 'Gestion - Ver' },
  { codigo: 'c9284003-5927-41ee-af47-3feba5e2dfbf', descripcion: 'Notificaciones - Ver' },
  {
    codigo: '150c580c-37b4-4b32-991d-9bc753ce48ca',
    descripcion: 'flujo de Caja - Compartir registro',
  },
  {
    codigo: '62e93d3d-483e-47e3-b1f5-eebe7dcae529',
    descripcion: 'Dashboard - Ver',
  },
];

export const listaAccesoPr = [
  {
    key: 'G.0',
    title: 'Dashboard',
    children: [{ key: '62e93d3d-483e-47e3-b1f5-eebe7dcae529', title: 'Ver' }],
  },
  {
    key: 'G.1',
    title: 'Administración',
    children: [
      {
        key: 'G.1.1',
        title: 'Usuario',
        children: [{ key: '7c49df50-915d-4145-a2fe-acb0a48d2bb2', title: 'Ver' }],
      },
      {
        key: 'G.1.2',
        title: 'Rol',
        children: [{ key: 'a1c0d803-669f-4e83-b032-650fcf8574a8', title: 'Ver' }],
      },
    ],
  },
  {
    key: 'G.2',
    title: 'Automatización',
    children: [{ key: '1474b009-012f-401a-97c6-00df18957fe1', title: 'Ver' }],
  },
  {
    key: 'G.3',
    title: 'Personalización',
    children: [{ key: 'f2e0cd22-34e5-4955-aa0b-2be9225a9ca4', title: 'Ver' }],
  },
  {
    key: 'G.4',
    title: 'Configuración',
    children: [
      {
        key: 'G.4.1',
        title: 'Sistemas',
        children: [
          { key: '380f98b4-9896-4b11-b853-35faa8d50de0', title: 'Ver' },
          { key: 'e3523222-f208-42e7-a81f-909fe0abbffb', title: 'Crear' },
          { key: '0c6c1bc9-207c-44a1-9856-1924980aa815', title: 'Editar' },
          { key: '1900fd25-c6a8-4735-9299-00f5448a8566', title: 'Eliminar' },
        ],
      },
      {
        key: 'G.4.2',
        title: 'Campos',
        children: [
          { key: 'c16a57f3-845b-4b0b-bd3c-6e1fa06305f3', title: 'Ver' },
          { key: '8ae98557-538b-4f61-8133-1b26700a9395', title: 'Crear' },
          { key: '28f498f4-39ce-4f3e-8cf0-615875f4f84c', title: 'Editar' },
          { key: '63f19fc0-9ff3-4d6e-bfcf-e61224a3e5ed', title: 'Eliminar' },
        ],
      },
      {
        key: 'G.4.3',
        title: 'Función',
        children: [
          { key: '26b44b41-c6bb-4b34-98b0-c22811557de8', title: 'Ver' },
          { key: '0aae0e6c-eb0c-4608-bdd6-38203160827f', title: 'Crear' },
          { key: 'e5a18ed0-7406-44ea-92ae-1c28276f5c73', title: 'Editar' },
          { key: '48e288b7-5b37-405f-a8fc-96b5de79df57', title: 'Eliminar' },
        ],
      },
      {
        key: 'G.4.4',
        title: 'Concepto',
        children: [
          { key: '91ecb586-d874-44a3-acc4-21835f98ffb6', title: 'Ver' },
          { key: '144bb075-eaab-45b8-abc7-b96e6c4e68e6', title: 'Crear' },
          { key: '6ccf291a-9f00-470d-a90b-67f407ef7477', title: 'Editar' },
          { key: 'e8c93da3-c244-4295-a245-e2ac32804957', title: 'Eliminar' },
        ],
      },
      {
        key: 'G.4.5',
        title: 'Posición',
        children: [
          { key: '2d68ef0c-aa67-423d-9e38-7da202188100', title: 'Ver' },
          { key: 'd4d439e2-b8fb-458a-9dcd-b345558652dc', title: 'Crear' },
          { key: 'ef053a08-2d61-411c-8af7-4e3bf26bc263', title: 'Editar' },
          { key: 'c548fb9a-92cb-4769-ac5b-d77fe9dbc9c6', title: 'Eliminar' },
        ],
      },
      {
        key: 'G.4.6',
        title: 'Estructura',
        children: [
          { key: '88d5e84e-bec8-4252-a8b1-c272acba147b', title: 'Ver' },
          { key: '13f42e9b-8f23-4d67-84da-b2719ee1171e', title: 'Crear' },
          { key: '5ba7a6be-2b25-4d2b-90a2-e1674420878f', title: 'Editar' },
          { key: '03c74a1c-20fe-4293-afaf-ddb1faef5b05', title: 'Eliminar' },
        ],
      },
      {
        key: 'G.4.7',
        title: 'Tipo de Cambio',
        children: [{ key: '98114ee3-dcec-4481-8ac7-4f27f460fd3b', title: 'Ver' }],
      },
      {
        key: 'G.4.8',
        title: 'Derivación',
        children: [{ key: '63b70dda-3d4b-46c5-b9bd-f9c99b914fe3', title: 'Ver' }],
      },
    ],
  },
  {
    key: 'G.5',
    title: 'Socio de negocio',
    children: [
      {
        key: 'G.5.1',
        title: 'Socios',
        children: [{ key: 'd8311eda-0d8b-4096-9da7-dc14dc52b5a5', title: 'Ver' }],
      },
      {
        key: 'G.5.2',
        title: 'Documentos',
        children: [{ key: '9d7379d8-6738-4547-bf98-300907b05f57', title: 'Ver' }],
      },
    ],
  },
  {
    key: 'G.6',
    title: 'Soluciones',
    children: [
      { key: 'a0fdaf1b-7bad-4cda-b9c1-cfa6f27b6e90', title: 'Ver' },
      { key: '88a647b1-d07c-4da1-aa52-d16f289f63ed', title: 'Crear' },
      { key: '2b17a7b1-e8b8-45dc-aba8-54d97124b85d', title: 'Editar' },
      { key: 'a293ce9a-f61c-4c02-8f5a-4136b3cf778c', title: 'Eliminar' },
    ],
  },
  {
    key: 'G.7',
    title: 'Flujo de caja',
    children: [
      {
        key: 'G.7.1',
        title: 'Registro fuente',
        children: [{ key: '82eb0e75-0262-46a7-985f-f443bb5ae2c7', title: 'Ver' }],
      },
      {
        key: 'G.7.2',
        title: 'Registro propio',
        children: [{ key: 'c39ff281-baeb-46b1-bdf0-65997d2227e5', title: 'Ver' }],
      },
      {
        key: 'G.7.3',
        title: 'Reporte',
        children: [
          { key: 'c9777b45-2082-498f-a369-52a7fcb6b269', title: 'Ver' },
          { key: '0cd1642d-edf8-4d95-96ee-f33ce92db6a2', title: 'Crear' },
          { key: '05a48963-b4f9-435b-9b87-d104288bf35c', title: 'Editar' },
          { key: '53e14e24-5255-40f9-ad5d-a95cc239605e', title: 'Eliminar' },
        ],
      },
      {
        key: '150c580c-37b4-4b32-991d-9bc753ce48ca',
        title: 'Compartir Registro',
      },
      {
        key: 'G.7.5',
        title: 'Gestión',
        children: [{ key: '710859f2-edd0-4bb4-b3e0-9613d3524ce4', title: 'Ver' }],
      },
    ],
  },
  {
    key: 'G.8',
    title: 'Notificaciones',
    children: [{ key: 'c9284003-5927-41ee-af47-3feba5e2dfbf', title: 'Ver' }],
  },
];

export const permisos = {
  VER_DASHBOARD: '62e93d3d-483e-47e3-b1f5-eebe7dcae529',
  VER_USUARIO: '7c49df50-915d-4145-a2fe-acb0a48d2bb2',
  VER_ROL: 'a1c0d803-669f-4e83-b032-650fcf8574a8',
  VER_AUTOMATIZACION: '1474b009-012f-401a-97c6-00df18957fe1',
  VER_PERSONALIZACION: 'f2e0cd22-34e5-4955-aa0b-2be9225a9ca4',
  VER_SISTEMAS: '380f98b4-9896-4b11-b853-35faa8d50de0',
  CREAR_SISTEMAS: 'e3523222-f208-42e7-a81f-909fe0abbffb',
  EDITAR_SISTEMAS: '0c6c1bc9-207c-44a1-9856-1924980aa815',
  ELIMINAR_SISTEMAS: '1900fd25-c6a8-4735-9299-00f5448a8566',
  VER_CAMPOS: 'c16a57f3-845b-4b0b-bd3c-6e1fa06305f3',
  CREAR_CAMPOS: '8ae98557-538b-4f61-8133-1b26700a9395',
  EDITAR_CAMPOS: '28f498f4-39ce-4f3e-8cf0-615875f4f84c',
  ELIMINAR_CAMPOS: '63f19fc0-9ff3-4d6e-bfcf-e61224a3e5ed',
  VER_FUNCION: '26b44b41-c6bb-4b34-98b0-c22811557de8',
  CREAR_FUNCION: '0aae0e6c-eb0c-4608-bdd6-38203160827f',
  EDITAR_FUNCION: 'e5a18ed0-7406-44ea-92ae-1c28276f5c73',
  ELIMINAR_FUNCION: '48e288b7-5b37-405f-a8fc-96b5de79df57',
  VER_CONCEPTO: '91ecb586-d874-44a3-acc4-21835f98ffb6',
  CREAR_CONCEPTO: '144bb075-eaab-45b8-abc7-b96e6c4e68e6',
  EDITAR_CONCEPTO: '6ccf291a-9f00-470d-a90b-67f407ef7477',
  ELIMINAR_CONCEPTO: 'e8c93da3-c244-4295-a245-e2ac32804957',
  VER_POSICION: '2d68ef0c-aa67-423d-9e38-7da202188100',
  CREAR_POSICION: 'd4d439e2-b8fb-458a-9dcd-b345558652dc',
  EDITAR_POSICION: 'ef053a08-2d61-411c-8af7-4e3bf26bc263',
  ELIMINAR_POSICION: 'c548fb9a-92cb-4769-ac5b-d77fe9dbc9c6',
  VER_ESTRUCTURA: '88d5e84e-bec8-4252-a8b1-c272acba147b',
  CREAR_ESTRUCTURA: '13f42e9b-8f23-4d67-84da-b2719ee1171e',
  EDITAR_ESTRUCTURA: '5ba7a6be-2b25-4d2b-90a2-e1674420878f',
  ELIMINAR_ESTRUCTURA: '03c74a1c-20fe-4293-afaf-ddb1faef5b05',
  VER_TIPOCAMBIO: '98114ee3-dcec-4481-8ac7-4f27f460fd3b',
  VER_DERIVACION: '63b70dda-3d4b-46c5-b9bd-f9c99b914fe3',
  VER_SOCIOS: 'd8311eda-0d8b-4096-9da7-dc14dc52b5a5',
  VER_DOCUMENTOS: '9d7379d8-6738-4547-bf98-300907b05f57',
  VER_SOLUCIONES: 'a0fdaf1b-7bad-4cda-b9c1-cfa6f27b6e90',
  CREAR_SOLUCIONES: '88a647b1-d07c-4da1-aa52-d16f289f63ed',
  EDITAR_SOLUCIONES: '2b17a7b1-e8b8-45dc-aba8-54d97124b85d',
  ELIMINAR_SOLUCIONES: 'a293ce9a-f61c-4c02-8f5a-4136b3cf778c',
  VER_FLUJO_CAJA: 'c9777b45-2082-498f-a369-52a7fcb6b269',
  CREAR_FLUJO_CAJA: '0cd1642d-edf8-4d95-96ee-f33ce92db6a2',
  EDITAR_FLUJO_CAJA: '05a48963-b4f9-435b-9b87-d104288bf35c',
  ELIMINAR_FLUJO_CAJA: '53e14e24-5255-40f9-ad5d-a95cc239605e',
  VER_REGISTRO_FUENTE: '82eb0e75-0262-46a7-985f-f443bb5ae2c7',
  VER_REGISTRO_PROPIO: 'c39ff281-baeb-46b1-bdf0-65997d2227e5',
  COMPARTIR_REGISTRO: '150c580c-37b4-4b32-991d-9bc753ce48ca',
  VER_GESTION: '710859f2-edd0-4bb4-b3e0-9613d3524ce4',
  VER_NOTIFICACIONES: 'c9284003-5927-41ee-af47-3feba5e2dfbf',
};
