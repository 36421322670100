import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { ContextMenuTrigger, ContextMenu, ContextMenuItem, Submenu } from 'rctx-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  onChangeCollapseDetalleListaAgrupadaXSoluciones,
  onSetDetalleListaAgrupadaXSoluciones,
} from '../../../../../../redux/states';
import { useFetchAndLoad } from '../../../../../../common/hooks';

import collect from 'collect.js';
import {
  Content,
  NumberTable,
  NumberTotalTable,
  TableContain,
  TableFC,
} from '../../../../../../styles';

export const TableDetalleFlujoCajaSolucion = React.memo(function TableDetalleFlujoCajaSolucion(
  props
) {
  const dispatch = useDispatch();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const { oFlujoCajaAgregar } = flujoCajaState;
  const [objDrag, setObjDrag] = useState({});

  const handleClick = (oEvent, item, index) => {
    dispatch(onChangeCollapseDetalleListaAgrupadaXSoluciones({ index: index }));
    return;
    const rowsInput = [...flujoCajaState.detalleListaAgrupadaXSoluciones];
    const oItem = rowsInput[index];
    oItem['collapseIcon'] = !oItem['collapseIcon'];
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(oItem.Id + '-') && element.Id !== oItem.Id;
    });
    var aEncontrado2 = rowsInput.filter((element) => {
      return (
        element.Id.includes(oItem.Id + '-') &&
        element.Id !== oItem.Id &&
        oItem.Id.split('-').length + 1 === element.Id.split('-').length
      );
    });
    if (aEncontrado.length > 0) {
      aEncontrado2.forEach((element) => {
        element.collapse = !element.collapse;
        // element["collapseIcon"]  = !element["collapseIcon"]
        if (element.collapse) {
          element['collapseIcon'] = true;
        } else {
          element['collapseIcon'] = false;
        }
        var aEncontrado = rowsInput.filter((elemen2) => {
          return elemen2.Id.includes(element.Id + '-') && elemen2.Id !== element.Id;
        });
        aEncontrado.forEach((element) => {
          element.collapse = true;
          element['collapseIcon'] = false;
        });
      });
    }
    // props.setFinalArray(rowsInput);
    dispatch(onSetDetalleListaAgrupadaXSoluciones({ detalleListaAgrupadaXSoluciones: rowsInput }));
  };

  const onDragStart = (e, item2, index, oItem, indexPadre) => {
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', item2.Valor);
    setObjDrag({
      item2,
      indexPartida: index,
      oItem,
      indexPadre,
      e,
    });
  };

  const obtenerCabecera = (nuevoId, array) => {
    var aId = nuevoId.split('-');
    if (aId.length >= 2) {
      var sId = '';
      for (let index = 0; index < aId.length; index++) {
        const element = aId[index];
        if (index < aId.length - 1) {
          if (index === 0) {
            sId = element;
          } else {
            sId = sId + '-' + element;
          }
        }
      }
      array.push(sId);
      return obtenerCabecera(sId, array);
    }
    return array;
  };

  const obtenerHijos = (rowsInput, nuevoId) => {
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(nuevoId) && element.Id !== nuevoId;
    });
    return aEncontrado;
  };

  const findPadreXCaracteristica = (person) => {
    return !!props.selectedDesdobles.find((p) => p.oPadre.Id === person.oPadre.Id);
  };

  const filterPadreXCaracteristica = (person) => {
    return props.selectedDesdobles.filter((p) => p.oPadre.Id !== person.oPadre.Id);
  };

  const handleChangeCaracteristica = (person) => {
    const filteredPeople = findPadreXCaracteristica(person)
      ? filterPadreXCaracteristica(person)
      : [...props.selectedDesdobles, person];
    props.setSelectedDesdobles(filteredPeople);
  };

  const onSelectCaracteristicas = (e, item) => {
    // return;
    var aListaRows = [];
    var indexFind = -1;
    props.selectedDesdobles.find(function (obj, i) {
      if (obj.oPadre.Id === props.objRightPosicionClick.oItem.Id) {
        indexFind = i;
        return i;
      }
    });
    if (indexFind > -1) {
      props.selectedDesdobles.splice(indexFind, 1);
      var aFinalArray = [];
      for (let index = 0; index < props.finalArray.length; index++) {
        const element = { ...props.finalArray[index] };
        aFinalArray.push(element);
      }
      var Id = props.objRightPosicionClick.oItem.Id;
      var aHijosDesdobles = obtenerHijos(props.finalArray, Id);
      var aHijosInicialesSinDesdobles = obtenerHijos(props.finalArraySinFiltro, Id);
      if (aHijosDesdobles.length > aHijosInicialesSinDesdobles.length) {
        var indexFind = -1;
        aFinalArray.find(function (item, i) {
          if (item.Id === Id) {
            indexFind = i;
            return i;
          }
        });
        aFinalArray.splice(indexFind + 1, aHijosDesdobles.length);
        for (let index = 0; index < aHijosInicialesSinDesdobles.length; index++) {
          const element = aHijosInicialesSinDesdobles[index];
          indexFind = indexFind + 1;
          aFinalArray.splice(indexFind, 0, element);
        }
        aListaRows = aFinalArray;
        props.setFinalArray(aFinalArray);
      }
    } else {
      aListaRows = props.finalArray;
    }
    var oItem = props.objRightPosicionClick.oItem;
    handleChangeCaracteristica({
      oPadre: oItem,
      oDesdoble: item,
      collapse: true,
      collapseIcon: true,
    });
  };

  const onSelectLimpiarDesdoble = (e, oItem) => {
    var indexFind = -1;
    const selectedDesdobles = [...props.selectedDesdobles];
    props.selectedDesdobles.find(function (item, i) {
      if (item.oPadre.Id === oItem.Id) {
        indexFind = i;
        return i;
      }
    });
    if (indexFind > -1) {
      selectedDesdobles.splice(indexFind, 1);
      props.setSelectedDesdobles(selectedDesdobles);
      var aFinalArray = [];
      for (let index = 0; index < props.finalArray.length; index++) {
        const element = { ...props.finalArray[index] };
        aFinalArray.push(element);
      }
      var Id = props.objRightPosicionClick.oItem.Id;
      var aHijosDesdobles = obtenerHijos(props.finalArray, Id);
      var aHijosInicialesSinDesdobles = obtenerHijos(props.finalArraySinFiltro, Id);
      if (aHijosDesdobles.length > aHijosInicialesSinDesdobles.length) {
        var indexFind = -1;
        aFinalArray.find(function (item, i) {
          if (item.Id === Id) {
            indexFind = i;
            return i;
          }
        });
        aFinalArray.splice(indexFind + 1, aHijosDesdobles.length);
        for (let index = 0; index < aHijosInicialesSinDesdobles.length; index++) {
          const element = aHijosInicialesSinDesdobles[index];
          indexFind = indexFind + 1;
          aFinalArray.splice(indexFind, 0, element);
        }
        props.setFinalArray(aFinalArray);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No se ha seleccionado ningún desdoble.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha seleccionado ningún desdoble.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
    // }else{
    //   Swal.fire({
    //     position: "center",
    //     icon: "warning",
    //     title: "No se ha seleccionado ningún desdoble.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  return (
    <Content>
      {/* <button onClick={(e)=>onRealizarDesdoblesAutomatico(e)}></button> */}
      {flujoCajaState.selectListaSoluciones.length > 0 ? (
        <TableContain>
          <TableFC>
            <thead style={{ fontWeight: 'bold', textAlign: 'center' }}>
              <tr>
                <td style={{ width: '120px' }}> Posición \ Periodo </td>
                {flujoCajaState.detalleFlujoCajaCabecerasSolucion.map((item, index) => (
                  <td>{item.sCabecera}</td>
                ))}
              </tr>
            </thead>
            <tbody id="table_body">
              {flujoCajaState.detalleListaAgrupadaXSoluciones.map((item, index) => {
                var oItem = item;
                var indexPadre = index;
                var espacio = 0;
                var sangria = '';
                if (item.IdentificadorCPR != 'CS') {
                  if (oItem.Id.split('-').length > 1) {
                    espacio = oItem.Id.split('-').length;
                  }
                  for (var i = 0; i < espacio; i++) {
                    sangria += '&nbsp;&nbsp;';
                  }
                }
                return (
                  <tr id={'f-' + item.Id}>
                    {item.EsRaiz === 1 && item.EsGrupo === 1 ? (
                      <td
                        id={item.Id}
                        data-raiz={item.EsRaiz}
                        data-grupo={item.EsGrupo}
                        className={'redips-mark'}
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                        }}
                      >
                        <>
                          <span dangerouslySetInnerHTML={{ __html: sangria }} />
                        </>
                        <a
                          href="#"
                          data-id={'f-' + item.Id}
                          className={'show-row'}
                          onClick={(event) => handleClick(event, item, index)}
                        >
                          {/* [ - ]{" "} */}
                          {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                        </a>
                        {item.Posicion}
                      </td>
                    ) : item.EsRaiz === 0 && item.EsGrupo === 1 ? (
                      item.collapse ? (
                        <></>
                      ) : (
                        <td
                          id={item.Id}
                          data-raiz={item.EsRaiz}
                          data-grupo={item.EsGrupo}
                          className={'redips-mark'}
                        >
                          <ContextMenuTrigger id="my-context-menu-3">
                            <div
                              onContextMenu={(e) => {
                                props.setObjRightPosicionClick(null);
                                if (oItem.IdentificadorCPR == 'P') {
                                  let caracEncontradas = oItem.datos_posic;
                                  ////TEMPORAL GUILLE
                                  if (caracEncontradas.length === 0 && oItem.Id_Partida2 !== 0) {
                                    Swal.fire({
                                      position: 'center',
                                      icon: 'warning',
                                      title: 'No se encontraron características asociadas.',
                                      showConfirmButton: false,
                                      timer: 2000,
                                    });
                                  } else if (caracEncontradas.length > 0) {
                                    props.setObjRightPosicionClick({
                                      oEvent: e,
                                      item: item,
                                      indexPadre: indexPadre,
                                      posi: index + 1,
                                      oItem: oItem,
                                      aCaracteristicas: caracEncontradas,
                                    });
                                  }
                                } else {
                                  props.setObjRightPosicionClick({
                                    oEvent: e,
                                    item: item,
                                    indexPadre: indexPadre,
                                    posi: index + 1,
                                    oItem: oItem,
                                    aCaracteristicas: [],
                                  });
                                }
                              }}
                            >
                              <>
                                <span dangerouslySetInnerHTML={{ __html: sangria }} />
                              </>
                              <a
                                href="#"
                                data-id={'f-' + item.Id}
                                className={'show-row'}
                                onClick={(event) => handleClick(event, item, index)}
                              >
                                {/* [ - ] */}
                                {item.collapseIcon ? '[ - ] ' : '[ + ] '}
                              </a>
                              {item.Posicion}
                            </div>
                          </ContextMenuTrigger>
                        </td>
                      )
                    ) : item.collapse ? (
                      <></>
                    ) : (
                      <td
                        id={item.Id}
                        data-raiz={item.EsRaiz}
                        data-grupo={item.EsGrupo}
                        className={'redips-mark'}
                      >
                        <ContextMenuTrigger id="my-context-menu-4">
                          <div
                            onContextMenu={(e) => {
                              props.setObjRightRegistroClick({
                                oEvent: e,
                                item: item,
                                indexPadre: indexPadre,
                                posi: index + 1,
                                oItem: oItem,
                              });
                            }}
                          >
                            <>
                              <span dangerouslySetInnerHTML={{ __html: sangria }} />
                            </>
                            {item.Posicion}
                          </div>
                        </ContextMenuTrigger>
                      </td>
                    )}
                    {item.collapse ? (
                      <></>
                    ) : (
                      oItem.Items.map((item2, index) => (
                        <td
                          className={`contenidoTable ${
                            oItem.IdentificadorCPR !== 'R'
                              ? ' otros '
                              : item2.EsGrupo === 0 &&
                                item2.EsManual === 1 &&
                                item2.EsRaiz === 0 &&
                                item2.EsSplit === 0
                              ? ' manual '
                              : item2.EsGrupo === 0 &&
                                item2.EsManual === 0 &&
                                item2.EsRaiz === 0 &&
                                item2.EsSplit === 0
                              ? ' noManual '
                              : ''
                          }
                    `}
                        >
                          {item2.EsGrupo === 0 &&
                          item2.EsManual === 1 &&
                          item2.EsRaiz === 0 &&
                          item2.EsSplit === 0 &&
                          item2.bEditable === true ? (
                            <div></div>
                          ) : item2.Valor !== 0 ? (
                            <>
                              <div
                                id={item2.Id}
                                data-pos={oItem.Id}
                                data-raiz={item2.EsRaiz}
                                data-grupo={item2.EsGrupo}
                                data-col={index + 1}
                                data-val={item2.Valor}
                                data-mid={item2.Id_Movimiento}
                                className={`redips-drag`}
                                onContextMenu={(e) => {
                                  props.setObjRightClick({
                                    oEvent: e,
                                    item2: item2,
                                    indexPadre: indexPadre,
                                    posi: index + 1,
                                    indexCabecera: index,
                                    oItem: oItem,
                                  });
                                }}
                              >
                                <NumberTable
                                  onDragStart={(e) =>
                                    onDragStart(e, item2, index + 1, oItem, indexPadre)
                                  }
                                  draggable="true"
                                  value={item2.Valor}
                                  allowNegative={true}
                                  displayType={'text'}
                                  thousandSeparator={','}
                                  decimalSeparator={'.'}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                />
                              </div>
                            </>
                          ) : (
                            <div>
                              <NumberTable
                                style={{ display: 'block', width: '24px', height: '24px' }}
                                value={''}
                                allowNegative={true}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                              />
                            </div>
                          )}
                        </td>
                      ))
                    )}
                  </tr>
                );
              })}
              {flujoCajaState.aListaSolucionesSumarizada.map((item, index2) => (
                <tr>
                  <td
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty('color', item.HexColor, 'important');
                      }
                    }}
                    className={'redips-mark-Soluciones'}
                  >
                    {item.Posicion}
                  </td>
                  {item.Items.map((item2, index2) => (
                    <td className="soluciones">
                      <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                        <NumberTotalTable
                          isNegative={item2.Valor < 0 ? true : false}
                          value={item2.Valor == 0 ? '' : item2.Valor}
                          allowNegative={true}
                          displayType={'text'}
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className={'redips-mark-Totales'}>
                  {flujoCajaState.detalleListaAgrupadaXSolucionesParcial.sNombre}
                </td>
                {flujoCajaState.detalleListaAgrupadaXSolucionesParcial.Items != null ? (
                  flujoCajaState.detalleListaAgrupadaXSolucionesParcial.Items.map(
                    (item2, index2) => (
                      <td className="totales">
                        <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                          <NumberTotalTable
                            isNegative={item2.Valor < 0 ? true : false}
                            value={item2.Valor}
                            allowNegative={true}
                            displayType={'text'}
                            thousandSeparator={','}
                            decimalSeparator={'.'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </div>
                      </td>
                    )
                  )
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td className={'redips-mark-Totales'}>
                  {flujoCajaState.detalleListaAgrupadaXSolucionesTotal.sNombre}
                </td>
                {flujoCajaState.detalleListaAgrupadaXSolucionesTotal.Items != null ? (
                  flujoCajaState.detalleListaAgrupadaXSolucionesTotal.Items.map((item2, index2) => (
                    <td className="totales">
                      <div id={`t${index2 + 1}${item2.Id}`} data-pos={item2.Id}>
                        <NumberTotalTable
                          isNegative={item2.Valor < 0 ? true : false}
                          value={item2.Valor}
                          allowNegative={true}
                          displayType={'text'}
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </div>
                    </td>
                  ))
                ) : (
                  <></>
                )}
              </tr>
            </tfoot>
          </TableFC>
          {!props.objRightPosicionClick ? (
            <></>
          ) : (
            <ContextMenu id="my-context-menu-3">
              {props.objRightPosicionClick.oItem.IdentificadorCPR == 'P' ? (
                <>
                  <Submenu title="Características">
                    {props.objRightPosicionClick.aCaracteristicas.map((item, index) => (
                      <ContextMenuItem onClick={(e) => onSelectCaracteristicas(e, item)}>
                        {item.sNombreCampo}
                      </ContextMenuItem>
                    ))}
                  </Submenu>
                  <ContextMenuItem
                    onClick={(e) => onSelectLimpiarDesdoble(e, props.objRightPosicionClick.oItem)}
                  >
                    Limpiar Desdoble
                  </ContextMenuItem>
                </>
              ) : (
                <></>
              )}
            </ContextMenu>
          )}
        </TableContain>
      ) : (
        <></>
      )}
    </Content>
  );
});
