export const TipoFormularioTipoCambio = [
  {
    Id: 1,
    Cod: 'Tipo_1',
    value: 'Tipo_1',
    Nombre: 'Tipo 1',
    label: 'Tipo 1',
    Habilitado: 1,
  },
];
