export const numbersWithComa = (evnt) => {
  let regex = new RegExp('^[0-9-.]+$');
  let key = String.fromCharCode(!evnt.charCode ? evnt.which : evnt.charCode);
  if (!regex.test(key)) {
    evnt.preventDefault();
    return false;
  }
};

export const onlyNumbers = (evnt) => {
  let regex = new RegExp('^[0-9]+$');
  let key = String.fromCharCode(!evnt.charCode ? evnt.which : evnt.charCode);
  if (!regex.test(key)) {
    evnt.preventDefault();
    return false;
  }
};
