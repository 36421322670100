export const items = (
  onClickCopiarRegistroM,
  exportToExcelRegistroManual,
  data,
  setShowModalImport,
  onClickEliminarRegistroM
) => [
  {
    label: 'Crear copia',
    key: '1',
    onClick: onClickCopiarRegistroM,
  },
  {
    label: 'Exportar',
    key: '2',
    onClick: () => exportToExcelRegistroManual(data),
  },
  {
    label: 'Importar',
    key: '3',
    onClick: () => setShowModalImport(true),
  },
  {
    label: 'Eliminar',
    key: '4',
    onClick: onClickEliminarRegistroM,
  },
];
