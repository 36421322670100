import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../../../common/hooks';
import Swal from 'sweetalert2';
import {
  Button,
  InputTextAutocompleteNewUpdDel,
  LabelForm,
  SearchInputText,
  SelectForm,
  TableGrid,
} from '../../../../../../components';
import {
  eliminarVersionSolucionFC,
  guardarVersionSolucionFC,
  modificarVersionSolucionFC,
  obtenerDetalleVersionSolucionesFC,
  validarVersionesSolucionesFCxUsuario,
} from '../../../../../../services/public.service';
import { createTipoSolucionAdapter } from '../../../../../../adapters';
import {
  onChangeSearchModalSolSearchFlujoCaja,
  onChangeVersionSolucionDetalleFlujoCaja,
  onChangeVigenciaSolFlujoCaja,
  onOpenModalSolucionesDetalleFlujoCaja,
  onSelectTipoEstadoSoluFlujocaja,
  onSelectTipoSoluFlujocaja,
  onSetFilterListaModalSolFlujoCaja,
  onSetListaTipoSolucionesFlujoCaja,
  onSetSelectSolucionesModalFlujoCaja,
  updateIdSelectListaSolucionFlujoCaja,
} from '../../../../../../redux/states';
import { TipoSolucionesData } from '../../../../../../common';
import { dateTo_yyyy_mm_dd } from '../../../../../../common';
import { ColForm, DatePickerForm, ModalTable, RowForm } from '../../../../../../styles';
import { theme } from '../../../../../../common';
import { Divider } from 'antd';

export const ModalSoluciones = (props) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const [selectionModel, setSelectionModel] = React.useState([]);

  useEffect(() => {
    if (flujoCajaState.modalSolucionesFlujoCaja) {
      setSelectionModel(flujoCajaState.selectSolucionesModalFlujoCaja);
    }
  }, [flujoCajaState.modalSolucionesFlujoCaja]);

  useEffect(() => {
    (async () => {
      // await loadSoluciones();
      var tipoSolucionesFlujoCaja = [];
      TipoSolucionesData.forEach((element) => {
        tipoSolucionesFlujoCaja.push(createTipoSolucionAdapter(element));
      });
      dispatch(
        onSetListaTipoSolucionesFlujoCaja({ tipoSolucionesFlujoCaja: tipoSolucionesFlujoCaja })
      );
    })();
  }, []);

  useEffect(() => {
    let lista = [...flujoCajaState.listaModalSolFlujoCaja];
    if (flujoCajaState.selectTipoSoluFlujocaja.length > 0) {
      lista = lista.filter(function (item) {
        for (var i = 0; i < flujoCajaState.selectTipoSoluFlujocaja.length; i++) {
          if (item['tipo'] === flujoCajaState.selectTipoSoluFlujocaja[i].value) {
            return true;
          }
        }
        return false;
      });
    }
    if (flujoCajaState.selectTipoEstadoSolFlujoCaja.length > 0) {
      lista = lista.filter(function (item) {
        for (var i = 0; i < flujoCajaState.selectTipoEstadoSolFlujoCaja.length; i++) {
          if (item['idEstado'] === flujoCajaState.selectTipoEstadoSolFlujoCaja[i].value) {
            return true;
          }
        }
        return false;
      });
    }
    if (flujoCajaState.searchModalSolSearchFlujoCaja.length > 0) {
      lista = lista.filter(function (item) {
        return (
          item.codigo
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.nombre
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.descripcion
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.fechaVigencia
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase())
        );
      });
    }
    if (flujoCajaState.searchModalSolSearchFlujoCaja.length > 0) {
      lista = lista.filter(function (item) {
        return (
          item.codigo
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.nombre
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.descripcion
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase()) ||
          item.fechaVigencia
            .toLowerCase()
            .includes(flujoCajaState.searchModalSolSearchFlujoCaja.toLowerCase())
        );
      });
    }
    var func = dateTo_yyyy_mm_dd;
    if (
      flujoCajaState.dDateVigenciaSoluFlujoCaja[0] !== null &&
      flujoCajaState.dDateVigenciaSoluFlujoCaja[1] != null
    ) {
      lista = lista.filter(function (item) {
        return (
          item.fechaInicioFormat >= func(flujoCajaState.dDateVigenciaSoluFlujoCaja[0], '-') &&
          item.fechaInicioFormat <= func(flujoCajaState.dDateVigenciaSoluFlujoCaja[1], '-')
        );
      });
    }

    dispatch(onSetFilterListaModalSolFlujoCaja({ filterListaModalSolFlujoCaja: lista }));
  }, [
    flujoCajaState.selectTipoSoluFlujocaja,
    flujoCajaState.searchModalSolSearchFlujoCaja,
    flujoCajaState.dDateVigenciaSoluFlujoCaja,
    flujoCajaState.selectTipoEstadoSolFlujoCaja,
  ]);

  const columns = [
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'moneda',
      headerName: 'Moneda',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'descripcion',
      headerName: 'Descripción',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'fechaVigencia',
      headerName: 'Fecha de Vigencia',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  // const findConcepto = (person) => {
  //   return !!selectionModel.find((p) => p.idSolucionBase === person.idSolucionBase);
  // };

  // const filterConcepto = (person) => {
  //   return selectionModel.filter((p) => p.idSolucionBase !== person.idSolucionBase);
  // };

  // const handleChange = (person) => {
  //   const filteredPeople = findConcepto(person)
  //     ? filterConcepto(person)
  //     : [...selectionModel, person];
  //   setSelectionModel(filteredPeople);
  // };

  const onCloseModal = (e) => {
    limpiarSeleccion();
    dispatch(onOpenModalSolucionesDetalleFlujoCaja({ modalSolucionesFlujoCaja: false }));
  };

  const handleChangeMultiKey = (event) => {
    if (event) {
      dispatch(
        onSelectTipoSoluFlujocaja({
          selectTipoSoluFlujocaja: [event],
        })
      );
    } else {
      dispatch(
        onSelectTipoSoluFlujocaja({
          selectTipoSoluFlujocaja: [],
        })
      );
    }
  };

  const handleChangeMultiKeyEstado = (event) => {
    if (event) {
      dispatch(
        onSelectTipoEstadoSoluFlujocaja({
          selectTipoEstadoSolFlujoCaja: [event],
        })
      );
    } else {
      dispatch(
        onSelectTipoEstadoSoluFlujocaja({
          selectTipoEstadoSolFlujoCaja: [],
        })
      );
    }
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(
        onChangeSearchModalSolSearchFlujoCaja({
          searchModalSolSearchFlujoCaja: oEvent.target.value,
        })
      );
    } else {
      dispatch(onChangeSearchModalSolSearchFlujoCaja({ searchModalSolSearchFlujoCaja: '' }));
    }
  };

  const handleChangeFechaCrearFlujoCaja = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    oParam.sFechaInicio = sFechaInicio;
    oParam.sFechaFinal = sFechaFinal;
    dispatch(
      onChangeVigenciaSolFlujoCaja({
        dDateVigenciaSoluFlujoCaja: [oParam.dFechaInicio, oParam.dFechaFin],
        bValidDateVigenciaSoluFlujoCaja: true,
      })
    ); //oParam));
  };

  const onAplicarSoluciones = (e) => {
    dispatch(
      onSetSelectSolucionesModalFlujoCaja({ selectSolucionesModalFlujoCaja: selectionModel })
    );
    dispatch(onOpenModalSolucionesDetalleFlujoCaja({ modalSolucionesFlujoCaja: false }));
    limpiarFiltros();
  };

  const limpiarSeleccion = (e) => {
    setSelectionModel([]);
    limpiarFiltros();
  };

  const limpiarFiltros = () => {
    //Limpiar Filtros
    dispatch(onChangeSearchModalSolSearchFlujoCaja({ searchModalSolSearchFlujoCaja: '' }));
    dispatch(
      onChangeVigenciaSolFlujoCaja({
        dDateVigenciaSoluFlujoCaja: [null, null],
        bValidDateVigenciaSoluFlujoCaja: null,
      })
    );
    dispatch(
      onSelectTipoSoluFlujocaja({
        selectTipoSoluFlujocaja: [],
      })
    );
    dispatch(
      onSelectTipoEstadoSoluFlujocaja({
        selectTipoEstadoSolFlujoCaja: [],
      })
    );
  };

  const onSeleccionarTodos = (e) => {
    dispatch(
      onSetSelectSolucionesModalFlujoCaja({
        selectSolucionesModalFlujoCaja: flujoCajaState.listaModalSolFlujoCaja,
      })
    );
    setSelectionModel(flujoCajaState.listaModalSolFlujoCaja);
  };

  const onChangeInputVersion = (oEvent) => {
    let oParam = {
      selectVersionSolucionDetalleFlujoCaja: [],
    };
    if (oEvent) {
      oParam.selectVersionSolucionDetalleFlujoCaja = [
        {
          id: null,
          nombre: oEvent,
        },
      ];
    }
    dispatch(onChangeVersionSolucionDetalleFlujoCaja(oParam));
  };

  const onSelectVersionFlujoCaja = async (oSelect) => {
    let oParam = {
      selectVersionSolucionDetalleFlujoCaja: [],
    };

    if (oSelect) {
      oParam.selectVersionSolucionDetalleFlujoCaja = [oSelect];
    }
    if (oSelect.id != null) {
      //consultar Servicio
      const responseListarEtiquetas = await callEndpoint(
        obtenerDetalleVersionSolucionesFC({
          oVersionSolucion: {
            ID: oSelect.id,
          },
        })
      );

      if (responseListarEtiquetas.data) {
        // oParam.selectVersionSolucionDetalleFlujoCaja = responseListarEtiquetas.data.aSoluciones;
        setSelectionModel(responseListarEtiquetas.data.respuesta.aListaSoluciones);
      } else {
        // props.setSelectionFiltrosModel([]);
      }
    } else {
      // props.setSelectionFiltrosModel([]);
    }

    dispatch(onChangeVersionSolucionDetalleFlujoCaja(oParam));
  };

  const onOpenDeleteVersion = async (item) => {
    let oEliminar = item;
    const validado = await onValidarDeleteVersion(oEliminar);
    if (validado) {
      Swal.fire({
        title: 'Desea eliminar la version seleccionada',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onConfirmDeleteVersion(oEliminar);
        }
      });
    } else {
      Swal.fire({
        title: 'No se puede eliminar una versión que es compartida contigo',
        icon: 'warning',
        confirmButtonColor: theme.colors.primary,
        confirmButtonText: 'Confirmar',
      });
    }
  };

  const onValidarDeleteVersion = async (oEliminar) => {
    var validacion = await callEndpoint(
      validarVersionesSolucionesFCxUsuario({
        oVersionSolucion: {
          ID: oEliminar.id,
        },
      })
    );
    return validacion.data.respuesta.validador;
  };

  const onConfirmDeleteVersion = async (oEliminar) => {
    var respEliminarVersionFiltroFCXUsuario = await callEndpoint(
      eliminarVersionSolucionFC({
        IDVersion: oEliminar.id,
      })
    );
    setSelectionModel([]);
    await props.loadSolucionesVersionFCXUsuario();
  };

  const onOpenGuardarVersion = async (oEvent, finalArrayEliminar) => {
    var bValidForm = true; //validarCampos();
    if (bValidForm) {
      var updateVersion = true;
      var validado = true;
      if (flujoCajaState.selectVersionSolucionDetalleFlujoCaja.length === 0) {
        updateVersion = false;
      } else {
        if (flujoCajaState.selectVersionSolucionDetalleFlujoCaja[0].id == null) {
          updateVersion = false;
        } else {
          validado = await onValidarDeleteVersion(
            flujoCajaState.selectVersionSolucionDetalleFlujoCaja[0]
          );
        }
      }

      if (validado) {
        Swal.fire({
          title: !updateVersion ? '¿Desea guardar la versión?' : '¿Desea sobreescribir la versión?',
          html: `Desea compartir la versión?:  <input id="compartido" style="width:18px;height:18px" type='checkbox' ${
            flujoCajaState.selectVersionSolucionDetalleFlujoCaja[0].compartido == 1 ? 'checked' : ''
          }/>`,
          preConfirm: () => {
            var compartido;
            if (Swal.getPopup().querySelector('#compartido:checked')) {
              compartido = 1;
            } else compartido = 0;

            return { compartido: compartido };
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (!updateVersion) {
              // return;
              var oParam = {
                Compartido: result.value.compartido,
                NombreVersion: flujoCajaState.selectVersionSolucionDetalleFlujoCaja[0].nombre,
                aListaSoluciones: selectionModel,
              };

              var responseCrearVersionFiltroFlujoCaja = await callEndpoint(
                guardarVersionSolucionFC(oParam)
              );
              if (responseCrearVersionFiltroFlujoCaja == null) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Ocurrió un error en el servicio.',
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                if (responseCrearVersionFiltroFlujoCaja.data.cabecera.statusCode === 200) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: responseCrearVersionFiltroFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  dispatch(
                    updateIdSelectListaSolucionFlujoCaja({
                      id: responseCrearVersionFiltroFlujoCaja.data.respuesta.ID,
                    })
                  );
                  await props.loadSolucionesVersionFCXUsuario();
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: responseCrearVersionFiltroFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              }
            } else {
              var oParamUpdateVersionFiltroFlujoCaja = {
                Compartido: result.value.compartido,
                oVersionSolucion: {
                  ID: flujoCajaState.selectVersionSolucionDetalleFlujoCaja[0].id,
                },
                aListaSoluciones: selectionModel,
              };
              var responseUpdateVersionFlujoCaja = await callEndpoint(
                modificarVersionSolucionFC(oParamUpdateVersionFiltroFlujoCaja)
              );
              if (responseUpdateVersionFlujoCaja == null) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Ocurrió un error en el servicio.',
                  showConfirmButton: false,
                  timer: 2000,
                });
                await props.loadSolucionesVersionFCXUsuario();
              } else {
                if (responseUpdateVersionFlujoCaja.data.cabecera.statusCode === 200) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: responseUpdateVersionFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  await props.loadSolucionesVersionFCXUsuario();
                } else {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: responseUpdateVersionFlujoCaja.data.cabecera.descripcion,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              }
            }
          }
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Esta version no se puede editar',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, debe llenar los filtros y asignar un nombre.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const selectSetCheck = (e) => {
    debugger;
    let lista = [...flujoCajaState.listaModalSolFlujoCaja];
    let listaFiltrada = [...flujoCajaState.filterListaModalSolFlujoCaja];

    let arrayFiltrado = lista.filter((objeto) =>
      listaFiltrada.some((filtro) => filtro.idSolucionBase === objeto.idSolucionBase)
    );

    if (arrayFiltrado.length == lista.length) {
      setSelectionModel(e);
    } else {
      let selectionFilter = selectionModel.filter((elemento) => {
        // Verifica si algún objeto en el array de filtro coincide con el elemento actual
        return !listaFiltrada.some((objeto) => objeto.idSolucionBase === elemento);
      });
      let listaSelecFinal = [...selectionFilter, ...e];
      setSelectionModel([...new Set(listaSelecFinal)]);
    }
  };

  return (
    <ModalTable
      backdrop="static"
      keyboard={false}
      show={flujoCajaState.modalSolucionesFlujoCaja}
      onHide={onCloseModal}
    >
      <ModalTable.Header closeButton>
        <ModalTable.Title>Soluciones</ModalTable.Title>
      </ModalTable.Header>
      <ModalTable.Body>
        <RowForm>
          <ColForm xs={20} sm={12} md={12} lg={6} xl={6}>
            <RowForm>
              <LabelForm width={'80px'} title={'Versión :'}></LabelForm>
              <InputTextAutocompleteNewUpdDel
                data={flujoCajaState.listaVersionSolucionDetalleFlujoCaja}
                width={'200px'}
                label="languages"
                pholder="Buscar Versión..."
                onSelected={(evnt) => onSelectVersionFlujoCaja(evnt)}
                value={
                  flujoCajaState.selectVersionSolucionDetalleFlujoCaja.length === 0
                    ? ''
                    : flujoCajaState.selectVersionSolucionDetalleFlujoCaja[0].nombre
                }
                oValue={flujoCajaState.selectVersionSolucionDetalleFlujoCaja}
                text_align={'start'}
                onChange={(evnt) => onChangeInputVersion(evnt)}
                onDelete={onOpenDeleteVersion}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={12} md={12} lg={6} xl={6}>
            <RowForm>
              <Button
                stye={{ marginLeft: '2px' }}
                action={(e) => onOpenGuardarVersion(e, props)}
                name="Guardar versión"
                isAction={true}
                type="primary"
              />
            </RowForm>
          </ColForm>
        </RowForm>
        <Divider className="my-2" />
        <RowForm>
          <ColForm xs={24} sm={12} md={12} lg={6} xl={6}>
            <RowForm>
              <SearchInputText
                width={'250px'}
                placeholder={'Buscar...'}
                onChange={(e) => onChangeSearch(e)}
                filter={flujoCajaState.searchModalSolSearchFlujoCaja}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={12} md={12} lg={6} xl={6}>
            <RowForm>
              <DatePickerForm
                width="250px"
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                startDate={flujoCajaState.dDateVigenciaSoluFlujoCaja[0]}
                endDate={flujoCajaState.dDateVigenciaSoluFlujoCaja[1]}
                onChange={(evnt) => handleChangeFechaCrearFlujoCaja(evnt)}
                isClearable={true}
                placeholderText="Ingrese fecha de vigencia"
                locale="es"
                showMonthDropdown
                showYearDropdown
                portalId="root-portal"
                validate={flujoCajaState.bValidDateVigenciaSoluFlujoCaja}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={12} md={12} lg={6} xl={6}>
            <RowForm>
              <SelectForm
                width={'250px'}
                bValid={true}
                value={flujoCajaState.selectTipoSoluFlujocaja}
                handleChange={(evnt, v) => handleChangeMultiKey(v)}
                options={flujoCajaState.tipoSolucionesFlujoCaja}
                placeholder="Seleccione tipo de solucion"
                isMulti={true}
              ></SelectForm>
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={12} md={12} lg={6} xl={6}>
            <RowForm>
              <SelectForm
                width={'250px'}
                bValid={true}
                value={flujoCajaState.selectTipoEstadoSolFlujoCaja}
                handleChange={(evnt, v) => handleChangeMultiKeyEstado(v)}
                options={flujoCajaState.listaTipoEstadoSolFlujoCaja}
                placeholder="Seleccione estado"
                isMulti={true}
              ></SelectForm>
            </RowForm>
          </ColForm>
        </RowForm>
        <TableGrid
          columns={columns}
          rows={flujoCajaState.filterListaModalSolFlujoCaja}
          pageSize={15}
          getRowId={(row) => row.idSolucionBase}
          checkboxSelection={true}
          selectionModel={selectionModel}
          onSelectionModelChange={(oEvent) => selectSetCheck(oEvent)}
        />
      </ModalTable.Body>
      <ModalTable.Footer>
        <Button action={onCloseModal} name="Cancelar" isAction={true} type="warning" />
        <Button action={limpiarSeleccion} name="Limpiar" isAction={true} type="primary" />
        <Button
          action={(e) => onAplicarSoluciones(e)}
          name="Aplicar"
          isAction={true}
          type="secondary"
        />
      </ModalTable.Footer>
    </ModalTable>
  );
};
