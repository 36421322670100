export const createPosicionAdapter = (user) => ({
  iIdPosicion: user.iIdPosicion,
  sCodigoPosicion: user.sCodigoPosicion,
  sNombrePosicion: user.sNombrePosicion,
  sNombreConcepto: user.sNombreConcepto,
  TipoConcepto: user.TipoConcepto,
  Real_Proyectado: user.Real_Proyectado,
  iHabilitado: user.iHabilitado,
});

export const createPosicionMasterAdapter = (row) => ({
  value: row.IDCabeceraMaster,
  label: row.NombreMaster,
  ...row,
});

export const listaPosicionAdapter = (user) => ({
  value: user.iIdPosicion,
  label: user.sNombrePosicion,
  ...user,
});
