import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createTipoSistemaAdapter } from '../../../../adapters';
import {
  LabelForm,
  Input,
  SubTitle,
  Button,
  ModalConfirmation,
  LoadingSpinner,
  SelectForm,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  changeAliasSistema,
  changeTipoSistemaSistema,
  limpiarCamposFormSistema,
  limpiarCamposPostSelcTipoSistema,
  limpiarCamposSistema,
  setListaTipoSistemas,
  updateTitle,
} from '../../../../redux/states';
import {
  crearSistema,
  listarTipoSistema,
  obtenerCampoXTipoSistema,
} from '../../../../services/public.service';
import { Content, ColForm, ContentForm, RowFooterButton, RowForm } from '../../../../styles';

export const CrearSistema = () => {
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [dynamicListField, setDynamicListField] = useState([]);
  const sistemaState = useSelector((store) => store.sistema);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(updateTitle({ title: 'Crear Sistema' }));
    loadTipoSistemas();
  }, []);

  const loadTipoSistemas = async () => {
    let listTypesSystem = [];
    const responseLoadTipoSistemas = await callEndpoint(listarTipoSistema());
    if (responseLoadTipoSistemas) {
      if (responseLoadTipoSistemas.data) {
        if (responseLoadTipoSistemas.data.cabecera.statusCode === 200) {
          responseLoadTipoSistemas.data.respuesta.aListaTipoSistemas.forEach((element) => {
            let obj = createTipoSistemaAdapter(element);
            obj.value = obj.IDTipoSistema;
            obj.label = obj.TipoSistema;
            listTypesSystem.push(obj);
          });
        }
      }
    }
    dispatch(setListaTipoSistemas({ listaTipoSistemas: listTypesSystem }));
  };

  const handleChangeAlias = (oEvent) => {
    let alias = oEvent.target.value;
    let validate = null;
    if (alias.length > 0) {
      validate = true;
    }
    let oParam = {
      alias: alias,
      bValidAlias: validate,
    };
    dispatch(changeAliasSistema(oParam));
  };

  const handleChangeTipo = async (value) => {
    let oParam = {};
    let validate = null;
    oParam.selectTipoSistema = [];
    oParam.bValidSelectTipoSistema = validate;
    if (value) {
      oParam.selectTipoSistema = [value];
      oParam.bValidSelectTipoSistema = true;
      const responseObtnerCampoXTipoSistema = await callEndpoint(
        obtenerCampoXTipoSistema(oParam.selectTipoSistema[0].value)
      );
      setDynamicListField(responseObtnerCampoXTipoSistema.data.respuesta.aListaCamposXTipoSistema);
    } else {
      dispatch(limpiarCamposPostSelcTipoSistema());
      setDynamicListField([]);
    }
    dispatch(changeTipoSistemaSistema(oParam));
  };

  const handleChangeInputForm = (index, oEvent) => {
    const campo = oEvent.target.name.split('-')[0];
    const validate = oEvent.target.name.split('-')[1];
    const value = oEvent.target.value;
    let newDynamicListField = dynamicListField.map(function (item, posi) {
      if (posi == index) {
        item[campo] = value;
        item[validate] = value ? true : false;
      }
      return { ...item };
    });
    setDynamicListField(newDynamicListField);
  };

  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const onConfirmarConcepto = () => {
    setShowModalCancelar(false);
    dispatch(limpiarCamposSistema());
    navigate('/dashboard/configuracion/sistema/listar');
  };
  const limpiarCampos = () => {
    dispatch(limpiarCamposFormSistema());
    dispatch(limpiarCamposPostSelcTipoSistema());
    setDynamicListField([]);
  };
  const onPressGrabar = async () => {
    const { idSistema, alias, bValidAlias, selectTipoSistema, bValidSelectTipoSistema } =
      sistemaState;
    if (selectTipoSistema.length == 0) {
      dispatch(changeTipoSistemaSistema({ bValidSelectTipoSistema: false }));
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No ha seleccionado el tipo de sistema.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    let filterValorCampoVacio = dynamicListField.filter(function (item, index) {
      return item.ValorCampo.length == 0;
    });
    if (filterValorCampoVacio.length == 0) {
      let detalle = [];
      dynamicListField.map(function (item, index) {
        let obj = {};
        obj.IDSistema = selectTipoSistema[0].value;
        obj.NombreCampo = item.NombreCampo;
        obj.ValorCampo = item.ValorCampo;
        detalle.push(obj);
      });
      let oParam = {
        oRegistrarCabecera: {
          alias: alias,
          idTipoSistema: selectTipoSistema[0].value,
        },
        oRegistrarDetalle: detalle,
      };
      const responseCrearSistema = await callEndpoint(crearSistema(oParam));
      if (responseCrearSistema == null) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        if (responseCrearSistema.data) {
          if (responseCrearSistema.data.cabecera.statusCode === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseCrearSistema.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
            navigate('/dashboard/configuracion/sistema/listar');
          } else if (responseCrearSistema.data.cabecera.statusCode === 400) {
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: responseCrearSistema.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseCrearSistema.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se deben llenar todos los parámetros del tipo de sistema seleccionado.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
  };
  return (
    <Content>
      <SubTitle subtitle="1. Registrar datos generales del sistema." />
      <ContentForm>
        <RowForm style={{ columnGap: '5px' }}>
          <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Tipo de Sistema (*): '}></LabelForm>
              <SelectForm
                validate={sistemaState.bValidSelectTipoSistema}
                value={sistemaState.selectTipoSistema}
                handleChange={(e, v) => handleChangeTipo(v)}
                options={sistemaState.listaTipoSistemas}
                placeholder={'Seleccione Tipo'}
              />
            </RowForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
            <RowForm>
              <LabelForm obligatorio={true} title={'Alias (*): '}></LabelForm>
              <Input
                placeholder="Ingrese"
                value={sistemaState.alias}
                onChange={(evnt) => handleChangeAlias(evnt)}
                validate={sistemaState.bValidAlias}
              />
            </RowForm>
          </ColForm>

          {dynamicListField.map(function (item, index) {
            return (
              <ColForm xs={24} sm={24} md={24} lg={11} xl={11}>
                <RowForm>
                  <LabelForm title={item.NombreCampo + ' : '}></LabelForm>
                  <Input
                    placeholder="Ingrese"
                    value={item.ValorCampo} //{sistemaState.usuarioOracle}
                    onChange={(evnt) => handleChangeInputForm(index, evnt)}
                    validate={item.ValidValorCampo} //{sistemaState.bValidUsuarioOracle}
                    name={'ValorCampo-ValidValorCampo'}
                  />
                </RowForm>
              </ColForm>
            );
          })}
          <RowFooterButton>
            <Button
              action={(e) => onPressCancelar(e)}
              name="Cancelar"
              isAction={true}
              type="warning"
            />
            <Button
              action={(e) => limpiarCampos(e)}
              name="Limpiar"
              isAction={true}
              type="primary"
            />
            <Button
              action={(e) => onPressGrabar(e)}
              name="Grabar"
              isAction={true}
              type="secondary"
            />
          </RowFooterButton>
        </RowForm>
      </ContentForm>
      {loading === true ? <LoadingSpinner /> : <></>}
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación del Sistema?"
        action={(e) => onConfirmarConcepto(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
