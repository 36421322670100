import React, { useEffect, useState } from 'react';
import {
  ColFlex,
  ColForm,
  Content,
  ContentForm,
  ContentRow,
  RowDoubleInput,
  RowFooterButton,
  RowForm,
} from '../../styles';
import {
  setListaCaracteristicaPersonalizacion,
  setListaEstructuraPersonalizacion,
  setListaMonedaPersonalizacion,
  setListaPeriodoPersonalizacion,
  setListaPersonalizacion,
  setPersonalizacion,
  updateTitle,
} from '../../redux/states';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ContainerForm,
  Input,
  LabelForm,
  LoadingSpinner,
  SelectForm,
  SubTitle,
} from '../../components';
import {
  crearPersonalizacion,
  listarCamposMJ,
  obtenerEstructurasBase,
  obtenerListadoMoneda,
  obtenerListadoPeriodo,
  obtenerPersonalizacion,
} from '../../services/public.service';
import {
  listaFormatoNumero,
  listaLenguaje,
  listaUniverso,
  onlyNumbers,
  useFetchAndLoad,
} from '../../common';
import Swal from 'sweetalert2';
import { createEstructuraBaseAdapter, createPeriodoAdapter } from '../../adapters';
import { createMonedaAdapter } from '../../adapters/moneda.adapter';
import { listaProyeccion } from '../FlujoCaja/Reporte/CrearFlujoCaja/misc';
import i18next from 'i18next';

export const Personalizacion = () => {
  const personalizacionState = useSelector((store) => store.personalizacion);
  const authState = useSelector((store) => store.auth);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.user.uid) {
      dispatch(updateTitle({ title: 'Personalización' }));
      (async () => {
        await loadMoneda();
        await loadPeriodo();
        await loadCampos();
        await loadEstructura();
        await loadPersonalizacion();
      })();
    }
  }, [authState.user]);

  useEffect(() => {
    if (
      personalizacionState.listaPersonalizada.length > 0 &&
      personalizacionState.listaMoneda.length > 0
    ) {
      changeMonedaAdapter();
    }
  }, [personalizacionState.listaPersonalizada]);

  const loadPersonalizacion = async () => {
    const responsePersonalizacion = await callEndpoint(
      obtenerPersonalizacion({ IDUsuario: authState.user.uid })
    );
    var listaPersonalizacion = [];
    for (
      let index = 0;
      index < responsePersonalizacion.data.respuesta.aListadoPer.length;
      index++
    ) {
      const element = responsePersonalizacion.data.respuesta.aListadoPer[index];
      listaPersonalizacion.push(element);
    }
    if (listaPersonalizacion.length == 0) {
      listaPersonalizacion.push({ DescPersonalizacion: 'Moneda', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({ DescPersonalizacion: 'Periodo', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({ DescPersonalizacion: 'Formato', DatoPersonalizacion: 1 });
      listaPersonalizacion.push({
        DescPersonalizacion: 'texto_moneda',
        DatoPersonalizacion: 'nombre',
      });
    }
    if (listaPersonalizacion.filter((v) => v.DescPersonalizacion == 'Lenguaje').length > 0) {
      i18next.changeLanguage(
        listaPersonalizacion.filter((v) => v.DescPersonalizacion == 'Lenguaje')[0]
          .DatoPersonalizacion
      );
    }
    dispatch(setPersonalizacion(listaPersonalizacion));
  };

  const loadEstructura = async () => {
    let aListaEstructuras = [];
    var { data } = await callEndpoint(obtenerEstructurasBase());
    data.respuesta.aListaEstructuras.forEach(function (item, index) {
      var o = createEstructuraBaseAdapter(item);
      aListaEstructuras.push(o);
    });
    dispatch(setListaEstructuraPersonalizacion({ listaEstructura: aListaEstructuras }));
  };

  const loadCampos = async () => {
    const responseListaCaracteristica = await callEndpoint(listarCamposMJ());
    var listaCaracteristica = [];
    for (
      let index = 0;
      index < responseListaCaracteristica.data.respuesta.aListadoCampos.length;
      index++
    ) {
      const element = responseListaCaracteristica.data.respuesta.aListadoCampos[index];
      element.value = element.idCampo;
      element.label = element.nombreCampo;
      listaCaracteristica.push(element);
    }
    dispatch(setListaCaracteristicaPersonalizacion({ listaCaracteristica: listaCaracteristica }));
  };

  const loadPeriodo = async () => {
    let aListaPeriodo = [];
    var { data } = await callEndpoint(obtenerListadoPeriodo());
    data.respuesta.aListaPeriodo.forEach(function (item, index) {
      var o = createPeriodoAdapter(item);
      aListaPeriodo.push(o);
    });
    dispatch(setListaPeriodoPersonalizacion({ listaPeriodo: aListaPeriodo }));
  };

  const loadMoneda = async () => {
    let aListaMoneda = [];

    let tipoMoneda = null;
    var { data } = await callEndpoint(obtenerListadoMoneda());
    data.respuesta.aListaMoneda.forEach(function (item, index) {
      var o = createMonedaAdapter(item, tipoMoneda);
      aListaMoneda.push(o);
    });
    dispatch(setListaMonedaPersonalizacion({ listaMoneda: aListaMoneda }));
  };
  const changeMonedaAdapter = () => {
    let aListaMoneda = [];

    let tipoMoneda = personalizacionState.listaPersonalizada.filter(
      (v) => v.DescPersonalizacion == 'texto_moneda'
    )[0].DatoPersonalizacion;

    personalizacionState.listaMoneda.forEach(function (item, index) {
      var o = createMonedaAdapter(item, tipoMoneda);
      aListaMoneda.push(o);
    });
    dispatch(setListaMonedaPersonalizacion({ listaMoneda: aListaMoneda }));
  };

  const onChangeLista = (e, descrip) => {
    let listaPersonalizada = personalizacionState.listaPersonalizada;
    let listaNew = [];
    if (listaPersonalizada.length > 0) {
      listaPersonalizada = listaPersonalizada.map((v) => {
        if (v.DescPersonalizacion != descrip) {
          listaNew.push(v);
        }
      });
      listaNew.push({
        DescPersonalizacion: descrip,
        DatoPersonalizacion: e.target.value,
      });
    } else {
      listaNew.push({
        DescPersonalizacion: descrip,
        DatoPersonalizacion: e.target.value,
      });
    }

    dispatch(setListaPersonalizacion({ listaPersonalizada: listaNew }));
  };

  const onChangeFormato = (e, descrip) => {
    let listaPersonalizada = personalizacionState.listaPersonalizada;
    let listaNew = [];
    if (listaPersonalizada.length > 0) {
      listaPersonalizada = listaPersonalizada.map((v) => {
        if (v.DescPersonalizacion != descrip) {
          listaNew.push(v);
        }
      });
      if (e != null) {
        listaNew.push({
          DescPersonalizacion: descrip,
          DatoPersonalizacion: e.value,
        });
      }
    } else {
      if (e != null) {
        listaNew.push({
          DescPersonalizacion: descrip,
          DatoPersonalizacion: e.value,
        });
      }
    }

    dispatch(setListaPersonalizacion({ listaPersonalizada: listaNew }));
  };

  const onChangeLabel = (e, descrip) => {
    let listaPersonalizada = personalizacionState.listaPersonalizada;
    let listaNew = [];
    if (listaPersonalizada.length > 0) {
      listaPersonalizada = listaPersonalizada.map((v) => {
        if (v.DescPersonalizacion != descrip) {
          listaNew.push(v);
        }
      });
      if (e != null) {
        listaNew.push({
          DescPersonalizacion: descrip,
          DatoPersonalizacion: e.label,
        });
      }
    } else {
      if (e != null) {
        listaNew.push({
          DescPersonalizacion: descrip,
          DatoPersonalizacion: e.label,
        });
      }
    }

    dispatch(setListaPersonalizacion({ listaPersonalizada: listaNew }));
  };

  const onSave = async () => {
    let oParam = {};
    oParam = {
      IDUsuario: authState.user.uid,
      Personalizacion: personalizacionState.listaPersonalizada,
    };
    const responseCrearPersonalizacion = await callEndpoint(crearPersonalizacion(oParam));

    if (responseCrearPersonalizacion.data.cabecera.statusCode === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se guardo correctamente la personalización.',
        showConfirmButton: false,
        timer: 2000,
      });
      await loadPersonalizacion();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: responseCrearPersonalizacion.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const listTextMoneda = [
    { value: 'codigo', label: 'codigo' },
    { value: 'nombre', label: 'nombre' },
    { value: 'codigo-nombre', label: 'codigo-nombre' },
  ];

  return (
    <Content>
      <h1>Datos Personalizados</h1>
      <ContentForm>
        <RowForm style={{ alignItems: 'flex-start' }}>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <ContainerForm title={'Obligatorios'}>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Lenguaje (*):'}></LabelForm>
                  <SelectForm
                    bValid={true}
                    disableClearable={true}
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Lenguaje'
                      ).length > 0
                        ? listaLenguaje.filter(
                            (v) =>
                              v.value ==
                              personalizacionState.listaPersonalizada.filter(
                                (v) => v.DescPersonalizacion == 'Lenguaje'
                              )[0].DatoPersonalizacion
                          )
                        : []
                    }
                    handleChange={(evnt, v) => onChangeFormato(v, 'Lenguaje')}
                    options={listaLenguaje}
                    placeholder="Seleccione Lenguaje"
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm obligatorio={true} title={'Formato (*):'}></LabelForm>

                  <SelectForm
                    bValid={true}
                    disableClearable={true}
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Formato'
                      ).length > 0
                        ? listaFormatoNumero.filter(
                            (v) =>
                              v.value ==
                              personalizacionState.listaPersonalizada.filter(
                                (v) => v.DescPersonalizacion == 'Formato'
                              )[0].DatoPersonalizacion
                          )
                        : []
                    }
                    handleChange={(evnt, v) => onChangeFormato(v, 'Formato')}
                    options={listaFormatoNumero}
                    placeholder="Seleccione Formato"
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm weight="bold" title={'Texto Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={true}
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'texto_moneda'
                      ).length > 0
                        ? listTextMoneda.filter(
                            (v) =>
                              v.value ==
                              personalizacionState.listaPersonalizada.filter(
                                (v) => v.DescPersonalizacion == 'texto_moneda'
                              )[0].DatoPersonalizacion
                          )
                        : []
                    }
                    handleChange={(evnt, v) => onChangeLabel(v, 'texto_moneda')}
                    options={listTextMoneda}
                    placeholder="Seleccione texto Moneda"
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm weight="bold" title={'Moneda (*):'}></LabelForm>
                  <SelectForm
                    bValid={true}
                    disableClearable={true}
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Moneda'
                      ).length > 0
                        ? personalizacionState.listaMoneda.filter(
                            (v) =>
                              v.value ==
                              personalizacionState.listaPersonalizada.filter(
                                (v) => v.DescPersonalizacion == 'Moneda'
                              )[0].DatoPersonalizacion
                          )
                        : []
                    }
                    handleChange={(evnt, v) => onChangeFormato(v, 'Moneda')}
                    options={personalizacionState.listaMoneda}
                    placeholder="Seleccione Moneda"
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm weight="bold" title={'Periodo (*):'}></LabelForm>
                  <SelectForm
                    bValid={true}
                    disableClearable={true}
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Periodo'
                      ).length > 0
                        ? personalizacionState.listaPeriodo.filter(
                            (v) =>
                              v.value ==
                              personalizacionState.listaPersonalizada.filter(
                                (v) => v.DescPersonalizacion == 'Periodo'
                              )[0].DatoPersonalizacion
                          )
                        : []
                    }
                    handleChange={(evnt, v) => onChangeFormato(v, 'Periodo')}
                    options={personalizacionState.listaPeriodo}
                    placeholder="Seleccione Periodo"
                  />
                </RowForm>
              </ColForm>
            </ContainerForm>
          </ColForm>
          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <ContainerForm title={'Flujo Caja'}>
              <RowForm>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RowForm>
                    <LabelForm title={'Estructura : '}></LabelForm>

                    <SelectForm
                      bValid={true}
                      value={
                        personalizacionState.listaPersonalizada.filter(
                          (v) => v.DescPersonalizacion == 'Estructura'
                        ).length > 0
                          ? personalizacionState.listaEstructura.filter(
                              (v) =>
                                v.value ==
                                personalizacionState.listaPersonalizada.filter(
                                  (v) => v.DescPersonalizacion == 'Estructura'
                                )[0].DatoPersonalizacion
                            )
                          : []
                      }
                      handleChange={(evnt, v) => onChangeFormato(v, 'Estructura')}
                      options={personalizacionState.listaEstructura}
                      placeholder="Seleccione Estructura"
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RowForm>
                    <LabelForm title={'Horizonte :'}></LabelForm>
                    <RowDoubleInput>
                      <Input
                        width={'37%'}
                        onKeyPress={(evnt) => onlyNumbers(evnt)}
                        placeholder="Ingrese..."
                        onChange={(e) => onChangeLista(e, 'Horizonte_Num')}
                        value={
                          personalizacionState.listaPersonalizada.filter(
                            (v) => v.DescPersonalizacion == 'Horizonte_Num'
                          ).length > 0
                            ? personalizacionState.listaPersonalizada.filter(
                                (v) => v.DescPersonalizacion == 'Horizonte_Num'
                              )[0].DatoPersonalizacion
                            : ''
                        }
                      ></Input>
                      <SelectForm
                        width={'60%'}
                        bValid={true}
                        value={
                          personalizacionState.listaPersonalizada.filter(
                            (v) => v.DescPersonalizacion == 'Horizonte_Det'
                          ).length > 0
                            ? listaProyeccion.filter(
                                (v) =>
                                  v.value ==
                                  personalizacionState.listaPersonalizada.filter(
                                    (v) => v.DescPersonalizacion == 'Horizonte_Det'
                                  )[0].DatoPersonalizacion
                              )
                            : []
                        }
                        handleChange={(evnt, v) => onChangeFormato(v, 'Horizonte_Det')}
                        options={listaProyeccion}
                        placeholder="Seleccione tiempo"
                      />
                    </RowDoubleInput>
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RowForm>
                    <LabelForm title={'Universo : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      value={
                        personalizacionState.listaPersonalizada.filter(
                          (v) => v.DescPersonalizacion == 'Universo'
                        ).length > 0
                          ? listaUniverso.filter(
                              (v) =>
                                v.value ==
                                personalizacionState.listaPersonalizada.filter(
                                  (v) => v.DescPersonalizacion == 'Universo'
                                )[0].DatoPersonalizacion
                            )
                          : []
                      }
                      handleChange={(evnt, v) => onChangeFormato(v, 'Universo')}
                      options={listaUniverso}
                      placeholder="Seleccione Universo"
                    />
                  </RowForm>
                </ColForm>

                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RowForm>
                    <LabelForm title={'Filtro Personalizado 1 :'}></LabelForm>
                    <SelectForm
                      bValid={true}
                      value={
                        personalizacionState.listaPersonalizada.filter(
                          (v) => v.DescPersonalizacion == 'Filtro_1'
                        ).length > 0
                          ? personalizacionState.listaCaracteristica.filter(
                              (v) =>
                                v.label ==
                                personalizacionState.listaPersonalizada.filter(
                                  (v) => v.DescPersonalizacion == 'Filtro_1'
                                )[0].DatoPersonalizacion
                            )
                          : []
                      }
                      handleChange={(evnt, v) => onChangeLabel(v, 'Filtro_1')}
                      options={personalizacionState.listaCaracteristica}
                      placeholder="Seleccione Filtro"
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RowForm>
                    <LabelForm title={'Filtro Personalizado 2 :'}></LabelForm>
                    <SelectForm
                      bValid={true}
                      value={
                        personalizacionState.listaPersonalizada.filter(
                          (v) => v.DescPersonalizacion == 'Filtro_2'
                        ).length > 0
                          ? personalizacionState.listaCaracteristica.filter(
                              (v) =>
                                v.label ==
                                personalizacionState.listaPersonalizada.filter(
                                  (v) => v.DescPersonalizacion == 'Filtro_2'
                                )[0].DatoPersonalizacion
                            )
                          : []
                      }
                      handleChange={(evnt, v) => onChangeLabel(v, 'Filtro_2')}
                      options={personalizacionState.listaCaracteristica}
                      placeholder="Seleccione Filtro Secundario"
                    />
                  </RowForm>
                </ColForm>
              </RowForm>
            </ContainerForm>
          </ColForm>

          <ColForm xs={24} sm={24} md={12} lg={12} xl={12}>
            <ContainerForm title={'Colores'}>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm title={'Color Primario :'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Color Primario'
                      ).length > 0
                        ? personalizacionState.listaPersonalizada.filter(
                            (v) => v.DescPersonalizacion == 'Color Primario'
                          )[0].DatoPersonalizacion
                        : ''
                    }
                    onChange={(evnt) => onChangeLista(evnt, 'Color Primario')}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm title={'Color Secundario :'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Color Secundario'
                      ).length > 0
                        ? personalizacionState.listaPersonalizada.filter(
                            (v) => v.DescPersonalizacion == 'Color Secundario'
                          )[0].DatoPersonalizacion
                        : ''
                    }
                    onChange={(evnt) => onChangeLista(evnt, 'Color Secundario')}
                  />
                </RowForm>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                <RowForm>
                  <LabelForm title={'Color Terciario :'}></LabelForm>
                  <Input
                    placeholder="Ingrese..."
                    value={
                      personalizacionState.listaPersonalizada.filter(
                        (v) => v.DescPersonalizacion == 'Color Terciario'
                      ).length > 0
                        ? personalizacionState.listaPersonalizada.filter(
                            (v) => v.DescPersonalizacion == 'Color Terciario'
                          )[0].DatoPersonalizacion
                        : ''
                    }
                    onChange={(evnt) => onChangeLista(evnt, 'Color Terciario')}
                  />
                </RowForm>
              </ColForm>
            </ContainerForm>
          </ColForm>
          <RowFooterButton>
            <Button action={onSave} name={'Guardar'} isAction={true} type="secondary" />
          </RowFooterButton>
        </RowForm>
      </ContentForm>
      {loading === true ? <LoadingSpinner /> : <></>}
    </Content>
  );
};
