import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components';
import { RowButton } from '../../../../styles';
import { faList, faLock, faTrash } from '@fortawesome/fontawesome-free-solid';

export const columns = (onOpenDrawerPermiso, onOpenDrawerAcceso, onDeleteRol, t) => {
  return [
    {
      field: 'Descripcion',
      headerName: t('rol.listaRol.1'),
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'permiso',
      headerName: t('rol.listaRol.2'),
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'left',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <Button
              action={(oEvent) => onOpenDrawerPermiso(oEvent, params.row)}
              icon={faLock}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        </>
      ),
    },
    {
      field: 'edit',
      headerName: t('rol.listaRol.3'),
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <Button
              action={(oEvent) => onOpenDrawerAcceso(oEvent, params.row)}
              icon={faList}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        </>
      ),
    },
    {
      field: 'edite',
      headerName: '',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 50,
      renderCell: (params) => (
        <>
          <RowButton>
            <Button
              action={(oEvent) => onDeleteRol(oEvent, params.row)}
              icon={faTrash}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        </>
      ),
    },
  ];
};
