export const createConceptoPosicionAdapter = (user) => ({
  value: user.iIDAgrupador,
  label: user.sNombreAgrupador,
  iIDAgrupador: user.iIDAgrupador,
  sCodigoAgrupador: user.sCodigoAgrupador,
  sNombreAgrupador: user.sNombreAgrupador,
  sTipoConcepto: user.sTipoConcepto,
  sReal_Proyectado: user.sReal_Proyectado,
  sNombreFuncion: user.sNombreFuncion,
  iHabilitado: user.iHabilitado,
})
