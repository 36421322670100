import { useEffect, useState } from 'react';
export const useFetchAndLoad = () => {
  const [loading, setLoading] = useState(false);
  let controller;
  const callEndpoint = async (axiosCall) => {
    if (axiosCall.controller) controller = axiosCall.controller;
    setLoading(true);
    // let result = {};
    let result = null;
    try {
      result = await axiosCall.call;
    } catch (err) {
      result = err;
      setLoading(false);
      //throw err;
    }
    setLoading(false);
    return result;
  };
  const cancelEndpoint = () => {
    setLoading(false);
    controller && controller.abort();
  };
  useEffect(() => {
    return () => {
      cancelEndpoint();
    };
  }, []);
  return { loading, callEndpoint, setLoading };
};

export const useFetch = () => {
  let controller;
  const callEndpointSP = async (axiosCall) => {
    if (axiosCall.controller) controller = axiosCall.controller;
    // let result = {};
    let result = null;
    try {
      result = await axiosCall.call;
    } catch (err) {
      result = err;
      //throw err;
    }
    return result;
  };
  const cancelEndpointSP = () => {
    controller && controller.abort();
  };
  useEffect(() => {
    return () => {
      cancelEndpointSP();
    };
  }, []);
  return { callEndpointSP };
};
