import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
const useAuth = () => {
  let token;
  const _user = localStorage.getItem('x-token');
  if (_user) {
    token = _user;
  }
  return token;
};
const ProtectedRoutes = () => {
  const token = useAuth();
  return token ? <Outlet /> : <Navigate to="/login" />;
};
export default ProtectedRoutes;
