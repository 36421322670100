export var initialFormAgregarRegM = {
  fMonedaPago: '',
  bMonedaPago: null,
  fCliente: '',
  bValidPosicion: null,
  fMonto: '',
  bValidMonto: null,
  fMoneda: '',
  bValidMoneda: null,
  dateRegistroM: null,
  dateFechaVigenciaRegistroM: null,
  listaConceptos: [],
  selectConcepto: [],
  listaPosicionManual: [],
  selectPosicion: [],
  objSeleccion: null,
  rowsKeyData: [],
  bValidListEtiquetaManual: null,
  listarEtiquetasManual: [],
  selectListEtiquetaManual: [],
  selectMoneda: [],
  bValidMonedaPago: null,
  selectMonedaPago: [],
};

export var listaProyeccion = [
  {
    value: '1',
    label: 'Semana(s)',
  },
  {
    value: '2',
    label: 'Mes(es)',
  },
  {
    value: '3',
    label: 'Día(s)',
  },
];
