import { permisos } from '../../../../../common';
import { PBRFunction } from '../../../../../components';

export const items = (
  onClickEliminarConcepto,
  exportToExcel,
  apiData,
  fileName,
  mapColumnsDownloadExcel,
  copiarPosicion
) => [
  {
    label: 'Eliminar',
    key: '1',
    onClick: onClickEliminarConcepto,
    disabled: PBRFunction([permisos.ELIMINAR_POSICION]),
  },
  {
    label: 'Exportar',
    key: '2',
    onClick: () => exportToExcel(apiData, fileName, mapColumnsDownloadExcel),
  },
  {
    label: 'Copiar',
    key: '3',
    onClick: copiarPosicion,
  },
];
