import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  onChangeMoneda,
  onChangeMonedaDashboard,
  onCleanDashboard,
  onSetListarDashboard,
  setListaMonedaPersonalizacion,
  updateTitle,
} from '../../redux/states';
import PageContainer from '../../components/Container/PageContainer';
import {
  finazonPruebas,
  generarDatosDashboardRed,
  obtenerAgentes,
  obtenerListadoMoneda,
} from '../../services/public.service';
import { useFetchAndLoad } from '../../common/hooks';
import {
  ActualPartners,
  AvailableSolution,
  Expenses,
  NumberSolution,
  SalesMonth,
  TopClient,
  SalesOverview,
  MonthlyEarnings,
  TipoCambio,
  Income,
  Market,
  Balances,
  Incomes,
} from './components';
import { createAgenteSelectAdapter, createMonedaAdapter, versionDashboard } from '../../adapters';
import { ColFlex, ColDash, RowFlex, RowForm } from '../../styles';
import { useTranslation } from 'react-i18next';
import { PBR } from '../../components';
import { permisos } from '../../common';

export const DashboardDetalle = () => {
  const dashboardState = useSelector((store) => store.dashboard);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [loadingDash, setLoadingDash] = useState(true);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(onCleanDashboard());
      dispatch(updateTitle({ title: t('dashboard.titulo') }));
      loadAgente();
      loadMoneda();
      loadDashboard();
    })();
  }, []);

  useEffect(() => {
    if (
      personalizacionState.listaPersonalizada.length > 0 &&
      personalizacionState.listaMoneda.length > 0
    ) {
      dispatch(
        onChangeMonedaDashboard({
          moneda: personalizacionState.listaMoneda.filter(
            (v) =>
              v.value ==
              personalizacionState.listaPersonalizada.filter(
                (v) => v.DescPersonalizacion == 'Moneda'
              )[0].DatoPersonalizacion
          )[0].sAbreviatura,
        })
      );
    }
  }, [personalizacionState.listaPersonalizada, personalizacionState.listaMoneda]);

  const loadMoneda = async () => {
    let aListaMoneda = [];

    let tipoMoneda = null;
    var { data } = await callEndpoint(obtenerListadoMoneda());
    data.respuesta.aListaMoneda.forEach(function (item, index) {
      var o = createMonedaAdapter(item, tipoMoneda);
      aListaMoneda.push(o);
    });
    dispatch(setListaMonedaPersonalizacion({ listaMoneda: aListaMoneda }));
  };

  const loadDashboard = async () => {
    // const responseFinan = await callEndpoint(finazonPruebauseTranslationuseTranslations());

    const responseDashboard = await callEndpoint(generarDatosDashboardRed());
    // const responseDashboardr = await callEndpoint(generarDatosDashboard());
    let listaSol = [],
      listaVal = [],
      listaVer = [];
    for (let index = 0; index < responseDashboard.data.respuesta.aListaSoluciones.length; index++) {
      const element = responseDashboard.data.respuesta.aListaSoluciones[index];
      listaSol.push(element);
    }
    for (let index = 0; index < responseDashboard.data.respuesta.aListaValores.length; index++) {
      const element = responseDashboard.data.respuesta.aListaValores[index];
      listaVal.push(element);
    }
    for (let index = 0; index < responseDashboard.data.respuesta.aListaVerReg.length; index++) {
      const element = versionDashboard(responseDashboard.data.respuesta.aListaVerReg[index]);
      listaVer.push(element);
    }
    dispatch(
      onSetListarDashboard({
        listaValores: listaVal,
        listaSoluciones: listaSol,
        listaVersiones: listaVer,
      })
    );
    setLoadingDash(false);
  };

  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());

    let aAgentes = [];

    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;

        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }
    dispatch(
      onSetListarDashboard({
        listaAgente: aAgentes,
      })
    );
  };

  // return <TitlePage title='DashboardDetalle' />
  return (
    <PBR permiso={[permisos.VER_DASHBOARD]}>
      <PageContainer>
        <Box>
          <RowForm style={{ alignItems: 'flex-start' }}>
            <ColDash xs={24} sm={24} md={24} lg={12} xl={12}>
              <SalesOverview
                titulo={t('dashboard.widget1')}
                data={dashboardState.listaValores}
                version={dashboardState.listaVersiones}
                loading={loadingDash}
              />
            </ColDash>
            <ColDash xs={24} sm={24} md={12} lg={6} xl={6}>
              <RowForm>
                <ColDash xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TipoCambio titulo={t('dashboard.widget2')} loading={loadingDash} />
                </ColDash>
                <ColDash xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Market
                    titulo={t('dashboard.widget3')}
                    data={dashboardState.listaValores}
                    loading={loadingDash}
                  />
                </ColDash>
              </RowForm>
            </ColDash>
            <ColDash xs={24} sm={24} md={12} lg={6} xl={6}>
              <RowForm>
                <ColDash xs={24} sm={24} md={24} lg={24} xl={24}>
                  <MonthlyEarnings
                    titulo={t('dashboard.widget4')}
                    data={dashboardState.listaValores}
                    loading={loadingDash}
                  />
                </ColDash>
                <ColDash xs={24} sm={24} md={24} lg={24} xl={24}>
                  <ActualPartners
                    titulo={t('dashboard.widget5')}
                    dataLength={dashboardState.listaAgente}
                    loading={loadingDash}
                  />
                </ColDash>
              </RowForm>
            </ColDash>
          </RowForm>
          <RowForm style={{ alignItems: 'flex-start' }}>
            <ColDash xs={24} sm={24} md={12} lg={6} xl={6}>
              <Balances
                titulo={t('dashboard.widget6')}
                data={dashboardState.listaValores}
                loading={loadingDash}
              />
            </ColDash>
            <ColDash xs={24} sm={24} md={12} lg={6} xl={6}>
              <Expenses
                titulo={t('dashboard.widget7')}
                data={dashboardState.listaValores}
                loading={loadingDash}
              />
            </ColDash>
            <ColDash xs={24} sm={24} md={12} lg={6} xl={6}>
              <Incomes
                titulo={t('dashboard.widget8')}
                data={dashboardState.listaValores}
                loading={loadingDash}
              />
            </ColDash>
            <ColDash xs={24} sm={24} md={12} lg={6} xl={6}>
              <AvailableSolution
                titulo={t('dashboard.widget9')}
                data={dashboardState.listaSoluciones}
                loading={loadingDash}
              />
            </ColDash>
          </RowForm>
        </Box>
      </PageContainer>
    </PBR>
  );
};
