export const TipoFormularioLineaCredito = [
  {
    Id: 1,
    Cod: 'Tipo_1',
    value: 'Tipo_1',
    Nombre: 'Tipo 1',
    label: 'Tipo 1',
    Habilitado: 1,
  },
  // {
  //   Id: 2,
  //   Cod: 'Tipo_2',
  //   value: 'Tipo_2',
  //   Nombre: 'Tipo 2',
  //   label: 'Tipo 2',
  //   Habilitado: 1,
  // },
  {
    Id: 3,
    Cod: 'Tipo_3',
    value: 'Tipo_3',
    Nombre: 'Tipo 3',
    label: 'Tipo 3',
    Habilitado: 1,
  },
];
