import React from 'react';
import { Modal } from 'antd';
import { Button, InputNumberFormat, LabelForm, SelectForm } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeModalTCC } from '../../../../../redux/states';
import { ColForm, DatePickerForm, RowForm, Title } from '../../../../../styles';
import moment from 'moment';

export const ModalTipoCambio = (props) => {
  const tipoCambioState = useSelector((store) => store.tipoCambio);

  const dispatch = useDispatch();

  const handleChangeFecha = (oEvent) => {
    const formatDate = moment(oEvent[0]).format('DD/MM/YYYY');
    let oParam = {
      fechaTipoCambio: formatDate,
    };
    dispatch(onChangeModalTCC(oParam));
  };

  const handleChangeRespecto = (e) => {
    let oParam = {};
    let validate = null;
    oParam.respectoTipoCambio = [];
    oParam.bValidRespectoTipoCambio = validate;
    if (e) {
      oParam.respectoTipoCambio = [e];
      oParam.bValidRespectoTipoCambio = true;
    }
    dispatch(onChangeModalTCC(oParam));
  };

  const handleChangeMoneda = (e) => {
    let oParam = {};
    let validate = null;
    oParam.monedaTipoCambio = [];
    oParam.bValidMonedaTipoCambio = validate;
    if (e) {
      oParam.monedaTipoCambio = [e];
      oParam.bValidMonedaTipoCambio = true;
    }
    dispatch(onChangeModalTCC(oParam));
  };

  const handleChangeImporte = (values) => {
    // var monto = oEvent.target.value;
    debugger;
    var importe = values.value;
    var validate = null;
    if (importe.length > -1) {
      validate = true;
    }
    var oParam = {
      importeTipoCambio: importe,
      bValidImporteTipoCambio: validate,
    };
    dispatch(onChangeModalTCC(oParam));
  };

  const handleChangeFactor = (values) => {
    // var monto = oEvent.target.value;
    var factor = values.value;
    var validate = null;
    if (factor.length > 0) {
      validate = true;
    }
    var oParam = {
      factorTipoCambio: factor,
      bValidFactorTipoCambio: validate,
    };
    dispatch(onChangeModalTCC(oParam));
  };

  return (
    <>
      <Modal
        open={props.open}
        title={<Title>Crear tipo de cambio</Title>}
        closable={false}
        footer={[
          <Button
            action={(oEvent) => props.handleCloseModal(oEvent)}
            name={'Cerrar'}
            isAction={true}
            type="warning"
          />,
          <Button
            style={{ marginLeft: '5px' }}
            action={(oEvent) => props.handleGuardarModal(oEvent)}
            name={'Guardar'}
            isAction={true}
            type="secondary"
          />,
        ]}
      >
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Fecha : '}></LabelForm>
            <DatePickerForm
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              isClearable={true}
              placeholderText="Ingrese"
              locale="es"
              showMonthDropdown
              showYearDropdown
              portalId="root-portal"
              value={tipoCambioState.fechaTipoCambio}
              onChange={(evnt) => handleChangeFecha(evnt)}
              minDate={new Date()}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Moneda base : '}></LabelForm>
            <SelectForm
              validate={tipoCambioState.bValidRespectoTipoCambio}
              value={tipoCambioState.respectoTipoCambio}
              handleChange={(e, v) => handleChangeRespecto(v)}
              options={tipoCambioState.listaMoneda}
              placeholder={'Seleccione Moneda Respecto'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Moneda destino : '}></LabelForm>
            <SelectForm
              validate={tipoCambioState.bValidMonedaTipoCambio}
              value={tipoCambioState.monedaTipoCambio}
              handleChange={(e, v) => handleChangeMoneda(v)}
              options={tipoCambioState.listaMoneda}
              placeholder={'Seleccione Moneda'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Factor : '}></LabelForm>

            <SelectForm
              validate={tipoCambioState.bValidFactorTipoCambio}
              value={
                tipoCambioState.factorTipoCambio
                  ? [
                      {
                        value: tipoCambioState.factorTipoCambio,
                        label: tipoCambioState.factorTipoCambio,
                      },
                    ]
                  : []
              }
              handleChange={(e, v) => handleChangeFactor(v)}
              options={[
                { value: 1, label: 1 },
                { value: 10, label: 10 },
                { value: 100, label: 100 },
                { value: 1000, label: 1000 },
                { value: 10000, label: 10000 },
                { value: 100000, label: 100000 },
              ]}
              placeholder={'Seleccione Factor'}
            />
          </RowForm>
        </ColForm>
        <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
          <RowForm>
            <LabelForm title={'Importe : '}></LabelForm>
            <InputNumberFormat
              validate={tipoCambioState.bValidImporteTipoCambio}
              value={tipoCambioState.importeTipoCambio}
              allowNegative={false}
              fixedDecimalScale={true}
              decimalScale={4}
              onValueChange={(values) => handleChangeImporte(values)}
            />
          </RowForm>
        </ColForm>
      </Modal>
    </>
  );
};
