import React from 'react';
import { Typography } from '@mui/material';
import { CardDash, ColForm, RowForm, Title } from '../../../styles';
import { LoadingForm } from '../../../components';
import { theme } from '../../../common';

export const ActualPartners = (props) => {
  return (
    <CardDash title={<Title>{props.titulo}</Title>} size={'medium'}>
      {props.loading ? (
        <LoadingForm />
      ) : (
        <RowForm style={{ height: '11.5vh' }}>
          <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ alignSelf: 'center' }}>
            <Typography
              variant="h3"
              fontWeight="700"
              style={{ textAlign: 'center', fontFamily: theme.font.family }}
            >
              {props.dataLength.length}
            </Typography>
          </ColForm>
        </RowForm>
      )}
    </CardDash>
  );
};
