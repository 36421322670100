import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
import moment from 'moment';
import { listaUniverso } from '../../common';

export const flujoCajaInitialState = {
  disableParametrosFC: true,
  IDFlujoCajaGeneral: null,
  aplicarBusqueda: null,
  searchtext: '',
  modalOpenEliminarFlujoCaja: false,
  modalEliminarConfiguracionFlujoCaja: false,
  modalOpenModalRegM: false,
  modalOpenModalMoverFechaM: false,
  startLoading: false,
  loadingFlujoCaja: false,
  listaFlujoCajas: [],
  filterListaFlujoCajas: [],
  successDeleteFlujoCaja: 0,
  modalOpenCancelarCrearFlujoCaja: false,
  modalOpenVersionFlujoCaja: false,
  listaVersionesFlujoCaja: [],
  idVersion: null,
  selectVersionFlujoCaja: [],
  oDetalleVersionFlujoCaja: null,
  oFlujoCajaAgregar: {
    detalleFlujoCajaCabeceras: [],
    detalleFlujoCajaContenido: [],
    detalleFlujoCajaContenidoIngreso: [],
    detalleFlujoCajaContenidoEgreso: [],
  },
  detalleFlujoCajaCabecerasInicial: [],
  detalleFlujoCajaCabecerasSolucion: [],
  aListaSolucionesSumarizada: [],
  //Cabeceras-------
  sVersion: '',
  iIdEstructura: null,
  sEstructura: '',
  bValidEstructura: null,
  sNombre: '',
  bValidNombre: null,
  iIdFechaPeriodo: null,
  sCodFechaPeriodo: '',
  sFechaPeriodo: '',
  bValidFechaPeriodo: null,
  iIdMoneda: null,
  sMoneda: '',
  sAbreviatura: '',
  bValidMoneda: null,
  sDescripcion: '',
  bValidDescripcion: null,
  dDateRange: [new Date(), new Date()],
  sFechaInicio: '',
  sFechaFinal: '',
  bValidFecha: null,
  iProyeccion: 0,
  iIdtiempoProyeccion: null,
  sTiempoProyeccion: '',
  //Fin Cabeceras-----
  aListaEstructurasBaseCrearFlujoCaja: [],
  aTiemposPeriodoCrearFlujoCaja: [],
  aMonedasCrearFlujoCaja: [],
  successRegistrarFlujoCaja: 0,
  //ModalFiltros
  modalOpenFiltrosDetalleFlujoCaja: false,
  listaVersionFiltroDetalleFlujoCaja: [],
  selectVersionFiltroDetalleFlujoCaja: [],
  selectVersionSolucionDetalleFlujoCaja: [],
  aFiltrosDetalleFlujoCaja: [],
  aFiltrosDetalleFlujoCajaSolucion: [],
  //MODAL DE REGLAS:
  modalOpenRestriccionesDetalleFlujoCaja: false,
  listaVersionRestriccionesDetalleFlujoCaja: [],
  selectVersionRestriccionesDetalleFlujoCaja: [],
  aRestriccionesDetalleFlujoCaja: [],
  aListaTipoReglas: [
    {
      value: 'Monto mínimo',
      label: 'Monto mínimo',
    },
    {
      value: 'Aplazar',
      label: 'Aplazar',
    },
    {
      value: 'Aplazar con Moneda',
      label: 'Aplazar con Moneda',
    },
    {
      value: 'No aplazar a',
      label: 'No aplazar a',
    },
    {
      value: 'No Solucionar con',
      label: 'No Solucionar con',
    },
    {
      value: 'Priorizacion Moneda',
      label: 'Priorizacion Moneda',
    },
    {
      value: 'Priorizacion Agente',
      label: 'Priorizacion Agente',
    },
  ],
  aListaSolucionesRegla: [
    {
      value: 'Línea de Crédito',
      label: 'Línea de Crédito',
    },
    {
      value: 'Préstamo',
      label: 'Préstamo',
    },
    {
      value: 'Aplazamiento',
      label: 'Aplazamiento',
    },
    {
      value: 'Factoring',
      label: 'Factoring',
    },
    {
      value: 'Inversión',
      label: 'Inversión',
    },
    {
      value: 'Tipo de Cambio',
      label: 'Tipo de Cambio',
    },
  ],
  aListaObligatorio: [
    {
      value: 'Obligatorio',
      label: 'Obligatorio',
    },
    {
      value: 'No Obligatorio',
      label: 'No Obligatorio',
    },
  ],
  selectIdRegla: '',
  selectSponsor: [],
  selectNecesidad: [],
  selectTipoRegla: [],
  selectObligatorio: [],
  selectSolucionRegla: [],
  aListaValorRegla: [],
  selectValor: [],
  aListaPriorizacionMoneda: [],
  aListaPriorizacionAgente: [],
  aListaCaracteristica: [],
  aListaAgente: [],
  selectAgente: [],
  aListaSeleccionAgente: [],
  selectCaracteristica: [],
  aMonto: 0,
  aListaRegla: [],
  aVersionesDetalleFlujoCaja: [
    {
      nombre: 'version1',
      aContenido: [],
    },
    {
      nombre: 'version2',
      aContenido: [],
    },
    {
      nombre: 'version3',
      aContenido: [],
    },
    {
      nombre: 'version4',
      aContenido: [],
    },
  ],

  listaVersionSolucionDetalleFlujoCaja: [],

  //Panel de filtros
  listaPosicionesFiltros: [],
  tabProceso: 0,
  aListaSoluciones: [],
  labelsChartFC: [],
  datasetsChartFC: [],

  selectListaSoluciones: [],
  aListaAgrupadaXSoluciones: [],
  selectListaAgrupadaXSoluciones: [],
  detalleListaAgrupadaXSoluciones: [],
  detalleListaAgrupadaXSolucionesTotal: [],
  detalleListaAgrupadaXSolucionesParcial: [],
  selectEtiquetaFlujoCaja: [],
  listarEtiquetasFlujoCaja: [],
  selectedDesdoblesInicialTableSolucionFC: [],
  selectedDesdoblesInicialTableXSolucionesFC: [],
  //SOLUCIONES - MODAL
  modalSolucionesFlujoCaja: false,
  listaModalSolFlujoCaja: [],
  filterListaModalSolFlujoCaja: [],
  //Nuevo x Yakon
  listaModalSolFlujoCajaIni: [],
  //
  searchModalSolSearchFlujoCaja: '',
  tipoSolucionesFlujoCaja: [],
  selectTipoSoluFlujocaja: [],
  selectTipoEstadoSolFlujoCaja: [],
  listaTipoEstadoSolFlujoCaja: [],
  selectSolucionesModalFlujoCaja: [],
  dDateVigenciaSoluFlujoCaja: [null, null],
  bValidDateVigenciaSoluFlujoCaja: null,
  //estados para manejar sucesos
  listaSucesosCreados: [],
  listaSucesosModificados: [],
  listaSucesosEliminados: [],
  //Optimizacion
  idOptimiacion: null,
  //Tabla flujo Caja:
  collapseDesdobles: null,
  //ModalAgregarFLujoCaja
  modalOpenAgregarNombreFlujoCaja: false,
  //Periodicidad/Moneda
  selectPeridiocidadTableFC: [],
  selectPeridiocidadTableSolucionFC: [],
  selectMonedaTableFC: [],
  aListaTC: [],
  factorMonedaTableTC: [{ valor: 1 }],
  //
  selectMonedaTableFCInicial: [],
  logSolucion: [],
  labelsChartFI: [],
  datasetsChartFI: [],

  universo: [listaUniverso[0]],
  listFiltroCaract: [],
};
export const flujoCajaSlice = createSlice({
  name: 'flujoCaja',
  initialState: flujoCajaInitialState,
  reducers: {
    onOpenModalEliminarFlujoCaja: (state, { payload }) => {
      state.modalEliminarConfiguracionFlujoCaja = payload;
    },
    onOpenModalFiltrosDetalleFlujoCaja: (state, { payload }) => {
      state.modalOpenFiltrosDetalleFlujoCaja = payload;
    },
    onOpenModalRegM: (state, { payload }) => {
      state.modalOpenModalRegM = payload;
    },
    onOpenModalMoverFechaM: (state, { payload }) => {
      state.modalOpenModalMoverFechaM = payload;
    },
    onOpenModalRestriccionesDetalleFlujoCaja: (state, { payload }) => {
      state.modalOpenRestriccionesDetalleFlujoCaja = payload;
    },
    onStartLoadingFlujoCaja: (state, { payload }) => {
      state.loadingFlujoCaja = payload;
    },
    onSetFlujoCajas: (state, { payload }) => {
      state.listaFlujoCajas = payload;
    },
    onSetAplicarBusquedaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    filterListarFlujoCajas: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaFlujoCajas = estadoInspeccionado.listaFlujoCajas.filter(function (
        item,
        index
      ) {
        return (
          item.CodigoFlujoCaja.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.NombreFlujoGeneral.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.DetalleFlujoGeneral.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.FechaOptimizacion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.NombreVersion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.NombreEstructura.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaFlujoCajas: filterListaFlujoCajas,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    onSetDetalleFlujoCajaCabeceras: (state, { payload }) => {
      state.oFlujoCajaAgregar.detalleFlujoCajaCabeceras = payload;
    },
    onSetDetalleFlujoCajaCabecerasSolucion: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetDetalleFlujoCajaCabecerasInicial: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetDetalleFlujoCajaContenido: (state, { payload }) => {
      state.oFlujoCajaAgregar.detalleFlujoCajaContenido = payload;
    },
    onSuccessDeleteFlujoCaja: (state, { payload }) => {
      state.successDeleteFlujoCaja = state.successDeleteFlujoCaja + 1;
    },
    onOpenModalCancelarCrearFlujoCaja: (state, { payload }) => {
      state.modalOpenCancelarCrearFlujoCaja = payload;
    },
    onChangeVersion: (state, action) => {
      return { ...state, ...action.payload };
    },
    //Crear FlujoCaja - Campo Codigo
    onChangeCodigoCrearFlujoCaja: (state, { payload }) => {
      state.sCodigo = payload.sCodigo;
      state.bValidCodigo = payload.validate;
    },
    onSetValidCodigoCrearFlujoCaja: (state, { payload }) => {
      state.bValidCodigo = payload;
    },
    //Crear FlujoCaja - Campo Nombre
    onChangeNombreCrearFlujoCaja: (state, { payload }) => {
      state.sNombre = payload.sNombre;
      state.bValidNombre = payload.validate;
    },
    onSetValidNombreCrearFlujoCaja: (state, { payload }) => {
      state.bValidNombre = payload;
    },
    onChangeEstructuraCrearFlujoCaja: (state, { payload }) => {
      state.iIdEstructura = payload.iIdEstructura;
      state.sEstructura = payload.sEstructura;
      state.bValidEstructura = payload.validate;
    },
    onSetValidEstructuraCrearFlujoCaja: (state, { payload }) => {
      state.bValidEstructura = payload;
    },
    onChangeFechaPeriodoCrearFlujoCaja: (state, { payload }) => {
      state.iIdFechaPeriodo = payload.iIdFechaPeriodo;
      state.sCodFechaPeriodo = payload.sCodFechaPeriodo;
      state.sFechaPeriodo = payload.sFechaPeriodo;
      state.bValidFechaPeriodo = payload.validate;
    },
    onSetValidFechaPeriodoCrearFlujoCaja: (state, { payload }) => {
      state.bValidFechaPeriodo = payload;
    },
    onChangeMonedaCrearFlujoCaja: (state, { payload }) => {
      state.iIdMoneda = payload.iIdMoneda;
      state.sMoneda = payload.sMoneda;
      state.sAbreviatura = payload.sAbreviatura;
      state.bValidMoneda = payload.validate;
    },
    onSetValidMonedaCrearFlujoCaja: (state, { payload }) => {
      state.bValidMoneda = payload;
    },
    onChangeDescripcionCrearFlujoCaja: (state, { payload }) => {
      state.sDescripcion = payload.sDescripcion;
      state.bValidDescripcion = payload.validate;
    },
    onChangeFechaCrearFlujoCaja: (state, { payload }) => {
      state.dDateRange = [payload.dFechaInicio, payload.dFechaFin];
      state.sFechaInicio = payload.sFechaInicio;
      state.sFechaFinal = payload.sFechaFinal;
      state.bValidFecha = payload.validate;
    },
    onChangeProyeccion: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeTiempoPeridiocidad: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetValidFechaCrearFlujoCaja: (state, { payload }) => {
      state.bValidFecha = payload;
    },
    //Métodos para Crear Posición
    onSetEstructurasBaseCrearFlujoCaja: (state, { payload }) => {
      state.aListaEstructurasBaseCrearFlujoCaja = payload;
    },
    onSetPeriodoCrearFlujoCaja: (state, { payload }) => {
      state.aTiemposPeriodoCrearFlujoCaja = payload;
    },
    onSetMonedasCrearFlujoCaja: (state, { payload }) => {
      state.aMonedasCrearFlujoCaja = payload;
    },
    onSetTipoCambioFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSuccessRegistrarFlujoCaja: (state, { payload }) => {
      state.successRegistrarFlujoCaja = state.successRegistrarFlujoCaja + 1;
    },
    onLimpiarCamposFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onLimpiarCamposGeneralesFlujoCaja: (state, action) => {
      var obj = {};
      //Limpiar Cabeceras
      obj.sVersion = '';
      obj.iIdEstructura = null;
      obj.sEstructura = '';
      obj.bValidEstructura = null;
      obj.sNombre = '';
      obj.bValidNombre = null;
      obj.iIdFechaPeriodo = null;
      obj.sCodFechaPeriodo = '';
      obj.sFechaPeriodo = '';
      obj.bValidFechaPeriodo = null;
      obj.iIdMoneda = null;
      obj.sMoneda = '';
      obj.sAbreviatura = '';
      obj.bValidMoneda = null;
      obj.sDescripcion = '';
      obj.bValidDescripcion = null;
      obj.dDateRange = [new Date(), new Date()];
      obj.sFechaInicio = '';
      obj.sFechaFinal = '';
      obj.bValidFecha = null;
      obj.iProyeccion = 0;
      obj.iIdtiempoProyeccion = null;
      obj.sTiempoProyeccion = '';
      obj.selectVersionFlujoCaja = [];
      obj.modalSolucionesFlujoCaja = false;
      obj.listaModalSolFlujoCaja = [];
      obj.filterListaModalSolFlujoCaja = [];
      obj.searchModalSolSearchFlujoCaja = '';
      // state.tipoSolucionesFlujoCaja = [];
      obj.selectSolucionesDetalleFlujoCaja = [];
      obj.selectTipoSoluFlujocaja = [];
      obj.selectTipoEstadoSolFlujoCaja = [];
      obj.selectSolucionesModalFlujoCaja = [];
      obj.dDateVigenciaSoluFlujoCaja = [null, null];
      obj.bValidDateVigenciaSoluFlujoCaja = null;
      //estados para manejar sucesos
      obj.listaSucesosCreados = [];
      obj.listaSucesosModificados = [];
      obj.listaSucesosEliminados = [];
      obj.idOptimiacion = null;
      //Filtros
      obj.selectVersionFiltroDetalleFlujoCaja = [];
      obj.aFiltrosDetalleFlujoCaja = [];
      //Restricciones
      obj.selectVersionRestriccionesDetalleFlujoCaja = [];
      obj.aRestriccionesDetalleFlujoCaja = [];

      //Periodicidad/Moneda
      obj.selectPeridiocidadTableFC = [];
      obj.selectPeridiocidadTableSolucionFC = [];
      obj.selectMonedaTableFC = [];
      obj.selectMonedaTableFCInicial = [];
      obj.selectMonedaTableSolucionFC = [];
      obj.factorMonedaTableTC = [{ valor: 1 }];
      obj.factorMonedaTableSolucionFTC = [{ valor: 1 }];
      obj.detalleFlujoCajaCabecerasInicial = [];
      obj.detalleFlujoCajaCabecerasSolucion = [];
      obj.aListaSolucionesSumarizada = [];
      //Panel de filtros
      obj.listaPosicionesFiltros = [];
      obj.tabProceso = 0;
      obj.selectVersionSolucionDetalleFlujoCaja = [];
      obj.aListaSoluciones = [];
      obj.labelsChartFC = [];
      obj.datasetsChartFC = [];
      obj.selectListaSoluciones = [];
      obj.aListaAgrupadaXSoluciones = [];
      obj.selectListaAgrupadaXSoluciones = [];
      obj.detalleListaAgrupadaXSoluciones = [];
      obj.detalleListaAgrupadaXSolucionesTotal = [];
      obj.detalleListaAgrupadaXSolucionesParcial = [];
      obj.selectEtiquetaFlujoCaja = [];
      return { ...state, ...obj };
    },
    onLimpiarCamposCrearFlujoCaja: (state, action) => {
      var obj = {};
      obj.oFlujoCajaAgregar = {
        oVersionSeleccionada: null,
        sCodigo: '',
        bValidCodigo: null,
        iIdEstructura: null,
        sEstructura: '',
        bValidEstructura: null,
        sNombre: '',
        bValidNombre: null,
        iIdFechaPeriodo: null,
        sFechaPeriodo: '',
        bValidFechaPeriodo: null,
        iIdMoneda: null,
        sMoneda: '',
        sAbreviatura: '',
        bValidMoneda: null,
        sDescripcion: '',
        bValidDescripcion: null,
        dDateRange: [null, null],
        sFechaInicio: '',
        sFechaFinal: '',
        bValidFecha: null,
        detalleFlujoCajaCabeceras: [],
        detalleFlujoCajaContenido: [],
        detalleFlujoCajaContenidoIngreso: [],
        detalleFlujoCajaContenidoEgreso: [],
        selectEtiquetaFlujoCaja: [],
      };
      obj.modalSolucionesFlujoCaja = false;
      obj.listaModalSolFlujoCaja = [];
      obj.filterListaModalSolFlujoCaja = [];
      obj.searchModalSolSearchFlujoCaja = '';
      // state.tipoSolucionesFlujoCaja = [];
      obj.selectTipoSoluFlujocaja = [];
      obj.selectTipoEstadoSolFlujoCaja = [];
      obj.selectSolucionesModalFlujoCaja = [];
      obj.dDateVigenciaSoluFlujoCaja = [null, null];
      obj.bValidDateVigenciaSoluFlujoCaja = null;
      //estados para manejar sucesos
      obj.listaSucesosCreados = [];
      obj.listaSucesosModificados = [];
      obj.listaSucesosEliminados = [];
      obj.idOptimiacion = null;
      return { ...state, ...obj };
    },
    onChangeVersionRestriccionesDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeVersionFiltroDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetFiltroDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaVersionFiltroDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaVersionRestriccionesDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onAddFiltroDetalleFlujoCaja: (state, { payload }) => {
      state.aFiltrosDetalleFlujoCaja = [...state.aFiltrosDetalleFlujoCaja, payload];
    },
    onSetAgente: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeVersionSolucionDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetRestriccionesDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaVersionSolucionDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },

    onAddRestriccionDetalleFlujoCaja: (state, { payload }) => {
      state.aRestriccionesDetalleFlujoCaja = [...state.aRestriccionesDetalleFlujoCaja, payload];
    },
    onDeleteFiltroDetalleFlujoCaja: (state, { payload }) => {
      var oState = current(state);
      const aFiltrosDetalleFlujoCajaFinal = [...oState.aFiltrosDetalleFlujoCaja];
      aFiltrosDetalleFlujoCajaFinal.splice(payload.index, 1);
      state.aFiltrosDetalleFlujoCaja = aFiltrosDetalleFlujoCajaFinal;
    },
    onChangeRowFPosicionFiltroDetalleFlujoCaja: (state, { payload }) => {
      var oState = current(state);
      state.aFiltrosDetalleFlujoCaja.map((item, index) => {
        if (index === payload.index) {
          item.selectPosicion = payload.selectPosicion;
        }
        return { ...item };
      });
      //   return updatedItems
    },
    onChangeRowFSetListaCaracteristicasFiltroDetalleFlujoCaja: (state, { payload }) => {
      var oState = current(state);
      state.aFiltrosDetalleFlujoCaja.map((item, index) => {
        if (index === payload.index) {
          item.listaCaracteristica = payload.listaCaracteristica;
        }
        return { ...item };
      });
      //   return updatedItems
    },
    onChangeRowFCaracteristicailtroDetalleFlujoCaja: (state, { payload }) => {
      var oState = current(state);
      state.aFiltrosDetalleFlujoCaja.map((item, index) => {
        if (index === payload.index) {
          item.selectCaracteristica = payload.selectCaracteristica;
        }
        return { ...item };
      });
      //   return updatedItems
    },
    onChangeRowFSetListaValoresFiltroDetalleFlujoCaja: (state, { payload }) => {
      state.aFiltrosDetalleFlujoCaja.map((item, index) => {
        if (index === payload.index) {
          item.listaValor = payload.listaValor;
          item.listaValorPlano = payload.listaValorPlano;
        }
        return { ...item };
      });
    },
    onChangeRowFValoresFiltroDetalleFlujoCaja: (state, { payload }) => {
      state.aFiltrosDetalleFlujoCaja.map((item, index) => {
        if (index === payload.index) {
          item.selectValor = payload.selectValor;
        }
        return { ...item };
      });
    },
    onChangeTipoRestriccionDetalleFLujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeListaReglaS: (state, action) => {
      let obj = { aListaRegla: action.payload };
      return { ...state, ...obj };
    },

    onSelectCaracteristicasReglas: (state, action) => {
      let obj = { aListaCaracteristica: action.payload };
      return { ...state, ...obj };
    },
    onSelectValorRegla: (state, action) => {
      let obj = {
        aListaValorRegla: action.payload.listaValor,
      };
      return { ...state, ...obj };
    },
    onChangeListaRegla: (state, action) => {
      let lista = [];
      state.aListaRegla.forEach((element) => {
        lista.push({ ...element });
      });
      lista.push(action.payload.oRegla);
      let obj = { aListaRegla: lista };
      return { ...state, ...obj };
    },
    onChangeEditListaRegla: (state, action) => {
      let lista = [];
      state.aListaRegla.forEach((element) => {
        lista.push({ ...element });
      });
      lista[action.payload.indice] = action.payload.edita;
      let obj = { aListaRegla: lista };
      return { ...state, ...obj };
    },
    onChangeTipoObligatorio: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeTipoRegla: (state, action) => {
      return { ...state, ...action.payload };
    },

    onChangeTipoMonto: (state, action) => {
      return { ...state, ...action.payload };
    },

    onChangeTipoMoneda: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeIdRegla: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeTipoRestriccionDetalleFLujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onDeleteRestriccionDetalleFlujoCaja: (state, { payload }) => {
      var oState = current(state);
      const aRestriccionesDetalleFlujoCajaFinal = [...oState.aRestriccionesDetalleFlujoCaja];
      aRestriccionesDetalleFlujoCajaFinal.splice(payload.index, 1);
      state.aRestriccionesDetalleFlujoCaja = aRestriccionesDetalleFlujoCajaFinal;
    },
    changeRowsTipoRestriccionDetalleFLujoCaja: (state, action) => {
      state.aRestriccionesDetalleFlujoCaja.map((item, index) => {
        if (index === action.payload.index) {
          item.selectTipoRegla = action.payload.selectTipoRegla;
        }
        return { ...item };
      });
    },
    changeRowsMontoRestriccionDetalleFLujoCaja: (state, action) => {
      state.aRestriccionesDetalleFlujoCaja.map((item, index) => {
        if (index === action.payload.index) {
          item.valor = action.payload.valor;
        }
        return { ...item };
      });
    },
    changeRowsMonedaRestriccionDetalleFLujoCaja: (state, action) => {
      state.aRestriccionesDetalleFlujoCaja.map((item, index) => {
        if (index === action.payload.index) {
          item.selectMoneda = action.payload.selectMoneda;
        }
        return { ...item };
      });
    },
    onLimpiarFiltroDetalleFlujoCaja: (state, { payload }) => {
      state.aFiltrosDetalleFlujoCaja = [];
      state.selectVersionFiltroDetalleFlujoCaja = [];
    },
    onLimpiarListaRegla: (state, { payload }) => {
      state.aListaRegla = [];
    },
    onLimpiarParamRegla: (state, { payload }) => {
      state.selectTipoRegla = [];
      state.selectIdRegla = '';
      state.selectCaracteristica = [];
      state.aListaValorRegla = [];
      state.selectValor = [];
      state.selectTipoRegla = [];
      state.selectMoneda = [];
      state.selectObligatorio = [];
      state.selectSolucionRegla = [];
      state.selectNecesidad = [];
      state.selectSponsor = [];
      state.aListaPriorizacionMoneda = [];
      state.aMonto = 0;
      state.aListaSeleccionAgente = [];
      state.selectAgente = [];
    },
    onLimpiarMonedaReglaPriorizacion: (state, { payload }) => {
      state.selectNecesidad = [];
      state.selectSponsor = [];
    },
    onLimpiarRestriccionDetalleFlujoCaja: (state, { payload }) => {
      state.aRestriccionesDetalleFlujoCaja = [];
      state.selectVersionRestriccionesDetalleFlujoCaja = [];
    },
    onOpenModalVersionFlujoCaja: (state, { payload }) => {
      state.modalOpenVersionFlujoCaja = payload;
    },
    onSetListaVersionesFlujoCaja: (state, { payload }) => {
      state.listaVersionesFlujoCaja = payload;
    },
    onSelectVersionFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateIdSelectListaVersionFlujoCaja: (state, action) => {
      state.selectVersionFiltroDetalleFlujoCaja.map(function (item, index) {
        item.id = action.payload.id;
        return item;
      });
    },

    updateIdSelectListaSolucionFlujoCaja: (state, action) => {
      state.selectVersionSolucionDetalleFlujoCaja.map(function (item, index) {
        item.id = action.payload.id;
        return item;
      });
    },

    updateIdSelectListaVersionRestriccionesFlujoCaja: (state, action) => {
      state.selectVersionRestriccionesDetalleFlujoCaja.map(function (item, index) {
        item.id = action.payload.id;
        return item;
      });
    },
    onSetDetalleVersionFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetParametrosFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaPosicionesFiltrosFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetTabProceso: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaSoluciones: (state, action) => {
      return { ...state, ...action.payload };
    },
    onGenerateDataLineChartFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeSelectListaSoluciones: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaAgrupadaXSoluciones: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeListaAgruadaXSoluciones: (state, action) => {
      var aEncontrado = state.aListaAgrupadaXSoluciones.filter((element2) => {
        return element2.IdSolucion === action.payload.IdSolucion;
      });
      var obj = {};
      if (aEncontrado.length > 0) {
        obj.selectListaAgrupadaXSoluciones = aEncontrado;
      } else {
        obj.selectListaAgrupadaXSoluciones = [];
      }
      return { ...state, ...obj };
    },
    onSetListaAgrupadaXSolucionesSumarizada: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaAgrupadaXSolucionesTotal: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaAgrupadaXSolucionesParcial: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeCaracteristica: (state, action) => {
      let obj = { selectCaracteristica: action.payload };
      return { ...state, ...obj };
    },
    onChangeValorRegla: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeListaPriorizacionMoneda: (state, action) => {
      let obj = {
        aListaPriorizacionMoneda: [
          ...state.aListaPriorizacionMoneda,
          action.payload.aListaPriorizacionMoneda,
        ],
      };
      return { ...state, ...obj };
    },
    onChangeListaPriorizacionMonedaOr: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeAgente: (state, action) => {
      let obj = {
        aListaSeleccionAgente: [
          ...state.aListaSeleccionAgente,
          action.payload.aListaSeleccionAgente,
        ],
      };
      return { ...state, ...obj };
    },
    onChangeAgenteOr: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeSponsor: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeSolucionRegla: (state, action) => {
      return { ...state, ...action.payload };
    },

    onChangeCollapseDetalleListaAgrupadaXSoluciones: (state, action) => {
      const rowsInput = [...state.detalleListaAgrupadaXSoluciones];
      const oItem = rowsInput[action.payload.index];
      oItem['collapseIcon'] = !oItem['collapseIcon'];
      var aEncontrado = rowsInput.filter((element) => {
        return element.Id.includes(oItem.Id + '-') && element.Id !== oItem.Id;
      });
      var aEncontrado2 = rowsInput.filter((element) => {
        return (
          element.Id.includes(oItem.Id + '-') &&
          element.Id !== oItem.Id &&
          oItem.Id.split('-').length + 1 === element.Id.split('-').length
        );
      });
      if (aEncontrado.length > 0) {
        aEncontrado2.forEach((element) => {
          element.collapse = !element.collapse;
          // element["collapseIcon"]  = !element["collapseIcon"]
          if (element.collapse) {
            element['collapseIcon'] = true;
          } else {
            element['collapseIcon'] = false;
          }
          var aEncontrado = rowsInput.filter((elemen2) => {
            return elemen2.Id.includes(element.Id + '-') && elemen2.Id !== element.Id;
          });
          aEncontrado.forEach((element) => {
            element.collapse = true;
            element['collapseIcon'] = false;
          });
        });
      }
      // return { ...state, ...action.payload };
    },
    onChangeCollapseSolucionesXSolucion: (state, action) => {
      const rowsInput = [...state.aListaSolucionesSumarizada];
      const oItem = rowsInput[action.payload.index];
      oItem['collapseIcon'] = !oItem['collapseIcon'];
      var aEncontrado = rowsInput.filter((element) => {
        return element.Id_Partida1 == oItem.Id_Partida1 && element.IdPadre != oItem.IdPadre;
      });

      if (aEncontrado.length > 0) {
        aEncontrado.forEach((element) => {
          element.collapse = !element.collapse;
          // element["collapseIcon"]  = !element["collapseIcon"]
          if (element.collapse) {
            element['collapseIcon'] = true;
          } else {
            element['collapseIcon'] = false;
          }
        });
      }
      // return { ...state, ...action.payload };
    },
    onSetDetalleListaAgrupadaXSoluciones: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaEtiqueFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectEtiquetaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaModalSolFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaTipoSolucionesFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListaTipoEstadoSolFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectTipoSoluFlujocaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectTipoEstadoSoluFlujocaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetFilterListaModalSolFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeSearchModalSolSearchFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeVigenciaSolFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onOpenModalSolucionesDetalleFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetSelectSolucionesModalFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarSolucionesFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    collapseDesdobles: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetOptimizador: (state, action) => {
      return { ...state, ...action.payload };
    },
    addListaSucesosCreados: (state, action) => {
      return {
        ...state,
        ...state.listaSucesosCreados.concat(action.payload.newItem),
      };
    },
    addListaSucesosModificados: (state, action) => {
      debugger;

      let listaSucesosModificados = state.listaSucesosModificados.concat(action.payload.newItem);
      return {
        ...state,
        ...{
          listaSucesosModificados: listaSucesosModificados,
        },
      };
      return {
        ...state,
        ...{
          listaSucesosModificados: listaSucesosModificados,
        },
      };
    },
    updateListaSucesosModificados: (state, action) => {
      debugger;
      state.listaSucesosModificados.map((item, index) => {
        debugger;
        if (item.IDRegistro === action.payload.IDRegistro) {
          debugger;
          item.IDUsuario = action.payload.IDUsuario;
          item.IDRegistro = action.payload.IDRegistro;
          item.MonedaImporte = action.payload.MonedaImporte;
          item.Fecha = action.payload.Fecha;
          item.Moneda = action.payload.Moneda;
        }
        return { ...item };
      });
    },
    addListaSucesosEliminados: (state, action) => {
      let listaSucesosEliminados = state.listaSucesosEliminados.concat(action.payload.newItem);
      return {
        ...state,
        ...{
          listaSucesosEliminados: listaSucesosEliminados,
        },
      };
    },
    onOpenModalAgregarNombreFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onLimpiarCombinacionSolucuionesFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectPeridiocidadTableFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectPeridiocidadTableSolucionFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectMonedaTableFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSelectMonedaTableFCInicial: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetFactorMonedaTableFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    loadVisualizarConceptoFC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onLimpiarDesdobleXSolucionFC: (state, action) => {},
    onSetLog: (state, action) => {
      return { ...state, ...action.payload };
    },

    onUniverso: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetListFiltroCaract: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  onSetAplicarBusquedaFlujoCaja,
  onOpenModalEliminarFlujoCaja,
  onStartLoadingFlujoCaja,
  onSetFlujoCajas,
  onSuccessDeleteFlujoCaja,
  onOpenModalCancelarCrearFlujoCaja,
  //********Métodos para Crear Posición********
  onChangeVersion,
  onChangeCodigoCrearFlujoCaja,
  onChangeConceptoCrearFlujoCaja,
  onChangeNombreCrearFlujoCaja,
  onSetValidNombreCrearFlujoCaja,
  onChangeEstructuraCrearFlujoCaja,
  onSetValidEstructuraCrearFlujoCaja,
  onChangeFechaPeriodoCrearFlujoCaja,
  onSetValidFechaPeriodoCrearFlujoCaja,
  onChangeMonedaCrearFlujoCaja,
  onSetValidMonedaCrearFlujoCaja,
  onChangeDescripcionCrearFlujoCaja,
  onSetEstructurasBaseCrearFlujoCaja,
  onSetPeriodoCrearFlujoCaja,
  onSetMonedasCrearFlujoCaja,
  onSetTipoCambioFlujoCaja,
  onChangeFechaCrearFlujoCaja,
  onSetValidFechaCrearFlujoCaja,
  onSetDetalleFlujoCajaCabeceras,
  onSetDetalleFlujoCajaCabecerasSolucion,
  onSetDetalleFlujoCajaCabecerasInicial,
  onSetDetalleFlujoCajaContenido,
  onSetDetalleFlujoCajaContenidoIngreso,
  onSetDetalleFlujoCajaContenidoEgreso,
  //Validaciones Formulario Crear FlujoCaja
  onSetValidCodigoCrearFlujoCaja,
  onSetValidConceptoCrearFlujoCaja,
  onLimpiarCamposCrearFlujoCaja,
  onSuccessRegistrarFlujoCaja,
  onLimpiarCamposFlujoCaja,
  onLimpiarCamposGeneralesFlujoCaja,
  onOpenModalFiltrosDetalleFlujoCaja,
  onOpenModalRegM,
  onOpenModalMoverFechaM,
  onOpenModalRestriccionesDetalleFlujoCaja,
  onChangeVersionFiltroDetalleFlujoCaja,
  onSetFiltroDetalleFlujoCaja,
  onSetListaVersionFiltroDetalleFlujoCaja,
  onSetListaVersionRestriccionesDetalleFlujoCaja,
  onChangeVersionSolucionDetalleFlujoCaja,
  onSetRestriccionesDetalleFlujoCaja,
  onSetListaVersionSolucionDetalleFlujoCaja,
  onAddFiltroDetalleFlujoCaja,
  onAddRestriccionDetalleFlujoCaja,
  onDeleteFiltroDetalleFlujoCaja,
  onChangeRowFPosicionFiltroDetalleFlujoCaja,
  onChangeRowFSetListaCaracteristicasFiltroDetalleFlujoCaja,
  onChangeRowFCaracteristicailtroDetalleFlujoCaja,
  onChangeRowFSetListaValoresFiltroDetalleFlujoCaja,
  onChangeRowFValoresFiltroDetalleFlujoCaja,
  onDeleteRestriccionDetalleFlujoCaja,
  changeRowsTipoRestriccionDetalleFLujoCaja,
  changeRowsMontoRestriccionDetalleFLujoCaja,
  changeRowsMonedaRestriccionDetalleFLujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onLimpiarRestriccionDetalleFlujoCaja,
  onOpenModalVersionFlujoCaja,
  onSetListaVersionesFlujoCaja,
  filterListarFlujoCajas,
  onSelectVersionFlujoCaja,
  updateIdSelectListaVersionFlujoCaja,
  updateIdSelectListaSolucionFlujoCaja,
  onSetDetalleVersionFlujoCaja,
  onSetParametrosFlujoCaja,
  onChangeProyeccion,
  onChangeTiempoPeridiocidad,
  onSetListaPosicionesFiltrosFlujoCaja,
  onSetTabProceso,
  onSetListaSoluciones,
  onGenerateDataLineChartFC,
  changeSelectListaSoluciones,
  onSetListaAgrupadaXSoluciones,
  changeListaAgruadaXSoluciones,
  onSetListaAgrupadaXSolucionesSumarizada,
  onSetListaAgrupadaXSolucionesTotal,
  onSetListaAgrupadaXSolucionesParcial,
  onChangeCollapseDetalleListaAgrupadaXSoluciones,
  onChangeCollapseSolucionesXSolucion,
  onSetDetalleListaAgrupadaXSoluciones,
  onSetListaEtiqueFlujoCaja,
  onSelectEtiquetaFlujoCaja,
  onSetListaModalSolFlujoCaja,
  onSetListaTipoSolucionesFlujoCaja,
  onSetListaTipoEstadoSolFlujoCaja,
  onSelectTipoSoluFlujocaja,
  onSelectTipoEstadoSoluFlujocaja,
  onSetFilterListaModalSolFlujoCaja,
  onChangeSearchModalSolSearchFlujoCaja,
  onChangeVigenciaSolFlujoCaja,
  onOpenModalSolucionesDetalleFlujoCaja,
  onSetSelectSolucionesModalFlujoCaja,
  limpiarSolucionesFlujoCaja,
  collapseDesdobles,
  onSetOptimizador,
  addListaSucesosCreados,
  addListaSucesosModificados,
  updateListaSucesosModificados,
  addListaSucesosEliminados,
  onOpenModalAgregarNombreFlujoCaja,
  onLimpiarCombinacionSolucuionesFlujoCaja,
  onSelectPeridiocidadTableFC,
  onSelectPeridiocidadTableSolucionFC,
  onSelectMonedaTableFC,
  onSelectMonedaTableFCInicial,
  onSetFactorMonedaTableFC,
  loadVisualizarConceptoFC,
  onLimpiarDesdobleXSolucionFC,
  onChangeTipoRestriccionDetalleFLujoCaja,
  onChangeTipoObligatorio,
  onChangeTipoRegla,
  onChangeTipoMonto,
  onChangeTipoMoneda,
  onChangeListaRegla,
  onChangeListaReglaS,
  onLimpiarListaRegla,
  onLimpiarParamRegla,
  onLimpiarMonedaReglaPriorizacion,
  onChangeVersionRestriccionesDetalleFlujoCaja,
  updateIdSelectListaVersionRestriccionesFlujoCaja,
  onSelectCaracteristicasReglas,
  onChangeCaracteristica,
  onSelectValorRegla,
  onChangeValorRegla,
  onChangeIdRegla,
  onChangeEditListaRegla,
  onChangeListaPriorizacionMoneda,
  onChangeListaPriorizacionMonedaOr,
  onChangeSponsor,
  onSetAgente,
  onChangeAgente,
  onChangeAgenteOr,
  onChangeSolucionRegla,
  onSetLog,
  onUniverso,
  onSetListFiltroCaract,
} = flujoCajaSlice.actions;
