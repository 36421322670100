export const createTipoSistemaAdapter = (user) => ({
  IDTipoSistema: user.IDTipoSistema,
  TipoSistema: user.TipoSistema,
  Habilitado: user.Habilitado,
});
export const createTipoSistemaCampoAdapter = (user) => ({
  idTipoSistema: user.IDTipoSistema,
  nombreSistema: user.TipoSistema,
  Habilitado: user.Habilitado,
  value: user.IDTipoSistema,
  label: user.TipoSistema,
});
