import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
import collect from 'collect.js';
export const solTipoCambioInitialState = {
  //TablaBuscador
  searchtext: '',
  //tabla
  listaSolTipoCambio: [],
  filterListaSolTipoCambio: [],
  //formulario
  selectTipoFormulario: [],
  bValidselectTipoFormulario: null,

  idSolucion: null,
  descripcion: '',
  bValidDescripcion: null,
  selectAgente: [],
  bValidAgente: null,
  listaAgente: [],
  dDateRange: [null, null],
  dDateRangeStart: [],
  dDateRangeEnd: [],
  dDateTimeStart: '',
  dDateTimeEnd: '',
  bValidFechaStart: null,
  bValidFechaEnd: null,
  selectEstado: [],
  bValidEstado: null,
  selectTipo: [],
  bValidTipo: null,
  listaEstado: [],

  detalleConversion: [],
  listaMoneda: [],
  mapMonedaConversion: [
    {
      id: 1,
      value: 'Compra',
      tipo: 'Compra',
      monedas: [],
    },
    {
      id: 2,
      value: 'Venta',
      tipo: 'Venta',
      monedas: [],
    },
  ],
  formMonedaCambio: {},
};

export const solTipoCambioSlice = createSlice({
  name: 'soltipocambio',
  initialState: solTipoCambioInitialState,
  reducers: {
    agregarSolTipoCambio: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarSolTipoCambio: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarSolTipoCambios: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTipoFomularioSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeCantCuotaLC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaSolTipoCambio: (state, action) => {
      return { ...state, ...action.payload };
    },
    //Metodos del Formuario de Creacion
    changeDescripcionSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedAgenteSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaAgenteSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeVigenciaSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMonedaSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeListaMonedaConversionSOLTC: (state, action) => {
      state.mapMonedaConversion
        .filter(function (item, index) {
          return item.value === action.payload.tipoConversion;
        })
        .map(function (item2, index2) {
          if (action.payload.seleccion) {
            item2.monedas = [...item2.monedas, action.payload.moneda];
          } else {
            item2.monedas = item2.monedas.filter(function (item3, index3) {
              return item3 != action.payload.moneda;
            });
          }
          return item2;
        });
    },
    onChangeSelectListaMonedaSOLTC: (state, action) => {
      state.listaMoneda.map(function (item, index) {
        if (item.value == action.payload.value) {
          item.seleccionada = action.payload.seleccionada;
        }
        return item;
      });
    },
    selectedEstadoSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEstadoSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    filterListarSolTipoCambio: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaSolTipoCambio = estadoInspeccionado.listaSolTipoCambio.filter(function (
        item,
        index
      ) {
        return (
          item.codigo.toLowerCase().includes(action.payload.toLowerCase()) ||
          // item.nombre
          //   .toLowerCase()
          //   .includes(action.payload.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.estado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaSolTipoCambio: filterListaSolTipoCambio,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setDataInfoSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarCamposSOLTC: (state, action) => {
      return {
        ...state,
        ...{
          searchtext: '',
          //formulario
          idSolucion: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          dDateRangeStart: [],
          dDateRangeEnd: [],
          dDateTimeStart: '',
          dDateTimeEnd: '',
          bValidFechaStart: null,
          bValidFechaEnd: null,
          bValidFecha: null,
          selectEstado: [],
          bValidEstado: null,

          detalleConversion: [],
        },
      };
    },
    limpiarEditCamposSOLTC: (state, action) => {
      return {
        ...state,
        ...{
          idSolucion: null,
          //TablaBuscador
          searchtext: '',
          //formulario
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          dDateRangeStart: [],
          dDateRangeEnd: [],
          dDateTimeStart: '',
          dDateTimeEnd: '',
          bValidFechaStart: null,
          bValidFechaEnd: null,
          selectMoneda: [],
          bValidMoneda: null,
          selectMultiMonedaConversion: [],
          bValidMultiMonedaConversion: null,
          regManualesSOLTC: [],
          selectRegManualesSOLTC: [],
          detalleConversion: [],
          // listaColumnasMultiplestableSOLTC: [],
          montoMaximo: '',
          bValidMontoMaximo: null,
          tea: '',
          bValidTea: null,
          periodoMaximo: '',
          bValidPeriodoMaximo: null,
          bValidSelectTiempoPeriodoMaximo: null,
          selectTiempoPeriodoMaximo: [],
          selectEstado: [],
          bValidEstado: null,
          apariciones: '',
          bValidApariciones: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    setValidCamposSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    addSelectRegManualesSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
    addDetalleConversionTableSOLTC: (state, action) => {
      state.detalleConversion = [...state.detalleConversion, action.payload];
    },

    editDetalleConversionTableSOLTC: (state, action) => {
      let filtradoDetalle = state.detalleConversion.filter((r) => r.id != action.payload.id);
      state.detalleConversion = [...filtradoDetalle, action.payload];
    },
    deleteRowConversionTableSOLTC: (state, action) => {
      let listaMoneda = [];
      let listaMonedaUtilizadas = [];
      let detalleConversion = [];
      let aEliminar = state.detalleConversion.filter(function (item, index) {
        return index == action.payload.index;
      });
      let item = aEliminar[0];

      if (item.selectMoneda.length > 0) {
        listaMonedaUtilizadas.push(item.selectMoneda[0].value);
      }
      if (item.selectMonedaConversion.length > 0) {
        listaMonedaUtilizadas.push(item.selectMonedaConversion[0].value);
      }
      state.listaMoneda.map(function (item2, index) {
        let obj = { ...item2 };
        let encontrado = listaMonedaUtilizadas.filter(function (item, index) {
          return item == obj.value;
        });
        if (encontrado.length > 0) {
          obj.seleccionada = false;
        }
        listaMoneda.push(obj);
      });

      // state.detalleConversion.slice(0, action.payload.index);
      state.detalleConversion.map(function (item, index) {
        if (index !== action.payload.index) {
          detalleConversion.push(item);
        }
      });

      return { ...state, ...{ detalleConversion: detalleConversion, listaMoneda: listaMoneda } };
    },
    deleteRowTableSOLTC: (state, action) => {
      let detalleConversion = [];

      // state.detalleConversion.slice(0, action.payload.index);
      state.detalleConversion.map(function (item, index) {
        if (item.id !== action.payload.id) {
          detalleConversion.push(item);
        }
      });

      return { ...state, ...{ detalleConversion: detalleConversion } };
    },

    deleteAllRowConversionTableSOLTC: (state, action) => {
      return { ...state, ...{ detalleConversion: [] } };
    },
    onChangeRowsSelectTipoConversionTableSOLTC: (state, action) => {
      state.detalleConversion.filter(function (item, index) {
        if (index === action.payload.index) {
          item.selectTipoConverion = action.payload.selectTipoConverion;
          if (item.selectTipoConverion.length == 0) {
            item.selectMoneda = [];
            item.selectMonedaConversion = [];
            item.rangoDesde = '';
          }
          //
        }
      });
    },
    onLimpiarCamposTabla: (state, action) => {
      state.detalleConversion.filter(function (item, index) {
        if (index === action.payload.index) {
          return { ...item, ...action.payload };
        }
      });
    },
    onChangeRowsConversionMonedaTableSOLTC: (state, action) => {
      state.detalleConversion.map(function (item, index) {
        if (index === action.payload.index) {
          item.rangoDesde = '';
          item.rangoDesde = action.payload.rangoDesde;
          item.bValidRangoDesde = action.payload.bValidRangoDesde;
          if (action.payload.rangoDesde.length == 0) {
            item.selectMoneda = [];
            item.selectMonedaConversion = [];
          }
        }
      });
    },
    onChangeRowsSelectMonedaTableSOLTC: (state, action) => {
      state.detalleConversion.filter(function (item, index) {
        if (index === action.payload.index) {
          item.selectMoneda = action.payload.selectMoneda;
          item.selectMonedaConversion = [];
        }
      });
    },
    onCreateFormTipoCambio: (state, action) => {
      state.formMonedaCambio.id = action.payload;
    },
    onEditFormTipoCambio: (state, action) => {
      state.formMonedaCambio = action.payload;
    },
    onCleanFormTipoCambio: (state, action) => {
      state.formMonedaCambio = {
        id: state.formMonedaCambio.id,
        cantidadMonedaBase: '',
        cantidadMonedaDestino: '',
        Minimo: '',
        Maximo: '',
        FactorRango: '',
      };
    },
    onChangeRowsSelectMonedaBaseTableSOLTC: (state, action) => {
      state.formMonedaCambio.MonedaBase = action.payload.MonedaBase;
      state.formMonedaCambio.bValidMonedaBase = action.payload.bValidMonedaBase;
    },
    onChangeRowsSelectMonedaDestinoTableSOLTC: (state, action) => {
      state.formMonedaCambio.MonedaDestino = action.payload.MonedaDestino;
      state.formMonedaCambio.bValidMonedaDestino = action.payload.bValidMonedaDestino;
    },
    onChangeRowsSelectMonedaRangoTableSOLTC: (state, action) => {
      state.formMonedaCambio.MonedaRango = action.payload.MonedaRango;
      state.formMonedaCambio.bValidMonedaRango = action.payload.bValidMonedaRango;
      if (state.formMonedaCambio.MonedaBase == action.payload.MonedaRango) {
        state.formMonedaCambio.FactorRango = state.formMonedaCambio.FactorBase;
      } else {
        state.formMonedaCambio.FactorRango = state.formMonedaCambio.FactorDestino;
      }
    },
    onChangeRowsFactorBaseTableSOLTC: (state, action) => {
      state.formMonedaCambio.FactorBase = action.payload.FactorBase;
      state.formMonedaCambio.bValidMonedaBase = action.payload.bValidMonedaBase;
    },
    onChangeRowsFactorDestinoTableSOLTC: (state, action) => {
      state.formMonedaCambio.FactorDestino = action.payload.FactorDestino;
      state.formMonedaCambio.bValidMonedaDestino = action.payload.bValidMonedaDestino;
    },
    onChangeRowsSelectMonedaConversionTableSOLTC: (state, action) => {
      state.detalleConversion.filter(function (item, index) {
        if (index === action.payload.index) {
          item.selectMonedaConversion = action.payload.selectMonedaConversion;
        }
      });
    },
    onChangeRowsConversionMonedaConversioTableSOLTC: (state, action) => {
      state.detalleConversion.filter(function (item, index) {
        if (item.id == action.payload.id) {
          item.cantidadMonedaConversion = action.payload.cantidadMonedaConversion;
          item.bValidCantidadMonedaConversion = action.payload.bValidCantidadMonedaConversion;
        }
      });
    },
    onChangeRowsCambioBaseTableSOLTC: (state, action) => {
      state.formMonedaCambio.cantidadMonedaBase = action.payload.cantidadMonedaBase;
      state.formMonedaCambio.bValidCantidadMonedaBase = action.payload.bValidCantidadMonedaBase;
    },
    onChangeRowsCambioDestinoTableSOLTC: (state, action) => {
      state.formMonedaCambio.cantidadMonedaDestino = action.payload.cantidadMonedaDestino;
      state.formMonedaCambio.bValidCantidadMonedaDestino =
        action.payload.bValidCantidadMonedaDestino;
    },
    onChangeRowsMinimoTableSOLTC: (state, action) => {
      state.formMonedaCambio.Minimo = action.payload.Minimo;
      state.formMonedaCambio.bValidMinimo = action.payload.bValidMinimo;
    },
    onChangeRowsMaximoTableSOLTC: (state, action) => {
      state.formMonedaCambio.Maximo = action.payload.Maximo;
      state.formMonedaCambio.bValidMaximo = action.payload.bValidMaximo;
    },
    onClearConversionMonedaTableSOLTC: (state, action) => {
      return { ...state, ...{ detalleConversion: [] } };
    },

    deleteFiltroMultiplesTableSOLTC: (state, action) => {
      let newList = [];
      state.detalleConversion.map((item, index) => {
        let obj = { ...item };
        if (index != action.payload.index) {
          newList.push(obj);
        } else {
          obj.status = 'E';
        }
      });

      let listaColumnasMultiplestableSOLTC = [];

      if (action.payload.oSelectColumna.length > 0) {
        for (let index = 0; index < state.listaColumnasMultiplestableSOLTC.length; index++) {
          let element = { ...state.listaColumnasMultiplestableSOLTC[index] };
          if (element.value === action.payload.oSelectColumna[0].value) {
            element.seleccionada = 0;
          }
          listaColumnasMultiplestableSOLTC.push(element);
        }
      } else {
        listaColumnasMultiplestableSOLTC = [...state.listaColumnasMultiplestableSOLTC];
      }

      return {
        ...state,
        ...{
          detalleConversion: newList,
          listaColumnasMultiplestableSOLTC: listaColumnasMultiplestableSOLTC,
        },
      };
    },

    onChangeRowsMonedaSolucionTableSOLTC: (state, action) => {
      state.listMonedaSolucionTeaSOLTC.map(function (item, index) {
        let aEncontrado = action.payload.selectMonedasSolucionTEA.filter(function (
          itemSelect,
          indexSelect
        ) {
          return itemSelect === item.codMoneda;
        });
        if (aEncontrado.length > 0) {
          item.tea = action.payload.tea;
          item.bValidTea = action.payload.bValidTea;
        }
        return item;
      });
      // return {
      //   ...state,
      //   listMonedaSolucionTeaSOLTC: [],
      // };
    },
    onAddRowMonedaSolucionTableSOLTC: (state, action) => {
      return {
        ...state,
        listMonedaSolucionTeaSOLTC: state.listMonedaSolucionTeaSOLTC.concat(action.payload.newItem),
      };
    },
    onChangeMultiMonedaMultiCheckboxTableSOLTC: (state, action) => {
      state.listMonedaSolucionTeaSOLTC.map((item, index) => {
        if (index === action.payload.index) {
          item.selectMultiMonedaConversion = action.payload.selectMultiMonedaConversion; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },

    onDeleteRowMultiMonedaMultiTableSOLTC: (state, action) => {
      var colectData = collect;
      let selectMultiMonedaConversionEliminar =
        action.payload.data.selectMultiMonedaConversion.filter(function (item, index) {
          return item.value != '*';
        });
      let lista = [];
      state.listMonedaSolucionTeaSOLTC.map(function (item, index) {
        if (index != action.payload.index) {
          let obj = { ...item };
          obj.listaMoneda = colectData([...obj.listaMoneda, ...selectMultiMonedaConversionEliminar])
            .unique('value')
            .all();

          lista.push(obj);
        }
      });

      return {
        ...state,
        listMonedaSolucionTeaSOLTC: lista,
      };
    },

    onChangeOpenModalAsignarTEAMultipleSolSOLTC: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  agregarSolTipoCambio,
  editarSolTipoCambio,
  eliminarSolTipoCambios,
  selectedTipoFomularioSOLTC,
  setListaSolTipoCambio,
  changeDescripcionSOLTC,
  changeMontoMaximoSOLTC,
  changeMoraFijoRetrasoSOLTC,
  changeMoraVariableRetrasoSOLTC,
  selectedEstadoSOLTC,
  setListaEstadoSOLTC,
  filterListarSolTipoCambio,
  setValidCamposSOLTC,
  limpiarCamposSOLTC,
  limpiarEditCamposSOLTC,
  setDataInfoSOLTC,
  selectedAgenteSOLTC,
  setListaAgenteSOLTC,
  changeVigenciaSOLTC,
  setListaMonedaSOLTC,
  onChangeListaMonedaConversionSOLTC,
  onChangeSelectListaMonedaSOLTC,

  addDetalleConversionTableSOLTC,
  deleteRowConversionTableSOLTC,
  deleteAllRowConversionTableSOLTC,

  onLimpiarCamposTabla,
  onChangeRowsSelectTipoConversionTableSOLTC,
  onChangeRowsConversionMonedaTableSOLTC,
  onChangeRowsSelectMonedaTableSOLTC,
  onChangeRowsSelectMonedaConversionTableSOLTC,
  onChangeRowsConversionMonedaConversioTableSOLTC,
  onClearConversionMonedaTableSOLTC,

  deleteFiltroMultiplesTableSOLTC,

  onChangeRowsMonedaSolucionTableSOLTC,
  onAddRowMonedaSolucionTableSOLTC,
  onChangeMultiMonedaMultiCheckboxTableSOLTC,
  onDeleteRowMultiMonedaMultiTableSOLTC,

  onChangeRowsSelectMonedaBaseTableSOLTC,
  onChangeRowsSelectMonedaDestinoTableSOLTC,
  onChangeRowsSelectMonedaRangoTableSOLTC,
  onChangeRowsFactorBaseTableSOLTC,
  onChangeRowsFactorDestinoTableSOLTC,
  onChangeRowsCambioBaseTableSOLTC,
  onChangeRowsCambioDestinoTableSOLTC,
  onChangeRowsMinimoTableSOLTC,
  onChangeRowsMaximoTableSOLTC,
  deleteRowTableSOLTC,
  onCreateFormTipoCambio,
  onCleanFormTipoCambio,
  onEditFormTipoCambio,
  editDetalleConversionTableSOLTC,
} = solTipoCambioSlice.actions;
export default solTipoCambioSlice.reducer;
