export const versionDashboard = (oParam) => ({
  IDCabeceraFlujo: oParam.IDCabeceraFlujo,
  value: oParam.IDCabeceraFlujo,
  NombreCabeceraFlujo: oParam.NombreCabeceraFlujo,
  label: oParam.NombreCabeceraFlujo,
  IdEstructuraBase: oParam.IdEstructuraBase,
  NombreEstructura: oParam.NombreEstructura,
  FechaInicio: oParam.FechaInicio,
  FechaFin: oParam.FechaFin,
  ListaEtiquetas: oParam.ListaEtiquetas,
  NombrePeriodo: oParam.NombrePeriodo,
  Abreviatura: oParam.Abreviatura,
  NombreMoneda: oParam.NombreMoneda,
  Registros: oParam.Registros,
});
