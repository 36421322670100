import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { theme } from '../../common';

export const ButtonCard = (props) => (
  <ButtonStandar onClick={props.action} isAction={props.isAction}>
    {props.icon ? (
      <IconButton>
        <FontAwesomeIcon icon={props.icon} />
      </IconButton>
    ) : (
      <></>
    )}
    {props.name ? <span>{props.name}</span> : <></>}
  </ButtonStandar>
);
const ButtonStandar = styled.button`
  font-family: UbuntuBold;
  font-size: 12px;
  background: transparent;
  padding: 6px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  gap: 10px;
  span {
    color: white;
  }
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    transform: scale(1.09);
    span {
      color: ${({ isAction }) => (isAction ? theme.colors.primary : '#183838')};
    }
  }
`;
const IconButton = styled.div`
  color: ${({ isAction }) => (isAction ? `${theme.colors.primary} !important` : '#fff !important')};
  align-items: center;
`;
