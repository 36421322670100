import {
  faBell,
  faShieldAlt,
  faBriefcase,
  faTable,
  faRocket,
  faPalette,
  faIndustry,
  faProjectDiagram,
} from '@fortawesome/fontawesome-free-solid';
import { permisos } from './accesos';

export const MenuItems = [
  {
    id: 0,
    name: 'menu.0',
    icon: 'chart-pie',
    to: `/dashboard`,
    onExpand: false,
    permiso: [permisos.VER_DASHBOARD],
    subMenus: [],
  },
  {
    id: 1,
    name: 'menu.1',
    icon: faShieldAlt,
    to: `/dashboard/seguridad`,
    onExpand: false,
    permiso: [permisos.VER_USUARIO, permisos.VER_ROL],
    subMenus: [
      {
        name: 'menu.1-1',
        to: '/dashboard/seguridad/usuario',
        permiso: [permisos.VER_USUARIO],
      },
      {
        name: 'menu.1-2',
        to: '/dashboard/seguridad/rol',
        permiso: [permisos.VER_ROL],
      },
      // {
      //   name: 'Auditoría',
      //   alias: 'Listado de Auditorías',
      //   to: '/dashboard/seguridad/auditoria',
      //   permiso: [],
      // },
      // {
      //   name: 'Pagos',
      //   alias: 'Listado de Pagos',
      //   to: '/dashboard/seguridad/pagos',
      //   permiso: [],
      // },
    ],
  },
  {
    id: 2,
    name: 'menu.2',
    icon: faProjectDiagram,
    to: `/dashboard/Automatizacion/listar`,
    onExpand: false,
    subMenus: [],
    permiso: [permisos.VER_AUTOMATIZACION],
  },
  {
    id: 3,
    name: 'menu.3',
    icon: faPalette,
    to: `/dashboard/personalizacion`,
    onExpand: false,
    subMenus: [],
    permiso: [permisos.VER_PERSONALIZACION],
  },
  {
    id: 4,
    name: 'menu.4',
    icon: faTable,
    to: `/dashboard/configuracion`,
    onExpand: false,
    permiso: [
      permisos.VER_SISTEMAS,
      permisos.VER_CAMPOS,
      permisos.VER_FUNCION,
      permisos.VER_CONCEPTO,
      permisos.VER_POSICION,
      permisos.VER_ESTRUCTURA,
      permisos.VER_TIPOCAMBIO,
      permisos.VER_DERIVACION,
    ],
    subMenus: [
      {
        name: 'menu.4-1',
        alias: 'Listar de Sistemas',
        to: '/dashboard/configuracion/sistema/listar',
        permiso: [permisos.VER_SISTEMAS],
      },
      {
        name: 'menu.4-2',
        alias: 'Listado de Campos',
        to: '/dashboard/configuracion/campo/listar',
        permiso: [permisos.VER_CAMPOS],
      },
      {
        name: 'menu.4-3',
        alias: 'Listado de Funciones',
        to: '/dashboard/configuracion/funcion/listar',
        permiso: [permisos.VER_FUNCION],
      },
      {
        name: 'menu.4-4',
        alias: 'Listar Concepto',
        to: '/dashboard/configuracion/concepto/listar',
        permiso: [permisos.VER_CONCEPTO],
      },
      {
        name: 'menu.4-9',
        alias: 'Lista de Posiciones Master',
        to: '/dashboard/configuracion/posicion-master/listar',
        permiso: [permisos.VER_POSICION],
      },
      {
        name: 'menu.4-5',
        alias: 'Lista de Posiciones',
        to: '/dashboard/configuracion/posicion/listar',
        permiso: [permisos.VER_POSICION],
      },
      {
        name: 'menu.4-6',
        alias: 'Lista de Estructuras',
        to: '/dashboard/configuracion/estructura/listar',
        permiso: [permisos.VER_ESTRUCTURA],
      },
      {
        name: 'menu.4-7',
        alias: 'Listado de tipos de cambio',
        to: '/dashboard/configuracion/tipo_cambio/listar',
        permiso: [permisos.VER_TIPOCAMBIO],
      },
      {
        name: 'menu.4-8',
        alias: 'Listado de derivaciones',
        to: '/dashboard/configuracion/derivacion/listar',
        permiso: [permisos.VER_DERIVACION],
      },
    ],
  },
  {
    id: 5,
    name: 'menu.5',
    icon: faBriefcase,
    to: `/dashboard/socio_negocio`,
    onExpand: false,
    permiso: [permisos.VER_SOCIOS, permisos.VER_DOCUMENTOS],
    subMenus: [
      {
        name: 'menu.5-1',
        alias: 'Lista de Socios',
        to: '/dashboard/socio_negocio/socios',
        permiso: [permisos.VER_SOCIOS],
      },
      {
        name: 'menu.5-2',
        alias: 'Lista de Documentos',
        to: '/dashboard/socio_negocio/documentos',
        permiso: [permisos.VER_DOCUMENTOS],
      },
    ],
  },
  {
    id: 6,
    name: 'menu.6',
    icon: faIndustry,
    to: `/dashboard/soluciones`,
    onExpand: false,
    permiso: [permisos.VER_SOLUCIONES],
    subMenus: [
      {
        name: 'menu.6-1',
        alias: 'Listar línea de Crédito',
        to: '/dashboard/soluciones/linea_credito/listar',
        permiso: [],
      },
      {
        name: 'menu.6-2',
        alias: 'Listar préstamos',
        to: '/dashboard/soluciones/prestamo/listar',
        permiso: [],
      },
      {
        name: 'menu.6-3',
        alias: 'Listar aplazamientos',
        to: '/dashboard/soluciones/aplazamiento/listar',
        permiso: [],
      },
      {
        name: 'menu.6-4',
        alias: 'Listar adelantos',
        to: '/dashboard/soluciones/adelanto/listar',
        permiso: [],
      },
      {
        name: 'menu.6-5',
        alias: 'Listar factorings',
        to: '/dashboard/soluciones/factoring/listar',
        permiso: [],
      },
      {
        name: 'menu.6-6',
        alias: 'Listar depositos',
        to: '/dashboard/soluciones/deposito/listar',
        permiso: [],
      },
      {
        name: 'menu.6-7',
        alias: 'Listar tipos de Cambio',
        to: '/dashboard/soluciones/tipoCambio/listar',
        permiso: [],
      },
    ],
  },
  {
    id: 7,
    name: 'menu.7',
    icon: faRocket,
    to: `/dashboard/flujo_caja`,
    onExpand: false,
    permiso: [
      permisos.VER_REGISTRO_FUENTE,
      permisos.VER_REGISTRO_PROPIO,
      permisos.VER_FLUJO_CAJA,
      permisos.VER_GESTION,
    ],
    subMenus: [
      {
        name: 'menu.7-1',
        alias: 'Lista de registro fuente',
        to: '/dashboard/flujo_caja/fuente/listar',
        permiso: [permisos.VER_REGISTRO_FUENTE],
      },
      {
        name: 'menu.7-2',
        alias: 'Lista de registro propio',
        to: '/dashboard/flujo_caja/propio/listar/Listado_Propio',
        permiso: [permisos.VER_REGISTRO_PROPIO],
      },
      {
        name: 'menu.7-3',
        alias: 'Lista de Flujo de Caja',
        to: '/dashboard/flujo_caja/reporte/listar',
        permiso: [permisos.VER_FLUJO_CAJA],
      },
      {
        name: 'menu.7-4',
        alias: 'Lista de Gestión',
        to: '/dashboard/flujo_caja/gestion/listar',
        permiso: [permisos.VER_GESTION],
      },
    ],
  },
  {
    id: 8,
    name: 'menu.8',
    icon: faBell,
    to: `/dashboard/notificaciones`,
    onExpand: false,
    subMenus: [],
    permiso: [permisos.VER_NOTIFICACIONES],
  },
];
