import {createSlice} from '@reduxjs/toolkit'
const initialState = null
export const subMenuSlice = createSlice({
  name: 'submenu',
  initialState: initialState,
  reducers: {
    selectActiveSubMenu: (state, action) => {
      return {...state, ...action.payload}
    },
  },
})
export const {selectActiveSubMenu} = subMenuSlice.actions
export default subMenuSlice.reducer
