import styled from 'styled-components';
import { theme } from '../common';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentDouble = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 35%;
  @media only screen and (max-width: 1200px) {
    width: 50%;
  }
  @media only screen and (max-width: 830px) {
    width: 100%;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  row-gap: 5px;
  margin-bottom: 5px;
  padding: 15px 35px;
  /* border: 2px solid ${theme.colors.secondary}; */
  border-radius: 5px;
  width: ${({ width }) => (width ? width : '75%')};
  @media only screen and (max-width: 1200px) {
    width: ${({ width }) => (width ? width : '90%')};
  }
  @media only screen and (max-width: 830px) {
    width: 100%;
  }
`;

export const ContentButton = styled.div`
  margin-left: auto;
`;
export const ContentOption = styled.div`
  margin-bottom: 10px;
`;
export const ContentCheckBox = styled.div`
  display: flex;
  margin: 10px 0px 0px 0px;
`;
export const ContentWrapForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 32px;
  max-width: 80%;
  overflow: auto;
`;
