import React, { useEffect, useState } from 'react';
import { SearchInputText, Button, TableGrid, PBR, ButtonAccion } from '../../../../components';
import {
  obtenerMaster,
  registrarMaster,
  modificarMaster,
  eliminarMaster,
} from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListarPosicionMaster,
  setFilterListaPosicionMaster,
  setModalDetallePM,
  limpiarModalDetallePM,
} from '../../../../redux/states';
import { createPosicionAdapter } from '../../../../adapters';
import { LoadingSpinner } from '../../../../components';
import { updateTitle } from '../../../../redux/states';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { faEdit, faTrash } from '@fortawesome/fontawesome-free-solid';
import {
  CheckboxStyle,
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowButton,
  RowFlex,
} from '../../../../styles';
import { Dropdown } from 'antd';
import { permisos } from '../../../../common';
import { items } from './misc';
import { ModalDetallePM } from './components';

export const ListarPosicionMaster = () => {
  const posicionMasterState = useSelector((store) => store.posicionMaster);
  const [openModalDetalle, setOpenModalDetalle] = useState(false);
  const [selectedPosicionM, setSelectedPosicionM] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Lista de posiciones maestras' }));
      loadPosicionMaster();
    })();
  }, []);

  const loadPosicionMaster = async () => {
    const responseListaPosiciones = await callEndpoint(obtenerMaster());
    dispatch(setListarPosicionMaster(responseListaPosiciones.data.respuesta.aListaMaster));
  };

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(setFilterListaPosicionMaster(oEvent.target.value));
    } else {
      dispatch(setFilterListaPosicionMaster(''));
    }
  };

  const findPosicionMaster = (person) => {
    return !!selectedPosicionM.find((p) => p.IDCabeceraMaster === person.IDCabeceraMaster);
  };
  const filterPosicionMaster = (person) => {
    return selectedPosicionM.filter((p) => p.IDCabeceraMaster !== person.IDCabeceraMaster);
  };
  const onCheckRow = (person) => {
    const filteredPeople = findPosicionMaster(person)
      ? filterPosicionMaster(person)
      : [...selectedPosicionM, person];
    setSelectedPosicionM(filteredPeople);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <CheckboxStyle
          checked={findPosicionMaster(params.row)}
          onChange={() => onCheckRow(params.row)}
          size="small"
        />
      ),
    },
    {
      field: 'NombreMaster',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerName: '',
      width: 75,
      renderCell: (params) => (
        <RowButton>
          {/* <PBR permiso={[permisos.EDITAR_POSICION]}> */}
          <Button
            action={(e) => onEditarPosicion(params.row)}
            icon={faEdit}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
          />
          {/* </PBR> */}
        </RowButton>
      ),
    },
  ];

  const onAgregarPosicion = () => {
    setOpenModalDetalle(true);
  };

  const onEditarPosicion = async (row) => {
    debugger;
    let oParam = {};
    oParam.idDetallePM = row.IDCabeceraMaster;
    oParam.nombreDetallePM = row.NombreMaster;
    dispatch(setModalDetallePM(oParam));
    setOpenModalDetalle(true);
  };

  const onCloseModalDetalle = () => {
    dispatch(limpiarModalDetallePM());
    setOpenModalDetalle(false);
  };

  const onGuardarModalDetalle = async () => {
    let oParam = {};
    if (!posicionMasterState.idDetallePM) {
      oParam.NombreMaster = posicionMasterState.nombreDetallePM;
      const responseRegistrarDetallePM = await callEndpoint(registrarMaster(oParam));
      if (responseRegistrarDetallePM.data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseRegistrarDetallePM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        loadPosicionMaster();
        onCloseModalDetalle();
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseRegistrarDetallePM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      oParam.IDMaster = posicionMasterState.idDetallePM;
      oParam.NombreMaster = posicionMasterState.nombreDetallePM;
      const responseEditarDetallePM = await callEndpoint(modificarMaster(oParam));
      if (responseEditarDetallePM.data.cabecera.statusCode === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseEditarDetallePM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
        loadPosicionMaster();
        onCloseModalDetalle();
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseEditarDetallePM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onEliminar = async () => {
    let oParam = {};
    let ids = [];
    selectedPosicionM.map((r) => ids.push(r.IDCabeceraMaster));
    oParam.IDMaster = ids;

    const responseEliminarPM = await callEndpoint(eliminarMaster(oParam));
    if (responseEliminarPM == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseEliminarPM.data.cabecera.statusCode === 200) {
        loadPosicionMaster();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseEliminarPM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseEliminarPM.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar posicion master...'}
              filter={posicionMasterState.searchtext}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(onEliminar),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                {/* <PBR permiso={[permisos.CREAR_POSICION]}> */}
                <Button action={onAgregarPosicion} name="Agregar" isAction={true} type="primary" />
                {/* </PBR> */}
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        {
          <TableGrid
            columns={columns}
            rows={
              posicionMasterState.searchtext.length === 0
                ? posicionMasterState.listaPosicionMaster
                : posicionMasterState.filterListaPosicionMaster
            }
            pageSize={15}
            getRowId={(row) => row.IDCabeceraMaster}
          />
        }
      </ColFlex>
      <ModalDetallePM
        open={openModalDetalle}
        onCloseModal={onCloseModalDetalle}
        onGuardarModal={onGuardarModalDetalle}
      />

      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
