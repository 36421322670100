import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Input,
  LabelForm,
  LoadingSpinner,
  ModalConfirmation,
  SelectForm,
  SubTitle,
  TableGrid,
  TitlePage,
} from '../../../../components';
import { useFetchAndLoad } from '../../../../common/hooks';
import Swal from 'sweetalert2';
import {
  changeNameFunction,
  changeRowsChangeServiceName,
  changeRowsSelectAvailableTypeSystemFunction,
  clearAllFieldsFunction,
  clearFieldFormFunction,
  onAddRowtableTypeSystemFunction,
  onDeleteRowAvailableTypeSystemFunction,
  onSelectTypeFunction,
  onSetDeleteAvailableTypeSystemFunction,
  setHeaderDetailBoardFunction,
  setSelectAmdminCampos,
  setSelectAmdminCamposFinal,
  updateTitle,
} from '../../../../redux/states';
import {
  getInfoFunction,
  listTypeFuncion,
  obtenerCampos,
  obtenerInfoSistema,
  updateFunction,
} from '../../../../services/public.service';
import { createTypeFunctionAdapter } from '../../../../adapters/typeFunction';
import {
  ColFlex,
  ColForm,
  Column,
  Content,
  ContentForm,
  RowButton,
  RowFooterButton,
  RowForm,
} from '../../../../styles';
import { Drawer } from 'antd';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import * as XLSX from 'xlsx';

export const EditarFuncion = () => {
  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [openSheetCampos, setOpenSheetCampos] = useState(false);
  const funcionState = useSelector((store) => store.funciones);
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const columnCampo = [
    {
      field: 'NombreCampoHomo',
      headerName: 'Campo',
      flex: 1,
      filterable: true,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'NombreCampoAlias',
      headerName: 'Descripción',
      flex: 1,
      filterable: true,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  const columnFuncion = [
    {
      field: 'selectTypeSystem',
      headerName: 'Sistema',
      flex: 1,
      renderCell: (params) => (
        <SelectForm
          isMultiple={true}
          width={'380px'}
          value={params.value}
          handleChange={(e, v) => handleChangeSystemType(params.id, v)}
          options={funcionState.availableTypeSystemFunction}
          placeholder={'Seleccione Tipo'}
        />
      ),
    },
    {
      field: 'serviceName',
      headerName: 'Servicio',
      flex: 1,
      align: 'left',
      renderCell: (params) => (
        <Input
          width={'280px'}
          placeholder="Ingrese..."
          value={params.value}
          onChange={(e) => handleChangeServiceName(params.id, e)}
          validate={params.row.bValidServiceName}
        />
      ),
    },
    {
      field: 'edit',

      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <RowButton>
          <Button
            action={() => deleteTableRows(params.id)}
            icon={faMinus}
            isAction={false}
            isOnlyIcon={true}
            type="primary"
          />
        </RowButton>
      ),
      renderHeader: (params) => (
        <RowButton>
          <Button
            action={addTableRows}
            icon={faPlus}
            isAction={false}
            isOnlyIcon={true}
            type="secondary"
          />
        </RowButton>
      ),
    },
  ];

  function onDismiss() {
    setOpenSheetCampos(false);
    dispatch(setSelectAmdminCamposFinal(funcionState.selectAmdminCamposFinal));
    dispatch(setSelectAmdminCampos(funcionState.selectAmdminCamposFinal));
  }
  const onCLickEditarFuncion = async () => {
    dispatch(updateTitle({ title: 'Editar Función' }));
    const { data } = await callEndpoint(getInfoFunction({ idFuncion: id }));
    var oCabeceraFuncion = data.respuesta.oCabeceraFuncion;
    if (oCabeceraFuncion) {
      const respObtenerCampos = await callEndpoint(obtenerCampos());
      var listAvailableFields = [];
      for (let index = 0; index < respObtenerCampos.data.respuesta.listaCampos.length; index++) {
        let obj = respObtenerCampos.data.respuesta.listaCampos[index];
        listAvailableFields.push(obj);
      }

      const responseTypeListFuncion = await callEndpoint(listTypeFuncion());
      var listTypeFunction = [];
      for (
        let index = 0;
        index < responseTypeListFuncion.data.respuesta.aListaTipoFunc.length;
        index++
      ) {
        const element = responseTypeListFuncion.data.respuesta.aListaTipoFunc[index];
        var obj = createTypeFunctionAdapter(element);
        obj.value = obj.IDTypeFunction;
        obj.label = obj.TypeFuncion;
        listTypeFunction.push(obj);
      }
      let availableTypeSystemFunction = [];
      data.respuesta.aListadoSistemaCab.forEach((element) => {
        var obj = { ...element };
        obj.value = obj.IDSistema;
        obj.label = obj.AliasSistema;
        availableTypeSystemFunction.push(obj);
      });

      let selectTypeFunction = listTypeFunction.filter(function (item, index) {
        return item.value == oCabeceraFuncion.IDTipoFuncion;
      });

      dispatch(
        setHeaderDetailBoardFunction({
          idFuncion: oCabeceraFuncion.IDFConexionAgrupador,
          name: oCabeceraFuncion.NombreFuncion,
          bValidName: true,
          listTypeSystemService: data.respuesta.aListaDetalleFuncion,
          bValidSelectTypeSystem: data.respuesta.aListaDetalleFuncion.length === 0 ? false : true,
          listTypeFunction: listTypeFunction,
          selectTypeFunction: selectTypeFunction,
          availableTypeSystemFunction: availableTypeSystemFunction,
          listAvailableFields: listAvailableFields,
          selectAmdminCampos: data.respuesta.aListaDetalleFuncionHomo,
          selectAmdminCamposFinal: data.respuesta.aListaDetalleFuncionHomo,
        })
      );
    }
    setLoadingDetalle(false);
  };
  const onPressCancelar = () => {
    setShowModalCancelar(true);
  };
  const onCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };
  const onConfirmFunction = () => {
    setShowModalCancelar(false);
    dispatch(clearAllFieldsFunction());
    navigate('/dashboard/configuracion/funcion/listar');
  };
  const onPressGrabar = async () => {
    var valid = true;
    var bValidRowTypeSystemService = funcionState.listTypeSystemService.filter(function (
      item,
      index
    ) {
      return item.serviceName.trim() != '' && item.selectTypeSystem.length > 0;
    });
    if (
      bValidRowTypeSystemService.length == 0 ||
      funcionState.name.trim().length == 0 ||
      funcionState.selectTypeFunction.length == 0
    ) {
      valid = false;
    }
    if (!valid) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Completar todos los campos.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (
      funcionState.selectTypeFunction[0].value != 1 &&
      funcionState.selectAmdminCamposFinal.length == 0
    ) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debe seleccionar al menos un campo.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    // return
    let oRegisterHeadBoard = {};
    oRegisterHeadBoard.idFunction = funcionState.idFuncion;
    oRegisterHeadBoard.name = funcionState.name;
    oRegisterHeadBoard.selectTypeFunction = funcionState.selectTypeFunction;
    let oRegisterDetailBoard = {};
    oRegisterDetailBoard.newRowAvailableTypeSystemFunction = funcionState.listTypeSystemService;
    var oParam = {
      oRegisterHeadBoard: oRegisterHeadBoard,
      oRegisterDetailBoard: oRegisterDetailBoard,
      aAdministrarCampos: funcionState.selectAmdminCamposFinal,
    };
    if (funcionState.selectTypeFunction[0].value == 1) {
      oParam.aAdministrarCampos = [
        ...funcionState.listAvailableFields
          .filter(function (item, index) {
            return item.Obligatorio == 1;
          })
          .map(function (item2, index2) {
            return item2.IDCampoHomo;
          }),
        ...funcionState.selectAmdminCamposFinal,
      ];
    }

    // return;
    // return;
    const responseRegisterFunction = await callEndpoint(updateFunction(oParam));
    if (responseRegisterFunction == null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseRegisterFunction.data) {
        if (responseRegisterFunction.data.cabecera.statusCode === 200) {
          // setButtonAction(0);
          // setTabProceso(0);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseRegisterFunction.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          // dispatch(loadConceptos());
          limpiarCampos();
          navigate('/dashboard/configuracion/funcion/listar');
        } else if (responseRegisterFunction.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: responseRegisterFunction.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseRegisterFunction.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const limpiarCampos = (oEvent) => {
    dispatch(onSetDeleteAvailableTypeSystemFunction());
    dispatch(clearFieldFormFunction());
  };
  const handleChangeTipo = (value) => {
    var oParam = {};
    oParam.selectTypeFunction = [];
    oParam.bValidSelectTypeFunction = null;
    oParam.selectAmdminCampos = [];
    oParam.selectAmdminCamposFinal = [];
    if (value) {
      oParam.selectTypeFunction = [value];
      oParam.bValidSelectTypeFunction = true;
    }
    dispatch(onSelectTypeFunction(oParam));
  };
  useEffect(() => {
    (async () => {
      onCLickEditarFuncion();
    })();
  }, []);
  const loadSistemaDisponible = async () => {};
  const handleChangeNombre = (oEvent) => {
    var name = oEvent.target.value;
    var validate = null;
    if (name.length > 0) {
      validate = true;
    }
    var oParam = {
      name: name,
      bValidName: validate,
    };
    dispatch(changeNameFunction(oParam));
  };
  const onPressShowSheetCampo = () => {
    if (funcionState.selectTypeFunction.length == 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Debe seleccionar el tipo de función.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    setOpenSheetCampos(true);
  };
  const onApplyAllAdminCampos = (oEvent) => {
    let lista =
      funcionState.selectTypeFunction[0].value != 1
        ? funcionState.listAvailableFields
        : funcionState.listAvailableFields.filter((item) => item.Obligatorio == 2);
    let aId = lista.map(function (item, index) {
      return item.IDCampoHomo;
    });
    dispatch(setSelectAmdminCamposFinal(aId));
    dispatch(setSelectAmdminCampos(aId));
    setOpenSheetCampos(false);
  };
  const onApplyAdminCampos = (oEvent) => {
    dispatch(setSelectAmdminCamposFinal(funcionState.selectAmdminCampos));
    setOpenSheetCampos(false);
  };
  const showSubtitle = () => {
    let listaObligatorios = funcionState.listAvailableFields.filter(function (item, index) {
      return item.Obligatorio == 1;
    });
    var msg = 'Campos Obligatorios : ';
    for (let index = 0; index < listaObligatorios.length; index++) {
      const element = listaObligatorios[index];
      if (index == 0) {
        msg = element.NombreCampoAlias;
      } else {
        msg = msg + ' , ' + element.NombreCampoAlias;
      }
    }
    return <SubTitle subtitle={'Campos Obligatorios : ' + msg} />;
  };

  const addTableRows = () => {
    var uuid = uuidv4();
    dispatch(
      onAddRowtableTypeSystemFunction({
        newItem: {
          idAvailableTypeSystemFunction: uuid,
          selectTypeSystem: [],
          bValidTypeSystem: null,
          serviceName: '',
          bValidServiceName: null,
          action: 'N',
        },
      })
    );
  };

  const handleChangeSystemType = (id, value) => {
    dispatch(
      changeRowsSelectAvailableTypeSystemFunction({
        index: id,
        selectTypeSystem: value,
      })
    );
  };

  const deleteTableRows = (id) => {
    dispatch(
      onDeleteRowAvailableTypeSystemFunction({
        index: id,
      })
    );
  };

  const handleChangeServiceName = (id, oEvent) => {
    let bValidServiceName = null;
    let serviceName = oEvent.target.value;
    if (serviceName.length == 0) {
      bValidServiceName = false;
    }
    dispatch(
      changeRowsChangeServiceName({
        index: id,
        serviceName: serviceName,
        bValidServiceName: bValidServiceName,
      })
    );
  };

  const cargaDatosFuncion = async () => {
    let funcionDatos = funcionState.listTypeSystemService[0];
    let detalleSistema = await onLoadDetalleSistema(funcionDatos.selectTypeSystem[0].value);

    // const axios = require('axios');
    // const encoded = Buffer.from('API_USER' + ':' + 'nQTGU50onjLxHGGZk5UC').toString('base64');
    axios
      .post(
        funcionDatos.serviceName,
        { CompanyDB: 'PWC_GALILEA_PROD' },
        {
          auth: {
            username: detalleSistema.filter((r) => r.NombreCampo == 'User')[0].ValorCampo,
            password: detalleSistema.filter((r) => r.NombreCampo == 'Password')[0].ValorCampo,
          },
        }
      )
      .then((response) => {
        // Crear un nuevo libro de Excel
        const workbook = XLSX.utils.book_new();

        // Convertir el JSON a una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(response.data.Response.message.value);

        // Añadir la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

        // Escribir el libro de Excel en un archivo
        const excelFilePath = 'datos.xlsx';
        XLSX.writeFile(workbook, excelFilePath);
      })
      .catch((error) => {});
  };

  const onLoadDetalleSistema = async (id) => {
    const { data } = await callEndpoint(obtenerInfoSistema({ idSistema: id }));
    if (data) {
      if (data.cabecera.statusCode == 200) {
        const { aListadoDetalle } = data.respuesta;
        return aListadoDetalle;
      }
    }
  };

  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : funcionState.idFuncion == null ? (
    <Content>No se encontró información</Content>
  ) : (
    <Content>
      {loading === true ? <LoadingSpinner /> : <></>}
      <ColFlex>
        <SubTitle subtitle="1. Registrar datos generales de la función." />
        <ContentForm>
          <RowForm>
            <ColForm xs={24} sm={24} md={24} lg={8} xl={8}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Nombre (*):'}></LabelForm>
                <Input
                  placeholder="Ingrese..."
                  value={funcionState.name}
                  onChange={(evnt) => handleChangeNombre(evnt)}
                  validate={funcionState.bValidName}
                />
              </RowForm>
            </ColForm>
            {/* <ColForm xs={24} sm={24} md={24} lg={8} xl={8}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Tipo (*):'}></LabelForm>
                <SelectForm
                  validate={funcionState.bValidSelectTypeFunction}
                  value={funcionState.selectTypeFunction}
                  handleChange={(e, v) => handleChangeTipo(v)}
                  options={funcionState.listTypeFunction}
                  placeholder={'Seleccione tipo'}
                  isDisabled={true}
                />
              </RowForm>
            </ColForm> */}
            <ColForm xs={24} sm={24} md={24} lg={8} xl={8}>
              <RowForm>
                <LabelForm obligatorio={true} title={'Campo (*):'}></LabelForm>
                <Button
                  width={'98%'}
                  action={(e) => onPressShowSheetCampo()}
                  name="Administrar campos"
                  isAction={true}
                  type="primary"
                />
              </RowForm>
            </ColForm>
          </RowForm>
          <LabelForm title={'Listado de servicios :'}></LabelForm>
          <TableGrid
            columns={columnFuncion}
            rows={funcionState.listTypeSystemService}
            pageSize={15}
            getRowId={(row) => row.idAvailableTypeSystemFunction}
          />
          <RowFooterButton>
            <Button
              action={(e) => onPressCancelar()}
              name="Cancelar"
              isAction={true}
              type="warning"
            />
            <Button
              action={(e) => limpiarCampos(e)}
              name="Limpiar"
              isAction={true}
              type="primary"
            />
            <Button
              action={(e) => onPressGrabar(funcionState)}
              name="Grabar"
              isAction={true}
              type="secondary"
            />
          </RowFooterButton>
          {/* <Button
            action={(e) => {
              cargaDatosFuncion();
            }}
            name="Prueba"
            isAction={true}
            type="primary"
          /> */}
        </ContentForm>
        <Drawer
          title={<TitlePage title={'Administrador campos'} />}
          placement="right"
          width={'33%'}
          closable={false}
          onClose={onDismiss}
          open={openSheetCampos}
          footer={
            <RowFooterButton>
              <Button action={(e) => onDismiss()} name="Cancelar" isAction={true} type="warning" />
              <Button
                action={(e) => onApplyAllAdminCampos()}
                name="Seleccionar Todos"
                isAction={true}
                type="primary"
              />
              <Button
                action={(e) => onApplyAdminCampos()}
                name="Aplicar"
                isAction={true}
                type="secondary"
              />
            </RowFooterButton>
          }
        >
          <ColFlex>
            {funcionState.selectTypeFunction.length == 0 ? (
              <></>
            ) : funcionState.selectTypeFunction[0].value != 1 ? (
              <></>
            ) : funcionState.listAvailableFields.filter(function (item, index) {
                return item.Obligatorio == 1;
              }).length == 0 ? (
              <></>
            ) : (
              showSubtitle()
            )}

            <TableGrid
              columns={columnCampo}
              rows={
                funcionState.selectTypeFunction.length == 0 ? (
                  <></>
                ) : funcionState.selectTypeFunction[0].value != 1 ? (
                  funcionState.listAvailableFields
                ) : (
                  funcionState.listAvailableFields.filter(function (item, index) {
                    return item.Obligatorio == 2;
                  })
                )
              }
              pageSize={15}
              getRowId={(row) => row.IDCampoHomo}
              checkboxSelection={true}
              onSelectionModelChange={(oEvent) => {
                dispatch(setSelectAmdminCampos(oEvent));
              }}
              selectionModel={funcionState.selectAmdminCampos}
            />
          </ColFlex>
        </Drawer>
      </ColFlex>
      <ModalConfirmation
        showModal={showModalCancelar}
        closeModal={(e) => onCloseModalCancelar()}
        title="¿Confirmación?"
        subtitle="¿Esta seguro que desea cancelar la creación de la función?"
        action={(e) => onConfirmFunction(e)}
        labelButtonCancel="Cancelar"
        labelButtonConfirm="Confirmar"
      />
    </Content>
  );
};
