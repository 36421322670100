import {createSlice} from '@reduxjs/toolkit'
import {current} from '@reduxjs/toolkit'
export const lineaCreditoInitialState = {
  //TablaBuscador
  searchtext: '',
  //tabla
  listaLineaCredito: [],
  filterListaLineaCredito: [],
  //formulario
  idSolucion: null,
  selectTipoFormulario: [],
  bValidselectTipoFormulario: null,
  descripcion: '',
  bValidDescripcion: null,
  selectAgente: [],
  bValidAgente: null,
  listaAgente: [],
  dDateRange: [null, null],
  bValidFecha: null,
  monto: '',
  bValidMonto: null,
  montoCuota: '',
  bValidMontoCuota: null,
  montoDes: '',
  bValidMontoDes: null,
  selectMoneda: [],
  bValidMoneda: null,
  listaMoneda: [],
  listaTiempo: [],
  peGracia: '',
  bValidPeGracia: null,
  bValidTiempoPeGracia: null,
  selectTiempoPeGracia: [],
  peCuotas: '',
  bValidCuotas: null,
  bValidTiempoPeCuotas: null,
  selectTiempoPeCuotas: [],
  tea: '',
  bValidTea: null,
  frecMax: '',
  bValidFrecMax: null,
  bValidTiempoFrecMax: null,
  selectTiempoFrecMax: [],
  apariciones: '',
  bValidApariciones: null,
  minimo: '',
  bValidMinimo: null,
  maximo: '',
  bValidMaximo: null,
  selectEstado: [],
  bValidEstado: null,
  listaEstado: [],
  //
  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
}
export const lineaCreditoSlice = createSlice({
  name: 'lineaCredito',
  initialState: lineaCreditoInitialState,
  reducers: {
    agregarLineaCredito: (state, action) => {
      return {...state, ...action.payload}
    },
    editarLineaCredito: (state, action) => {
      return {...state, ...action.payload}
    },
    eliminarLineaCreditos: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaLineaCredito: (state, action) => {
      return {...state, ...action.payload}
    },
    //Metodos del Formuario de Creacion
    selectedTipoFomularioLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeDescripcionLC: (state, action) => {
      return {...state, ...action.payload}
    },
    selectedAgenteLC: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaAgente: (state, action) => {
      return {...state, ...action.payload}
    },
    changeVigenciaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeMontoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeMontoCuotaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeMontoDesembolsoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    selectedMonedaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    setMonedaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaMonedaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changePeriodoGraciaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    selectedTiempoPeriodoGraciaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changePeriodoCutoaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    selectedTiempoPeriodoCuotaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeTeaLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeFrecMaxLC: (state, action) => {
      return {...state, ...action.payload}
    },
    selectedTiempoFrecMaxLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeAparicionesLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeMinimoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    changeMaximoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    selectedEstadoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaEstadoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    filterListarLineaCredito: (state, action) => {
      let estadoInspeccionado = current(state)
      const filterListaLineaCredito = estadoInspeccionado.listaLineaCredito.filter(function (
        item,
        index,
      ) {
        return (
          item.codigo.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.nombre.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.estado.toLowerCase().includes(action.payload.toLowerCase())
        )
      })
      var obj = {
        filterListaLineaCredito: filterListaLineaCredito,
        searchtext: action.payload,
      }
      return {...estadoInspeccionado, ...obj}
    },
    setDataInfoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    setListaTiempoLC: (state, action) => {
      return {...state, ...action.payload}
    },
    limpiarCamposLC: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          idSolucion: null,
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          monto: '',
          bValidMonto: null,
          montoCuota: '',
          bValidMontoCuota: null,
          montoDes: '',
          bValidMontoDes: null,
          selectMoneda: [],
          bValidMoneda: null,
          peGracia: '',
          bValidPeGracia: null,
          bValidTiempoPeGracia: null,
          selectTiempoPeGracia: [],
          peCuotas: '',
          bValidCuotas: null,
          bValidTiempoPeCuotas: null,
          selectTiempoPeCuotas: [],
          tea: '',
          bValidTea: null,
          frecMax: '',
          bValidFrecMax: null,
          bValidTiempoFrecMax: null,
          selectTiempoFrecMax: [],
          apariciones: '',
          bValidApariciones: null,
          minimo: '',
          bValidMinimo: null,
          maximo: '',
          bValidMaximo: null,
          selectEstado: [],
          bValidEstado: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      }
    },
    limpiarFormLC: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          monto: '',
          bValidMonto: null,
          montoCuota: '',
          bValidMontoCuota: null,
          montoDes: '',
          bValidMontoDes: null,
          selectMoneda: [],
          bValidMoneda: null,
          peGracia: '',
          bValidPeGracia: null,
          bValidTiempoPeGracia: null,
          selectTiempoPeGracia: [],
          peCuotas: '',
          bValidCuotas: null,
          bValidTiempoPeCuotas: null,
          selectTiempoPeCuotas: [],
          tea: '',
          bValidTea: null,
          frecMax: '',
          bValidFrecMax: null,
          bValidTiempoFrecMax: null,
          selectTiempoFrecMax: [],
          apariciones: '',
          bValidApariciones: null,
          minimo: '',
          bValidMinimo: null,
          maximo: '',
          bValidMaximo: null,
          selectEstado: [],
          bValidEstado: null,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      }
    },
    setValidCampos: (state, action) => {
      return {...state, ...action.payload}
    },
  },
})
export const {
  agregarLineaCredito,
  editarLineaCredito,
  eliminarLineaCreditos,
  setListaLineaCredito,
  //Metodos del formulario
  selectedTipoFomularioLC,
  changeDescripcionLC,
  selectedAgenteLC,
  setListaAgente,
  changeVigenciaLC,
  changeMontoLC,
  changeMontoCuotaLC,
  changeMontoDesembolsoLC,
  selectedMonedaLC,
  setListaMonedaLC,
  changePeriodoGraciaLC,
  selectedTiempoPeriodoGraciaLC,
  changePeriodoCutoaLC,
  selectedTiempoPeriodoCuotaLC,
  changeTeaLC,
  changeFrecMaxLC,
  selectedTiempoFrecMaxLC,
  changeAparicionesLC,
  changeMinimoLC,
  changeMaximoLC,
  selectedEstadoLC,
  setListaEstadoLC,
  filterListarLineaCredito,
  limpiarCamposLC,
  limpiarFormLC,
  setDataInfoLC,
  setListaTiempoLC,
  setValidCampos,
} = lineaCreditoSlice.actions
export default lineaCreditoSlice.reducer
