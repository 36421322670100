import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFetchAndLoad } from '../../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { PruebaCognito, login, signIn } from '../../services/public.service';
import {
  changePassword,
  changeTenant,
  changeUserName,
  limpiarCamposLogin,
  onLogin,
  onTenant,
} from '../../redux/states';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import logoPanel from '../../assets/images/zcf.png';
import { LoadingSpinner } from '../../components';
import { theme } from './../../common/constants';
import { ColForm, RowForm } from '../../styles';
import UserPool from '../../UserPool';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const RecoveryPassword = () => {
  const loginState = useSelector((store) => store.login);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [showPassword, setShowPassword] = useState(false);
  const [disabledTenant, setDisabledTenant] = useState(false);
  const { tenantUrl } = useParams();

  useEffect(() => {
    if (tenantUrl) {
      let tenantDecode = atob(tenantUrl);
      dispatch(changeTenant({ tenant: tenantDecode }));
      setDisabledTenant(true);
    }
  }, []);

  const handlechangeTenant = (oEvent) => {
    let tenant = oEvent.target.value;
    let oParam = {
      tenant: tenant,
    };
    dispatch(changeTenant(oParam));
  };

  const handlechangeUserName = (oEvent) => {
    let userName = oEvent.target.value;
    let oParam = {
      userName: userName,
    };
    dispatch(changeUserName(oParam));
  };

  const handlechangePassword = (oEvent) => {
    let password = oEvent.target.value;
    let oParam = {
      password: password,
    };
    dispatch(changePassword(oParam));
  };

  const onPressIniciarSesion = async (event) => {
    event.preventDefault();

    const responseCognito = await signIn(loginState.userName, loginState.password);

    if (responseCognito.statusCode == 200) {
      localStorage.setItem('auth-token', responseCognito.result.AccessToken);
      localStorage.setItem('id-token', responseCognito.result.IdToken);
      localStorage.setItem('refresh-token', responseCognito.result.RefreshToken);

      // const resPruebaCognito = await callEndpoint(
      //   PruebaCognito(responseCognito.result.AccessToken)
      // );
      // Swal.fire({
      //   position: 'center',
      //   icon: 'warning',
      //   title: resPruebaCognito.data.cabecera.descripcion,
      //   showConfirmButton: false,
      //   timer: 2000,
      // });

      const responseLogin = await callEndpoint(
        login(
          {
            email: loginState.userName,
            password: loginState.password,
            tenant: loginState.tenant,
          },
          responseCognito.result.IdToken
        )
      );
      if (responseLogin != null) {
        if (responseLogin.data) {
          localStorage.setItem('x-token', responseLogin.data.token);
          localStorage.setItem('x-tenant', loginState.tenant);
          dispatch(onTenant(loginState.tenant));
          dispatch(limpiarCamposLogin());
          dispatch(
            onLogin({
              name: responseLogin.data.usuario,
              uid: responseLogin.data.id,
              isSuperAdmin: responseLogin.data.Admin,
              permisos: responseLogin.data.permisos,
              foto: responseLogin.data.foto,
            })
          );
          navigate('/dashboard');
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Usuario y/o clave incorrectos.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else if (responseCognito.statusCode == 400) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Usuario y/o clave incorrectos.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <Content>
      <MainContainer>
        <RowDiv className="row no-gutter">
          <LogDiv className="login d-flex align-items-center">
            <RowForm style={{ alignSelf: 'flex-start' }}>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '15px 0px ' }}>
                <TitleImg src={logoPanel} alt="Italian Trulli"></TitleImg>
              </ColForm>
              <ColForm xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                <h2>Cambiar contraseña</h2>
              </ColForm>
              <FormLogin onSubmit={onPressIniciarSesion}>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Label>Usuario</Label>
                  <PasswordContainer>
                    <Input
                      placeholder="Ingrese usuario"
                      type={'text'}
                      value={loginState.userName}
                      onChange={(oEvent) => handlechangeUserName(oEvent)}
                    ></Input>
                  </PasswordContainer>
                </ColForm>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Label>Contraseña</Label>
                  <PasswordContainer>
                    <Input
                      placeholder="Ingrese nueva contraseña"
                      type={showPassword ? 'text' : 'password'}
                      value={loginState.password}
                      onChange={(oEvent) => handlechangePassword(oEvent)}
                    ></Input>
                    {!showPassword ? (
                      <AiOutlineEye
                        className="login"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="login"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </PasswordContainer>
                </ColForm>
                <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Label>Contraseña</Label>
                  <PasswordContainer>
                    <Input
                      placeholder="Confirmar contraseña"
                      type={showPassword ? 'text' : 'password'}
                      value={loginState.password}
                      onChange={(oEvent) => handlechangePassword(oEvent)}
                    ></Input>
                    {!showPassword ? (
                      <AiOutlineEye
                        className="login"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="login"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </PasswordContainer>
                </ColForm>
                <ColForm
                  style={{ marginTop: '20px', padding: '5px 0px ' }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Button type="submit">Cambiar contraseña</Button>
                </ColForm>
              </FormLogin>
            </RowForm>
          </LogDiv>
        </RowDiv>
      </MainContainer>
      {loading ? <LoadingSpinner /> : <></>}
    </Content>
  );
};

const Content = styled.div`
  background: linear-gradient(
    150deg,
    ${theme.colors.secondary} 21%,
    ${theme.colors.tertiary} 50%,
    ${theme.colors.primary} 86%
  );
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 32px;
  width: 100%;
`;

const MainContainer = styled.div`
  width: 30vw;
  @media (max-width: 1400px) {
    width: 40vw;
  }
  @media (max-width: 1300px) {
    width: 42vw;
  }
  @media (max-width: 1200px) {
    width: 45vw;
  }
  @media (max-width: 900px) {
    width: 50vw;
  }
  @media (max-width: 750px) {
    width: 60vw;
  }
  @media (max-width: 550px) {
    width: 80vw;
  }
`;

const RowDiv = styled.div`
  justify-content: center;
  overflow: hidden;
  border-radius: 15px;
  background-color: ${theme.colors.white};
`;

const LogDiv = styled.div`
  padding: 30px 20px;
  width: 70%;
  @media (max-height: 800px) {
    height: 80vh;
  }
  @media (max-height: 700px) {
    height: 90vh;
  }
  @media (max-height: 700px) {
    height: 95 vh;
  }
  @media (max-width: 1200px) {
    padding: 30px 20px;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

const FormLogin = styled.form`
  width: 100%;
`;

const TitleImg = styled.img`
  width: 100%;
`;

const Label = styled.label`
  font-family: ${theme.font.family};
  padding: '5px 0px ';
  margin: 15px 0px 5px;
  line-height: 1.5;
  font-weight: 500;
  font-size: 18px;
  display: block;
`;

const Input = styled.input`
  border: 0.5px solid ${theme.colors.line};
  background-color: ${theme.colors.white};
  outline: none !important;
  padding: 16.5px 14px;
  border-radius: 2px;
  width: 100%;
  &:focus {
    border: 0.5px solid ${theme.colors.line};
  }
`;

const PasswordContainer = styled.div`
  position: relative;
  input[type='password'],
  input[type='text'] {
    width: 100%;
    padding: 12px 36px 12px 12px;
    box-sizing: border-box;
  }
`;

const Button = styled.button`
  background-color: ${theme.colors.tertiary};
  font-family: ${theme.font.family};
  color: ${theme.colors.white};
  padding: 10px 22px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  min-width: 64px;
  border: 0px;
  width: 100%;
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;
