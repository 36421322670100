import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
import collect from 'collect.js';
export const aplazamientoInitialState = {
  //TablaBuscador
  searchtext: '',
  modalOpenTEAMultipleSolAP: false,
  //tabla
  listaAplazamiento: [],
  filterListaAplazamiento: [],
  //formulario
  idSolucion: null,
  descripcion: '',
  bValidDescripcion: null,
  selectAgente: [],
  bValidAgente: null,
  listaAgente: [],
  dDateRange: [null, null],
  bValidFecha: null,
  selectTipoFormulario: [],
  bValidselectTipoFormulario: null,
  listaTipoFommulario: [],
  selectMoneda: [],
  bValidMoneda: null,
  listaMoneda: [],
  selectMultiMonedaConversion: [],
  bValidMultiMonedaConversion: null,
  montoMaximo: '',
  bValidMontoMaximo: null,
  periodoMaximo: '',
  bValidPeriodoMaximo: null,
  selectTiempoPeriodoMaximo: [],
  bValidSelectTiempoPeriodoMaximo: null,
  periodoMinimo: 0,
  bValidPeriodoMinimo: null,
  selectTiempoPeriodoMinimo: [],
  bValidSelectTiempoPeriodoMinimo: null,
  tea: '',
  bValidTea: null,
  moraVariableRetraso: '',
  bValidMoraVariableRetraso: null,
  periodoEntreMora: '',
  bValidPeriodoEntreMora: null,
  selectTiempoPeriodoEntreMora: [],
  bValidSelectTiempoPeriodoEntreMora: null,
  moraPorcentajeRetraso: '',
  bValidMoraPorcentajeRetraso: null,
  apariciones: '',
  bValidApariciones: null,
  selectEstado: [],
  bValidEstado: null,
  listaEstado: [],

  valueFileUploader: '',
  modalOpenUploadDataExcel: false,
  uploadDataExcel: [],
  regManualesAP: [],
  selectRegManualesAP: [],
  //

  listaColumnasDinamicaTablaAP: [],
  selectColumnasDinamicaTablaAP: [],
  listaTipoOperadorMultiplestableAP: [
    {
      value: 1,
      label: 'Contiene',
      idTipo: 1,
    },
    {
      value: 2,
      label: 'Igual',
      idTipo: 1,
    },
    {
      value: 3,
      label: 'Múltiple',
      idTipo: 1,
    },
    //Casos Number
    {
      value: 4,
      label: '=',
      idTipo: 2,
    },
    {
      value: 5,
      label: '>=',
      idTipo: 2,
    },
    {
      value: 6,
      label: '<=',
      idTipo: 2,
    },
    {
      value: 7,
      label: '>',
      idTipo: 2,
    },
    {
      value: 8,
      label: '<',
      idTipo: 2,
    },
    {
      value: 9,
      label: '<>',
      idTipo: 2,
    },
    {
      value: 10,
      label: 'Rango',
      idTipo: 2,
    },
    {
      value: 3,
      label: 'Múltiple',
      idTipo: 2,
    },
    //Casos Date
    {
      value: 4,
      label: '=',
      idTipo: 3,
    },
    {
      value: 7,
      label: '>',
      idTipo: 3,
    },
    {
      value: 8,
      label: '<',
      idTipo: 3,
    },
    {
      value: 9,
      label: '<>',
      idTipo: 3,
    },
    {
      value: 10,
      label: 'Rango',
      idTipo: 3,
    },
    {
      value: 3,
      label: 'Multiple',
      idTipo: 3,
    },
  ],

  aplicarAllReg: false,

  listaMonedaSolucionDisponiblesTableAP: [],
  listMonedaSolucionTeaAP: [],

  listaColumnasMultiplestableAP: [],
  listaColumnasMultiplesGeneraltableAP: [],

  listaFiltrosMultiplesAppliedTableAP: [],
  listaFiltrosMultiplesTableAP: [],
};

export const aplazamientoSlice = createSlice({
  name: 'aplazamiento',
  initialState: aplazamientoInitialState,
  reducers: {
    agregarAplazamiento: (state, action) => {
      return { ...state, ...action.payload };
    },
    editarAplazamiento: (state, action) => {
      return { ...state, ...action.payload };
    },
    eliminarAplazamientos: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTipoFomularioAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeCantCuotaLC: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaAplazamiento: (state, action) => {
      return { ...state, ...action.payload };
    },
    //Metodos del Formuario de Creacion
    changeDescripcionAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoMaximoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMoraFijoRetrasoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMoraVariableRetrasoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoEntreMoraAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoEntreMoraAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoMaximoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changePeriodoMinimoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedTiempoPeriodoMaximoAP: (state, action) => {
      return { ...state, ...action.payload };
    },

    selectedTiempoPeriodoMinimoAP: (state, action) => {
      return { ...state, ...action.payload };
    },

    changeMoraPorcentajeRetraso: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeTeaAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeMontoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeAparicionesAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedAgenteAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaAgenteAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeVigenciaAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectedMonedaAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMonedaAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaMonedaAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMultiMonedaConversionAP: (state, action) => {
      return { ...state, ...action.payload };
    },

    selectedEstadoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaEstadoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    filterListarAplazamiento: (state, action) => {
      let estadoInspeccionado = current(state);
      const filterListaAplazamiento = estadoInspeccionado.listaAplazamiento.filter(function (
        item,
        index
      ) {
        return (
          item.codigo.toLowerCase().includes(action.payload.toLowerCase()) ||
          // item.nombre
          //   .toLowerCase()
          //   .includes(action.payload.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.estado.toLowerCase().includes(action.payload.toLowerCase())
        );
      });
      var obj = {
        filterListaAplazamiento: filterListaAplazamiento,
        searchtext: action.payload,
      };
      return { ...estadoInspeccionado, ...obj };
    },
    setDataInfoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setListaTiempoAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarCamposAP: (state, action) => {
      return {
        ...state,
        ...{
          //TablaBuscador
          searchtext: '',
          //formulario
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          selectMoneda: [],
          bValidMoneda: null,
          selectMultiMonedaConversion: [],
          bValidMultiMonedaConversion: null,
          regManualesAP: [],
          selectRegManualesAP: [],
          listaFiltrosMultiplesTableAP: [],
          // listaColumnasMultiplestableAP: [],
          montoMaximo: '',
          bValidMontoMaximo: null,
          tea: '',
          bValidTea: null,
          periodoMaximo: '',
          bValidPeriodoMaximo: null,
          bValidSelectTiempoPeriodoMaximo: null,
          selectTiempoPeriodoMaximo: [],
          periodoMinimo: '',
          bValidPeriodoMinimo: null,
          selectTiempoPeriodoMinimo: [],
          bValidSelectTiempoPeriodoMinimo: null,
          selectEstado: [],
          bValidEstado: null,
          apariciones: '',
          bValidApariciones: null,
          aplicarAllReg: false,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    limpiarEditCamposAP: (state, action) => {
      return {
        ...state,
        ...{
          idSolucion: null,
          //TablaBuscador
          searchtext: '',
          //formulario
          selectTipoFormulario: [],
          bValidselectTipoFormulario: null,
          descripcion: '',
          bValidDescripcion: null,
          selectAgente: [],
          bValidAgente: null,
          dDateRange: [null, null],
          bValidFecha: null,
          selectMoneda: [],
          bValidMoneda: null,
          selectMultiMonedaConversion: [],
          bValidMultiMonedaConversion: null,
          regManualesAP: [],
          selectRegManualesAP: [],
          listaFiltrosMultiplesTableAP: [],
          // listaColumnasMultiplestableAP: [],
          montoMaximo: '',
          bValidMontoMaximo: null,
          tea: '',
          bValidTea: null,
          periodoMaximo: '',
          bValidPeriodoMaximo: null,
          bValidSelectTiempoPeriodoMaximo: null,
          selectTiempoPeriodoMaximo: [],
          selectEstado: [],
          bValidEstado: null,
          apariciones: '',
          bValidApariciones: null,
          aplicarAllReg: false,
          //
          valueFileUploader: '',
          modalOpenUploadDataExcel: false,
          uploadDataExcel: [],
        },
      };
    },
    setValidCamposAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setRegManualesAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    addSelectRegManualesAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectRegManualesAP: (state, action) => {
      let oParam = {
        selectRegManualesAP: action.payload,
      };
      return { ...state, ...oParam };
    },
    //Acciones de Modal Filtros
    setColumnsFiltroMultiplesTableAP: (state, action) => {
      // state.listaColumnasMultiplestableAP = [...state.listaFiltrosMultiplesTableAP, action.payload];
      return { ...state, ...action.payload };
      // listaColumnasMultiplestableAP
    },
    setSelectColumnasDinamicaTablaAP: (state, action) => {
      // state.listaColumnasMultiplestableAP = [...state.listaFiltrosMultiplesTableAP, action.payload];
      return { ...state, ...action.payload };
      // listaColumnasMultiplestableAP
    },
    onChangeColumnsFiltroMultiplesTableAP: (state, action) => {
      let aEncontrado;

      if (action.payload.action == 'ADD') {
        aEncontrado = state.listaColumnasMultiplestableAP.filter(function (item, index) {
          return item.value == action.payload.oSelectColumna.value;
        });
        if (aEncontrado.length > 0) {
          aEncontrado[0].seleccionada = 1;
        }
      } else if (action.payload.action == 'REMOVE') {
        aEncontrado = state.listaColumnasMultiplestableAP.filter(function (item, index) {
          return item.value == action.payload.oSelectColumna.value;
        });
        if (aEncontrado.length > 0) {
          aEncontrado[0].seleccionada = 0;
        }
      }

      // listaColumnasMultiplestableAP
    },
    onChangeRowFiltroMultipleColumnaTableAP: (state, action) => {
      let oSelectColumnaPrev;
      let oSelectColumnaActual;
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index === action.payload.index) {
          if (action.payload.oSelectColumna) {
            item.selectColumna = [action.payload.oSelectColumna];
            // oSelectColumnaActual = { ...item.selectColumna };
          } else {
            item.selectColumna = [];
            // oSelectColumnaPrev = null;
          }
          item.selectOperador = [];

          item.valueFields = [];
          item.newValue = '';
          item.aListaValores = [];
          item.CantidadReg = null;
          item.pagina = 1;
          item.paginaciones = null;
          item.paginacion = null;
        }
        return item;
      });
    },
    onChangeRowCondicionoMultipleOperadorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index == action.payload.index) {
          if (action.payload.oSelectCondicion) {
            item.selectCondicion = [action.payload.oSelectCondicion];
          } else {
            item.selectCondicion = [];
          }
        }
        return item;
      });
    },
    onChangeRowFiltroMultipleOperadorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index == action.payload.index) {
          item.valueFields = [];
          item.newValue = '';
          if (action.payload.oSelectOperador) {
            item.selectOperador = [action.payload.oSelectOperador];
          } else {
            item.selectOperador = [];
          }
        }
        return item;
      });
    },
    onChangeRowFiltroMultipleValorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = action.payload.valueFields;

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onChangeRowFiltroMultipleFieldValorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index === action.payload.index) {
          //item.valueFields = action.payload.valueFields;

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onChangeRowMultiInputValueSingleEntryMultipleOperadorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index === action.payload.index) {
          item.newValue = action.payload.newValue;
        }
        return item;
      });
    },

    addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index === action.payload.index) {
          // item.valueFields = [...item.valueFields, action.payload.newValue];
          item.valueFields = [...new Set([...item.valueFields, action.payload.newValue])];

          item.newValue = '';

          item.aListaValores = action.payload.aListaValores;
          item.CantidadReg = action.payload.CantidadReg;
          item.pagina = action.payload.pagina;
          item.paginaciones = action.payload.paginaciones;
          item.paginacion = action.payload.paginacion;
        }
        return item;
      });
    },

    onSetRowMultiInputValueNultriEntryMultipleOperadorTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        if (index === action.payload.index) {
          item.valueFields = action.payload.valueFields; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },

    addFiltroMultiplesTableAP: (state, action) => {
      state.listaFiltrosMultiplesTableAP = [...state.listaFiltrosMultiplesTableAP, action.payload];
    },
    onSetFiltroMultiplesTableAP: (state, action) => {
      let listaColumnasMultiplestableAP = [];
      state.listaColumnasMultiplestableAP.forEach((element) => {
        let obj = { ...element };
        let aEncontrado = action.payload.aEncontradoNoAplicados.filter(function (item, index) {
          return element.value === item.selectColumna[0].value;
        });
        if (aEncontrado.length > 0) {
          obj.seleccionada = 0;
        }
        listaColumnasMultiplestableAP.push(obj);
      });

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableAP: action.payload.listaFiltrosMultiplesTableAP,
          listaColumnasMultiplestableAP: listaColumnasMultiplestableAP,
        },
      };
    },
    clearFiltroMultiplesTableAP: (state, action) => {
      return { ...state, ...{ listaFiltrosMultiplesTableAP: [] } };
    },
    deleteFiltroMultiplesTableAP: (state, action) => {
      let newList = [];
      state.listaFiltrosMultiplesTableAP.map((item, index) => {
        let obj = { ...item };
        if (index != action.payload.index) {
          newList.push(obj);
        } else {
          obj.status = 'E';
        }
      });

      let listaColumnasMultiplestableAP = [];

      if (action.payload.oSelectColumna.length > 0) {
        for (let index = 0; index < state.listaColumnasMultiplestableAP.length; index++) {
          let element = { ...state.listaColumnasMultiplestableAP[index] };
          if (element.value === action.payload.oSelectColumna[0].value) {
            element.seleccionada = 0;
          }
          listaColumnasMultiplestableAP.push(element);
        }
      } else {
        listaColumnasMultiplestableAP = [...state.listaColumnasMultiplestableAP];
      }

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableAP: newList,
          listaColumnasMultiplestableAP: listaColumnasMultiplestableAP,
        },
      };
    },
    deleteAllFiltroMultiplesTableAP: (state, action) => {
      let newList = [];
      state.listaColumnasMultiplestableAP.map((item, index) => {
        let obj = { ...item };
        obj.seleccionada = 0;
        newList.push(obj);
      });

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableAP: [],
          listaFiltrosMultiplesAppliedTableAP: [],
          listaColumnasMultiplestableAP: newList,
        },
      };
    },

    aplicarFiltrosMultiplesTableAP: (state, action) => {
      let listaColumnasMultiplestableAP = [];

      for (let index = 0; index < state.listaColumnasMultiplestableAP.length; index++) {
        let element = { ...state.listaColumnasMultiplestableAP[index] };
        listaColumnasMultiplestableAP.push(element);
      }

      action.payload.deleteNewList.forEach((element) => {
        if (element.selectColumna.length > 0) {
          let encontrado = listaColumnasMultiplestableAP.filter(function (item, index) {
            return item.value === element.selectColumna[0].value;
          });
          if (encontrado.length > 0) {
            encontrado[0].seleccionada = 0;
          }
        }
      });

      return {
        ...state,
        ...{
          listaFiltrosMultiplesTableAP: action.payload.listaFiltrosMultiplesTableAP,
          listaColumnasMultiplestableAP: listaColumnasMultiplestableAP,
        },
      };
    },
    onSetListaFiltrosMultiplesTableAppliedAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetMonedaSolucionDisponiblesTableAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    onSetMonedaSolucionTableAP: (state, action) => {
      return { ...state, ...action.payload };
    },
    onChangeRowsMonedaSolucionTableAP: (state, action) => {
      state.listMonedaSolucionTeaAP.map(function (item, index) {
        let aEncontrado = action.payload.selectMonedasSolucionTEA.filter(function (
          itemSelect,
          indexSelect
        ) {
          return itemSelect === item.codMoneda;
        });
        if (aEncontrado.length > 0) {
          item.tea = action.payload.tea;
          item.bValidTea = action.payload.bValidTea;
        }
        return item;
      });
      // return {
      //   ...state,
      //   listMonedaSolucionTeaAP: [],
      // };
    },

    onAddRowMonedaSolucionTableAP: (state, action) => {
      return {
        ...state,
        listMonedaSolucionTeaAP: state.listMonedaSolucionTeaAP.concat(action.payload.newItem),
      };
    },

    onChangeRowMonedaSolucionTableAP: (state, action) => {
      state.listMonedaSolucionTeaAP.map((item, index) => {
        if (item.idRow === action.payload.id) {
          item.selectMonedaSolucion = action.payload.selectMonedaSolucion; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },

    onChangeRowInputTeaSolucionTableAP: (state, action) => {
      state.listMonedaSolucionTeaAP.map((item, index) => {
        if (item.idRow === action.payload.id) {
          item.tea = action.payload.tea; //action.payload.newValue;
          item.bValidTea = action.payload.bValidTea;
          // item.newValue = '';
        }
        return item;
      });
    },

    onChangeMultiMonedaMultiCheckboxTableAP: (state, action) => {
      state.listMonedaSolucionTeaAP.map((item, index) => {
        if (index === action.payload.index) {
          item.selectMultiMonedaConversion = action.payload.selectMultiMonedaConversion; //action.payload.newValue;
          // item.newValue = '';
        }
        return item;
      });
    },

    onDeleteRowMultiMonedaMultiTableAP: (state, action) => {
      let listMonedaSolucionTeaAP = state.listMonedaSolucionTeaAP.filter(function (item, index) {
        return item.idRow !== action.payload.id;
      });

      return {
        ...state,
        listMonedaSolucionTeaAP: listMonedaSolucionTeaAP,
      };
    },

    onOpenModalAsignarTEAMultipleSolAP: (state, action) => {
      return { ...state, ...action.payload };
    },

    onChangeAplicarAllRegAP: (state, action) => {
      return { ...state, ...{ aplicarAllReg: !state.aplicarAllReg } };
    },
  },
});
export const {
  agregarAplazamiento,
  editarAplazamiento,
  eliminarAplazamientos,
  selectedTipoFomularioAP,
  setListaAplazamiento,
  changeDescripcionAP,
  changeMontoMaximoAP,
  changeMoraFijoRetrasoAP,
  changeMoraVariableRetrasoAP,
  changePeriodoEntreMoraAP,
  selectedTiempoPeriodoEntreMoraAP,
  changePeriodoMaximoAP,
  changePeriodoMinimoAP,
  selectedTiempoPeriodoMaximoAP,
  selectedTiempoPeriodoMinimoAP,
  changeTeaAP,
  changeMoraPorcentajeRetraso,
  changeMontoAP,
  changeAparicionesAP,
  selectedEstadoAP,
  setListaEstadoAP,
  filterListarAplazamiento,
  setValidCamposAP,
  limpiarCamposAP,
  limpiarEditCamposAP,
  setDataInfoAP,
  setListaTiempoAP,
  selectedAgenteAP,
  setListaAgenteAP,
  changeVigenciaAP,
  selectedMonedaAP,
  setListaMonedaAP,
  setMultiMonedaConversionAP,
  setRegManualesAP,
  setSelectRegManualesAP,
  setColumnsFiltroMultiplesTableAP,
  setSelectColumnasDinamicaTablaAP,
  onChangeRowFiltroMultipleColumnaTableAP,
  onChangeColumnsFiltroMultiplesTableAP,
  onChangeRowCondicionoMultipleOperadorTableAP,
  onChangeRowFiltroMultipleOperadorTableAP,
  onChangeRowFiltroMultipleValorTableAP,
  onChangeRowFiltroMultipleFieldValorTableAP,
  onChangeRowMultiInputValueSingleEntryMultipleOperadorTableAP,
  addNewEntryRowMultiInputValueNultriEntryMultipleOperadorTableAP,
  onSetRowMultiInputValueNultriEntryMultipleOperadorTableAP,
  addFiltroMultiplesTableAP,
  onSetFiltroMultiplesTableAP,
  clearFiltroMultiplesTableAP,
  deleteFiltroMultiplesTableAP,
  deleteAllFiltroMultiplesTableAP,
  aplicarFiltrosMultiplesTableAP,
  onSetListaFiltrosMultiplesTableAppliedAP,

  onSetMonedaSolucionDisponiblesTableAP,
  onSetMonedaSolucionTableAP,

  onChangeRowsMonedaSolucionTableAP,
  onAddRowMonedaSolucionTableAP,
  onChangeRowMonedaSolucionTableAP,
  onChangeRowInputTeaSolucionTableAP,
  onChangeMultiMonedaMultiCheckboxTableAP,
  onDeleteRowMultiMonedaMultiTableAP,
  onOpenModalAsignarTEAMultipleSolAP,
  onChangeAplicarAllRegAP,
} = aplazamientoSlice.actions;
export default aplazamientoSlice.reducer;
