export const formatString_DD_MM_YYYY_TO_YYYY_MM_DD = (sFecha) => {
  var sFecharFomart = '';
  var aFecha = sFecha.split('/');
  sFecharFomart = aFecha[2] + '-' + aFecha[1] + '-' + aFecha[0];
  return sFecharFomart;
};
export const formatString_DDMMYYYY_TO_YYYYMMDD = (sFecha, separador) => {
  var sFecharFomart = '';
  var aFecha = sFecha.split(separador);
  sFecharFomart = aFecha[2] + '-' + aFecha[1] + '-' + aFecha[0];
  return sFecharFomart;
};

export function excelDateToJSDate(excelDate) {
  const excelStartDate = new Date(Date.UTC(1899, 11, 31)); // Excel fecha base
  let inputDate = new Date(excelStartDate.getTime() + excelDate * 86400000); // 86400000 ms en un día

  let date, month, year;
  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${date}/${month}/${year}`;
}

export const formatString_MMDDYYYY_TO_DDMMYYYY = (sFecha, separador) => {
  var sFecharFomart = '';
  var aFecha = sFecha.split(separador);
  sFecharFomart = aFecha[1] + '/' + aFecha[0] + '/' + aFecha[2];
  return sFecharFomart;
};
export const formatString_YYYYMMDD_TO_DDMMYYYY = (sFecha, separadorInicial, separadorFinal) => {
  var sFecharFomart = '';
  var aFecha = sFecha.split(separadorInicial);
  sFecharFomart = aFecha[2] + separadorFinal + aFecha[1] + separadorFinal + aFecha[0];
  return sFecharFomart;
};
export const formatString_change_separator = (sFecha, separadorInicial, separadorFinal) => {
  var sFecharFomart = '';
  var aFecha = sFecha.split(separadorInicial);
  sFecharFomart = aFecha[0] + separadorFinal + aFecha[1] + separadorFinal + aFecha[2];
  return sFecharFomart;
};

export const formatStringDDMMYY = (input) => {
  if (input.length > 0) {
    var dateParts = input.split('/');
    var dateObject = dateParts[0] + '/' + dateParts[1] + '/' + dateParts[2].substring(2, 4);
    return dateObject;
  } else return input;
};

export const formatDateMMDDYYYY = (input) => {
  if (input.length > 0) {
    var dateParts = input.split('/');
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
  } else return input;
};
export const dateTo_dd_mm_yyyy = (inputDate, separator) => {
  let date, month, year;
  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${date}${separator}${month}${separator}${year}`;
};
export const dateTo_yyyy_mm_dd = (inputDate, separator) => {
  let date, month, year;
  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${year}${separator}${month}${separator}${date}`;
};
export const stringFechaToDate = (inputDate, separatorInicial, separatorFinal) => {
  var aFecha = inputDate.split(separatorInicial);
  var sFecharFomart =
    aFecha[2] + separatorFinal + aFecha[1] + separatorFinal + aFecha[0] + 'T00:00:00';
  return new Date(sFecharFomart);
};
export const stringFechaToDateWithOutTime = (inputDate, separatorInicial) => {
  var aFecha = inputDate.split(separatorInicial);
  // var sFecharFomart = aFecha[2]+separatorFinal+aFecha[1]+separatorFinal+aFecha[0]
  return new Date(parseInt(aFecha[2]), parseInt(aFecha[1]) - 1, parseInt(aFecha[0]));
};
export const monthToString = (month) => {
  let aMes = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  return aMes[parseInt(month) - 1];
};

export function GroupDatesByWeek(dates) {
  // Crear un objeto para almacenar las fechas agrupadas por semana
  const groupedDates = {};

  dates.forEach((date) => {
    // Obtener el número de semana del año para la fecha
    const weekNumber = GetWeekNumber(date);

    // Si la semana no está en el objeto, crear un nuevo array para almacenar las fechas
    if (!groupedDates[weekNumber]) {
      groupedDates[weekNumber] = [];
    }

    // Agregar la fecha al array correspondiente a esa semana
    groupedDates[weekNumber].push(date);
  });

  return groupedDates;
}

// Función auxiliar para obtener el número de semana del año para una fecha
function GetWeekNumber(date) {
  let splitDate = date.split('/');
  const d = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNumber;
}

export function GroupDatesByMonth(dates, t) {
  // Crear un objeto para almacenar las fechas agrupadas por mes
  const groupedDates = {};

  dates.forEach((date) => {
    // Obtener el nombre del mes y el año para la fecha
    const monthYear = GetMonthYear(date, t);

    // Si el mes y año no están en el objeto, crear un nuevo array para almacenar las fechas
    if (!groupedDates[monthYear]) {
      groupedDates[monthYear] = [];
    }

    // Agregar la fecha al array correspondiente a ese mes y año
    groupedDates[monthYear].push(date);
  });

  return groupedDates;
}

// Función auxiliar para obtener el nombre del mes y año para una fecha
function GetMonthYear(date, t) {
  let splitDate = date.split('/');
  const d = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
  return new Intl.DateTimeFormat(t('idioma'), { year: 'numeric', month: 'long' }).format(d);
}
