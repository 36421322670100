import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DashboardCard from '../../../components/DashboardCard/DashboardCard';
import Chart from 'react-apexcharts';
import { Avatar, Divider, List, Skeleton } from 'antd';
import { useEffect } from 'react';
import { LoadingForm, SelectForm } from '../../../components';
import { dashboardData } from '../../../common';
import { CardDash, RowForm, Title } from '../../../styles';
import { obtenerUrlImg } from '../../../services/public.service';

export const AvailableSolution = (props) => {
  // select
  const [tipoSolucion, setTipoSolucion] = React.useState([
    {
      value: 'LÍNEA CRÉDITO',
      label: 'LÍNEA CRÉDITO',
    },
  ]);
  const [data, setData] = React.useState([]);
  const [dataFiltrada, setDataFiltrada] = React.useState([]);

  useEffect(() => {
    let listaData = [];
    debugger;
    if (props.data) {
      props.data.map((a) => {
        if (
          new Date(
            a.VigenciaInicio.substring(6, 10),
            a.VigenciaInicio.substring(3, 5) - 1,
            a.VigenciaInicio.substring(0, 2)
          ) <= new Date() &&
          new Date() <=
            new Date(
              a.VigenciaFin.substring(6, 10),
              a.VigenciaFin.substring(3, 5) - 1,
              a.VigenciaFin.substring(0, 2)
            )
        ) {
          listaData.push(a);
        }
      });

      (async () => {
        listaData = await obtenerImg(listaData);
      })();
    }
  }, [props.data]);

  const obtenerImg = async (lista) => {
    let listaData = [];
    let listaLogo = [];
    for await (let ele of lista) {
      let LogoUrl = await obtenerUrlImg(ele.Logo);
      listaLogo.push(LogoUrl);
      listaData.push({ ...ele, LogoUrl: LogoUrl });
    }
    setData(listaData);
    setDataFiltrada(listaData.filter((r) => r.TipoSolucion === tipoSolucion[0].value));
  };

  const handleChange = (event) => {
    setTipoSolucion([event]);
    setDataFiltrada(data.filter((r) => r.TipoSolucion === event.value));
  };

  return (
    <CardDash
      title={
        <RowForm>
          <Title>{props.titulo}</Title>
          <SelectForm
            width={'40%'}
            style={{ marginLeft: 'auto' }}
            value={tipoSolucion}
            handleChange={(evnt, v) => handleChange(v)}
            validate={true}
            options={dashboardData}
            placeholder={'Seleccione'}
            disableClearable={true}
          />
        </RowForm>
      }
      size={'large'}
      bodyStyle={{ height: '340px', overflow: 'auto' }}
    >
      {props.loading ? (
        <LoadingForm />
      ) : (
        <List
          style={{ height: 'fit-content', overflow: 'overlay' }}
          itemLayout="horizontal"
          dataSource={dataFiltrada}
          renderItem={(item, index) => (
            <List.Item>
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  avatar={<Avatar src={item.LogoUrl} />}
                  title={item.NombreAgente}
                  description={
                    tipoSolucion[0].value != 'TIPO CAMBIO' ? item.NombreSolucion : item.CXV
                  }
                />
                <div>
                  {tipoSolucion[0].value != 'TIPO CAMBIO'
                    ? `${new Intl.NumberFormat('en-US').format(item.Importe)} ${item.Moneda}`
                    : item.NombreSolucion}
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </CardDash>
  );
};
