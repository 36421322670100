import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonDobleIcon } from '../../styles';

export const ButtonAccion = (props) => (
  <ButtonDobleIcon {...props}>
    <div>
      <FontAwesomeIcon icon="fa-solid fa-code" size="xs" /> <span>Acciones</span>
      <FontAwesomeIcon icon="fa-solid fa-chevron-down" size="xs" />
    </div>
  </ButtonDobleIcon>
);
