import { createSlice } from '@reduxjs/toolkit';

export const tablaFlujoCajaSlice = createSlice({
  name: 'tablaFlujoCaja',
  initialState: {
    cabecerasTablaFlujoCaja: [],
    rowsTablaFlujoCaja: [],
    rowsTotalParcialesTablaFlujoCaja: [],
    rowsTotalTablaFlujoCaja: [],
  },
  reducers: {
    setCabecerasTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    setRowsTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    setRowsTotalParcialesTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    setRowsTotalTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    addDesdobleRowTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    editRowTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    deleteRowTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
    limpiarRowsTablaFlujoCaja: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const {
  setCabecerasTablaFlujoCaja,
  setRowsTablaFlujoCaja,
  setRowsTotalParcialesTablaFlujoCaja,
  setRowsTotalTablaFlujoCaja,
  addDesdobleRowTablaFlujoCaja,
  editRowTablaFlujoCaja,
  deleteRowTablaFlujoCaja,
  limpiarRowsTablaFlujoCaja,
} = tablaFlujoCajaSlice.actions;
