import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonForm, Input, LabelForm, ModalConfirmation, TitlePage } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../common/hooks';
import { crearUsuario } from '../../services/public.service';
import {
  changeApellidos,
  changeCreatePassword,
  changeCreatePasswordConfirm,
  changeCreateUserName,
  changeEmail,
  changeNombres,
  limpiarCampos,
  onLogin,
} from '../../redux/states';
import { ColFlex, RowFlex } from '../../styles';
export const SignUpPage = () => {
  const SignUpState = useSelector((store) => store.usuario);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModalCrearUsuario, setShowModalCrearUsuario] = useState(false);
  const { callEndpoint } = useFetchAndLoad();
  const handleChangeNombres = (oEvent) => {
    let nombres = oEvent.target.value;
    let oParam = {
      nombres: nombres,
    };
    dispatch(changeNombres(oParam));
  };
  const handleChangeApellidos = (oEvent) => {
    let apellidos = oEvent.target.value;
    let oParam = {
      apellidos: apellidos,
    };
    dispatch(changeApellidos(oParam));
  };
  const handleChangeEmail = (oEvent) => {
    let email = oEvent.target.value;
    let oParam = {
      email: email,
    };
    dispatch(changeEmail(oParam));
  };
  const handleChangeUserName = (oEvent) => {
    let userName = oEvent.target.value;
    let oParam = {
      userName: userName,
    };
    dispatch(changeCreateUserName(oParam));
  };
  const handleChangePassword = (oEvent) => {
    let password = oEvent.target.value;
    let oParam = {
      password: password,
    };
    dispatch(changeCreatePassword(oParam));
  };
  const handleChangePasswordConfirm = (oEvent) => {
    let passwordConfirm = oEvent.target.value;
    let oParam = {
      passwordConfirm: passwordConfirm,
    };
    dispatch(changeCreatePasswordConfirm(oParam));
  };
  const onClickCrearUsuario = () => {
    setShowModalCrearUsuario(true);
  };
  const onCloseModalCrearUsuario = () => {
    setShowModalCrearUsuario(false);
  };
  const onPressCrearUsuario = async () => {
    //crearUsuario
    const responseCrearUsuario = await callEndpoint(
      crearUsuario({
        email: SignUpState.email,
        password: SignUpState.password,
        usuario: SignUpState.userName,
      })
    );
    if (responseCrearUsuario.data) {
      await localStorage.clear();
      dispatch(limpiarCampos());
      localStorage.setItem('x-token', responseCrearUsuario.data.token);
      dispatch(
        onLogin({
          name: responseCrearUsuario.data.usuario.Correo,
          uid: responseCrearUsuario.data.usuario.IDUsuario,
        })
      );
      navigate('/dashboard');
    }
  };
  const onCancelarCrearUsuario = async () => {
    dispatch(limpiarCampos());
    navigate('/login');
  };
  return (
    <Background>
      <div className="FieldsInput">
        <Content>
          <ColFlex>
            <RowContent>
              <Header>
                <TitlePage title="Registro" color="white" />
              </Header>
            </RowContent>
            <RowContent>
              <ColFlex>
                <ContentForm>
                  <RowFlex className="FieldsInput">
                    <LabelForm title="Nombres:" weight="bold" color={'white'}></LabelForm>
                    <Input
                      placeholder="Ingrese nombre"
                      value={SignUpState.nombres}
                      onChange={(evnt) => handleChangeNombres(evnt)}
                    />
                  </RowFlex>
                  <RowFlex className="FieldsInput">
                    <LabelForm title="Apellidos:" weight="bold" color={'white'}></LabelForm>
                    <Input
                      placeholder="Ingrese apellidos"
                      value={SignUpState.apellidos}
                      onChange={(evnt) => handleChangeApellidos(evnt)}
                    />
                  </RowFlex>
                  <RowFlex className="FieldsInput">
                    <LabelForm title="Email:" weight="bold" color={'white'}></LabelForm>
                    <Input
                      placeholder="Ingrese email"
                      value={SignUpState.email}
                      onChange={(evnt) => handleChangeEmail(evnt)}
                    />
                  </RowFlex>
                  <RowFlex className="FieldsInput">
                    <LabelForm title="Usuario:" weight="bold" color={'white'}></LabelForm>
                    <Input
                      placeholder="Ingrese usuario"
                      value={SignUpState.userName}
                      onChange={(evnt) => handleChangeUserName(evnt)}
                    />
                  </RowFlex>
                  <RowFlex className="FieldsInput">
                    <LabelForm title="Contraserña" weight="bold" color={'white'}></LabelForm>
                    <Input
                      placeholder="Ingrese contraseña"
                      type={'password'}
                      value={SignUpState.password}
                      onChange={(evnt) => handleChangePassword(evnt)}
                    />
                  </RowFlex>
                  <RowFlex className="FieldsInput">
                    <LabelForm title="Contraserña" weight="bold" color={'white'}></LabelForm>
                    <Input
                      placeholder="Repetir contraseña"
                      type={'password'}
                      value={SignUpState.passwordConfirm}
                      onChange={(evnt) => handleChangePasswordConfirm(evnt)}
                    />
                  </RowFlex>
                </ContentForm>
              </ColFlex>
            </RowContent>
            <ContentRowFormButton>
              <ButtonForm name={'Registrar'} isAction={true} action={onClickCrearUsuario} />
              <ButtonForm
                name={'Cancelar'}
                isAction={true}
                action={(e) => onCancelarCrearUsuario(e)}
              />
            </ContentRowFormButton>
          </ColFlex>
          <ModalConfirmation
            showModal={showModalCrearUsuario}
            closeModal={(e) => onCloseModalCrearUsuario()}
            title="¿Confirmación?"
            subtitle="¿Desea registrarse?"
            action={(e) => onPressCrearUsuario(e)}
            labelButtonCancel="Cancelar"
            labelButtonConfirm="Aceptar"
          />
        </Content>
      </div>
    </Background>
  );
};
const Background = styled.body`
  background-color: #2e6b6a;
`;
const Content = styled.div`
  display: flex;
  margin: 100px;
  background: #183838;
  padding: 5px 20px 15px 20px;
  border: 1px solid rgb(191, 246, 250);
  border-radius: 3px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Header = styled.div`
  margin: 50px 250px 0px 250px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-height: 70vh;
  row-gap: 12px;
`;
const ContentForm = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 70vh;
  row-gap: 12px;
`;
const ContentRowFormButton = styled.div`
  margin: 50px 200px 50px 200px;
  display: flex;
  flex-direction: row;
  row-gap: 12px;
`;
const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 50px;
`;
