import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonAccion,
  LoadingSpinner,
  SearchInputText,
  TableGrid,
} from '../../../components';
import { useFetchAndLoad } from '../../../common/hooks';
import {
  ColFlex,
  ContentButton,
  ContentOption,
  RowAccion,
  RowButton,
  RowFlex,
} from '../../../styles';
import {
  cargaArchivosS3,
  editarArchivosS3,
  eliminaArchivosS3,
  obtenerRutasDocu,
  obtenerUrlImg,
} from '../../../services/public.service';
import {
  changeModalComDocumento,
  changeModalDocumento,
  changeSearchDocumento,
  limpiarModalDocumento,
  setListaDocumento,
  updateTitle,
} from '../../../redux/states';
import { useEffect } from 'react';
import { faDownload, faEdit, faTrash } from '@fortawesome/fontawesome-free-solid';
import AWS from 'aws-sdk';
import { ModalArchivo, ModalComDocumento, ModalDocumento } from './components';
import Swal from 'sweetalert2';
import { Dropdown } from 'antd';
import { items } from './misc';

export const Documentos = () => {
  const dispatch = useDispatch();
  const socioState = useSelector((store) => store.socio);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModal, setOpenModal] = useState(false);
  const [openModalArchivo, setOpenModalArchivo] = useState(false);
  const [openModaComDocumento, setOpenModaComDocumento] = useState(false);
  const [listaArchivos, setListaArchivos] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);

  useEffect(() => {
    (async () => {
      dispatch(updateTitle({ title: 'Listar Documentos' }));
      loadDocumento();
    })();
  }, []);

  const onChangeSearch = (oEvent) => {
    if (oEvent) {
      dispatch(changeSearchDocumento(oEvent.target.value));
    } else {
      dispatch(changeSearchDocumento(''));
    }
  };

  const loadDocumento = async () => {
    debugger;
    const responseListaDocumento = await callEndpoint(obtenerRutasDocu());
    var listaDocumento = [];
    for (let index = 0; index < responseListaDocumento.data.respuesta.aListaDocu.length; index++) {
      const element = responseListaDocumento.data.respuesta.aListaDocu[index];
      let filtroEle = listaDocumento.filter((r) => r.IDAgrupado == element.IDAgrupado);
      //
      if (filtroEle.length > 0) {
        let lista = [...filtroEle[0].Documentos, element];
        filtroEle[0].Documentos = lista;
        // listaDocumento.push(element);
      } else {
        let lista = { ...element, Documentos: [element] };
        // element.Documentos = [element];
        listaDocumento.push(lista);
      }
    }
    dispatch(setListaDocumento({ listaDocumento: listaDocumento }));
  };

  const columns = [
    {
      field: 'NombreAgrupado',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'TipoDoc',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Detalle',
      headerName: 'Descripcion',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'FechaVigencia',
      headerName: 'Fecha de vigencia',
      flex: 0.5,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'edit',
      headerName: '',
      disableColumnMenu: true,
      disableFilters: true,
      sortable: false,
      headerAlign: 'center',
      width: 125,
      renderCell: (params) => (
        <>
          <RowButton>
            <Button
              action={(oEvent) => onDownload(oEvent, params.row)}
              icon={faDownload}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
            <Button
              action={(oEvent) => onPressOpenModal(oEvent, params.row)}
              icon={faEdit}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
            <Button
              action={(oEvent) => onDeleteDocumento(oEvent, params.row)}
              icon={faTrash}
              isAction={false}
              isOnlyIcon={true}
              type="primary"
            />
          </RowButton>
        </>
      ),
    },
  ];
  const onDownload = async (e, data) => {
    setOpenModalArchivo(true);
    setListaArchivos(data.Documentos);
    // let url = await obtenerUrlImg(data.URLDocu);
    // obtenerFile(url);
  };
  const onDeleteDocumento = (e, data) => {
    Swal.fire({
      title: `Seguro que desea eliminar a ${data.NombreAgrupado}`,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDocumentoDelete = await callEndpoint(
          eliminaArchivosS3({ IDAgrupador: data.IDAgrupado })
        );
        if (responseDocumentoDelete.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseDocumentoDelete.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadDocumento();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseDocumentoDelete.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const onPressOpenModal = async (e, data) => {
    debugger;
    if (data) {
      if (data.Documentos.length > 0) {
        let fotos = [];

        for await (let r of data.Documentos) {
          const splitLogo = r.URLDocu.split('/');
          const name = splitLogo[splitLogo.length - 1];
          const res = await obtenerUrlImg(r.URLDocu);
          const response = await fetch(res, {
            mode: 'no-cors',
          });
          const datafoto = await response.blob();
          let foto = new File([datafoto], r.NombreArchivo, {
            type: datafoto.type,
          });
          fotos.push(foto);
        }

        var modal = {
          nId: data.IDAgrupado,
          tTipo: [{ value: data.TipoDoc, label: data.TipoDoc }],
          tDocumento: fotos,
          tDescripcion: data.Detalle,
          tNombre: data.NombreAgrupado,
          tFecha: data.FechaVigencia,
          tURL: data.URLDocu,
        };
        dispatch(changeModalDocumento({ oModalDocumento: modal }));
        setOpenModal(true);
      } else {
        var modal = {
          nId: data.IDAgrupado,
          tTipo: [{ value: data.TipoDoc, label: data.TipoDoc }],
          tDocumento: [],
          tDescripcion: data.Detalle,
          tNombre: data.NombreAgrupado,
          tFecha: data.FechaVigencia,
          tURL: data.URLDocu,
        };
        dispatch(changeModalDocumento({ oModalDocumento: modal }));
        setOpenModal(true);
      }
    } else setOpenModal(true);
  };

  const limpiarModal = () => {
    dispatch(limpiarModalDocumento());
  };

  const onPressCloseModal = () => {
    limpiarModal();
    setOpenModal(false);
  };
  const onPressCloseModalArchivo = () => {
    setOpenModalArchivo(false);
    setListaArchivos([]);
  };

  const onPressCloseModalComDocumento = () => {
    setOpenModaComDocumento(false);
  };

  const validarCamposModal = () => {
    let validar = false;
    if (socioState.oModalDocumento.tTipo) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModalDocumento.tDescripcion) {
      validar = true;
    } else return (validar = false);

    if (socioState.oModalDocumento.tDocumento) {
      validar = true;
    } else return (validar = false);
    if (socioState.oModalDocumento.tFecha) {
      validar = true;
    } else return (validar = false);
    return validar;
  };

  const onPressSaveModal = async () => {
    debugger;
    let validarDatos = false;
    validarDatos = await validarCamposModal();
    if (validarDatos) {
      if (!socioState.oModalDocumento.nId) {
        const formData = new FormData();
        formData.append('TipoDocumento', socioState.oModalDocumento.tTipo[0].value);
        formData.append('DetalleDocumento', socioState.oModalDocumento.tDescripcion);
        formData.append('NombreDocumento', socioState.oModalDocumento.tNombre);
        formData.append('Fecha', socioState.oModalDocumento.tFecha);

        socioState.oModalDocumento.tDocumento.map((r) => formData.append('file', r.originFileObj));

        // formData.append('file', socioState.oModalDocumento.tDocumento[0].originFileObj);

        const responseCargarArchivo = await callEndpoint(cargaArchivosS3(formData));
        setOpenModal(false);
        if (responseCargarArchivo.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseCargarArchivo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadDocumento();
          limpiarModal();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseCargarArchivo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        const formData = new FormData();
        formData.append('IDAgrupador', socioState.oModalDocumento.nId);
        formData.append('TipoDocumento', socioState.oModalDocumento.tTipo[0].value);
        formData.append('DetalleDocumento', socioState.oModalDocumento.tDescripcion);
        formData.append('NombreDocumento', socioState.oModalDocumento.tNombre);
        formData.append('Fecha', socioState.oModalDocumento.tFecha);

        socioState.oModalDocumento.tDocumento.map((r) => {
          if (r.originFileObj) {
            formData.append('file', r.originFileObj);
          } else {
            formData.append('file', r);
          }
        });
        // formData.append('file', socioState.oModalDocumento.tDocumento[0].originFileObj);
        const responseEditarArchivo = await callEndpoint(editarArchivosS3(formData));
        setOpenModal(false);
        if (responseEditarArchivo.data.cabecera.statusCode === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseEditarArchivo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          loadDocumento();
          limpiarModal();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseEditarArchivo.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Se tienen que llenar todos los campos',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const selectSetCheck = (e) => {
    let lista = [...socioState.listaDocumento];
    let listaFiltrada = [...socioState.filterListaDocumento];

    let arrayFiltrado = lista.filter((objeto) =>
      listaFiltrada.some((filtro) => filtro.IDAgrupado === objeto.IDAgrupado)
    );
    if (arrayFiltrado.length > 0) {
      if (arrayFiltrado.length == lista.length) {
        setSelectionModel(e);
      } else {
        let selectionFilter = selectionModel.filter((elemento) => {
          // Verifica si algún objeto en el array de filtro coincide con el elemento actual
          return !listaFiltrada.some((objeto) => objeto.IDAgrupado === elemento);
        });
        let listaSelecFinal = [...selectionFilter, ...e];
        setSelectionModel([...new Set(listaSelecFinal)]);
      }
    } else {
      setSelectionModel(e);
    }
  };

  const onClickCompartir = () => {
    if (selectionModel.length > 0) {
      let listaDocumentos = [];
      socioState.listaDocumento.map((v) => {
        let encontrado = selectionModel.find((r) => r == v.IDAgrupado);
        if (encontrado) {
          listaDocumentos.push(v);
        }
      });
      dispatch(changeModalComDocumento({ oModalComDocumento: listaDocumentos }));
      setSelectionModel([]);
      setOpenModaComDocumento(true);
    }
  };

  const onPressEnviarComDoc = () => {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Seguro que desea compartir sus documentos con los socios seleccionados?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
    });
  };

  return (
    <>
      <ColFlex>
        <ContentOption>
          <RowFlex>
            <SearchInputText
              width="350px"
              placeholder={'Buscar Documentos...'}
              filter={socioState.searchtextHomologacion}
              onChange={(e) => onChangeSearch(e)}
            />
            <ContentButton>
              <RowAccion>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: items(onClickCompartir),
                  }}
                >
                  <ButtonAccion />
                </Dropdown>
                <Button
                  action={(e) => onPressOpenModal(e)}
                  name="Agregar"
                  isAction={true}
                  type="primary"
                />
              </RowAccion>
            </ContentButton>
          </RowFlex>
        </ContentOption>
        <TableGrid
          columns={columns}
          rows={
            socioState.searchtextHomologacionDocumento.length === 0
              ? socioState.listaDocumento
              : socioState.filterListaDocumento
          }
          pageSize={15}
          getRowId={(row) => row.IDAgrupado}
          checkboxSelection={true}
          selectionModel={selectionModel}
          onSelectionModelChange={(oEvent) => selectSetCheck(oEvent)}
        />
        <ModalDocumento
          open={openModal}
          onPressClose={onPressCloseModal}
          onPressSave={onPressSaveModal}
        />
        <ModalArchivo
          open={openModalArchivo}
          onPressClose={onPressCloseModalArchivo}
          listaArchivo={listaArchivos}
        />
        <ModalComDocumento
          open={openModaComDocumento}
          onPressClose={onPressCloseModalComDocumento}
          onPressEnviar={onPressEnviarComDoc}
        />
      </ColFlex>
      {loading === true ? <LoadingSpinner /> : <></>}
    </>
  );
};
