import React, { useEffect, useState } from 'react';
import { Drawer, Modal, Tree } from 'antd';
import { Button, LabelForm, TableGrid } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { ColForm, RowButton, RowForm, Title } from '../../../../styles';
import { faDownload } from '@fortawesome/fontawesome-free-solid';
import { obtenerFile, obtenerSocio, obtenerUrlImg } from '../../../../services/public.service';
import { useFetchAndLoad } from '../../../../common';
import { changeModalComDocumento } from '../../../../redux/states';
import { listarSocioCompartido } from '../../../../adapters';

export const ModalComDocumento = (props) => {
  const socioState = useSelector((store) => store.socio);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (props.open) {
        await loadSocio();
      }
    })();
  }, [props.open]);

  const loadSocio = async () => {
    const responseListaSocio = await callEndpoint(obtenerSocio());
    var listaSocio = [];
    for (let index = 0; index < responseListaSocio.data.respuesta.aLista.length; index++) {
      const element = responseListaSocio.data.respuesta.aLista[index];
      listaSocio.push(listarSocioCompartido(element));
    }
    dispatch(changeModalComDocumento({ listaComSocio: listaSocio }));
  };

  const onDownload = async (e, data) => {
    let url = await obtenerUrlImg(data.URLDocu);
    obtenerFile(url);
  };

  const columns = [
    {
      field: 'Nombre_Socio',
      headerName: 'Nombre',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Documento',
      headerName: 'Número de identificación fiscal',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: 'Nombre_Contacto',
      headerName: 'Contacto',
      flex: 1,
      renderCell: (params) => <>{params.value}</>,
    },
  ];

  const selectSetCheck = (e) => {
    dispatch(changeModalComDocumento({ selectComSocio: e }));
  };

  return (
    <Drawer
      open={props.open}
      title={<Title>{'Compartir Documento'}</Title>}
      placement="right"
      width={'33%'}
      closable={false}
      onClose={props.onPressClose}
      footer={[
        <Button
          action={(oEvent) => props.onPressClose(oEvent)}
          name={'Cerrar'}
          isAction={true}
          type="warning"
        />,
        <Button
          style={{ marginLeft: '5px' }}
          action={(oEvent) => props.onPressEnviar(oEvent)}
          name={'Enviar'}
          isAction={true}
          type="secondary"
        />,
      ]}
    >
      <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
        <Title>Listado de Socios : </Title>
      </ColForm>
      {/* <ColForm xs={24} sm={24} md={24} lg={24} xl={24}>
        <RowForm>
          <TableGrid
            columns={columns}
            rows={socioState.listaComSocio}
            pageSize={15}
            getRowId={(row) => row.IDSocio}
            checkboxSelection={true}
            selectionModel={socioState.selectComSocio}
            onSelectionModelChange={(oEvent) => selectSetCheck(oEvent)}
          />
        </RowForm>
      </ColForm> */}
      <div className="ant-drawer-body" style={{ padding: '0px', height: '82%' }}>
        <Tree
          checkable
          treeData={socioState.listaComSocio}
          showLine={true}
          checkedKeys={socioState.selectComSocio}
          onCheck={(oEvent) => selectSetCheck(oEvent)}
        />
      </div>
    </Drawer>
  );
};
